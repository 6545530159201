import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import tinycolor from 'tinycolor2'



function MetroLineLogo(props) {

  const useStyles = makeStyles({
    root: {
      backgroundColor: (props) => `#${props.color}`,
      fontSize: 10,
      padding: '4px 6px',
      fontWeight: 800,
      color: (props) => tinycolor(props.color).isDark() ? '#FFF' : '#000',
      marginRight: '3px',
      borderRadius: (props) => props.shape === 'square' ? 'none' : '100px',
      display: 'inline-block',
      paddingTop: '6px',
      width: '25px',
      height: '25px',
      textAlign: 'center',
      verticalAlign: 'middle'
    }
  });

  const classes = useStyles(props);

  return (
    <>
      <span className={classes.root}>{props.message}</span>
    </>
  );
};

export default MetroLineLogo;