import React from "react";
import { useState } from "react";
import I18n from "../../../i18n-js/index.js.erb";
import { formatJSTimeLocale } from "../../../helpers/base";
import { makeStyles } from "@material-ui/core/styles";
import Truncate from "react-truncate-html";
import { titleCase } from "../../../helpers/base.js";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

function ReviewBlock(props) {
  const currentLocale = useSelector((state) => state.currentLocale)
  const useStyles = makeStyles((theme) => ({
    reviewBlock: {
      padding: "1rem",
      margin: ".5rem",
      background: "white",
      borderRadius: "10px",
    },
    reviewText: {
      fontSize: "1.5rem",
    },
    reviewInfo: {
      fontSize: "1.2rem",
    },
    sameLineText: {
      display: "inline-block",
      marginRight: "1rem",
    },
    reviewHeader: {
      display: "flex",
      justifyContent: "space-between",
      fontWeight: "1000",
      fontSize: "1.5rem",
    },
    note: {
      color: props.brand ? `${props.brand?.main_color} !important` : '#7d2647',
      fontWeight: 800,
      fontSize: '20px'
    },
    headings: {
      fontFamily: props.brand ? props.brand?.font : 'inherit',
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.reviewBlock}>
        <div className={clsx(classes.reviewHeader, classes.headings)}>
          <p className={classes.note}>{titleCase(props.user_name)}</p>
          <p className={classes.note}>{`${props.note} / 5`}</p>
        </div>
        <p className={classes.reviewText}>
          {props.truncate && (
            <Truncate
              lines={3}
              dangerouslySetInnerHTML={{
                __html: props.description,
              }}
            />
          )}

          {!props.truncate && (
            <p dangerouslySetInnerHTML={{ __html: props.description }} />
          )}
        </p>
        <p className={classes.reviewInfo}
          dangerouslySetInnerHTML={{ __html:
              I18n.t("reviewed_on_html", {
                date: formatJSTimeLocale(props.review_js_time, currentLocale),
                user_name: props.user_name,
                product: props.product_name,
                enjoyed_on: formatJSTimeLocale(props.start_date_js_time, currentLocale)
                })
            }}
        />
      </div>
    </React.Fragment>
  );
}

export default ReviewBlock;
