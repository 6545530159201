import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";

function LazyImage({ lazyImgStyles, src, alt, lqip }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(function () {
        setLoaded(true);
      }, 1500);

    return function cleanup() {
      clearTimeout(timeoutId);
    }
  }, []);
  return (
    <React.Fragment>
      <img
        src={lqip}
        style={lazyImgStyles}
        className={clsx(loaded && "displaynone")}
        aria-hidden="true"
      />
      <img
        loading="lazy"
        style={lazyImgStyles}
        src={src}
        alt={alt}
        className={clsx("source", loaded && "loaded", !loaded && "displaynone")}
      />
    </React.Fragment>
  );
}
export default LazyImage;
