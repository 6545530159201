import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import slugify from 'react-slugify';
import I18n from '../../../i18n-js/index.js.erb'

function CategoryBlock(props) {

  const area = useSelector((state) => state.area)
  const currentLocale = useSelector((state) => state.currentLocale)

  I18n.locale = currentLocale;

  const useStyles = makeStyles((theme) => ({
    root: {},
    category: {
      marginBottom: "50px",
      position: "relative",
      width: "49%",
      flex: "0 0 100%",
      paddingBottom: "1rem",
      maxWidth: "49%",
    },
    categoryLarge: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingBottom: "4rem",
      flex: "0 0 50%",
      maxWidth: "23.5%",
      cursor: "pointer",
    },
    categoryLargeCaption: {
      position: "absolute",
      bottom: "-30px",
      width: "100%",
      background: "#F4A583",
      color: "#7d2647",
      borderBottomRightRadius: "10px",
      borderBottomLeftRadius: "10px",
      height: "80px",
      padding: "24px 10px",
      fontFamily: "TobiasTRIAL-Regular",
      textAlign: "center",
      fontSize: "20px",
      cursor: 'pointer'
    },
    categoryCaption: {
      position: "absolute",
      bottom: "-30px",
      width: "100%",
      background: "#F4A583",
      color: "#7d2647",
      borderBottomRightRadius: "10px",
      borderBottomLeftRadius: "10px",
      height: "80px",
      padding: "12px 10px",
      fontFamily: "TobiasTRIAL-Regular",
      textAlign: "center",
      fontSize: "20px",
      cursor: 'pointer'
    },
    provinceNameLarge: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      top: 0,
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: "3rem",
      paddingBlockEnd: "4rem",
      paddingRight: "4rem",
    },
    provinceImageLarge: {
      width: "100%",
      height: "250px",
      minHeight: "250px",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    provinceImage: {
      width: "100%",
      height: "170px",
      minHeight: "170px",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  }));

  const classes = useStyles();

  const handleRedirect = (slug) => {
    location.href = location.origin + "/" + currentLocale + "/" + handleParams(slug, area)
  }

  const handleParams = (slug, area) => {
    console.log(slug)
    return slug.includes('&') ? `experiences?${slug}` : slug === 'Christmas' ? I18n.t("routes.create_gift") : slugify(area) + "/" + slugify(slug);
  }

  return (
    <li
      className={props.large ? classes.categoryLarge : classes.category}
      onClick={props.function ? props.function : () => handleRedirect(props.slug)}
    >
      <figure>
        <div
          className={props.large ? classes.provinceImageLarge : classes.provinceImage}
          style={{
            backgroundImage:
              `url(${props.image})`,
          }}
        >
          {props.slug === 'Christmas' && <span className='category new' style={{top: "10px",bottom: "unset",textTransform: "uppercase",fontWeight: "800"}}>{I18n.t("gift_voucher")}</span>}
        </div>
        <div
          className={props.text.length > 20 ? classes.categoryCaption : classes.categoryLargeCaption}
        >
          {props.text}
        </div>
        <figcaption
          className={classes.provinceNameLarge}
        ></figcaption>
      </figure>
    </li>
  )
}

export default CategoryBlock;
