import React from "react";
import FavoriteOff from "./favoritebtn/favoriteoff";
import FavoriteOn from "./favoritebtn/favoriteon";
import FavoriteOffNoUser from "./favoritebtn/favoriteoffnouser";
import PropTypes from "prop-types";
import axios from "axios";

class AddFavorite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      favoriteBtnToggle: [this.props.favoriteids].flat().includes(this.props.roomid),
    };
  }

  handleClick = (ev, value) => {
    ev.preventDefault();

    if (value === "BtnOff") {
      axios
        .post("/es/create_favorite", { slug: this.props.slug })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post("/es/destroy_favorite", { id: this.props.slug })
        .catch(function (error) {
          console.log(error);
        });
    }

    this.setState((state) => ({
      favoriteBtnToggle: !state.favoriteBtnToggle,
    }));
  };

  renderFavoriteButton = (favoriteBtnToggle) => {
    if (favoriteBtnToggle && this.props.current_user) {
      return <FavoriteOn handleChange={this.handleClick}></FavoriteOn>;
    } else if (!favoriteBtnToggle && this.props.current_user) {
      return <FavoriteOff handleChange={this.handleClick}></FavoriteOff>;
    } else {
      return <FavoriteOffNoUser></FavoriteOffNoUser>;
    }
  };

  render() {
    return this.renderFavoriteButton(this.state.favoriteBtnToggle);
  }
}

export default AddFavorite;
