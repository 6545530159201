import React, { useEffect } from "react";
import I18n from "../../i18n-js/index.js.erb";
import { useSelector } from "react-redux";
import ReservationUserFormFunnel from "./components/users/ReservationUserFormFunnel";

function UserStep(props) {
  const currentLocale = useSelector((state) => state.currentLocale);

  I18n.locale = currentLocale || props.locale;

  useEffect(() => { }, []);

  return (
    <React.Fragment>
      <br />
      <div className="col-md-7 checkout_column">
        <h4>
          <span className="number_step">1</span>&nbsp;&nbsp;{" "}
          {I18n.t("checkout_first_step")}
        </h4>

        <ReservationUserFormFunnel {...props} />
      </div>

      {false && (
        <form onSubmit={handleSubmit}>
          <div className="col-md-7 checkout_column">
            <h4>
              <span className="number_step">1</span>&nbsp;&nbsp;{" "}
              {I18n.t("checkout_first_step")}
            </h4>
          </div>
        </form>
      )}
    </React.Fragment>
  );
}

export default UserStep;
