import React from "react";
import { useState, useEffect } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useStylesPayment } from "../styles/styles";
import I18n from "../../../../i18n-js/index.js.erb";
import WidgetLoader from "../../WidgetLoader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { setStepsVoucherCreationParams } from "../../../redux/actions/actions";

const schema = yup.object({
  resevation_conditions: yup.boolean().oneOf([true], I18n.t("is_required")),
});

let conditionsCheck = {
  float: "left",
};

let labelNoMarginTop = {
  marginTop: "2px",
};
function WidgetPayment(props) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [price, setPrice] = useState();
  const [elementLoad, setElementLoad] = useState(true);
  const clientSecret = useSelector((state) => state.clientSecret);
  const currentLocale = useSelector((state) => state.currentLocale);
  const currentUser = useSelector((state) => state.currentUser);

  const currentCountry = currentUser?.isInformationComplete?.country_code ? currentUser.isInformationComplete.country_code : 'ES'

  const paymentElementOptions = {
    defaultValues: {
      billingDetails: {
        address: {
          country: currentCountry
        }
      }
    }
  }

  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const {
    register,
    watch,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const classes = useStylesPayment();

  useEffect(() => {
    setTimeout(() => {
      if (clientSecret) {
        setElementLoad(false);
      } else {
        props.handleBack();
      }
    }, 1500);
    setPrice(
      stepsVoucherCreationParams.step2.evoucher
        ? stepsVoucherCreationParams.step1.price
        : stepsVoucherCreationParams.step1.price + 4
    );
  }, [stripe]);

  useEffect(() => {
    if (!stepsVoucherCreationParams.step6.status) {
      axios
        .post("/api/main/tracking/checkouts/viewed.json", {
          params: { step: 6, step_name: "payment" },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) { });
    }
  }, []);

  const onSubmit = (event) => {
    Object.keys(errors).length === 0 && initPayment(event);
  };

  const initPayment = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      redirect: "if_required",
    });

    if (error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(error.message);

      dispatch(
        setStepsVoucherCreationParams({
          ...stepsVoucherCreationParams,
          step6: {
            status: true,
          },
        })
      );
    } else {
      dispatch(
        setStepsVoucherCreationParams({
          ...stepsVoucherCreationParams,
          step6: {
            status: true,
          },
        })
      );

      axios
        .post("/api/main/tracking/checkouts/completed.json", {
          params: { step: 6, step_name: "payment" },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) { });

      window.location.replace(
        `/${currentLocale}/checkout?payment_intent=${stepsVoucherCreationParams.step5.payment_intent}&payment_intent_client_secret=${clientSecret}redirect_status=succeeded`
      );

    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {elementLoad ? (
        <WidgetLoader {...props} />
      ) : (
        <>
          <PaymentElement id="payment-element" options={paymentElementOptions}
            onBlur={() => analyticsTrack("Filled CardElement", {})}
            onFocus={() => analyticsTrack("Entered CardElement", {})}
            style={{ height: "auto !important", padding: "0 !important" }} />
          {cardError && <p className={classes.error}>{cardError}</p>}

          <br />
          <label
            htmlFor={"reservation_conditions"}
            style={labelNoMarginTop}
            className={clsx(errors.resevation_conditions && "error")}
            dangerouslySetInnerHTML={{
              __html: I18n.t("condiciones_widget_checkout_html"),
            }}
          />
          <input
            type="checkbox"
            name="reservation_conditions"
            {...register("resevation_conditions")}
            id="reservation_conditions"
            style={conditionsCheck}
            className={clsx(
              "form-control",
              errors.resevation_conditions && "error"
            )}
          />

          <button
            className={clsx("btn btn-primary", classes.paymentButton)}
            disabled={!stripe}
          >
            {I18n.t("pay_button", {
              price: price,
              currency: "€",
            })}
          </button>
        </>
      )}
    </form>
  );
}

export default WidgetPayment;
