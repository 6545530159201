import React from "react";

function WidgetLoader(props) {
  return (
    <React.Fragment>
      <div className="loading__container">
        {props?.brand?.logo && (
          <img
            className="loading__hotelbreak"
            alt={"Loading logo"}
            src={props?.brand?.logo}
            data-original={props?.brand?.logo}
            style={props.innerStyle}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default WidgetLoader;
