import React, { useEffect, useState, useRef }   from 'react';
import RichTextEditor from 'react-rte';

let editorArea = {
	height: '200px'
}

let toolbarStyle = {
  zIndex: '1 !important'
}

function TextEditor(props) {
	const [editorState, setEditorState] = useState(
    props.editorValue === undefined ?
    RichTextEditor.createEmptyValue() :
    RichTextEditor.createValueFromString(props.editorValue.toString(), 'html')
  )

	const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display:
      props.seo ?
        ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN']
      :
        ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS']
      ,
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'hotel-toolbar'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'H2', style: 'header-two'},
      {label: 'H3', style: 'header-three'},
      {label: 'H4', style: 'header-four'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ],
    className: 'hotel-toolbar'
  };

  const onChange = (value) => {
    setEditorState(value);
    if (props.handleTextEditorChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      props.handleTextEditorChange(
        value.toString('html'), props.language
      );
    }
  };

  
	
	return (
		<div className = 'MyEditor'>
			<RichTextEditor
				value={editorState}
        onChange={onChange}
				toolbarConfig={toolbarConfig}
				placeholder={props.placeholder}
				editorStyle={editorArea}
        toolbarStyle={toolbarStyle}
			/>
		</div>
	);
}

export default TextEditor;