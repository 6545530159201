import React from "react";
import { useState } from "react";
import ProductBlockFull from "../../products/ProductBlockFull";
import { currencyToSymbol, to_eur } from "../../../../helpers/base.js";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import slugify from "react-slugify";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Fab from "@material-ui/core/Fab";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import moment from "moment";
import AppsIcon from "@mui/icons-material/Apps";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";

function MarketingLandingProductSlide(props) {
  const [mode, setMode] = useState("grid");

  const [hasLeftScroll, setHasLeftScroll] = useState(false);
  const [hasRightScroll, setHasRightScroll] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      overflow: "hidden",
      margin: "20px 0 0",
      padding: "0 10px",
    },
    gridList: {
      flexWrap: mode === "list" ? "nowrap" : "wrap",
      width: mode === "list" ? "initial" : "100%",
    },
    title: {
      paddingLeft: "10px",
    },
    buttonLeft: {
      position: "absolute",
      marginTop: "15%",
      marginLeft: "20px",
      zIndex: "10",
      background: "white",
    },
    buttonRight: {
      position: "absolute",
      zIndex: "10",
      right: "10px",
      background: "white",
      marginTop: "15%",
      marginRight: "10px",
    },
    modeButton: {
      float: "right",
      marginTop: "-50px",
      position: "relative",
      right: mode === "list" ? "25px" : "-12px",
    },
  }));

  const classes = useStyles();

  const scrollRight = () => {
    $(`#gridListScroll-${props.selector}`).animate(
      {
        scrollLeft: "+=400px",
      },
      "slow"
    );
    setHasLeftScroll(true);
    if (
      $(`#gridListScroll-${props.selector}`).get(0).scrollWidth -
        $(`#gridListScroll-${props.selector}`).scrollLeft() -
        $(`#gridListScroll-${props.selector}`).width() -
        400 <=
      0
    ) {
      setHasRightScroll(false);
    }
  };

  const scrollLeft = () => {
    $(`#gridListScroll-${props.selector}`).animate(
      {
        scrollLeft: "-=400px",
      },
      "slow"
    );
    setHasRightScroll(true);
    if ($(`#gridListScroll-${props.selector}`).scrollLeft() - 400 <= 0) {
      setHasLeftScroll(false);
    }
  };

  const handleMode = () => {
    analytics.track("Changed Product Mode", { mode: mode });
    mode === "list" ? setMode("grid") : setMode("list");
  };

  return (
    <React.Fragment>
      {props.products.length > 0 ? (
        <>
          <ImageList
            className={classes.gridList}
            cols={
              mode && mode === "list"
                ? props.listCols
                : Math.floor(props.listCols)
            }
            rowHeight="auto"
            id={`gridListScroll-${props.selector}`}
          >
            {props.products.map((product) => {
              return (
                <ImageListItem key={product.id}>
                  <ProductBlockFull
                    key={product.id}
                    id={product.id}
                    name={product.attributes.name}
                    block_photo={product.attributes.blockPhoto}
                    week_price={to_eur(product.attributes.pvp)}
                    base_week_price={to_eur(product.attributes.basePvp)}
                    listing_name={product.attributes.hotel}
                    star_rating={product.attributes.hotelStarRating}
                    city={product.attributes.city}
                    area={slugify(product.attributes.province)}
                    province_name={product.attributes.province}
                    max_guest={product.attributes.maxGuest ? product.attributes.maxGuest : 1}
                    price_model={product.attributes.priceModel ? product.attributes.priceModel : "pvp"}
                    currency={currencyToSymbol(product.attributes.currency)}
                    slug={product.attributes.slug}
                    productSlug={slugify(product.attributes.name)}
                    roomSlug={product.attributes.hotelSlug}
                    modal={props.modal}
                    category={product.attributes.category}
                    is_new={
                      moment(product.attributes.bookableAt || product.attributes.createdAt) >
                      moment().subtract(2, "months")
                    }
                    has_calendar={product.attributes.has_calendar}
                    brand={props.brand}
                    widget={props.widget && props.widget}
                    forceOpenId={props.forceOpenId}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        </>
      ) : (
        <Skeleton variant="rect" width="100%" height="20rem" />
      )}
    </React.Fragment>
  );
}

export default MarketingLandingProductSlide;
