import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createMedia } from "@artsy/fresnel";
import I18n from "../../../i18n-js/index.js.erb";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ProductForm from "./steps/step1/ProductForm";
import ChooseDesign from "./steps/step3/ChooseDesign";
import CustomizeVoucher from "./steps/step4/CustomizeVoucher";
import PreviewVoucher from "./steps/step5/PreviewVoucher";
import WidgetPayment from "./WidgetPayments/WidgetPayment";
import { setClientSecret } from "../../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import EllipsisText from "react-ellipsis-text";
import axios from "axios";
import { setStepsVoucherCreationParams } from "../../redux/actions/actions";

const setApiKey = () => {
  if (process.env.RAILS_ENV !== "production") {
    return "pk_test_t6NYOy0tevDzxRtvhhdUSHiU";
  } else {
    return process.env.PUBLISHABLE_KEY;
  }
};

const stripePromise = loadStripe(setApiKey());

function WidgetWalkthrough(props) {
  const [activeStep, setActiveStep] = useState(0);
  const product = window.location.pathname.split("/").pop();

  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const dispatch = useDispatch();

  const clientSecret = useSelector((state) => state.clientSecret);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBackByStep = (step) => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const currentUser = useSelector((state) => state.currentUser);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      overflow: "hidden",
      margin: "20px 0 0",
      padding: "0 10%",
    },
    sideBlock: {
      maxWidth: "90%",
      background: "rgb(240, 240, 240)",
      width: "90%",
      borderRadius: "10px",
      marginTop: "20px",
      padding: "10px 35px",
      position: "-webkit-sticky",
      position: "sticky",
      top: "20px",
    },
    sideImage: {
      width: "100%",
      margin: "10px auto",
      display: "block",
    },
    penEdit: {
      display: "inline-block",
      float: "right",
      color: "rgba(0,0,0,0.5)",
      fontSize: "1.5rem",
      cursor: "pointer",
      marginTop: "8px",
      marginLeft: "10px",
    },
    iconStep: {
      width: "30px !important",
      height: "30px !important",
      "& *": {
        fontSize: "12px !important",
        fontFamily: "Open Sans !important",
      },
    },
    iconStepValid: {
      color: "#6BD098 !important",
    },
    stepLabelRightText: {
      float: "right",
    },
    cardDesign: {
      width: "250px !important",
      maxWidth: "250px !important",
      height: "300px !important",
      margin: "0 auto 20px auto",
      borderRadius: "5px",
      padding: "20px",
      backgroundPosition: "center",
    },
  }));

  const classes = useStyles();

  const currentStepResumen = (index) => {
    switch (index) {
      case 0:
        if (stepsVoucherCreationParams.step1.status) {
          return (
            <small>
              <EllipsisText
                text={stepsVoucherCreationParams.step1.productInfo.name}
                length={"30"}
              />
            </small>
          );
        }
      case 1:
        if (stepsVoucherCreationParams.step3.status) {
          if (stepsVoucherCreationParams.step3.custom.status) {
            return <small>Tu propia foto</small>;
          } else {
            if (stepsVoucherCreationParams.step3.design !== undefined) {
              return (
                <small>
                  {!stepsVoucherCreationParams.step3.custom_design_id &&
                    I18n.t(
                      "new_gift__print." +
                      stepsVoucherCreationParams.step3.design
                    )}

                  {stepsVoucherCreationParams.step3.custom_design_id &&
                    stepsVoucherCreationParams.step3.design}
                </small>
              );
            }
          }
        }
    }
  };

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  const options = {
    clientSecret: clientSecret,
    paymentMethodCreation: 'manual',
    locale: currentLocale,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#30313d',
        fontFamily: 'Tobias, Times New Roman, serif',
        borderRadius: "4px",
        colorText: '#30313d',
        colorBackground: '#fff',
      }
    }
  }

  const handleClientSecret = (clientSecret) => {
    dispatch(setClientSecret(clientSecret));
  };

  const steps = [
    {
      label: I18n.t("widget_voucher__select_experience"),
      template: (
        <ProductForm
          handleBack={handleBack}
          handleNext={handleNext}
          mobile={false}
          product={product}
          brand={props.brand}
        />
      ),
      mobileTemplate: (
        <ProductForm
          handleBack={handleBack}
          handleNext={handleNext}
          mobile={true}
          product={product}
          brand={props.brand}
        />
      ),
    },

    {
      label: I18n.t("new_gift__select_photo"),
      template: (
        <ChooseDesign handleBack={handleBack} handleNext={handleNext} />
      ),
      mobileTemplate: (
        <ChooseDesign handleBack={handleBack} handleNext={handleNext} />
      ),
    },

    {
      label: I18n.t("new_gift__customize"),
      template: (
        <CustomizeVoucher
          handleBack={handleBack}
          handleNext={handleNext}
          mobile={false}
        />
      ),
      mobileTemplate: (
        <CustomizeVoucher
          handleBack={handleBack}
          handleNext={handleNext}
          mobile={true}
        />
      ),
    },
    {
      label: I18n.t("new_gift__preview"),
      template: (
        <PreviewVoucher
          handleBack={handleBack}
          handleNext={handleNext}
          handleClientSecret={handleClientSecret}
          brand={props.brand}
        />
      ),
      mobileTemplate: (
        <PreviewVoucher
          handleBack={handleBack}
          handleNext={handleNext}
          handleClientSecret={handleClientSecret}
          brand={props.brand}
        />
      ),
    },

    {
      label: I18n.t("about_you_gift_card"),
      template: (
        <React.Fragment>
          <Elements stripe={stripePromise} options={options}>
            <WidgetPayment {...props} handleBack={handleBack} />
          </Elements>
        </React.Fragment>
      ),
      mobileTemplate: (
        <React.Fragment>
          <Elements stripe={stripePromise} options={options}>
            <WidgetPayment {...props} handleBack={handleBack} />
          </Elements>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    axios
      .get("/widget/widget_voucher_configurations/config")
      .then((response) => {
        console.log(response);
        dispatch(
          setStepsVoucherCreationParams({
            ...stepsVoucherCreationParams,
            images: {
              ...response.data.data.attributes,
            },
          })
        );
      })
      .catch(function (error) { });
  }, []);

  useEffect(() => {
    currentUser &&
      currentUser.id &&
      Object.values(stepsVoucherCreationParams)
        .flatMap((x) => x["status"])
        .lastIndexOf(true) > 0 &&
      setActiveStep(
        Object.values(stepsVoucherCreationParams)
          .flatMap((x) => x["status"])
          .lastIndexOf(true) + 1
      );
  }, [currentUser]);

  return (
    <>
      <MediaContextProvider>
        <Media at="sm">
          <div className={classes.root}>
            <Box sx={{ width: "80vw" }}>
              <Stepper
                activeStep={activeStep}
                disabled={-1}
                orientation="vertical"
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          root: classes.iconStep,
                          completed: classes.iconStepValid,
                        },
                      }}
                      optional={
                        index === 5 ? (
                          <Typography variant="caption"></Typography>
                        ) : null
                      }
                    >
                      <span>
                        <h3>
                          {step.label}
                          {activeStep > index && (
                            <i
                              onClick={() => handleBackByStep(index)}
                              className={`fas fa-pen ${classes.penEdit}`}
                            ></i>
                          )}
                          <span className={classes.stepLabelRightText}>
                            {currentStepResumen(index)}
                          </span>
                        </h3>
                      </span>
                    </StepLabel>
                    <StepContent>
                      <div>{step.mobileTemplate}</div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Box>
          </div>
        </Media>
        <Media at="md">
          <div className={classes.root}>
            <Box sx={{ width: "80vw" }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          root: classes.iconStep,
                          completed: classes.iconStepValid,
                        },
                      }}
                      optional={
                        index === 5 ? (
                          <Typography variant="caption"></Typography>
                        ) : null
                      }
                    >
                      <span>
                        <h3>
                          {step.label}
                          {activeStep > index && (
                            <i
                              onClick={() => handleBackByStep(index)}
                              className={`fas fa-pen ${classes.penEdit}`}
                            ></i>
                          )}
                          <span className={classes.stepLabelRightText}>
                            {currentStepResumen(index)}
                          </span>
                        </h3>
                      </span>
                    </StepLabel>
                    <StepContent>
                      <div>{step.mobileTemplate}</div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Box>
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <div className={classes.root}>
            <Box sx={{ width: "30vw" }}>
              <div className={classes.sideBlock}>
                <h3>{I18n.t("widget_voucher_how")}</h3>
                {/* <img
                  src="https://testmaxime.s3.us-east-2.amazonaws.com/photos/images/default/gift/evoucher.png"
                  className={classes.sideImage}
                /> */}
                <ol>
                  <li>{I18n.t("widget_voucher_usp_1")}</li>
                  <li>{I18n.t("widget_voucher_usp_2")}</li>
                  <li>{I18n.t("widget_voucher_usp_3")}</li>
                </ol>
              </div>
            </Box>
            <Box sx={{ width: "49vw" }}>
              <Stepper
                activeStep={activeStep}
                disabled={-1}
                orientation="vertical"
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          root: classes.iconStep,
                          completed: classes.iconStepValid,
                        },
                      }}
                      optional={
                        index === 5 ? (
                          <Typography variant="caption"></Typography>
                        ) : null
                      }
                    >
                      <span>
                        <h3>
                          {step.label}
                          {activeStep > index && -1 !== index && (
                            <i
                              onClick={() => handleBackByStep(index)}
                              className={`fas fa-pen ${classes.penEdit}`}
                            ></i>
                          )}
                          <span className={classes.stepLabelRightText}>
                            {currentStepResumen(index)}
                          </span>
                        </h3>
                      </span>
                    </StepLabel>
                    <StepContent>
                      <div>{step.template}</div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Box>
          </div>
        </Media>
      </MediaContextProvider>
    </>
  );
}

export default WidgetWalkthrough;
