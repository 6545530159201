import React from "react";
import I18n from "../../../../i18n-js/index.js.erb";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { setPaymentData } from "../../../redux/actions/actions";

function LoginButtons(props) {
  const useStyles = makeStyles((theme) => ({
    thirdWith: {
      minWidth: "30% !important",
    },
    socialAuth: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  }));

  const handleLogin = () => {
    dispatch(
      setPaymentData({
        ...FunnelData,
        log_in_form: !FunnelData.login_in_form,
      })
    );

    analyticsTrack("Login with hotelbreak", {
      form: props.source,
    });
  };

  const analyticsTrack = (eventType, inputHash) => {
    analytics.track(eventType, {
      inputHash,
      form: props.source,
    });
  };

  const classes = useStyles();

  const dispatch = useDispatch();
  const FunnelData = useSelector((state) => state.PaymentData);
  const currentLocale = useSelector((state) => state.currentLocale);

  return (
    <div id="loginButton">
      <div className="col-md-12 checkout_login no-padding">
        <div className={classes.socialAuth}>
          <div className="col-md-12 col-xs-12 no-padding">
            <p>{I18n.t("sign_in_form_title")}</p>
          </div>
          <a
            href="/auth/google_oauth2"
            onClick={(e) => {
              e.preventDefault();
              const state = btoa(JSON.stringify({ locale: currentLocale }));
              window.location.href = `/auth/google_oauth2?state=${encodeURIComponent(state)}`;
              analyticsTrack("Login with google", { form: props.source });
            }} 
            className={clsx(
              "btn btn-primary google no-padding",
              classes.thirdWith
            )}
          >
            <span className="fab fa-google"></span>{" "}
            {I18n.t("sign_up_with", { media: "Google" })}
          </a>
          {/* <a
            href="/auth/facebook"
            onClick={() =>
              analyticsTrack("Login with facebook", {
                form: props.source,
              })
            }
            className={clsx(
              "btn btn-primary facebook no-padding",
              classes.thirdWith
            )}
          >
            <span className="fab fa-facebook"></span>{" "}
            {I18n.t("sign_up_with", { media: "Facebook" })}
          </a> */}
          <span
            onClick={() => handleLogin()}
            className={clsx(
              "btn btn-primary email no-padding",
              classes.thirdWith
            )}
          >
            <span className="fa fa-envelope"></span>{" "}
            {I18n.t("sign_up_with", { media: "Email" })}
          </span>
        </div>
        <br />
      </div>
    </div>
  );
}

export default LoginButtons;
