import React from "react";
import LazyImage from "../../utilities/lazyload";
import Rectangle from "images/Rectangle.png";
import { useState } from "react";
import { capitalizeFirstLetter } from "../../../helpers/base";
import I18n from "../../../i18n-js/index.js.erb";
import Moment from "moment";
import { NavLink } from "react-router-dom";
import AddFavorite from "../../addfavorite"

let noMarginTop = {
  marginTop: "0",
};

let prodNameStyles = {
  color: "grey",
  paddingTop: "15px",
};

let prodPriceStyles = {
  display: "block",
  margin: "0 10px 0 0",
  color: "rgb(45,45,45)",
};

let notAvailableStyles = {
  display: "block",
  margin: "0 10px 0 0",
  fontWeight: "800",
};

let listingNameStyles = {
  fontWeight: "600 !important",
  color: "rgb(45,45,45)",
  fontSize: "14px",
};

let cityAreaStyles = {
  fontSize: "14px",
};

let lazyImgStyles = {
  height: "20rem",
  width: "100%",
  borderRadius: "10px",
  objectFit: "cover",
};

function HotelBlockFull(props) {
  const [locale, setLocale] = useState(props.current_locale);

  return (
    <React.Fragment>
      <NavLink
        to={`/${props.area}/hotels/${props.slug}`}
        className="toRoom marginbottom"
        alt={props.name}
        data-price={props.week_price}
        data-position={props.index}
        target="_blank"
      >
        <div className="row result-hotel no-gutters" style={rowStyles}>

          <div className="col-md-12">
            
            { props.category && 
                  <span className={`category ${props.category}`}>

                    { typeof props.category === 'string' && I18n.t(props.category)}

                    </span>													
            }

            {   props.is_new &&
            <span className="category new">{"NEW"}</span>
            }

            {
              props.direct_booking && !props.is_closed_today &&
              <span className="category direct_booking"><i className="fa fa-bolt"></i>  {I18n.t('direct_booking')} </span>
            }
            {  
            props.block_photo && 
            <LazyImage
              src = {`${props.block_photo}`}
              lqip = {Rectangle}
              alt = {props.listing_name}
              lazyImgStyles = {lazyImgStyles}
            > </LazyImage>
            }

          </div>

          <div className="col-md-12" style={colStyles}>
              <div className="col-md-8 col-xs-8 no-padding">
              {

                props.city_old_name && props.area &&
                <span style={locationStyle}>{props.city_old_name} · {props.area}</span>

              }
              </div>


              {
                props.review_note > 0 &&
                props.review_note > 300 &&
                <div className="col-md-4 col-xs-4 no-padding">
                  <span className="note">{(props.review_note/100).toFixed(2)}<small>&nbsp;({props.reviews_count })</small></span>
                </div>  }

              <div className="col-md-12 col-xs-12 no-padding product_cat">
                    <span style={productCat}>
                            {props.listing_name}

                              <small>&nbsp;
                                {props.star_rating &&
                                '*'.repeat(props.star_rating)
                                }
                              </small>
                            </span><br></br>
                              
                            {translate_products(props.product_categories, locale).join(" • ")}
              </div>

              <div className="col-md-12 col-xs-12 no-padding">	
                <p className={clsx(props.is_closed_today && "displaynone")} style={priceStyle}>
                
                  { props.price_model === 'net' && I18n.t('price_from', {currency: props.currency, price: add_margin(props.price, props.margin) } ) }
                  { props.price_model === 'pvp' && I18n.t('price_from', {currency: props.currency, price: props.price } ) }
                  &nbsp;

                  { props.min_price_max_guest == 1 && I18n.t('per_person')}
                  { props.min_price_max_guest > 1 && I18n.t('for_various_number', {number: props.min_price_max_guest})}
                  
                </p>

                <p className={clsx(!props.is_closed_today && "displaynone")} style={notAvailableStyles}>
                  { I18n.t('not_available') }
                </p>
              </div>
          </div>

        </div>
        <AddFavorite
          slug = {props.slug}
          favoriteids = {props.favoriteids}
          roomid = {props.roomid}
          current_user = {props.current_user}
          csrf_token={props.csrf_token}
        />
      </NavLink>
    </React.Fragment>
  );
}

export default HotelBlockFull;
