import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createMedia } from "@artsy/fresnel";
import I18n from "../../../i18n-js/index.js.erb";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ProductStep from "./steps/step1_ProductForm/ProductForm";
import PriceSelection from "./steps/step1_PriceSelection/PriceSelection";
import ChooseDesign from "./steps/step3/ChooseDesign";
import CustomizeVoucher from "./steps/step4/CustomizeVoucher";
import PreviewVoucher from "./steps/step5/PreviewVoucher";
import Payment from "../payments/Payment";
import { setClientSecret } from "../../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const setApiKey = () => {
  if (process.env.RAILS_ENV !== 'production') {
    return 'pk_test_t6NYOy0tevDzxRtvhhdUSHiU'
  } else {
    return process.env.PUBLISHABLE_KEY
  }
}

const stripePromise = loadStripe(setApiKey());

function WalkthroughReorder(props) {
  const queryString = require('query-string');
  const [activeStep, setActiveStep] = useState(0);
  const [disabledStep, setDisabledStep] = useState(-1);
  const [product, setProduct] = useState();

  useEffect(() => {
    queryString.parse(location.search).product_id != undefined &&
      setProduct(parseInt(queryString.parse(location.search).product_id));
  }, [])

  const stepsVoucherCreationParams = useSelector((state) => state.stepsVoucherCreationParams);
  const currentLocale = useSelector((state) => state.currentLocale);

  const dispatch = useDispatch();

  const clientSecret = useSelector((state) => state.clientSecret);

  const options = {
    clientSecret: clientSecret,
    paymentMethodCreation: 'manual',
    locale: currentLocale,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#30313d',
        fontFamily: 'Tobias, Times New Roman, serif',
        borderRadius: "4px",
        colorText: '#30313d',
        colorBackground: '#fff',
      }
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const jumpOver = () => {
    setDisabledStep(activeStep + 1);
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBackByStep = (step) => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const currentUser = useSelector(
    (state) => state.currentUser
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      overflow: "hidden",
      margin: "20px 0 0",
      padding: "0 10%",
    },
    sideBlock: {
      maxWidth: '90%',
      background: 'rgb(240, 240, 240)',
      width: '90%',
      borderRadius: '10px',
      marginTop: '20px',
      padding: '10px 35px',
      position: '-webkit-sticky',
      position: 'sticky',
      top: '20px',
    },
    sideImage: {
      width: '100%',
      margin: '10px auto',
      display: 'block',
    },
    penEdit: {
      display: 'inline-block',
      float: 'right',
      color: 'rgba(0,0,0,0.5)',
      fontSize: '1.5rem',
      cursor: 'pointer',
      marginTop: '8px',
      marginLeft: '10px'
    },
    iconStep: {
      width: '30px !important',
      height: '30px !important',
      '& *': {
        fontSize: '12px !important',
        fontFamily: 'Open Sans !important'
      }
    },
    iconStepValid: {
      color: '#6BD098 !important'
    },
    stepLabelRightText: {
      float: 'right'
    },
    cardDesign: {
      width: '250px !important',
      maxWidth: '250px !important',
      height: '300px !important',
      margin: '0 auto 20px auto',
      borderRadius: '5px',
      padding: '20px',
      backgroundPosition: 'center'
    },
  }));

  const classes = useStyles();

  const currentStepResumen = (index) => {
    switch (index) {
      case 1:
        if (stepsVoucherCreationParams.step1.status) {
          return <small>{stepsVoucherCreationParams.step1.price}€</small>
        }
      // case 1:
      //   if (stepsVoucherCreationParams.step2.status) {
      //     if (stepsVoucherCreationParams.step2.evoucher) {
      //       return <small>{I18n.t('gift_voucher_format_print')}</small>
      //     } else {
      //       return <small>{I18n.t('gift_voucher_format_send')}</small>
      //     }
      //   }
      case 0:
        if (stepsVoucherCreationParams.step3.status) {
          if (stepsVoucherCreationParams.step3.custom.status) {
            return <small>{I18n.t("new_gift__your_image")}</small>
          } else {
            if (stepsVoucherCreationParams.step3.design != undefined) {
              return <small>{I18n.t("new_gift__print." + stepsVoucherCreationParams.step3.design)}</small>
            }
          }
        }
    }
  }

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  const handleClientSecret = (clientSecret) => {
    dispatch(setClientSecret(clientSecret));
  };

  const steps = [
    {
      label: I18n.t('new_gift__select_photo'),
      template: (
        <ChooseDesign handleBack={handleBack} handleNext={handleNext} />
      ),
      mobileTemplate: (
        <ChooseDesign handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      label: I18n.t('new_gift__select_amount'),
      template: (
        <PriceSelection handleBack={handleBack} handleNext={handleNext} mobile={false}  />
      ),
      mobileTemplate: (
        <PriceSelection handleBack={handleBack} handleNext={handleNext} mobile={true}  />
      ),
      fromProductTemplate: (
        <ProductStep handleBack={handleBack} handleNext={handleNext} mobile={false} product={product}  />
      ),
      fromProductTemplateMobile: (
        <ProductStep handleBack={handleBack} handleNext={handleNext} mobile={true} product={product}  />
      )
    },
    // {
    //   label: I18n.t('new_gift__select_format'),
    //   template: (
    //     <ChooseFormat handleBack={handleBack} handleNext={handleNext} jumpOver={jumpOver} mobile={false} />
    //   ),
    //   mobileTemplate: (
    //     <ChooseFormat handleBack={handleBack} handleNext={handleNext} jumpOver={jumpOver} mobile={true} />
    //   )
    // },
    

    {
      label: I18n.t('new_gift__customize'),
      template: (
        <CustomizeVoucher handleBack={handleBack} handleNext={handleNext} mobile={false}  />
      ),
      mobileTemplate: (
        <CustomizeVoucher handleBack={handleBack} handleNext={handleNext} mobile={true}  />
      ),
    },
    {
      label: I18n.t('new_gift__preview'),
      template: (
        <PreviewVoucher
          handleBack={handleBack}
          handleNext={handleNext}
          handleClientSecret={handleClientSecret}
          
        />
      ),
      mobileTemplate: (
        <PreviewVoucher
          handleBack={handleBack}
          handleNext={handleNext}
          handleClientSecret={handleClientSecret}
          
        />
      )
    },

    {
      label: I18n.t('about_you_gift_card'),
      template: (
        <React.Fragment>
          <Elements stripe={stripePromise} options={options}>
            <Payment  />
          </Elements>
        </React.Fragment>
      ),
      mobileTemplate: (
        <React.Fragment>
          <Elements stripe={stripePromise} options={options}>
            <Payment  />
          </Elements>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    currentUser && currentUser.id &&
      Object.values(stepsVoucherCreationParams).flatMap(x => x["status"]).lastIndexOf(true) > 0 &&
      setActiveStep(Object.values(stepsVoucherCreationParams).flatMap(x => x["status"]).lastIndexOf(true))
  }, [currentUser])


  return (
    <>
      <MediaContextProvider>
        <Media at="sm">
          <div className={classes.root}>
            <Box sx={{ width: '80vw' }}>
              <Stepper activeStep={activeStep} disabled={disabledStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          root: classes.iconStep,
                          completed: classes.iconStepValid
                        }
                      }}
                      optional={
                        index === 5 ? (
                          <Typography variant="caption"></Typography>
                        ) : null
                      }
                    >
                      <span>
                        <h3>
                          {step.label}
                          {(activeStep > index) && <i onClick={() => handleBackByStep(index)} className={`fas fa-pen ${classes.penEdit}`}></i>}
                          <span className={classes.stepLabelRightText}>{currentStepResumen(index)}</span>
                        </h3>
                      </span>
                    </StepLabel>
                    <StepContent >
                      <div>{(activeStep === 1 && index === 1 && product) ? step.fromProductTemplateMobile : step.mobileTemplate}</div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>All steps completed - you&apos;re finished</Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Box>
          </div>
        </Media>
        <Media at="md">
          <div className={classes.root}>
            <Box sx={{ width: '80vw' }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          root: classes.iconStep,
                          completed: classes.iconStepValid
                        }
                      }}
                      optional={
                        index === 5 ? (
                          <Typography variant="caption"></Typography>
                        ) : null
                      }
                    >
                      <span>
                        <h3>
                          {step.label}
                          {(activeStep > index) && <i onClick={() => handleBackByStep(index)} className={`fas fa-pen ${classes.penEdit}`}></i>}
                          <span className={classes.stepLabelRightText}>{currentStepResumen(index)}</span>
                        </h3>
                      </span>
                    </StepLabel>
                    <StepContent>
                      <div>{(activeStep === 1 && index === 1 && product) ? step.fromProductTemplateMobile : step.mobileTemplate}</div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>All steps completed - you&apos;re finished</Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Box>
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <div className={classes.root}>
            <Box sx={{ width: '25vw' }}>
              <div className={classes.sideBlock} >
                <h3>{I18n.t('gift_voucher_name_product')}</h3>
                <img src={`https://hotelbreak.s3.eu-west-3.amazonaws.com/gift_cards/evoucher_${currentLocale}.png`} className={classes.sideImage} />
                <ul>
                  <li>{I18n.t('gift_voucher_usp_1')}</li>
                  <li>{I18n.t('gift_voucher_usp_2')}</li>
                  <li>{I18n.t('gift_voucher_usp_3')}</li>
                </ul>
              </div>
            </Box>
            <Box sx={{ width: '55vw' }}>
              <Stepper activeStep={activeStep} disabled={disabledStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          root: classes.iconStep,
                          completed: classes.iconStepValid
                        }
                      }}
                      optional={
                        index === 5 ? (
                          <Typography variant="caption"></Typography>
                        ) : null
                      }
                    >
                      <span>
                        <h3>
                          {step.label}
                          {(activeStep > index) && (disabledStep != index) && <i onClick={() => handleBackByStep(index)} className={`fas fa-pen ${classes.penEdit}`}></i>}
                          <span className={classes.stepLabelRightText}>{currentStepResumen(index)}</span>
                        </h3>
                      </span>
                    </StepLabel>
                    <StepContent>
                      <div>{(activeStep === 1 && index === 1 && product) ? step.fromProductTemplate : step.template}</div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>All steps completed - you&apos;re finished</Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Box>
          </div>
        </Media>
      </MediaContextProvider>
    </>
  );
}

export default WalkthroughReorder;
