import React from "react";
import { useState, useEffect, useRef } from "react";
import { useStylesChooseDesign } from "../../styles/stepStyles";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useSelector, useDispatch } from "react-redux";
import { setStepsVoucherCreationParams } from "../../../../redux/actions/actions";
import I18n from '../../../../../i18n-js/index.js.erb'
import axios from "axios";
import Compressor from 'compressorjs';
import { Cookies, useCookies } from "react-cookie";


function ChooseDesign(props) {
  const BASE_URL = props.baseUrl || '' || '';
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const FILE_SIZE = 20000000;

  const dispatch = useDispatch();
  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const [cookies, setCookie] = useCookies(['variant'])
  const [hasLeftScroll, setHasLeftScroll] = useState(false);
  const [hasRightScroll, setHasRightScroll] = useState(true);
  const [customImage, setCustomImage] = useState({
    url: false,
    status: false,
    image: false,
  });
  const [selected, setSelected] = useState(false);
  const refPhoto = useRef(null);

  const scrollRight = () => {
    $(`#gridListScroll`).animate(
      {
        scrollLeft: "+=400px",
      },
      "slow"
    );
    setHasLeftScroll(true);
    if (
      $(`#gridListScroll`).get(0).scrollWidth -
      $(`#gridListScroll`).scrollLeft() -
      $(`#gridListScroll`).width() -
      400 <=
      0
    ) {
      setHasRightScroll(false);
    }
  };

  const scrollLeft = () => {
    $(`#gridListScroll`).animate(
      {
        scrollLeft: "-=400px",
      },
      "slow"
    );
    setHasRightScroll(true);
    if ($(`#gridListScroll`).scrollLeft() - 400 <= 0) {
      setHasLeftScroll(false);
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange'
  });

  const onSubmit = (data) => { console.log(data) }

  const handleDesignPick = (design) => {
    setSelected(design);
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step3: {
          status: true,
          design: design,
          custom: { status: false, image: false },
        },
      })
    );

    axios
      .post(`${BASE_URL}/api/main/tracking/checkouts/completed.json`, { params: { step: 3, step_name: 'design', design: design, version: cookies["variant"] } })
      .then((response) => { console.log(response.status) })
      .catch(function (error) { });

    setTimeout(() => {
      props.handleNext();
    }, 300);
  };

  useEffect(() => {
    if (!stepsVoucherCreationParams.step3.status) {
      axios
        .post(`${BASE_URL}/api/main/tracking/checkouts/viewed.json`, { params: { step: 3, step_name: 'design', version: cookies["variant"] } })
        .then((response) => { console.log(response.status) })
        .catch(function (error) { });
    }
  }, [])




  const handleCustomDesignPreview = () => {
    clearErrors('photo');
    trigger();
    const file = refPhoto.current.files[0];

    if (file.size > FILE_SIZE) {
      setError('photo', { type: 'manual', message: I18n.t("new_gift__image_size") });
      return;
    }

    if (!SUPPORTED_FORMATS.includes(file.type)) {
      setError('photo', { type: 'manual', message: I18n.t("new_gift__image_format") });
      return;
    }

    if (!file) {
      return;
    }

    new Compressor(file, {
      quality: 0.6,
      maxWidth: 1920,
      maxHeight: 1080,
      success(compressedResult) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setCustomImage({
            status: true,
            image: reader.result,
            url: URL.createObjectURL(compressedResult),
            format: compressedResult.type,
          });
        };
        reader.readAsDataURL(compressedResult);
      },
      error(err) {
        console.error('Compression Error:', err.message);
      },
    });

  };

  const handleCustomDesignSelection = () => {
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step3: {
          status: true,
          design: false,
          custom: {
            status: true,
            image: customImage.image,
            url: customImage.url,
            format: customImage.format,
          },
        },
      })
    );

    props.handleNext();
  };

  const useStylesCustom = makeStyles((theme) => ({
    custom: {
      border: "1px solid grey",
      background: "whitesmoke",
      backgroundImage: `url(${customImage.url || stepsVoucherCreationParams.step3.custom.url
        })`,
      backgroundSize: "cover",
      backgroundPosition: 'center',
      width: "100%",
      maxWidth: "300px",
      height: "200px",
      margin: "0 auto 20px auto",
      borderRadius: "5px",
      padding: "20px",
      textAlign: "center",
      fontSize: "20px",
      cursor: "pointer",
    },
    tooltip: {
      padding: "10px",
      background: "#F7EB67",
      display: "inline-block",
      marginBottom: "20px",
      borderRadius: "6px",
      fontWeight: 600
    },
    triangle: {
      border: "solid #F7EB67",
      padding: "4px",
      transform: "rotate(45deg)",
      borderWidth: "0 3px 3px 0",
      display: "block",
      width: "9px",
      position: "absolute",
      top: "34px",
      left: "40px",
      background: "#F7EB67",
    }
  }));

  const classesCustom = useStylesCustom();

  const classes = useStylesChooseDesign();

  const voucherDesigns = [
    "custom",
    "valentine",
    "cumple",
    "spa",
    "food",
    "pool",
    "christmas",
    "christmas2",

  ];

  const Photo = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
    <input
      onChange={() => handleCustomDesignPreview()}
      type="file"
      ref={refPhoto}
      style={{ display: "none" }}
    />
  ))

  return (
    <div>
      <div className={classes.gridWrapper}>
        {hasLeftScroll && (
          <Fab
            color="white"
            aria-label="add"
            className={classes.buttonLeft}
            onClick={scrollLeft}
          >
            <ArrowLeftIcon />
          </Fab>
        )}
        {hasRightScroll && (
          <Fab
            color="white"
            aria-label="add"
            className={classes.buttonRight}
            onClick={scrollRight}
          >
            <ArrowRightIcon />
          </Fab>
        )}
        <div className={classesCustom.tooltip}>🎉 {I18n.t("new_gift__add_image_tooltip")}</div>
        <div className={classesCustom.triangle} />

        <ImageList
          className={classes.gridList}
          cols={2.3}
          rowHeight="auto"
          id={`gridListScroll`}
        >
          {voucherDesigns.map((design, index) => {
            if (design === "custom") {
              return (
                <React.Fragment>

                  <ImageListItem
                    cols={2.3}
                    rows={1}
                    key={index}
                    classes={{
                      root: classes.overRideImageList,
                    }}
                  >
                    <label
                      className={clsx(
                        ((!customImage.status &&
                          !stepsVoucherCreationParams.step3.custom.status) ||
                          !stepsVoucherCreationParams.step3.custom.status) &&
                        classes.custom,
                        (customImage.status ||
                          stepsVoucherCreationParams.step3.custom.status) &&
                        classesCustom.custom,
                        classes.cardDesign
                      )}
                      id={`custom`}
                    >

                      <Photo control={control} name="photo" />

                      <p className={errors.photo && 'error'}>{errors.photo?.message}</p>


                      {!customImage.status &&
                        !stepsVoucherCreationParams.step3.custom.status &&
                        !customImage.status && (
                          <React.Fragment>
                            <div className={classes.customButton}>+</div>
                            <p>{I18n.t('new_gift__add_image')}</p>
                          </React.Fragment>
                        )}
                    </label>

                    <p style={{ textAlign: "center" }}>
                      {(customImage.status ||
                        stepsVoucherCreationParams.step3.custom.status) && (
                          <button
                            onClick={() => handleCustomDesignSelection()}
                            className="btn btn-outline-primary"
                            disabled={
                              !!errors.photo
                            }
                          >
                            {I18n.t("pax__modal_validate")}
                          </button>
                        )}
                    </p>
                  </ImageListItem>
                </React.Fragment>
              );
            } else {
              return (
                <ImageListItem
                  onClick={() => handleDesignPick(design)}
                  cols={2.3}
                  rows={1}
                  key={index}
                  classes={{
                    root: classes.overRideImageList,
                  }}
                >
                  {
                    <div
                      id={`card_design_2`}
                      className={
                        classes.cardDesign +
                        " " +
                        clsx(
                          design,
                          (selected === design ||
                            stepsVoucherCreationParams.step3.design ===
                            design) &&
                          classes.selectedDesign
                        )
                      }
                      style={{ width: "300px !important" }}
                    ></div>
                  }
                  <p style={{ textAlign: "center" }}>
                    {I18n.t(`desc_card_${design}`)}
                  </p>
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </div>
    </div>
  );
}

export default ChooseDesign;
