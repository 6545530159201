import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  configAreaReducer,
  changeLocaleReducer,
  configCsrfTokenReducer,
  changeHotelDataReducer,
  currentUserReducer,
  currentAvailabilities,
  desktopUserLoginFormStatus,
  areaHomeProductsReducer,
  areaHomeHotelCategoriesReducer,
  stepsVoucherCreationParams,
  clientSecret,
  configAreaNameReducer,
  selectedDate,
  filterReducer,
  JWTTokenReducer,
} from "./redux/reducers/reducers";
import ProductList from "./ProductList";
import EmptyProductList from "./EmptyProductList";
 

const store = configureStore({
  reducer: {
    area: configAreaReducer,
    areaName: configAreaNameReducer,
    currentLocale: changeLocaleReducer,
    csrfToken: configCsrfTokenReducer,
    hotelUsedData: changeHotelDataReducer,
    currentUser: currentUserReducer,
    currentAvailabilities: currentAvailabilities,
    desktopUserLoginFormStatus: desktopUserLoginFormStatus,
    areaHomeProducts: areaHomeProductsReducer,
    stepsVoucherCreationParams: stepsVoucherCreationParams,
    clientSecret: clientSecret,
    selectedDate: selectedDate,
    filtersData: filterReducer,
    areaHomeHotelCategories: areaHomeHotelCategoriesReducer,
    JWTToken: JWTTokenReducer,
  },
});

function ProductListApp(props) {
  return (
    <Provider store={store}>
      {props.all_products.data.length == 0 ? (
        <EmptyProductList {...props} />
      ) : (
        <ProductList {...props} />
      )}
    </Provider>
  );
}

export default ProductListApp;
