import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";

function LanguageTab(props) {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <div className="forms__name__container_languageButton">
      <p
        className={clsx(
          "forms__languageButton",
          props.currentLanguage === "es" && "languageButtons__bg"
        )}
        name="es"
        id="description-es"
        onClick={(ev) => {
          props.handleTabChange(ev);
        }}
      >
        {" "}
        🇪🇸&nbsp;Español{" "}
      </p>
      <p
        className={clsx(
          "forms__languageButton",
          props.currentLanguage === "fr" && "languageButtons__bg"
        )}
        name="fr"
        id="description-fr"
        onClick={(ev) => {
          props.handleTabChange(ev);
        }}
      >
        {" "}
        🇫🇷&nbsp;Français{" "}
      </p>
      <p
        className={clsx(
          "forms__languageButton",
          props.currentLanguage === "en" && "languageButtons__bg"
        )}
        name="en"
        id="description-en"
        onClick={(ev) => {
          props.handleTabChange(ev);
        }}
      >
        {" "}
        🇬🇧&nbsp;English{" "}
      </p>
      <p
        className={clsx(
          "forms__languageButton",
          props.currentLanguage === "it" && "languageButtons__bg"
        )}
        name="it"
        id="description-it"
        onClick={(ev) => {
          props.handleTabChange(ev);
        }}
      >
        {" "}
        🇮🇹&nbsp;Italiano{" "}
      </p>
      <p
        className={clsx(
          "forms__languageButton",
          props.currentLanguage === "pt" && "languageButtons__bg"
        )}
        name="pt"
        id="description-pt"
        onClick={(ev) => {
          props.handleTabChange(ev);
        }}
      >
        {" "}
        🇵🇹&nbsp;Português{" "}
      </p>
      <p
        className={clsx(
          "forms__languageButton",
          props.currentLanguage === "de" && "languageButtons__bg"
        )}
        name="pt"
        id="description-pt"
        onClick={(ev) => {
          props.handleTabChange(ev);
        }}
      >
        {" "}
        🇩🇪&nbsp;Deustch{" "}
      </p>
      {!props.isCreateMode && currentUser.role === "admin" && (
        <p
          className={clsx(
            "forms__languageButton",
            props.resetTranslations && "languageButtons__bg"
          )}
          name="reset_translations"
          id="reset-translations"
          onClick={() =>
            props.handleResetOn(props.fieldname, props.currentLanguage)
          }
        >
          {" "}
          {props.currentLanguage === "de" && "Reset con " + "🇩🇪"}
          {props.currentLanguage === "pt" && "Reset con " + "🇵🇹"}
          {props.currentLanguage === "it" && "Reset con " + "🇮🇹"}
          {props.currentLanguage === "es" && "Reset con " + "🇪🇸"}
          {props.currentLanguage === "fr" && "Reset con " + "🇫🇷"}
          {props.currentLanguage === "en" && "Reset con " + "🇬🇧"}
        </p>
      )}
    </div>
  );
}

export default LanguageTab;
