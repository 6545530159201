export const configHotelReducer = function (state = "all", action) {
  switch (action.type) {
    case "SELECT_HOTEL":
      return action.payload;
    default:
      return state;
  }
};

export const changeLocaleReducer = function (state = "es", action) {
  switch (action.type) {
    case "CHANGE_LOCALE":
      return action.payload;
    default:
      return state;
  }
};

export const changeCalendarProductReducer = function (state = false, action) {
  switch (action.type) {
    case "CHANGE_CALENDAR_PRODUCT":
      return action.payload;
    default:
      return state;
  }
};

export const changeCalendarDateReducer = function (state = false, action) {
  switch (action.type) {
    case "CHANGE_CALENDAR_DATE":
      return action.payload;
    default:
      return state;
  }
};

export const showCalendarMassiveFormReducer = function (
  state = { status: false, updateStatus: false },
  action
) {
  switch (action.type) {
    case "SHOW_CALENDAR_MASSIVE_FORM":
      return action.payload;
    default:
      return state;
  }
};

export const showCalendarAvailabilityFormReducer = function (
  state = {
    status: false,
    startDate: false,
    ticket_number: false,
    price: false,
    price_kids: false,
    bali_beds_number: false,
    categories: false,
    updateStatus: false,
  },
  action
) {
  switch (action.type) {
    case "SHOW_CALENDAR_AVAILABILITY_FORM":
      return action.payload;
    default:
      return state;
  }
};

export const configAreaReducer = function (state = false, action) {
  switch (action.type) {
    case "SELECT_AREA":
      return action.payload;
    default:
      return state;
  }
};

export const configAreaNameReducer = function (state = false, action) {
  switch (action.type) {
    case "SELECT_AREA_NAME":
      return action.payload;
    default:
      return state;
  }
};

export const configCsrfTokenReducer = function (state = "", action) {
  switch (action.type) {
    case "ADD_CSRF_TOKEN":
      return action.payload;
    default:
      return state;
  }
};

export const changeHotelDataReducer = function (
  state = { slug: false, products: false, photos: false, reviews: false },
  action
) {
  switch (action.type) {
    case "CHANGE_HOTEL_DATA":
      return action.payload;
    default:
      return state;
  }
};

export const currentUserReducer = function (
  state = {
    id: false,
    role: false,
    isInformationComplete: { country: false, status: false },
    loggedIn: false,
  },
  action
) {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return action.payload;
    default:
      return state;
  }
};

export const currentAvailabilities = function (
  state = { blocked_days: false, availabilities: {} },
  action
) {
  switch (action.type) {
    case "SET_AVAILABILITIES":
      return action.payload;
    default:
      return state;
  }
};

export const notificationsReducer = function (
  state = { status: false, notification_counter: 0 },
  action
) {
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      return action.payload;
    default:
      return state;
  }
};

export const desktopUserLoginFormStatus = function (
  state = { open: false, signup: false, register: false, subscription: false },
  action
) {
  switch (action.type) {
    case "SET_DESKTOP_USER_LOGIN_FORM_STATUS":
      return action.payload;
    default:
      return state;
  }
};

export const areaHomeProductsReducer = function (
  state = {
    status: false,
    payload: false,
    filters: { who: false, what: false },
  },
  action
) {
  switch (action.type) {
    case "SET_AREA_HOME_PRODUCTS":
      return {
        status: true,
        payload: action.payload,
        filters: {
          who: action.payload
            .filter((category) => category.type === "who")
            .sort((category) => category.title),
          what: action.payload
            .filter((category) => category.type === "what")
            .sort((category) => category.title),
        },
      };
    default:
      return state;
  }
};

export const areaHomeHotelCategoriesReducer = function (
  state = {
    status: false,
    payload: false,
  },
  action
) {
  switch (action.type) {
    case "SET_AREA_HOME_HOTEL_CATEGORIES":
      return {
        status: true,
        payload: action.payload,
      };
    default:
      return state;
  }
};

export const stepsVoucherCreationParams = function (
  state = {
    step1: {
      status: false,
      price: 100,
      adults: false,
      kids: false,
      productInfo: { status: false, pvp: false, max_guest: false },
    },
    step2: { status: false, print: false, evoucher: false },
    step3: {
      status: false,
      design: "",
      custom: { status: false, url: false, image: false },
      physical: false,
    },
    step4: { status: false },
    step5: { status: false },
    step6: { status: false },
    images: { images: [] },
  },
  action
) {
  switch (action.type) {
    case "SET_STEPS_VOUCHER_CREATION_PARAMS":
      return action.payload;
    default:
      return state;
  }
};

export const clientSecret = function (state = "", action) {
  switch (action.type) {
    case "SET_CLIENT_SECRET":
      return action.payload;
    default:
      return state;
  }
};

export const selectedDate = function (state = "", action) {
  switch (action.type) {
    case "SET_SELECTED_DATE":
      return action.payload;
    default:
      return state;
  }
};

export const PaymentData = function (
  state = {
    PaymentData: { total_margin: false, rest_to_pay: false, pay_now: false },
    firstLoad: false,
    ProductData: false,
    ReservationData: false,
    wallet: { balance: 0 },
    PaymentOption: "partial_payment",
    accept_terms: false,
    is_referral_active: false,
    loading: false,
    selectedPaymentMethod: "card",
    is_hotelbreak_direct: false,
    disable_gift_voucher: false,
    log_in_form: false,
    scheduled: { status: false, message: false },
    old_PaymentData: {
      total_margin: false,
      rest_to_pay: false,
      pay_now: false,
    },
    used_email: false,
    passwordRecovery: { open: false },
  },
  action
) {
  switch (action.type) {
    case "SET_PAYMENT_DATA":
      return action.payload;
    default:
      return state;
  }
};

export const selectedPax = function (
  state = { adults: false, kids: false, total: false },
  action
) {
  switch (action.type) {
    case "SET_SELECTED_PAX":
      return action.payload;
    default:
      return state;
  }
};

export const filterReducer = function (
  state = {
    open: false,
    payload: false,
    form: {
      "adults-only": false,
      afternoon: false,
      "all-inclusive": false,
      breakfast: false,
      brunch: false,
      cabana: false,
      daypass: false,
      dinner: false,
      "family-friendly": false,
      lunch: false,
      massage: false,
      "most-popular": false,
      "most-recent": false,
      outdoor: false,
      spa: false,
      wallet_friendly: false,
      yoga: false,
    },
  },
  action
) {
  switch (action.type) {
    case "SET_FILTER_DATA":
      return action.payload;
    default:
      return state;
  }
};
export const scheduleStates = function (
  state = {
    status: false,
    calendar_id: false,
    hotelHasCalendars: false,
  },
  action
) {
  switch (action.type) {
    case "SET_SCHEDULE_STATES":
      return action.payload;
    default:
      return state;
  }
};

export const teamMemberFormReducer = function (
  state = {
    open: false,
    teamMemberId: false,
    name: false,
    category: false,
    phone_number: false,
    email: false,
    availability: false,
    external_source: false,
    calendar_timetables_attributes: [],
  },
  action
) {
  switch (action.type) {
    case "SET_TEAM_MEMBER_FORM":
      return action.payload;
    default:
      return state;
  }
};

export const selectedTimeFrameReducer = function (
  state = {
    status: false,
    start_hour: false,
    finish_hour: false,
    open: false,
  },
  action
) {
  switch (action.type) {
    case "SET_SELECTED_TIMEFRAME":
      return action.payload;
    default:
      return state;
  }
};

export const setFiltersResults = function (state = false, action) {
  switch (action.type) {
    case "SET_FILTERS_RESULTS":
      return action.payload;
    default:
      return state;
  }
};

export const JWTTokenReducer = function (state = false, action) {
  switch (action.type) {
    case "SET_JWT_TOKEN":
      return action.payload;
    default:
      return state;
  }
};