import React from "react";
import { useState, useEffect } from "react";
import { useStylesCustomizeDesign } from "../../styles/stepStyles";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import loadingLogo from "images/logo_hotelbreak_mini.png";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import { setStepsVoucherCreationParams } from "../../../../redux/actions/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import Box from "@mui/material/Box";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import I18n from '../../../../../i18n-js/index.js.erb'
import axios from "axios";

const schema = yup
  .object({
    sender: yup.string().required(I18n.t('is_required')),
    message: yup.string().max(200),
    show_amount: yup.boolean(),
    send_recipient: yup.string().required(I18n.t('is_required')),
    send_fistname: yup.string().required(I18n.t('is_required')),
    send_lastname: yup.string().required(I18n.t('is_required')),
    send_address: yup.string().required(I18n.t('is_required')),
    send_address2: yup.string(),
    send_city: yup.string().required(I18n.t('is_required')),
    send_postal_code: yup.string().required(I18n.t('is_required')),
    send_province: yup.string().required(I18n.t('is_required')),
  })
  .required();

function CustomizeVoucherPhysical(props) {
  const dispatch = useDispatch();
  const [generateFormat, setGenerateFormat] = useState('sender');
  const [startDate, setStartDate] = useState(new Date());
  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const classes = useStylesCustomizeDesign();

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const toggleGenerateFormat = () => {
    setGenerateFormat(generateFormat === "sender" ? "recipient" : "sender");
  }

  const handleDate = (date) => {
    setStartDate(date);
  } 

  const onSubmit = (data) => {
    
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step4: {
          status: true,
          sender: data.sender,
          message: data.message,
          show_amount: data.show_amount,
          send_recipient: data.send_recipient,
          send_fistname: data.send_fistname,
          send_lastname: data.send_lastname,
          send_address: data.send_address,
          send_address2: data.send_address2,
          send_city: data.send_city,
          send_postal_code: data.send_postal_code,
          send_province: data.send_province,
        },
      })
    );

    axios
      .post("/api/main/tracking/checkouts/completed.json", {params: {step: 4, step_name: 'customize', version: cookies["variant"]}})
      .then((response) => {console.log(response.status)})
      .catch(function (error) {});

    props.handleNext();
  };

  const send_recipient = watch("send_recipient");

  useEffect(() => {
    setValue("sender", stepsVoucherCreationParams.step4.sender);
    setValue("message", stepsVoucherCreationParams.step4.message);
    setValue("show_amount", stepsVoucherCreationParams.step4.show_amount);
    setValue("send_recipient", stepsVoucherCreationParams.step4.send_recipient);
    setValue("send_fistname", stepsVoucherCreationParams.step4.send_fistname);
    setValue("send_lastname", stepsVoucherCreationParams.step4.send_lastname);
    setValue("send_address", stepsVoucherCreationParams.step4.send_address);
    setValue("send_address2", stepsVoucherCreationParams.step4.send_address2);
    setValue("send_city", stepsVoucherCreationParams.step4.send_city);
    setValue("send_postal_code:", stepsVoucherCreationParams.step4.send_postal_code);
    setValue("send_province", stepsVoucherCreationParams.step4.send_province);
  }, []);

  useEffect(() => {
    setValue("send_recipient", generateFormat)
  }, [send_recipient, setValue])

  return (
    <div className={classes.customizeBlock}>
      {props.mobile &&
        <div id={`card_design_2`} className={classes.cardDesign + " " + stepsVoucherCreationParams.step3.design}></div>
      }

      <Form onSubmit={handleSubmit(onSubmit)} >
        <FormGroup>

          <Row>
            <Col md={12}>
              <Label>
                <strong>{I18n.t('new_gift__from')} </strong>
              </Label>
              <Controller
                name="sender"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <Input {...field} type="text" innerRef={ref} className={errors.sender && 'error'} />
                )}
              />
              <p className={errors.sender && 'error'}>{errors.sender?.message}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Label>
                <strong>{I18n.t('new_gift__message')} </strong>
              </Label>
              <Controller
                name="message"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <Input {...field} type="textarea" innerRef={ref} className={errors.message && 'error'} />
                )}
              />
              <p className={errors.message && 'error'}>{errors.message?.message}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Controller
                name="show_amount"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Input
                    {...field}
                    id="checkbox2"
                    type="checkbox"
                    innerRef={ref}
                    className={errors.show_amount && 'error'}
                  />
                )}
              />
              <label htmlFor={'checkbox2'} className={classes.labelNoMarginTop} >{I18n.t('new_gift__show_amount')}</label>
              <p className={errors.show_amount && 'error'}>{errors.show_amount?.message}</p>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Label>
            <strong>{I18n.t('gift_voucher_recipient_who')}</strong>
          </Label>        
          <Controller
            name="send_recipient"
            control={control}
            render={( field ) => (
              <ToggleButtonGroup
                color="primary"
                {...register('send_recipient')}
                value={generateFormat}
                exclusive
                fullWidth={true}
                color={'success'}
                onChange={toggleGenerateFormat}
              >
                <ToggleButton 
                  value="sender"
                >
                  {I18n.t('send_recipient_sender')}
                </ToggleButton>
                <ToggleButton 
                  value="recipient" 
                >
                  {I18n.t('send_recipient_recipient')}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </FormGroup>

        <p className={errors.send_recipient && 'error'}>{errors.send_recipient?.message}</p>

        <FormGroup>
            <Row>
            <Col md={6}>
                <Label>
                <strong>{I18n.t('form_name')} </strong>
                </Label>
                <Controller
                name="send_fistname"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                    <Input
                    {...field}
                    id="send_fistname"
                    type="text"
                    placeholder={I18n.t('form_name_ex')}
                    innerRef={ref}
                    className={errors.send_fistname && 'error'}
                    />
                )}
                />
                <p className={errors.send_fistname && 'error'}>{errors.send_fistname?.message}</p>
            </Col>

            <Col md={6}>
                <Label>
                <strong>{I18n.t('form_last_name')} </strong>
                </Label>
                <Controller
                name="send_lastname"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                    <Input
                    {...field}
                    id="send_lastname"
                    type="text"
                    placeholder={I18n.t('form_last_name_ex')}
                    innerRef={ref}
                    className={errors.send_lastname && 'error'}
                    />
                )}
                />
                <p className={errors.send_lastname && 'error'}>{errors.send_lastname?.message}</p>
            </Col>

            <Col md={6}>
                <Label>
                <strong>{I18n.t('form_address')} </strong>
                </Label>
                <Controller
                name="send_address"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                    <Input
                    {...field}
                    id="send_address"
                    type="text"
                    placeholder={I18n.t('form_address_ex')}
                    innerRef={ref}
                    className={errors.send_address && 'error'}
                    />
                )}
                />
                <p className={errors.send_address && 'error'}>{errors.send_address?.message}</p>
            </Col>

            <Col md={6}>
                <Label>
                <strong>{I18n.t('form_address2')} </strong>
                </Label>
                <Controller
                name="send_address2"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                    <Input
                    {...field}
                    id="send_address2"
                    type="text"
                    placeholder={I18n.t('form_address2_ex')}
                    innerRef={ref}
                    className={errors.send_address2 && 'error'}
                    />
                )}
                />
                <p className={errors.send_address2 && 'error'}>{errors.send_address2?.message}</p>
            </Col>
        </Row>
        <Row>
            <Col md={4}>
                <Label>
                <strong>{I18n.t('form_city')} </strong>
                </Label>
                <Controller
                name="send_city"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                    <Input
                    {...field}
                    id="send_city"
                    type="text"
                    placeholder={I18n.t('form_city_ex')}
                    innerRef={ref}
                    className={errors.send_city && 'error'}
                    />
                )}
                />
                <p className={errors.send_city && 'error'}>{errors.send_city?.message}</p>
            </Col>
            <Col md={4}>
                <Label>
                <strong>{I18n.t('form_postal_code')} </strong>
                </Label>
                <Controller
                name="send_postal_code"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                    <Input
                    {...field}
                    id="send_postal_code"
                    type="text"
                    placeholder={I18n.t('form_postal_code_ex')}
                    innerRef={ref}
                    className={errors.send_postal_code && 'error'}
                    />
                )}
                />
                <p className={errors.send_postal_code && 'error'}>{errors.send_postal_code?.message}</p>
            </Col>
            <Col md={4}>
                <Label>
                <strong>{I18n.t('form_province')} </strong>
                </Label>
                <Controller
                name="send_province"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                    <Input
                    {...field}
                    id="send_province"
                    type="text"
                    placeholder={I18n.t('form_province_ex')}
                    innerRef={ref}
                    className={errors.send_province && 'error'}
                    />
                )}
                />
                <p className={errors.send_province && 'error'}>{errors.send_province?.message}</p>
            </Col>
            </Row>
        </FormGroup>

        <Button type="submit" className={props.mobile && classes.fullWidth}>{I18n.t('new_gift__customize_button')}</Button>
      </Form>
    </div>
  );
}

export default CustomizeVoucherPhysical;
