import React from "react";
import ReviewBlock from "./ReviewBlock";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'

function ReviewSlide(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      overflow: "hidden",
      margin: "20px 0 0",
      padding: "0 10px",
    },
    gridList: {
      flexWrap: "wrap",
    },
    title: {
      paddingLeft: "10px",
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      {props.reviews && (
        <ImageList
          className={classes.gridList}
          cols={1}
          rowHeight="auto"
        >
          {props.reviews.slice(0,5).map((review) => {
            return (
              <ImageListItem key={review.id} className={classes.reviewList}>
                <ReviewBlock
                description = {review.description}
                user_name = {review.user_name}
                note = {review.note}
                start_date_js_time = {review.start_date_js_time}
                review_js_time = {review.review_js_time}
                product_name = {review.name}
                truncate = {props.truncate}
                brand={props.brand}
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      )}
    </React.Fragment>
  );
}

export default ReviewSlide;
