export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SELECT_HOTEL = "SELECT_HOTEL";
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const CHANGE_CALENDAR_PRODUCT = "CHANGE_CALENDAR_PRODUCT";
export const CHANGE_CALENDAR_DATE = "CHANGE_CALENDAR_DATE";
export const SHOW_CALENDAR_MASSIVE_FORM = "SHOW_CALENDAR_MASSIVE_FORM";
export const SHOW_CALENDAR_AVAILABILITY_FORM =
  "SHOW_CALENDAR_AVAILABILITY_FORM";
export const ADD_CSRF_TOKEN = "ADD_CSRF_TOKEN";
export const CHANGE_HOTEL_DATA = "CHANGE_HOTEL_DATA";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SELECT_AREA = "SELECT_AREA";
export const SET_AVAILABILITIES = "SET_AVAILABILITIES";
export const SET_DESKTOP_USER_LOGIN_FORM_STATUS = "SET_DESKTOP_USER_LOGIN_FORM_STATUS";
export const SET_AREA_HOME_PRODUCTS = "SET_AREA_HOME_PRODUCTS";
export const SET_STEPS_VOUCHER_CREATION_PARAMS = "SET_STEPS_VOUCHER_CREATION_PARAMS";
export const SET_CLIENT_SECRET = "SET_CLIENT_SECRET";
export const SET_TEAM_MEMBER_FORM = "SET_TEAM_MEMBER_FORM";
export const SELECT_AREA_NAME = "SELECT_AREA_NAME";
export const SET_SCHEDULE_STATES = "SET_SCHEDULE_STATES";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const SET_SELECTED_TIMEFRAME = "SET_SELECTED_TIMEFRAME";
export const SET_SELECTED_PAX = "SET_SELECTED_PAX";
export const SET_PAYMENT_DATA = "SET_PAYMENT_DATA";
export const SET_FILTER_DATA = "SET_FILTER_DATA";
export const SET_AREA_HOME_HOTEL_CATEGORIES = "SET_AREA_HOME_HOTEL_CATEGORIES";
export const SET_FILTERS_RESULTS = "SET_FILTERS_RESULTS";
export const SET_JWT_TOKEN = "SET_JWT_TOKEN";