import React from "react";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import { Dialog, makeStyles, TextField } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import I18n from "../../../../i18n-js/index.js.erb";

export default function OddDialog(props) {
  const handleClose = () => {
    props.setOddDialogOpen(false)
  }

  const handleContinue = () => {
    props.setOddAccepted(true);
    props.setOddDialogOpen(false);
  }
  
  const maxPax = Math.ceil(props.totalPax / props.maxGuest) * props.maxGuest;
  const totalPax = props.totalPax;

  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    image: {

    },
    mainText: {
      
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
    },
    correctButton: {
      flex: "1 1 0",
      textAlign: "center",
      background: "#f7eb6780",
      padding: "5px 10px",
      textTransform: 'none',
      fontFamily: "TobiasTrial-SemiBold",
      borderRadius: "0",
      width: "100%",
      margin: "0 auto",
      fontSize: "16px",
      color: "black",
      fontWeight: 800,
      "&:hover": {
        background: '#f7eb6780',
        boxShadow: "2px 2px 0px rgba(0,0,0,0.8)"
      }
    },
    continueButton: {
      flex: "1 1 0",
      textAlign: "center",
      background: "#F7EB67",
      padding: "5px 10px",
      textTransform: 'none',
      fontFamily: "TobiasTrial-SemiBold",
      borderRadius: "0",
      width: "100%",
      margin: "0 auto",
      fontSize: "16px",
      color: "black",
      fontWeight: 800,
      "&:hover": {
        background: '#F7EB67',
        boxShadow: "4px 4px 0px black"
      }
    }
  }))

  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth={'xs'}
    >
      <DialogContent>
        <div className={classes.root}>
          <div className={classes.image} >
            <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M50.3 1.4C53.7 2.3 56.9 3.6 59.9 5.4C62.9 7.2 65.7 9.3 68.2 11.8C70.7 14.3 72.8 17.1 74.6 20.1C76.3 23.1 77.7 26.3 78.6 29.7C79.5 33.1 80 36.6 80 40.1C80 41.7 79.9 43.4 79.7 45C79.7 45.2 79.6 45.4 79.6 45.6C79.4 47.3 79 48.9 78.6 50.4C77.7 53.8 76.3 57 74.6 60C72.8 63 70.7 65.8 68.2 68.3L59 59.1C62.8 55.3 65.3 50.5 66.4 45.3C67.4 40.1 66.9 34.6 64.9 29.7C62.9 24.8 59.4 20.6 55 17.6C50.6 14.6 45.3 13.1 40 13.1V0C41.6 0 43.2 0.1 44.9 0.3C45.1 0.3 45.4 0.4 45.6 0.4C47.2 0.6 48.8 1 50.3 1.4Z" fill="#F7EB67"/>
              <path d="M20.8992 59.0996L11.6992 68.2996C14.1992 70.7996 16.8992 72.8996 19.9992 74.6996L26.4992 63.3996C24.3992 62.1996 22.4992 60.7996 20.8992 59.0996Z" fill="#F7EB67"/>
              <path d="M36.4 66.7996C35.2 66.5996 34 66.3996 32.9 66.0996L29.5 78.6996C31.2 79.0996 32.9 79.4996 34.6 79.6996C36.4 79.8996 38.1 79.9996 39.8 80.0996V67.0996C38.8 66.9996 37.6 66.8996 36.4 66.7996Z" fill="#F7EB67"/>
              <path d="M46.9004 66.1004C49.2004 65.5004 51.4004 64.6004 53.4004 63.4004L59.9004 74.7004C56.9004 76.4004 53.7004 77.8004 50.3004 78.7004L46.9004 66.1004Z" fill="#F7EB67"/>
              <path d="M13.9008 47C14.5008 49.3 15.4008 51.5 16.6008 53.5L5.30078 60C3.60078 57 2.20078 53.8 1.30078 50.4L13.9008 47Z" fill="#F7EB67"/>
              <path d="M26.5 16.5998C28.5 15.3998 30.7 14.4998 33 13.8998L29.6 1.2998C26.2 2.1998 23 3.5998 20 5.2998L26.5 16.5998Z" fill="#F7EB67"/>
              <path d="M20.9008 20.9002C19.2008 22.6002 17.8008 24.4002 16.6008 26.5002L5.30078 20.0002C7.10078 17.0002 9.20078 14.2002 11.7008 11.7002L20.9008 20.9002Z" fill="#F7EB67"/>
              <path d="M0.3 34.8002C0.5 33.0002 0.9 31.3002 1.3 29.7002L13.9 33.0002C13.6 34.1002 13.4 35.3002 13.2 36.5002C13 37.7002 13 38.9002 13 40.0002H0C0 38.3002 0.1 36.5002 0.3 34.8002Z" fill="#F7EB67"/>
              <path d="M53.5992 28.1998C53.4992 28.0998 53.2992 27.9998 53.1992 27.8998C52.9992 27.7998 52.8992 27.7998 52.6992 27.7998C52.4992 27.7998 52.2992 27.7998 52.1992 27.8998C51.9992 27.9998 51.8992 28.0998 51.7992 28.1998L34.4992 45.1998C34.1992 45.3998 33.8992 45.5998 33.4992 45.5998C33.0992 45.5998 32.7992 45.4998 32.4992 45.1998L28.1992 40.9998C27.8992 40.7998 27.5992 40.5998 27.1992 40.5998C26.7992 40.5998 26.4992 40.6998 26.1992 40.9998L23.5992 43.5998C23.3992 43.8998 23.1992 44.1998 23.1992 44.5998C23.1992 44.9998 23.2992 45.2998 23.5992 45.5998L32.3992 54.2998C32.6992 54.4998 32.9992 54.6998 33.3992 54.6998C33.7992 54.6998 34.0992 54.5998 34.3992 54.2998L56.2992 32.6998C56.3992 32.5998 56.4992 32.3998 56.5992 32.2998C56.6992 32.0998 56.6992 31.9998 56.6992 31.7998C56.6992 31.5998 56.6992 31.3998 56.5992 31.2998C56.4992 31.0998 56.3992 30.9998 56.2992 30.8998L53.5992 28.1998Z" fill="#F7EB67"/>
            </svg>
          </div>
          <div className={classes.mainText}>
            <p><strong> {I18n.t("odd_pax_alert.title")}</strong></p>
            {I18n.t("odd_pax_alert.message_1", {count: totalPax, maxPax: maxPax})}<br />
            {I18n.t("odd_pax_alert.message_2")}
          </div>
          <div className={classes.buttons}>
            <Button className={classes.correctButton} onClick={() => handleClose()}>{I18n.t("odd_pax_alert.correct_button")}</Button>
            <Button className={classes.continueButton} onClick={() => handleContinue()}>{I18n.t("odd_pax_alert.continue_button")}</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}