import React from "react";
import I18n from "../../i18n-js/index.js.erb";
import { to_eur } from "../../helpers/base";
import Header from "./widgetVoucherFunnel/Header";
const okokGif = require("../../../assets/images/okok.gif");
import { useSelector } from "react-redux";
import WidgetNav from "./WidgetNav";

function WidgetVoucherCheckoutPage(props) {
  const widget_voucher = props.widget_voucher.data.attributes;

  const currentLocale = useSelector((state) => state.currentLocale);

  I18n.locale = currentLocale;


  const handlePdfDownload = () => {
    const pdfUrl = widget_voucher?.url;
    window.open(pdfUrl, "_blank");
  };

  return (
    <React.Fragment>
      <WidgetNav brand={props.brand.data.attributes} />

      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
            <h3 className="margin-top-30">
              <img
                className="img-circle"
                style={{ width: "50px" }}
                src={okokGif}
                alt="Gift Voucher"
              />
              {I18n.t("gift_voucher_confirm")}
            </h3>
          </div>
          <br />
        </div>
        <div className="row">
          <div className="col-md-2 col-md-offset-3">
            <strong>{I18n.t("gift_voucher_card")}</strong>
          </div>
          {widget_voucher?.image_url ? (
            <div className="col-md-7">
              <img src={widget_voucher?.image_url} style={{width: '200px', margin: '0 auto', textAlign: 'center'}} />
            </div>
          ) : (
            <div id="card_design" className={widget_voucher?.design}>
              <div className="logo_hb"></div>
              <div id="main_text">{widget_voucher?.title}</div>
              <div className="card_details">
                <p>
                  <br />
                </p>
                <p>
                  De:{" "}
                  <span id="card_sender_name">
                    {widget_voucher?.name_sender}
                  </span>
                </p>
              </div>
            </div>
          )}
          <div className="col-md-6 col-md-offset-3">
            <h4 className="text-center">{I18n.t("gift_voucher_buy_sumup")}</h4>
            <div className="row">
              <div className="col-md-3 col-xs-6 col-md-offset-3">
                <strong>{I18n.t("gift_voucher_name_product")}</strong>
              </div>
              <div className="col-md-3 col-xs-6 text-right">
                <span> {to_eur(widget_voucher?.amount) + `€`}</span>
              </div>
            </div>
            <p>
              <strong>{I18n.t("new_gift__title")}</strong>
            </p>
            <p>{widget_voucher?.title}</p>
            {widget_voucher?.message &&
              <>
                <p>
                  <strong>{I18n.t("gift_voucher_message_html")}</strong>
                </p>
                <p>{widget_voucher?.message}</p>
              </>
            }
            <p>
              <strong>{I18n.t("gift_voucher_duration")}</strong>
            </p>
            <p>{widget_voucher?.expiration_date}</p>
          </div>
          <div className="col-md-6 col-md-offset-3 text-right">
            <button className="btn btn-primary" onClick={() => handlePdfDownload()}>
              {" "}
              <i className="fa fa-save"></i> {I18n.t("gift_voucher_download")}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WidgetVoucherCheckoutPage;
