import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Skeleton } from "@mui/material";
import axios from "axios";
import logo from "images/logo.svg";
import tinycolor from "tinycolor2";
import I18n from "../../i18n-js/index.js.erb";

function WidgetNav(props) {
  const brand = props.location === 'hotel' ?
                  props.brand
                :
                  props.brand_serialized?.data?.attributes

  const useStyles = makeStyles((theme) => ({
    logo: {
      width: "120px",
      margin: "10px 0 0 20px",
    },
    buttonText: {
      color: "black",
      fontSize: "14px",
      textTransform: "none !important",
    },
    menuButtonWithText: {
      backgroundColor: "white",
      padding: "6px 15px",
      float: "right",
      marginRight: "15px",
      color: "black",
      textDecoration: "none",
      marginTop: "14px",
      "&:hover": {
        textDecoration: "none",
      },
    },
    emojis: {
      marginRight: "15px",
      fontSize: "15px",
    },
    fullList: {
      width: "auto",
    },
    paper: {
      overflow: "hidden",
      width: "390px !important",
    },
    mainNav: {
      padding: "8px 0 8px 24px !important",
    },
    menuBar: {
      height: "65px",
      background: brand?.main_color ? `${brand?.main_color}` : "white",
      top: 0,
      width: "100%",
    },
    menuBarFixed: {
      position: "absolute",
    },
    menuBarIndex: {
      borderBottom: "1px solid #ccc",
      marginBottom: "10px",
    },
    accountButtonIcon: {
      fontSize: "1.5rem",
    },
    pL5: {
      paddingLeft: "5%",
    },
    hblogo: {
      padding: "5px 0px",
    },
    hbbanner: {
      float: "right",
      position: "relative",
      right: (props) => (props.location == "hotel" ? "7.5%" : "10.5%"),
      top: (props) => (props.location == "hotel" ? "inherit" : "95px"),
    },
    secondaryColor: {
      background: brand?.secondary_color,
      color:
        brand?.secondary_color && tinycolor(brand?.secondary_color).isLight()
          ? "black"
          : "white",
    },
  }));

  const classes = useStyles(props);

  return (
    <React.Fragment>
      <div
        className={clsx(
          classes.menuBar,
          props.index && classes.menuBarIndex,
          props.landing && classes.menuBarFixed
        )}
      >
        {brand ? (
          brand?.name === "widget" ? (
            <p className={classes.hbbanner}>
              Powered by{" "}
              <img
                src={logo}
                className={classes.hblogo}
                title={`hotelbreak logo`}
                alt={`hotelbreak logo`}
                width="120"
              />
            </p>
          ) : (
            <a href={`/`}>
              {brand?.logo || brand?.logo !== "" ? (
                <img
                  src={brand.logo}
                  className={classes.pL5}
                  title={`${brand?.name} logo`}
                  alt={`${brand?.name} logo`}
                  height="50"
                  style={{ marginTop: "5px" }}
                />
              ) : (
                <Skeleton
                  variant="text"
                  className={classes.pL5}
                  width="100px"
                />
              )}
            </a>
          )
        ) : null}
      </div>
      {brand?.discount && (
        <div className={clsx(classes.secondaryColor, classes.pL5)}>
          <p>
            {I18n.t("brand_special_discount", {
              brand: brand?.name.toUpperCase(),
            })}
          </p>
        </div>
      )}
    </React.Fragment>
  );
}

export default WidgetNav;
