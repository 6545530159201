export const wallet_amount = (
  adults_price,
  adults_pax,
  kids_price,
  kids_pax,
  max_guest
) => {
  const walletAmount = (
    parseFloat(
      to_eur(adults_price * Math.ceil(adults_pax / max_guest)) +
        to_eur(kids_price * Math.ceil(kids_pax / max_guest))
    ) * 0.05
  ).toFixed(2);
  return walletAmount;
};
export const calculateRewardGain = (total_margin, area, reward_percent = 2) => {
  const percentAsNumber = typeof reward_percent === 'number' ? reward_percent : 2;
  const today = new Date();
  const campaign_dates =
    today >= new Date("2023-09-10") && today <= new Date("2023-09-11");
  const campaign_area = area === "barcelona";
  const percent = campaign_area && campaign_dates ? 0.1 : percentAsNumber / 100;
  const rewardGain = (parseFloat(total_margin) * percent).toFixed(2);
  return rewardGain;
};
