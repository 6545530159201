import MailCheck from 'react-mailcheck'
import I18n from '../../../../i18n-js/index.js.erb'
import React, {useState, useEffect, useRef} from "react";
import {generateRandomPassword} from "../../../../helpers/app/generators"
// reactstrap components
import { Label, Button } from "reactstrap";
import { render } from "react-dom";
import axios from 'axios';


function UserCreation(props) {

  const emailVal = useRef(null)
  const fullname = useRef(null)
  const lastname = useRef(null)
  const password = generateRandomPassword()
  const [locale, setLocale] = useState();
  const [email, setEmail] = useState()
  const [userUpdated, setUserUpdated] = useState(false)
  const [error, setError] = useState()  
  const [hasErrorMessage, setHasErrorMessage] = useState('')
  const [hasNameError, setHasNameError] = useState()
  const [nameErrorText, setNameErrorText] = useState()
  const [hasLastnameError, setLastnameHasError] = useState()  
  const [lastnameErrorText, setLastnameErrorText] = useState()

  const handleNameChange = () => {
    if (fullname.current.value.length > 3) {
      setHasNameError()
      setNameErrorText()
    } else {
    }
  }

  const handleLastNameChange = () => {
    if (lastname.current.value.length > 3) {
      setLastnameHasError()
      setLastnameErrorText()
    } else {
    }
  }

  const handleEmailChange = () => {
    setEmail(emailVal.current.value)
    if (emailVal.current.value.length > 3) {
      setError('false')
      setHasErrorMessage('')
    }

  }

  const handleCreate = () => { 
    if (fullname.current.value.length > 3 && fullname.current.value.length > 3) {
      axios.post(`../es/users.json`, 
        {
          authenticity_token: props.authenticity_token,
          user: {
            email: emailVal.current.value,
            fullname: fullname.current.value,
            lastname: lastname.current.value,
            password: password,
            gdpr: true
          }
        }
      )
      .then((response) => {
        props.handleUpdate()
      })
      .catch((error) => {  
        if (error.response.status === 409) {
          setHasErrorMessage('taken_email')
        } else if (error.response.status === 401) {
          setHasErrorMessage('wrong_email')
        }
        setError('error')
      })
    } else {
      if (fullname.current.value.length <= 3) {
        if (fullname.current.value.length === 0) {
          setNameErrorText(`${I18n.t('reservation_name_required')}`)
        } else {
          setNameErrorText(`${I18n.t('reservation_name_length')}`)
          }
        setHasNameError('error')
      }
      if (lastname.current.value.length <= 3) {
        if (lastname.current.value.length === 0) {
          setLastnameErrorText(`${I18n.t('reservation_lastname_required')}`)
        } else {
          setLastnameErrorText(`${I18n.t('reservation_lastname_length')}`)
        }
        setLastnameHasError('error')
      }
    }
  };

  

  return (
<div>		
  {!userUpdated && 
  <div className="col-md-12" style={props.styles.newsletterbanner}>
    <div className="col-md-12 no-padding">
      <div className="col-md-12 no-padding">
        <p style={props.styles.h1_styles(props.isMobile)}>{I18n.t('register_banner__claim')}</p>
        <p>{I18n.t('register_banner__text')}</p>
      </div>
      <div className="col-md-12 no-padding">
      <br></br>
      </div>
      <form>
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="authenticity_token" value={props.authenticity_token} />
          <div>
            <div className="col-md-4 no-padding">
              <Label for="user[fullname]"><i className="fas fa-user"></i> {I18n.t('form_name')}</Label>
              <input type="text" ref={fullname} className={`form-control ${hasNameError}`} placeholder={I18n.t('form_name')} required onChange={handleNameChange}/>
              { hasNameError === 'error' && 
                <small className="error">{nameErrorText}</small>
              }
            </div>
            <div className="col-md-4 no-padding-6-r">
              <Label for="user[lastname]"> {I18n.t('form_last_name')}</Label>
              <input type="text" ref={lastname} className={`form-control ${hasLastnameError}`} placeholder={I18n.t('form_last_name_ex')} required onChange={handleLastNameChange} />
              { hasLastnameError === 'error' && 
                <small className="error">{lastnameErrorText}</small>
              }
            </div>
            <div className="col-md-4 no-padding-6-r">
              <Label for="user[email]"><i className="fas fa-envelope"></i> {I18n.t('form_email')}</Label>
              <MailCheck email={email}>
                {suggestion => (
                  <div>
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      className={`form-control ${error}`}
                      placeholder={I18n.t('form_email')}
                      ref={emailVal}
                    />
                    {suggestion &&
                      <div>
                        {I18n.t('do_you_mean')} <span style={props.styles.email_suggestion_link} onClick={(ev) => setEmail(suggestion.full)}>{suggestion.full}</span>?
                      </div>
                    }
                    { hasErrorMessage.length > 0 && 
                          <small className="error">{I18n.t(`${hasErrorMessage}`)}</small>
                        }
                  </div>
                )}
              </MailCheck>
            </div>
          </div>
        <div className="col-md-6 no-padding-6-l">
          <br/>
          <p><input type="checkbox" name="user[gdpr]" value="true"/>{I18n.t('gdpr_sentence')}</p>
          <small>{I18n.t('text_newsletter_legal')}</small>
        </div>
        <div className="col-md-6 no-padding-6-r text-right">
          <br/>
          <Button className="btn btn-primary" onClick={handleCreate}>{I18n.t('register_newsletter')}</Button>
        </div>
      </form>
    </div>
  </div>
} 
</div>
  )
}

export default UserCreation