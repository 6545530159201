import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonAreablocklarge(props) {
  const dispatch = useDispatch();
  const csrfToken = useSelector((state) => state.csrfToken);

  const useStyles = makeStyles((theme) => ({
    root: {},
    provinceLarge: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingRight: "2rem",
      paddingLeft: "2rem",
      paddingBottom: "4rem",
      flex: "0 0 33%",
      maxWidth: "50%",
      cursor: "pointer",
    },

    provinceBlockOuter: {
      boxShadow: "0px 2px 8px rgb(0 0 0 / 15%) !important",
      borderRadius: "10px",
    },

    provinceImageLarge: {
      width: "100%",
      height: "40vh",
      minHeight: "35rem",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },

    provinceBlockLarge: {
      marginTop: "-100px",
      background: "white",
      borderRadius: "0 0 10px 10px",
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <li className={classes.provinceLarge}>
        <figure className={classes.provinceBlockOuter}>
          <Skeleton variant="rect" className={classes.provinceImageLarge} />
          <div className={classes.provinceBlockLarge + " area-block-text-box"}>
            <div className="area-block-subtitle">
              <Skeleton variant="text" />
            </div>
            <h4>
              <Skeleton variant="text" />{" "}
            </h4>
          </div>
        </figure>
      </li>
    </React.Fragment>
  );
}

export default SkeletonAreablocklarge;
