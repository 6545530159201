import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

function TransparentButton(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      border: "2px solid white",
      borderRadius: "5px",
      color: "white",
      height: 48,
      position: "absolute",
      zIndex: "5000",
    },
  }));
  
  const classes = useStyles();

  return <Button className={classes.root}>{props.text}</Button>;
}

export default TransparentButton;
