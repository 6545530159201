import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import {
  configAreaReducer,
  changeLocaleReducer,
  configCsrfTokenReducer,
  changeHotelDataReducer,
  currentUserReducer,
  currentAvailabilities,
  desktopUserLoginFormStatus,
  areaHomeProductsReducer,
  stepsVoucherCreationParams,
  clientSecret,
  configAreaNameReducer,
  filterReducer,
  areaHomeHotelCategoriesReducer,
  JWTTokenReducer,
} from "./redux/reducers/reducers";
import RoomList from "./roomlist";
import WidgetRoomList from "./widget/WidgetRoomList"
import EmptyProductList from "./EmptyProductList";
 

const store = configureStore({
  reducer: {
    area: configAreaReducer,
    areaName: configAreaNameReducer,
    currentLocale: changeLocaleReducer,
    csrfToken: configCsrfTokenReducer,
    hotelUsedData: changeHotelDataReducer,
    currentUser: currentUserReducer,
    currentAvailabilities: currentAvailabilities,
    desktopUserLoginFormStatus: desktopUserLoginFormStatus,
    areaHomeProducts: areaHomeProductsReducer,
    stepsVoucherCreationParams: stepsVoucherCreationParams,
    clientSecret: clientSecret,
    filtersData: filterReducer,
    areaHomeHotelCategories: areaHomeHotelCategoriesReducer,
    JWTToken: JWTTokenReducer,
  },
});

function HotelListApp(props) {
  return (
    <Provider store={store}>
      {props.all_rooms.length > 0 ?
        props.brand?.data != (null || undefined) ?
          <WidgetRoomList {...props} />
        :
          <RoomList {...props} />
      :
        <EmptyProductList {...props} />
      }
    </Provider>
  );
}

export default HotelListApp;
