import { makeStyles } from "@material-ui/core/styles";
import { propsToClassKey } from "@mui/styles";

let sharedArrowButtons = {
  color: "white",
  cursor: "pointer",
  position: "relative",
  zIndex: "1",
  height: "3em",
  top: "17em",
  borderRadius: "4px solid red",
};

const useStylesSecondaryImagesSlider = makeStyles((theme) => ({
  infoPhoto: {
    width: "100%",
    borderRadius: '10px'
  },
  arrowLeft: {
    ...sharedArrowButtons,
    left: "2vw",
  },
  arrowRight: {
    ...sharedArrowButtons,
    left: "44vw",
  },
  arrowRightPhone: {
    ...sharedArrowButtons,
    left: "75vw",
    top: '8em !important'
  },
  arrowLeftPhone: {
    ...sharedArrowButtons,
    left: "5vw",
    top: '8em !important'
  },
  container: {
    width: "80%",
    margin: "0 auto",
  },
  slider: {
    width: "80%",
    marginLeft: "10%",
    marginTop: '-40px'
  },
  sliderMobile: {
    width: "100%",
    marginTop: "58px"
  },
  infoPhotoMobile: {
    width: '100%',
    borderRadius: '0'
  }
}));

export { useStylesSecondaryImagesSlider };
