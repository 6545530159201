import React from "react"

class FavoriteOff extends React.Component {


    render () {
        return(
                <div className="favoriteBtn__wrapper_favoriteOff" onClick={ (ev) => this.props.handleChange(ev, "BtnOff") }>
    		        <i className="far fa-heart"></i> 
                </div>
        )
    }

}

export default FavoriteOff