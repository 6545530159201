import React from "react";
import { useState, useEffect } from "react";
import { useStylesCustomizeDesign } from "../../styles/stepStyles";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import loadingLogo from "images/logo_hotelbreak_mini.png";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import { setStepsVoucherCreationParams } from "../../../../redux/actions/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import Box from "@mui/material/Box";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import I18n from '../../../../../i18n-js/index.js.erb'
import Moment from "moment";
import axios from "axios";
// import { DevTool } from "@hookform/devtools";
import { Cookies, useCookies } from "react-cookie";

function CustomizeEvoucher(props) {
  const [cookies, setCookie] = useCookies(['variant'])
  const dispatch = useDispatch();
  const [generateFormat, setGenerateFormat] = useState('print');
  const [startDate, setStartDate] = useState(new Date());
  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const schema = yup
  .object({
    title: yup.string().max(30, I18n.t('new_gift__char_limit', {char: 30})).required(I18n.t('is_required')),
    sender: yup.string().required(I18n.t('is_required')),
    message: yup.string().max(200, I18n.t('new_gift__char_limit', {char: 200})),
    show_amount: yup.boolean(),
    format: yup.string(),
    recipient_email: yup.string().when('format', {
      is: (format) => (generateFormat === 'email'),
      then: yup.string().required(I18n.t('is_required'))
    }),
    send_voucher_date: yup.string().when('format', {
      is: (value) => (value === 'email'),
      then: yup.date().default(function () { return new Date(); }).required(I18n.t('is_required'))
    }),
  })
  .required();

  const classes = useStylesCustomizeDesign();

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const toggleFormat = (format) => {
    setGenerateFormat(format);
  }

  const handleDate = (date) => {
    setValue('send_voucher_date', Moment(date).format("YYYY-MM-DD"))
    console.log(date);
    setStartDate(date);
  } 

  const onSubmit = (data) => {
    
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step4: {
          status: true,
          title: data.title,
          sender: data.sender,
          message: data.message,
          show_amount: data.show_amount,
          format: generateFormat,
          recipient_email: data.recipient_email,
          send_voucher_date: data.send_voucher_date,
        },
      })
    );

    axios
      .post("/api/main/tracking/checkouts/completed.json", {params: {step: 4, step_name: 'customize', version: cookies["variant"]}})
      .then((response) => {console.log(response.status)})
      .catch(function (error) {});

    props.handleNext();
  };

  useEffect(() => {
    setValue("title", stepsVoucherCreationParams.step4.title);
    setValue("sender", stepsVoucherCreationParams.step4.sender);
    setValue("message", stepsVoucherCreationParams.step4.message);
    setValue("show_amount", stepsVoucherCreationParams.step4.show_amount);
    setValue("recipient_email", stepsVoucherCreationParams.step4.recipient_email);
    setValue('send_voucher_date', Moment(new Date()).format("YYYY-MM-DD"))
    setValue("send_voucher_date", stepsVoucherCreationParams.step4.send_voucher_date);
    
    console.log()
    stepsVoucherCreationParams.step4.format && setGenerateFormat(stepsVoucherCreationParams.step4.format)
  }, []);

  return (
    <div className={classes.customizeBlock}>
      {props.mobile &&
        <div id={`card_design_2`} className={classes.cardDesign + " " + stepsVoucherCreationParams.step3.design}></div>
      }

      <Form onSubmit={handleSubmit(onSubmit)} >
        <FormGroup>
          <Row>
            <Col md={12}>
              <Label>
                <strong>{I18n.t('new_gift__title')} </strong>
              </Label>
              <input {...register("title")} type="text" maxLength="30" name="title" className={clsx("form-control", errors.title && 'error')} />
              <p className={errors.title && 'error'}>{errors.title?.message}</p>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Label>
                <strong>{I18n.t('new_gift__from')} </strong>
              </Label>
              <Controller
                name="sender"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <Input {...field} type="text" innerRef={ref} className={errors.sender && 'error'} />
                )}
              />
              <p className={errors.sender && 'error'}>{errors.sender?.message}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Label>
                <strong>{I18n.t('new_gift__message')} </strong>
              </Label>
              <Controller
                name="message"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <Input {...field} type="textarea" innerRef={ref} maxLength="200" className={errors.message && 'error'} />
                )}
              />
              <p className={errors.message && 'error'}>{errors.message?.message}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Controller
                name="show_amount"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Input
                    {...field}
                    id="checkbox2"
                    type="checkbox"
                    innerRef={ref}
                    className={errors.show_amount && 'error'}
                  />
                )}
              />
              <label htmlFor={'checkbox2'} className={classes.labelNoMarginTop} check>{I18n.t('new_gift__show_amount')}</label>
              <p className={errors.show_amount && 'error'}>{errors.show_amount?.message}</p>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Label>
            <strong>{I18n.t('new_gift__format_title')}</strong>
          </Label>        
          <Controller
            name="format"
            control={control}
            render={( field ) => (
              <ToggleButtonGroup
                color="primary"
                {...register('format')}
                value={generateFormat}
                exclusive
                fullWidth={true}
                color={'success'}
              >
                <ToggleButton 
                  value="print"
                  onClick={() => toggleFormat('print')}
                >
                  {I18n.t('new_gift__format_print')}
                </ToggleButton>
                <ToggleButton 
                  value="email" 
                  onClick={() => toggleFormat('email')}
                >
                  {I18n.t('new_gift__format_email')}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </FormGroup>

        <p>{errors.format?.message}</p>

        {generateFormat === 'email' &&
          <>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>
                    <strong>{I18n.t('new_gift__email_email')} </strong>
                  </Label>
                  <Controller
                    name="recipient_email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                      <Input
                        {...field}
                        id="receiver_email"
                        type="email"
                        placeholder="email@email.com"
                        innerRef={ref}
                        className={errors.email && 'error'}
                      />
                    )}
                  />
                  <p>{errors.recipient_email?.message}</p>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup className={classes.fullWidth}>
              <Label>
                <strong>{I18n.t('new_gift__email_date')} </strong>
              </Label>
              <Controller
                name="send_voucher_date"
                control={control}
                rules={{ required: true }}
                dateFormat="dd/MM/yyyy"
                render={({ field: { onChange, onBlur, value, ref, name } }) => (
                  <DatePicker
                    ref={(elem) => {
                      elem && ref(elem.input);
                    }}
                    name={name}
                    onChange={handleDate}
                    onBlur={onBlur}
                    className={clsx("form-control", errors.send_voucher_date && 'error')}
                    selected={startDate}
                    locale="es-ES"
                    style={{width: '100%'}}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                  />
                )}
              />
              <p className={errors.send_voucher_date && 'error'}>{errors.send_voucher_date?.message}</p>
            </FormGroup>
          </>
        }

        <Button type="submit" className={props.mobile && classes.fullWidth}>{I18n.t('new_gift__customize_button')}</Button>
      </Form>
       {/* <DevTool control={control} /> */}
    </div>
  );
}

export default CustomizeEvoucher;
