import React, {useEffect, useState} from "react";
import NavDesktop from "../main/nav/NavDesktop";
import NavMobile from "../main/nav/NavMobile";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import I18n from "../../i18n-js/index.js.erb";
import {
  addCSRFToken,
  setCurrentUser,
  changeLocale,
  changeSelectedArea,
  setAreaHomeProducts,
  changeSelectedAreaName,
  setAreaHomeHotelCategories,
} from "../redux/actions/actions";
import StarRatings from 'react-star-ratings'
import { createMedia } from "@artsy/fresnel";
import UserLoginWrap from "../main/components/modals/UserLogin/UserLoginWrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import clsx from "clsx";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { currencyToSymbol } from "../../helpers/base";
const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    lg: 1024,
  },
});

let strong = {
  fontWeight: 800
}

export default function Reviews(props) {
  const dispatch = useDispatch();
  axios.defaults.headers.common["X-CSRF-Token"] = props.csrf_token;
  axios.defaults.withCredentials = true;

  dispatch(addCSRFToken(props.csrf_token));
  dispatch(setCurrentUser(props.current_user));
  dispatch(changeLocale(props.locale));

  const schema = yup
    .object({
      food: yup.number().required(I18n.t("is_required")),
      reception: yup.number().required(I18n.t("is_required")),
      cleanliness: yup.number().required(I18n.t("is_required")),
      price_quality: yup.number().required(I18n.t("is_required")),
      description: yup.string().required(I18n.t("is_required")),
      extra_spend: yup.boolean(),
      extra_spend_amount: yup.number().positive(I18n.t("validation_positive_number")).when("extra_spend", {
        is: true,
        then: yup.number().positive(I18n.t("validation_positive_number")).required(I18n.t("is_required"))
      }),
      extra_spend_area: yup.array().when("extra_spend", {
        is: true,
        then: yup.array().of(yup.string()).min(1)
      }),
      extra_spend_area_other: yup.string().when("extra_spend_area", (extra_spend_area, schema) => {
        return extra_spend_area && extra_spend_area.includes('other') ? yup.string().required(I18n.t("is_required")) : schema
      }),
    })
  .required()

  useEffect(() => {
    document.title = I18n.t("dejanos_tu_opinion")
  }, [])

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const selectedAreaName = useSelector((state) => state.areaName);
  const currentLocale = useSelector((state) => state.currentLocale);
  const selectedArea = useSelector((state) => state.area);
  const [cleanValue, setCleanValue] = useState(0);
  const [foodValue, setFoodValue] = useState(0);
  const [receptionValue, setReceptionValue] = useState(0);
  const [priceValue, setPriceValue] = useState(0);
  const [showExtraSpendForm, setShowExtraSpendForm] = useState(false);
  const [step, setStep] = useState(1);
  const [validated, setValidated] = useState(false);
  const [other, setOther] = useState(false);

  const showExtra = () => {
    setShowExtraSpendForm(!showExtraSpendForm);
  }

  const reviewsCategories = [["cleanliness", cleanValue], ["food", foodValue], ["reception", receptionValue], ["price_quality", priceValue]]

  I18n.locale = currentLocale;

  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet,
      }),
      changeLocale(props.locale)
    );
  } else {
    changeLocale(props.locale);
  }

  const changeRating = (rating, name) => {
    switch(name) {
      case 'reception':
        setReceptionValue(rating);
        setValue('reception', rating);
        break;
      case 'food':
        setFoodValue(rating);
        setValue('food', rating);
        break;
      case 'price_quality':
        setPriceValue(rating);
        setValue('price_quality', rating);
        break;
      case 'cleanliness':
        setCleanValue(rating);
        setValue('cleanliness', rating)
        break;
      default:
        null
    }
  }

  const changeStep = async (step) => {
    const result = await trigger(["description", "food", "reception", "price_quality", "cleanliness"])
    step == 2 ? (
      result &&
        setStep(2)
    ) : (
      setStep(1)
    )
  }

  useEffect(() => {
    if ((props.area || props.province) && selectedArea) {
      dispatch(changeSelectedArea(selectedArea));
      var location_params = selectedArea;
    } else if ((props.area || props.province) && !selectedArea) {
      dispatch(changeSelectedArea(props.area));
      var location_params = props.area;
    }
    axios
      .get("/api/main/products", {
        params: { location: location_params, locale: currentLocale },
      })
      .then((response) => {
        dispatch(
          setAreaHomeProducts(
            response.data.area_home_products
              .map((product_list) => {
                return {
                  category: product_list.products.map(
                    (productSerialization) => productSerialization.attributes
                  ),
                  title: product_list.title,
                  slug: product_list.slug,
                  emoji: product_list.emoji,
                  popularity: product_list.popularity,
                  type: product_list.type,
                };
              })
              .sort((a, b) => {
                if (a.popularity > b.popularity) {
                  return -1;
                } else if (
                  a.popularity === "break_friday" ||
                  a.popularity === "most_popular" ||
                  a.popularity === "most_recent"
                ) {
                  return -1;
                } else {
                  return 1;
                }
              })
          )
        );
        dispatch(setAreaHomeHotelCategories(response.data.rooms_categories));
        dispatch(changeSelectedAreaName(response.data.province));
      });
  }, [selectedArea]);

  const ratingBlock = () => {
    var content = []
    reviewsCategories.forEach((category) => {
      const categoryBlock = (
        <div className="form-group col-md-12">
          <label
            htmlFor={`review[${category[0]}]`}
            className={clsx((errors.food || errors.reception || errors.cleanliness || errors.price_quality) && "error", "col-md-5 col-xs-6")}
            style={{...strong, marginTop: '2px'}}>
              {I18n.t(`review_${category[0]}_value`)}
          </label>
          <Controller
            control={control}
            name={category[0]}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StarRatings
                rating={category[1]}
                starRatedColor="#F7EB67"
                starHoverColor="#F7EB67"
                isAggregateRating
                changeRating={changeRating}
                numberOfStars={5}
                name={category[0]}
                starDimension={'25px'}
                onChange={onChange}
                selected={value}
              />
            )}
          />

        </div>
      )

      content.push(categoryBlock)
    })

    return content;
  }

  const onSubmit = (data) => {
    axios
    .post("/api/v2/reviews", data)
    .then((response) => {
      if (response.status === 200) {
        setValidated(true);
        setTimeout(goToRoot(), 500)
      }
    })
    .catch((error) => {

    })
  }

  const goToRoot = () => {
    window.location.href = 'https://www.hotelbreak.com';
  }

  const formContent = () => {
    return(
      <div className="row" style={{marginBottom: "0px"}}>
        <div className="col-md-5 col-xs-12 text-center review">
          <h1 className="review">{I18n.t("dejanos_tu_opinion")} <small style={{fontStyle: "normal"}}>😉</small></h1>
          <br />
          <h4 className="review">{I18n.t("email_review_email_text")}</h4>
          <br />
          <h5 className="review">{I18n.t("email_review_email_text_2", {hotel: props.room.listing_name})}</h5>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {validated ?
            <div className="col-md-5 col-md-offset-1" style={{marginTop: "70px"}}>
              <h4>
                <CheckCircleIcon sx={{ fontSize: 40, color: "#6BD098"}} />{" "}
                <span style={{position: 'relative', top: -11}}>{I18n.t("thanks_for_review")}</span>
              </h4>
            </div>
          : (
            <React.Fragment>
              <div className="col-md-5 col-md-offset-1 col-xs-11 col-xs-offset-1" style={{marginTop: "70px"}}>
                <h4><span className="number_step">{step}</span>{" "}  {step == 1 ? I18n.t("dejanos_tu_opinion") : I18n.t("review_extra_spend_content")}</h4>
              </div>
              {step == 1 ?
                <React.Fragment>
                  <div className="col-md-5 col-md-offset-1" style={{marginTop: "30px"}}>
                    {ratingBlock()}
                    {(errors.food || errors.reception || errors.cleanliness || errors.price_quality) && 
                      <div className="form-group col-md-12">
                        <p className="error">{I18n.t("review_error_review_note")}</p>
                      </div>
                    }
                  </div>
                  <div className="col-md-5 col-md-offset-1">
                    <div className="form-group col-md-12">
                      <label htmlFor="description" className={clsx(errors.description && "error", "col-md-12")}  style={strong}>{I18n.t("review_content")}</label>
                      <textarea name="description" id="description" {...register("description")} className={clsx(errors.description && "error", "col-md-12 form-control")} rows={4} />
                      {errors.description && <p className="error">{errors.description.message}</p>}
                    </div>
                  </div>
                </React.Fragment>
              :
                  <div className="col-md-5 col-md-offset-1 col-xs-12" style={{marginTop: "30px"}}>
                    <div className="form-group col-md-12">
                      <label htmlFor="extra_spend" className="col-md-10 col-xs-10" style={strong} >{I18n.t("review_extra_spend_content")}</label>
                      <input
                        type="checkbox"
                        onClick={() => showExtra()}
                        name="extra_spend"
                        {...register("extra_spend")}
                        id="extra_spend"
                        className="col-md-2 col-xs-2"
                        value={true}
                      />
                    </div>
                    <div className="col-md-12 col-xs-12"></div>
                    {showExtraSpendForm &&
                      <React.Fragment>
                        <div id="amount" className={"form-group col-md-12 col-xs-12"} style={{marginBottom: "10px"}}>
                          <label htmlFor="extra_spend_amount" style={strong} className={clsx(errors.extra_spend_amount && "error", "col-md-7 col-xs-8")}>{I18n.t("review_extra_spend_amount")}</label>
                          <input
                            type="number"
                            id="extra_spend_amount"
                            {...register("extra_spend_amount")}
                            name="extra_spend_amount"
                            className="col-md-5 col-xs-4"
                            placeholder={currencyToSymbol(props.room.currency)}
                          />
                          <div className="col-md-1 col-xs-1" />
                          {errors.extra_spend_amount && <p className="error">{errors.extra_spend_amount.message}</p>}
                        </div>
                        <div id="area" className="form-group col-md-12 col-xs-12" style={{marginBottom: "10px"}}>
                          <label className="col-md-7 col-xs-12" style={strong}>{I18n.t("review_extra_spend_area")}</label><br />
                          <div className="col-md-12">
                            <input type="checkbox" {...register("extra_spend_area")} value="cocktail" />{I18n.t("review_extra_spend_area_cocktail")}<br />
                            <input type="checkbox" {...register("extra_spend_area")} value="food" />{I18n.t("review_extra_spend_area_food")}<br />
                            <input type="checkbox" {...register("extra_spend_area")} value="champagne" />{I18n.t("review_extra_spend_area_champagne")}<br />
                            <input type="checkbox" {...register("extra_spend_area")} value="towels" />{I18n.t("review_extra_spend_area_towels")}<br />
                            <input type="checkbox" {...register("extra_spend_area")} value="upgrade" />{I18n.t("review_extra_spend_area_upgrade")}<br />
                            <input id="extra_spend_area_other" {...register("extra_spend_area")} onClick={() => setOther(!other)} type="checkbox" value="other" />{I18n.t("review_extra_spend_area_other")}<br />
                          </div>
                          {errors.extra_spend_area && <p className="error">{errors.extra_spend_area.message}</p>}
                        </div>
                        {other &&
                          <div id="other" className="form-group col-md-12 col-xs-12" style={{marginBottom: "10px", paddingLeft: 15, paddingRight: 15}}>
                            <input type="text" {...register("extra_spend_area_other")} placeholder={I18n.t("review_extra_spend_area_other")} className="col-md-12 form-control" />
                            {errors.extra_spend_area_other && <p className="error">{errors.extra_spend_area_other.message}</p>}
                          </div>
                        }
                      </React.Fragment>
                    }
                  </div>
                }
                </React.Fragment>
              )}
              <input type="hidden" name="reservation_code" {...register("reservation_code")} value={props.reservation.reservation_code} />
              <div className="col-md-5 col-md-offset-1 col-xs-10 col-xs-offset-1" style={{marginBottom: 40}}>
                {validated ?
                  null
                :
                  (step == 1 ?
                    <div className="btn btn-primary" style={{float: "right"}} onClick={async () => changeStep(2)}>{I18n.t("next_label")}</div>
                  :
                    <React.Fragment>
                      <div className="btn btn-secondary" style={{float: "left"}} onClick={async () => setStep(1)}>{I18n.t("previous_label")}</div>
                      <button className="btn btn-primary" style={{float: "right"}}type="submit">{I18n.t("send_review_button")}</button>
                    </React.Fragment>
                  )
              }
            </div>
        </form>
      </div>
    )
  }

  return (
    <MediaContextProvider>
      <UserLoginWrap baseUrl={props.baseUrl} />
      <Media at="sm">
        <NavMobile areaName={selectedAreaName} />
        {formContent()}
      </Media>
      <Media greaterThanOrEqual="lg">
        <NavDesktop areaName={selectedAreaName} />
        {formContent()}
      </Media>
      <DevTool control={control} /> {/* set up the dev tool */}
    </MediaContextProvider>
  )
}
