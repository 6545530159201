import React from "react";
import WidgetNav from "../../../../javascript/components/widget/WidgetNav";

function HeaderMobile(props) {
  return (
    <>
      <WidgetNav {...props} style={{ zIndex: 2 }} />
    </>
  );
}

export default HeaderMobile;
