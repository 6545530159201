import React, {useState, useEffect} from "react";
import I18n from '../../../../i18n-js/index.js.erb'
// reactstrap components
import { Label, Button } from "reactstrap";
import { render } from "react-dom";
import axios from 'axios';


function UpdateRegistration(props) {

  const handleUpdate = () => { 
    axios.put(`es/users/${props.current_user_id}`, {authenticity_token: props.authenticity_token})
    .catch(function(error) {  console.log(error);  })
    .then((response) => {
      props.handleUpdate()
    })
  };
  const [locale, setLocale] = useState();

  const [email, setEmail] = useState()

  return (
    <div>		
      <div className="col-md-12" style={props.styles.newsletterbanner}>
        <div className="col-md-12 no-padding">
          <div className="col-md-12 no-padding">
            <p style={props.styles.h1_styles(props.isMobile)}>{I18n.t('register_banner__claim')}</p>
            <p>{I18n.t('register_banner__text')}</p>
          </div>
          <div className="col-md-12 no-padding">
          <br></br>
          </div>
          
          <form>
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name="authenticity_token" value={props.authenticity_token} />
            <div className="col-md-6 no-padding-6-l">
              <br/>
              {(props.current_user && !props.gdpr_status) &&
                <p style={props.styles.checkBoxHide}><input type="checkbox" name="user[gdpr]" value="1" checked />{I18n.t('gdpr_sentence')}</p>
              }
            </div>
            <div className="col-md-6 no-padding-6-r text-right">
              <br/>
              <Button className="btn btn-primary" onClick={handleUpdate}>{I18n.t('register_newsletter')}</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UpdateRegistration