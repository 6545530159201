import React from "react";
import { useState, useEffect } from "react";
import { useStylesPreviewVoucher } from "../../styles/stepStyles";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import {
  setStepsVoucherCreationParams,
  setDesktopUserLoginFormStatus,
} from "../../../../redux/actions/actions";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import I18n from "../../../../../i18n-js/index.js.erb";
import logo from "images/logo.svg";

export default function Preview(props) {
  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const useStyles = makeStyles((theme) => ({
    customDesign: {
      width: "100% !important",
      maxWidth: "100% !important",
      height: "75% !important",
      margin: "0 auto 5px auto !important",
      backgroundImage: `url(${stepsVoucherCreationParams.step3.custom.url})`,
      borderRadius: "5px",
      padding: "20px",
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
  }));

  const localClasses = useStyles();
  const classes = useStylesPreviewVoucher();

  return (
    <div>
      {stepsVoucherCreationParams.step2.evoucher ? (
      <Box className={classes.previewVoucher} sx={{ boxShadow: 1 }}>
        <div className={classes.logoBlock} >
          <img className={classes.logo} src={logo} data-original={logo} />
        </div>
        <div className={clsx(classes.previewVoucherInner)}>
          <div
            id="card_design_2"
            className={clsx(
              classes.miniCardDesign,
              stepsVoucherCreationParams.step3.custom.status
                ? localClasses.customDesign
                : stepsVoucherCreationParams.step3.design
            )}
          ></div>
        </div>
        <div className={clsx(classes.previewVoucherInner)}>
          <p className={classes.cardTitle}>
            {stepsVoucherCreationParams.step4.title}
          </p>
          {stepsVoucherCreationParams.step4.message}
          <br />
          {stepsVoucherCreationParams.step4.sender}
        </div>
        <div className={clsx(classes.previewVoucherInner)}>
          <br />
          _______________<br />
          __________<br />
          ______________<br />
          <br />
          1. ______<br />
          2. ______<br />
          3. ______
        </div>
        <div className={clsx(classes.previewVoucherInner)}>
          <div className={classes.qrCode} />
          <small>
            ______
            <br />
            ____________
            <br />
            ______
            <br />
          </small>
        </div>
      </Box>)
      : (
        <div className="text-center">
          <img src="https://testmaxime.s3.us-east-2.amazonaws.com/photos/images/default/gift/physical_card.png" />
        </div>
      )}
    </div>
  )

}