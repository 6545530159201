import React from "react";
import { useState, useEffect } from "react";
import { useStylesPreviewVoucher } from "../../styles/stepStyles";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import loadingLogo from "images/logo_hotelbreak_mini.png";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import {
  setStepsVoucherCreationParams,
  setDesktopUserLoginFormStatus,
} from "../../../../redux/actions/actions";
import axios from "axios";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import I18n from "../../../../../i18n-js/index.js.erb";
import WidgetLoader from "../../../WidgetLoader";

import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";

function PreviewVoucher(props) {
  const dispatch = useDispatch();
  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );
  const currentUser = useSelector((state) => state.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue, watch, errors, control } =
    useForm();

  var previewVoucherBkg = stepsVoucherCreationParams.step3?.custom_design_id
    ? stepsVoucherCreationParams.images.images.filter(
        (x) =>
          x.attachment_id === stepsVoucherCreationParams.step3.custom_design_id
      )[0]?.url
    : stepsVoucherCreationParams.step3.custom.url;

  const useStyles = makeStyles((theme) => ({
    customDesign: {
      width: "100% !important",
      maxWidth: "100% !important",
      height: "75% !important",
      margin: "0 auto 5px auto !important",
      backgroundImage: `url(${previewVoucherBkg})`,
      borderRadius: "5px",
      padding: "20px",
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
  }));

  useEffect(() => {
    if (!stepsVoucherCreationParams.step5.status) {
      axios
        .post("/api/main/tracking/checkouts/viewed.json", {
          params: { step: 5, step_name: "previsualize" },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) {});
    }

    !currentUser.id &&
      dispatch(
        setDesktopUserLoginFormStatus({
          open: true,
          selectRegister: true,
          notClosable: true,
        })
      );
  }, []);

  const localClasses = useStyles();

  const onSubmit = () => {
    setIsLoading(true);

    axios
      .post("/widget/widget_vouchers", { ...stepsVoucherCreationParams })
      .then((response) => {
        dispatch(
          setStepsVoucherCreationParams({
            ...stepsVoucherCreationParams,
            step5: {
              status: true,
              code: response.data.voucher_code,
              payment_intent: response.data.payment_intent,
            },
          })
        );

        props.handleClientSecret(response.data.client_secret);

        axios
          .post("/api/main/tracking/checkouts/completed.json", {
            params: { step: 5, step_name: "previsualize" },
          })
          .then((response) => {
            console.log(response.status);
          })
          .catch(function (error) {});

        props.handleNext();
      })
      .catch(function (error) {
        console.log("check error", error);
      });
  };

  const classes = useStylesPreviewVoucher();

  return (
    <div>
      {isLoading ? (
        <WidgetLoader brand={props.brand} />
      ) : (
        <>
          {stepsVoucherCreationParams.step2.evoucher ? (
            <Box className={classes.previewVoucher} sx={{ boxShadow: 1 }}>
              <div className={clsx(classes.previewVoucherInner)}>
                <div className={classes.qrCode} />
                <small>
                  ______
                  <br />
                  ____________
                  <br />
                  ______
                  <br />
                </small>
              </div>
              <div className={clsx(classes.previewVoucherInner)}>
                {stepsVoucherCreationParams.step4.message}
                <br />
                {stepsVoucherCreationParams.step4.sender}
              </div>
              <div className={clsx(classes.previewVoucherInner)}>
                <img src={props.brand?.logo} width={40} />
              </div>
              <div className={clsx(classes.previewVoucherInner)}>
                <div
                  id="card_design_2"
                  className={clsx(
                    classes.miniCardDesign,
                    stepsVoucherCreationParams.step3.custom_design_id !==
                      undefined
                      ? localClasses.customDesign
                      : stepsVoucherCreationParams.step3.design
                  )}
                ></div>
                <p className={classes.cardTitle}>
                  {stepsVoucherCreationParams.step4.title}
                </p>
              </div>
            </Box>
          ) : (
            <div className="text-center">
              <img src="https://testmaxime.s3.us-east-2.amazonaws.com/photos/images/default/gift/physical_card.png" />
            </div>
          )}
          <br />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Button className={classes.fullWidth} type="submit">
              {I18n.t("new_gift__preview_button")}
            </Button>
          </Form>
        </>
      )}
    </div>
  );
}

export default PreviewVoucher;
