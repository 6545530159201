import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { Dialog, makeStyles, TextField } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import I18n from "../../../../i18n-js/index.js.erb";
import Emoji from "a11y-react-emoji";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import clsx from "clsx";
import {setSelectedPax} from "../../../redux/actions/actions";

function SelectPax(props) {

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false)
  const [selectedAdults, setSelectedAdults] = useState(0);
  const [selectedKids, setSelectedKids] = useState(0);
  const selectedPax = useSelector((state) => state.selectedPax);

  useEffect(() => {
    setSelectedAdults(selectedPax.adults ? selectedPax.adults : 0)
    setSelectedKids(selectedPax.kids ? selectedPax.kids : 0)
  }, [selectedPax])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveAdults = () => {
    if (selectedAdults > 0) {
      setSelectedAdults(selectedAdults - 1);
    }
  }

  const handleAddAdults = () => {
    if (selectedAdults < 10) {
      setSelectedAdults(selectedAdults + 1);
    }
  };

  const handleRemoveKids = () => {
    if (selectedKids > 0) {
      setSelectedKids(selectedKids - 1);
    }
  }

  const handleAddKids = () => {
    if (selectedKids < 10) {
      setSelectedKids(selectedKids + 1);
    }
  };

  const handleSelectedPax = () => {
    setOpen(false)
    dispatch(setSelectedPax({adults: selectedAdults, kids: selectedKids, total: selectedAdults + selectedKids}))
  }

  const useStyles = makeStyles((theme) => ({
    paxButton: {
      left: '25%',
      borderRadius: '20px',
      background: 'white',
      fontSize: '14px',
      textTransform: 'capitalize'
    },
    primaryColor: {
      backgroundColor: "#0098d7",
      color: "white !important",
    },
    paxSelector: {
      fontSize: "32px",
      fontWeight: "600 !important",
    },
    validateBtn: {
      textAlign: 'center',
      width: '100%',
    },
    marginB: {
      marginBottom: '10px'
    },
    closeButton: {
      float: 'right',
      marginTop: '-10px;'
    },

  }))

  const classes = useStyles();

  const showAdults = (adults) => {
    return ("🧑 " + adults)
  }

  const showKids = (kids) => {
    return (" + 🧒 " + kids)
  }

  return (
    <React.Fragment>
    <Button className={classes.paxButton} onClick={handleClickOpen} variant="contained">
      {selectedPax.total > 0 ? (
        <span>
          {selectedAdults !== 0 && showAdults(selectedAdults)}
          {selectedKids !== 0 && showKids(selectedKids)}
        </span>
      ) :
        <span>{I18n.t("pax__button")}</span>
      }
    </Button>

    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{I18n.t("pax__modal_title")}
          <IconButton className={classes.closeButton} edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className={clsx("col-xs-12", classes.marginB)}><strong>{I18n.t("pax__modal_adults")}</strong><br /></div>
            <div className="col-xs-4 text-center">
              <Fab
                className={classes.primaryColor}
                aria-label="remove"
                onClick={handleRemoveAdults}
                disabled={selectedAdults <= 0}
              >
                <RemoveIcon />
              </Fab>
            </div>
            <div className="col-xs-4 text-center">
              <p className={classes.paxSelector}>{selectedAdults}</p>
            </div>
            <div className="col-xs-4 text-center">
              <Fab
                className={classes.primaryColor}
                aria-label="add"
                onClick={handleAddAdults}
                disabled={selectedAdults >= 10}
              >
                <AddIcon />
              </Fab>
            </div>
          </div>
          <div className="row">
            <div className={clsx("col-xs-12", classes.marginB)}><strong dangerouslySetInnerHTML={{ __html: I18n.t("pax__modal_kids_html") }} /></div>
            <div className="col-xs-4 text-center">
              <Fab
                className={classes.primaryColor}
                aria-label="remove"
                onClick={handleRemoveKids}
                disabled={selectedKids <= 0}
              >
                <RemoveIcon />
              </Fab>
            </div>
            <div className="col-xs-4 text-center">
              <p className={classes.paxSelector}>{selectedKids}</p>
            </div>
            <div className="col-xs-4 text-center">
              <Fab
                className={classes.primaryColor}
                aria-label="add"
                onClick={handleAddKids}
                disabled={selectedKids >= 10}
              >
                <AddIcon />
              </Fab>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={clsx("btn", "btn-primary", classes.validateBtn)} onClick={handleSelectedPax}variant="contained">{I18n.t("pax__modal_validate")}</div>
        </DialogActions>
    </Dialog>
    </React.Fragment>
  )

}

export default SelectPax;
