import React from "react";
import { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import NavDesktop from "../main/nav/NavDesktop";
import { useSelector, useDispatch } from "react-redux";
import {
  changeLocale,
  addCSRFToken
} from "../redux/actions/actions";
import I18n from "../../i18n-js/index.js";
import { createMedia } from "@artsy/fresnel";
import NavMobile from "../main/nav/NavMobile";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    lg: 1024,
  },
});

// Put your API key here
builder.init("51c4483a55434a2c87bd705c818c4de4");

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function CatchAllRoute(props) {
  const dispatch = useDispatch();
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  console.log(props)
  dispatch(changeLocale(props.locale));
  dispatch(addCSRFToken(props.csrf_token));
  const currentLocale = useSelector((state) => state.currentLocale)
  I18n.locale = currentLocale;

  // get the page content from Builder
   useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: window.location.pathname
        })
        .promise();

      setContent(content);
      setNotFound(!content);

      // if the page title is found,
      // set the document title
      if (content?.data.title) {
       document.title = content.data.title
      }
    }
    fetchContent();
  }, [window.location.pathname]);

  // If no page is found, return
  // a 404 page from your code.
  // The following hypothetical
  // <FourOhFour> is placeholder.
  // if (notFound && !isPreviewingInBuilder) {
  //   return <FourOhFour/>
  // }
  // return the page when found
  return (
    <>
      <MediaContextProvider>
        <Media at="sm">
          <NavMobile source={"landing_page"} />
          {/* Render the Builder page */}
          <BuilderComponent model="page" content={content} />
        </Media>
        <Media at="lg">
          <NavDesktop source={"landing_page"} />
          {/* Render the Builder page */}
          <BuilderComponent model="page" content={content} />
        </Media>
      </MediaContextProvider>
    </>
  );
}