import React from "react"

class FavoriteOffNoUser extends React.Component {

    render () {
        return(
                <div className="favoriteBtn__wrapper_favoriteOff" data-toggle="modal" data-target="#favoritesModal" onClick={ (ev) => ev.preventDefault() }>
    		        <i className="far fa-heart"></i> 
                </div>
        )
    }

}

export default FavoriteOffNoUser