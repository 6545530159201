import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import I18n from "../../../i18n-js/index.js.erb";
import MailCheck from "react-mailcheck";
import libphonenumbers from "libphonenumbers";
import React, { useState } from "react";
// reactstrap components
import { FormGroup, Label } from "reactstrap";

function ReservationUserForm(props) {
  const [email, setEmail] = useState();

  const [phone, setPhone] = useState();

  let form_style = {
    width: "100%",
    borderRadius: "unset",
    height: "4rem",
  };

  let email_suggestion_link = {
    textDecoration: "underline",
    cursor: "pointer",
  };

  const [phoneError, setPhoneError] = useState(false);

  const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();

  const isValid = (value, country) => {
    const countryUp = country.toUpperCase();
    if (phoneUtil.parseAndKeepRawInput(value, countryUp)) {
      const number = phoneUtil.parseAndKeepRawInput(value, countryUp);
      if (!phoneUtil.isValidNumberForRegion(number, countryUp)) {
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    } else {
      setPhoneError(false);
    }
  };

  I18n.locale = props.current_locale;

  return (
    <div>
      <div id="loginButton">
        <div className="col-md-12 checkout_login no-padding">
          <div className="social-auth__container">
            <div className="col-md-12 col-xs-12 no-padding">
              <p>{I18n.t("sign_up_form_title")}</p>
            </div>
            <a
              href="/auth/google_oauth2"
              className="btn btn-primary google half-width no-padding"
            >
              <span className="fab fa-google"></span>{" "}
              {I18n.t("sign_up_with", { media: "Google" })}
            </a>
            {/* <a
              href="/auth/facebook"
              className="btn btn-primary facebook half-width no-padding"
            >
              <span className="fab fa-facebook"></span>{" "}
              {I18n.t("sign_up_with", { media: "Facebook" })}
            </a> */}
          </div>
          <br />
        </div>
      </div>
      <div className="col-md-12 no-padding">
        <p>{I18n.t("complete_guest_info")}</p>
        <div className="col-md-6 no-padding-6-l">
          <Label for="reservation_name">
            <i className="fas fa-user"></i> {I18n.t("form_name")}
          </Label>
          <input
            type="text"
            name="reservation_name"
            className="form-control"
            placeholder={I18n.t("form_name")}
            autoFocus="true"
            required
            minLength="3"
          />
        </div>
        <div className="col-md-6 no-padding-6-r">
          <Label for="reservation_lastname"> {I18n.t("form_last_name")}</Label>
          <input
            type="text"
            name="reservation_lastname"
            className="form-control"
            placeholder={I18n.t("form_last_name_ex")}
            required
            minLength="3"
          />
        </div>
      </div>
      <div className="col-md-12 no-padding">
        <Label for="reservation_email">
          <i className="fas fa-envelope"></i> {I18n.t("form_email")}
        </Label>
        <MailCheck email={email}>
          {(suggestion) => (
            <div>
              <input
                type="email"
                value={email}
                name="reservation_email"
                onChange={(ev) => setEmail(ev.target.value)}
                className="form-control"
                placeholder={I18n.t("form_email")}
              />
              {suggestion && (
                <div>
                  {I18n.t("do_you_mean")}{" "}
                  <span
                    style={email_suggestion_link}
                    onClick={(ev) => setEmail(suggestion.full)}
                  >
                    {suggestion.full}
                  </span>
                  ?
                </div>
              )}
            </div>
          )}
        </MailCheck>
      </div>
      <div className="col-md-12 no-padding">
        <br />
        <p>
          <input type="checkbox" name="gdpr" />
          {I18n.t("gdpr_sentence")}
        </p>
      </div>
      <div className="col-md-6 no-padding-6-l">
        <FormGroup>
          <Label
            for="reservation_phone"
            id="phoneLabel"
            className={phoneError ? "error" : ""}
          >
            <i className="fas fa-phone"></i> {I18n.t("form_phone")}
          </Label>
          <PhoneInput
            country={props.locale}
            value={phone}
            onChange={setPhone}
            onBlur={(e, country) =>
              isValid(e.target.value, country.countryCode)
            }
            placeholder={I18n.t("form_phone")}
            countryCodeEditable={false}
            inputProps={{
              name: "reservation_phone",
              required: true,
            }}
            inputStyle={form_style}
          />
          {phoneError && <p className="error">{I18n.t("wrong_phone")}</p>}
        </FormGroup>
      </div>
      <div className="col-md-6 no-padding-6-r">
        {props.room_country === "Spain" && (
          <>
            <Label for="reservation_dni">
              <i className="fas fa-id-card"></i> {I18n.t("identity_number")}
            </Label>
            <input
              type="text"
              name="reservation_dni"
              className="form-control"
              placeholder="ej: 13149093E"
              length="7"
              required
            />
            <br />
          </>
        )}
      </div>
    </div>
  );
}

export default ReservationUserForm;
