import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import WidgetUserRegister from "./WidgetUserRegister";
import { useSelector, useDispatch } from "react-redux";
import { setDesktopUserLoginFormStatus } from "../../../redux/actions/actions";
import I18n from "../../../../i18n-js/index.js.erb";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";

function WidgetUserLoginWrap(props) {
  const dispatch = useDispatch();

  const desktopUserLoginFormStatus = useSelector(
    (state) => state.desktopUserLoginFormStatus
  );

  const handleUserLoginClose = () => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: false,
        register: false,
        signin: false,
        selectRegister: false,
        selectSignin: false,
        notClosable: desktopUserLoginFormStatus.notClosable ? true : false,
      })
    );
  };

  const useStyles = makeStyles((theme) => ({
    loginBtn: {
      border: "1px solid #2d2d2d",
      borderRadius: "5px",
      padding: "5px 10px !important",
      marginTop: "10px",
      color: "#2d2d2d !important",
      fontWeight: "500",
      minHeight: "35px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      textAlign: "center",
      height: "48px",
      backgroundColor: "white",
      "&:hover": {
        textDecoration: "none",
        boxShadow: "0 0 4px  rgba(0,0,0,0.45)",
      },
    },
    loginText: {
      textAlign: "center",
      display: "inline-block",
      width: "100%",
      textDecoration: "none",
    },
    loginIcon: {
      maxWidth: "20px",
      float: "left",
      width: "20px",
      fontSize: "17px",
      display: "block",
      textDecoration: "none",
    },
    facebookLogo: {
      color: "#3b5998",
    },
    actions: {
      justifyContent: "center !important",
    },
    horizontalEelements: {
      display: "flex",
      justifyContent: "space-between",
    },
    selectMode: {
      textDecoration: "underline",
      cursor: "pointer",
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <div>
        <Dialog
          open={desktopUserLoginFormStatus.open}
          onClose={
            desktopUserLoginFormStatus.notClosable
              ? false
              : handleUserLoginClose
          }
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          scroll={"body"}
          fullWidth={true}
          maxWidth={"xs"}
          fullScreen={props.fullscreen}
        >
          <>
            <DialogTitle>
              <div className={classes.horizontalEelements}>
                {I18n.t("sign_up")}
                <div>
                  {!desktopUserLoginFormStatus.notClosable && (
                    <ClearIcon
                      onClick={() => handleUserLoginClose()}
                      style={{ fontSize: 30 }}
                    />
                  )}
                </div> 
              </div>
            </DialogTitle>
            <DialogContent>
              <WidgetUserRegister
                subscription={props.subscription}
              ></WidgetUserRegister>
            </DialogContent>
          </>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default WidgetUserLoginWrap;
