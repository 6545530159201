import React, { useEffect } from 'react'
import { useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import axios from 'axios';
import { Box, Popper } from '@mui/material';
import Typography from '@mui/material/Typography';
import {capitalizeFirstLetter} from "../../../helpers/base"
import { NavLink } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import I18n from "../../../i18n-js/index.js.erb";
import clsx from 'clsx';


function Search(props) {

  const [popperOpen, setPopperOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(false);
  const [searchOptionSelected, setSearchOptionSelected] = useState(false);

  const handleSearchSelected = (event, value) => {
    window.open(value.url, "_blank");
  }

  const CustomPopper = (props) => {
    return (
      <Popper
        {...props}
        className={clsx(classes.popper)}
      />
    )
  }

  const useStyles = makeStyles((theme) => ({
    hotelName: {
      fontSize: '1.1em',
      position: 'relative',
      bottom: '-10px !important',
    },
    title: {
      fontSize: '24px',
      top: '-5px !important',
      position: 'relative',
      bottom: 0
    },
    price: {
      fontWeight: 800,
      float: 'right',
      fontSize: '14px',
      textAlign: 'right',
      marginTop: '-4px',
    },
    fullPopper: {
      width: "500px !important"
    },

  }))

  const classes = useStyles();

  return (
    <Autocomplete
      id="free-solo-demo"
      open={popperOpen}
      onOpen={() => {
        // only open when in focus and inputValue is not empty
        if (searchValue) {
          setPopperOpen(true);
        }
      }}
      onClose={() => setPopperOpen(false)}
      forcePopupIcon={false}
      inputValue={searchValue}
      noOptionsText={I18n.t("no_options")}
      onInputChange={(e, value, reason) => {
        setSearchValue(value);

        // only open when inputValue is not empty after the user typed something
        if (!value) {
          setPopperOpen(false);
        }
      }}
      onChange={(event, value) => handleSearchSelected(event, value)}
      options={props.results}
      placeholder={I18n.t("search_hotelbreak_ex")}
      className={classes.popper}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 }, maxHeight: '60px', maxWidth: '100%', width: '100%' }}
          {...props}
        >
          <img
            loading="lazy"
            width="45"
            height="45"
            style={{objectFit: 'cover', borderRadius: '5px'}}
            src={`https://www.hotelbreak.com${option.photo}`}
            srcSet={`https://www.hotelbreak.com${option.photo} 2x`}
            alt={option.title}
          />
          <div sx={{display: 'flex', flexFlow: 'column', width: '100px'}}>
            <Typography noWrap sx={{fontSize: '13px', width: props.mobile_header ? '270px' : '320px', top: '0px', alignSelf: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis'}}>{option.title}</Typography>
            <p className={classes.hotelName}>
              {option.hotel && `${option.hotel} · `}
              {capitalizeFirstLetter(option.location)}{" "}
              <span className={classes.price}>
                {I18n.t('price_from', {price: to_eur(option.price), currency: option.currency})}
              </span>
            </p>
          </div>
        </Box>
      )}
      renderInput={(params) => <TextField {...params} placeholder={I18n.t("search_hotelbreak_ex")} InputLabelProps={{ shrink: true }} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, bgcolor: 'white'}}
      /> }
    />
  )
}

export default Search;