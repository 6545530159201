export function add_margin(price, margin) {
	if (margin === 100) {
		var result = price.toFixed(2);

		if (result % 1 === 0) {
				return result;
		} else {
				return result.toFixed(2);
		}

	} else {
		var result = ((Math.ceil(((price * 100) / (1 - (margin / 100))) / 100) / 50) + 1) * 50

		if (result%1 === 0) {
				return result;
		} else {
				return result.toFixed(2);
		}
	}
};
