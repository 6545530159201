import { makeStyles } from "@material-ui/core/styles";

var borderColors = "gainsboro";
var borderColorsSelected = "#6BD098";
var campaignColor = "#FBC658"

const useStylesPriceSelection = makeStyles((theme) => ({
  priceBox: {
    borderRadius: "2px",
    flex: '0 1 auto',
    width: '23%',
    border: `1.5px solid ${borderColors}`,
    borderRadius: "8px",
    cursor: "pointer",
    padding: '10px 20px',
  },
  priceBoxMobile: {
    borderRadius: "2px",
    flex: '0 1 auto',
    width: '48%',
    border: `1.5px solid ${borderColors}`,
    borderRadius: "8px",
    cursor: "pointer",
    padding: '10px 20px',
    marginBottom: '10px'
  },
  priceBoxWrap: {
    display: "flex",
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '20px'
  },
  productWrap: {
    display: "flex",
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  productWrapMobile: {
    display: "flex",
    justifyContent: 'space-between',
    marginBottom: '20px',
    flexWrap: 'wrap'
  },
  productBox: {
    flex: '0 1 auto',
    width: '48%',
    '&:first-child': {
      order: 2,
    }
  },
  productBoxMobile: {
    flex: '0 1 auto',
    width: '100%',
    '&:first-child': {
      order: 2,
    }
  },
  productBoxMobile: {
    flex: '0 1 auto',
    width: '48%'
  },
  productDescMobile: {
    flexGrow: 1,
    width: '100%',
    order: 3
  },
  productImage: {
    maxWidth: '100%',
    borderRadius: '10px'
  },
  discountBox: {
    height: "fit-content",
    background: campaignColor,
    borderRadius: '10px',
    padding: '10px 20px',
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center'
  },
  alertBox: {
    height: "fit-content",
    background: '#d3d3d352',
    borderRadius: '10px',
    padding: '10px 20px',
    display: 'flex',
    marginBottom: '20px',
    marginTop: '20px',
    alignItems: 'center'
  },
  discountBoxElemTitle: {
    flex: '1 0 30%',
    lineHeight: '90%',
    '& small': {
      lineHeight: '80% !important',
    }
  },
  discountBoxTitle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '15px',
    display: 'block'
  },
  discountBoxEmoji: {
    fontSize: '20px',
    width: '40px'
  },
  price: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    color: '#000000',
    paddingTop: '4px'
  },
  subText: {
    color: 'rgba(0,0,0,0.5)',
    display: 'block',
    fontSize: '12px',
    lineHeight: '12px',
    paddingTop: '4px'
  },
  priceField: {
    borderRadius: '8px',
    width: '23%',
  },
  priceFieldMobile: {
    borderRadius: '8px',
    width: '100%',
  },
  priceFieldLabel: {
    fontSize: '14px',
    color: 'rgba(0,0,0,0.5)'
  },
  selected: {
    border: `1.5px solid ${borderColorsSelected}`,
    color: borderColorsSelected,
  },
  overText: {
    fontSize: '10px',
    color: 'rgba(0,0,0,0.7)',
    display: 'block',
    lineHeight: '8px',
    marginTop: '-2px'
  },
  campaignPrice: {
    color: campaignColor,
  },
  priceBoxColumns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  priceBoxColumn: {
    flex: '1 1 auto',
    width: '50%',
  },
  // format selection styles
  formatBox: {
    borderRadius: "2px",
    flex: '0 1 auto',
    width: '48%',
    height: "fit-content",
    border: `1.5px solid ${borderColors}`,
    borderRadius: "8px",
    cursor: "pointer",
    padding: '10px 20px'
  },
  formatBoxMobile: {
    borderRadius: "2px",
    flex: '0 1 auto',
    width: '100%',
    height: "fit-content",
    border: `1.5px solid ${borderColors}`,
    borderRadius: "8px",
    cursor: "pointer",
    padding: '10px 20px',
    marginBottom: '10px'
  },
  formatBoxImage: {
    maxWidth: '100%'
  },
  formatBoxImageMobile: {
    maxWidth: '33% !important',
    height: '66px'
  },
  formatBoxText: {
    maxWidth: '100%',
  },
  formatBoxTextMobile: {
    maxWidth: '60% !important',
    float: 'right'
  },
  formatBoxTitle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: '18px',
    marginTop: '5px',
  },
  fullWidth: {
    width: '100% !important'
  },
  portCostTooltip: {
    textTransform: 'lowercase'
  },
  filtered: {
    opacity: "0.2 !important",
    cursor: 'not-allowed'
  }
}));

const useStylesChooseDesign = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap !important",
    bottom: "-29px",
    right: "-20px",
    position: "static",
    overflowY: "auto",
  },
  title: {
    paddingLeft: "10px",
  },
  gridWrapper: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    marginRight: "0px",
  },
  buttonLeft: {
    position: "absolute",
    marginTop: "15%",
    marginLeft: "20px",
    zIndex: "10",
    background: "white",
  },
  buttonRight: {
    position: "absolute",
    zIndex: "10",
    right: "10px",
    background: "white",
    marginTop: "15%",
    marginRight: "10px",
  },
  custom: {
    border: "1px solid grey",
    background: "whitesmoke",
    width: "100%",
    maxWidth: "300px",
    height: "200px",
    margin: "0 auto 20px auto",
    borderRadius: "5px",
    padding: "20px",
    textAlign: 'center',
    fontSize: '20px',
    cursor: "pointer",
  },
  selectedDesign: {
    border: "3px solid #6BD098",
    background: "whitesmoke",
    width: "100%",
    maxWidth: "300px",
    height: "200px",
    margin: "0 auto 20px auto",
    borderRadius: "5px",
    padding: "20px",
  },
  overRideImageList: {
    width: 'unset !important'
  },
  cardDesign: {
    width: '250px !important',
    maxWidth: '250px !important',
    height: '300px !important',
    margin: '0 auto 20px auto',
    borderRadius: '5px',
    padding: '20px',
    backgroundPosition: 'center'
  },
  customButton: {
    width: '60px',
    borderRadius: '100px',
    border: '1px solid rgba(0,0,0,0.8)',
    fontSize: '25px',
    textAlign: 'center',
    height: '60px',
    paddingTop: '10px',
    margin: '16% auto 10px',
  }
}));

const useStylesCustomizeDesign = makeStyles((theme) => ({
  prevVoucher: {
    border: "1px solid grey",
    background: "whitesmoke",
    width: "100%",
    maxWidth: "300px",
    height: "200px",
    margin: "0 auto 20px auto",
    borderRadius: "5px",
    padding: "20px",
  },
  cardDesign: {
    width: '250px !important',
    maxWidth: '250px !important',
    height: '300px !important',
    margin: '0 auto 20px auto',
    borderRadius: '5px',
    padding: '20px',
    backgroundPosition: 'center'
  },
  fullWidth: {
    width: '100% !important',
    '& .react-datepicker-wrapper': {
      width: '100% !important',
    }
  },
  labelNoMarginTop: {
    marginTop: '0px !important'
  }
}));

const useStylesPreviewVoucher = makeStyles((theme) => ({
  previewVoucher: {
    border: "1px solid grey",
    background: "white",
    width: "210px",
    maxWidth: "210px",
    height: "297px",
    margin: "0 auto",
    borderRadius: "5px",
    display: 'flex',
    flexWrap: 'wrap',
  },
  logo: {
    textAlign: 'center',
    width: '50px',
  },
  logoBlock: {
    width: '100%',
    height: '20px',
    textAlign: 'center',
  },
  previewVoucherInner: {
    flex: '1 1 auto',
    width: '50%',
    height: '45%',
    padding: "20px 15px",
    background: 'white',
    '&:nth-child(2)': {
      textAlign: 'center',
    },
    '&:nth-child(3)': {
      paddingTop: '20px',
      paddingLeft: '15px',
      fontSize: '40%',
      fontWeight: '800'
    },
    '&:nth-child(4)': {
      fontSize: '60%',
      fontWeight: '800',
      background: '#F7F7F7'
    },
    '&:nth-child(5)': {
      background: '#F7F7F7',
      paddingLeft: '15px'
    },
  },
  qrCode: {
    marginTop: '20%',
    background: 'darkgrey',
    width: '2.5rem',
    height: '2.5rem',
    margin: '0 auto'
  },
  miniCardDesign: {
    width: '100% !important',
    maxWidth: '100% !important',
    height: '85% !important',
    margin: '0 auto 5px auto !important',
    borderRadius: '5px',
    padding: '20px',
    backgroundPosition: 'center'
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: '10px',
    lineHeight: 1,
    wordWrap: 'break-word',
    width: '110%'
  },
  fullWidth: {
    width: '100% !important'
  }
}));

export {
  useStylesPriceSelection,
  useStylesChooseDesign,
  useStylesCustomizeDesign,
  useStylesPreviewVoucher,
};
