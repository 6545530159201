import * as React from "react";
import { styled } from "@mui/system";
import { TextField } from "@material-ui/core";
import I18n from "../../../i18n-js/index.js.erb";

const AreaSelectortextfield = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-outlined": {
    transform: "translate(10px, 10px) scale(1)",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)",
  },
  "& .MuiOutlinedInput-root": {
    boxShadow: "0px 4px 6px gainsboro",
  },
}));

export default function AreaSelectortextfieldStyled(props) {
  return (
    <AreaSelectortextfield
      fullWidth
      id="outlined-basic"
      label={I18n.t("select_city")}
      type="search"
      variant="outlined"
      onChange={(ev) => props.onSearchArea(ev)}
    />
  );
}
