import React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "images/logo.svg";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AreaCategoryNav from "./AreaCategoryNav";
import UserNav from "./UserNav";
import AreaNav from "./AreaNav";
import { useSelector, useDispatch } from "react-redux";
import { capitalizeFirstLetter, to_eur } from "../../../helpers/base.js";
import Drawer from "@material-ui/core/Drawer";
import { setDesktopUserLoginFormStatus, setCurrentUser } from "../../redux/actions/actions";
import I18n from "../../../i18n-js/index.js";
import clsx from "clsx";
import LanguageSelector from "../components/buttons/languageSelector";
import SelectArea from "../components/dialogs/SelectArea";
import { withStyles } from "@material-ui/core/styles";
import { useCookies } from 'react-cookie';

function NavDesktop(props) {
  const dispatch = useDispatch();

  const selectedArea = capitalizeFirstLetter(
    useSelector((state) => state.area)
  );

  const selectedAreaName = useSelector((state) => state.areaName);
  const currentLocale = useSelector((state) => state.currentLocale);
  const currentUser = useSelector((state) => state.currentUser);

  const [menu, setMenu] = useState(props.menu);
  const [open, setOpen] = useState(false);
  const [menuType, setMenuType] = useState("principal");
  const [cookies, setCookie, removeCookie] = useCookies(['jwt']);

  const StyledDialog = withStyles({
    paper: {
      width: "120rem",
      height: "50rem",
    },
  })(Dialog);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserNav = () => {
    setMenuType("user");
  };

  const handleAreaNav = () => {
    setMenuType("area");
  };

  const handleIsMenu = () => {
    setMenuType("principal");
  };

  const handleMenu = () => {
    setMenu(true);
  };

  const handleMenuUser = () => {
    setMenu(true);
    setMenuType("user");
  };

  const menuClose = () => {
    setMenu(false);
    setMenuType("principal");
  };

  const handleUserLoginOpen = () => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: true,
        signin: false,
        register: false,
        selectRegister: false,
        selectSignin: true,
      })
    );
  };

  const handleUserRegisterOpen = () => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: true,
        signin: false,
        register: false,
        selectRegister: true,
        selectSignin: false,
      })
    );
  };

  const handleUserSignOut = () => {
    removeCookie('jwt', { path: '/' });
    dispatch(
      setCurrentUser({
        id: false,
        role: false,
        isInformationComplete: { country: false, status: false },
        loggedIn: false,
      })
    );
    window.location.href = "/sign_out";
  };

  const experienceClick = () => {
    selectedArea
      ? (window.location.href = `/${selectedArea}`)
      : props.landing
        ? props.handleClickOpen
        : handleClickOpen;
  };

  const useStyles = makeStyles((theme) => ({
    closeButton: {
      float: "right",
      marginTop: "-10px;",
    },
    buttonText: {
      color: "black",
      fontSize: "14px",
      textTransform: "none !important",
      float: "right",
      margin: "15px 40px 0 0",
      fontFamily: "TobiasTrial-SemiBold",
    },
    buttonTextLeft: {
      color: "black",
      fontSize: "14px",
      textTransform: "none !important",
      top: "3px !important",
      position: "relative",
      fontFamily: "TobiasTrial-SemiBold",
      cursor: "pointer",
      "&:after": {
        content: " ' ' ",
        border: "solid black",
        borderWidth: "0 1px 1px 0",
        display: "inline-block",
        padding: "3px",
        marginLeft: "10px",
        marginBottom: "2px",
        transform: "rotate(45deg)",
      },
    },
    menuButton: {
      backgroundColor: "transparent",
      padding: "12px 5px",
      float: "right",
      marginRight: "15px",
      color: "black",
      textDecoration: "none",
      marginTop: "7px",
      "&:hover": {
        textDecoration: "none",
      },
    },
    accountButton: {
      padding: "12px 5px",
      float: "right",
      color: "black",
      textDecoration: "none",
      marginTop: "5px",
      "&:hover": {
        textDecoration: "none",
      },
    },
    userImage: {
      borderRadius: "100px",
      width: "20px",
      marginTop: "-1px !important",
      border: "2px solid rgba(0,0,0,0.8)",
    },
    menuButtonWithText: {
      backgroundColor: "white",
      padding: "6px 15px",
      float: "right",
      marginRight: "15px",
      color: "black",
      textDecoration: "none",
      marginTop: "14px",
      "&:hover": {
        textDecoration: "none",
      },
    },
    logoBlock: {
      backdropFilter: "blur(3px)",
      color: "white",
      padding: "0 20px 0 20px",
      borderRadius: "20px",
      display: "inline-block",
    },
    logo: {
      width: "150px",
    },
    loginButton: {
      backgroundColor: "#0098d7",
      color: "white",
      float: "right",
      padding: "6px 10px",
      marginTop: "14px",
      fontSize: "14px",
      textTransform: "none",
      marginRight: "20px",
      fontWeight: 800,
      "&:hover": {
        color: "#0098d7",
      },
    },
    signinButton: {
      color: "black",
      float: "right",
      padding: "7px 20px",
      marginTop: "7px",
      marginRight: "15px",
      fontSize: "14px",
      textTransform: "none",
      fontWeight: 800,
      border: "1px solid black",
      fontFamily: "TobiasTRIAL-SemiBold !important",
      borderRadius: 0,
    },
    fullWith: {
      width: "100%",
    },
    buttonTextRecommend: {
      color: "black",
      fontSize: "14px",
      textTransform: "none !important",
      margin: "15px 40px 0 0",
      fontFamily: "TobiasTrial-SemiBold",
    },
    newButtonTextRecommend: {
      color: "white",
      background: "black",
      padding: "2px",
      borderRadius: "4px",
      fontSize: "10px",
      marginRight: "2px",
    },
    signoutButton: {
      backgroundColor: "#ffb400",
      color: "white",
      float: "right",
      padding: "6px 10px",
      marginTop: "14px",
      marginRight: "15px",
      fontSize: "14px",
      textTransform: "none",
      fontWeight: 800,
      width: "calc(100% - 30px)",
      "&:hover": {
        color: "#ffb400",
      },
    },
    areaSelector: {
      backgroundColor: "black",
      color: "white",
      fontSize: "30px",
      fontWeight: "800",
      marginLeft: "-24px",
      paddingLeft: "37px",
      width: "390px !important",
      "&:hover": {
        backgroundColor: "black",
      },
    },
    emojis: {
      marginRight: "15px",
      fontSize: "15px",
    },
    fullList: {
      width: "auto",
    },
    paper: {
      overflow: "hidden",
      width: "390px !important",
    },
    mainNav: {
      padding: "8px 0 8px 24px !important",
    },
    menuBar: {
      height: "65px",
      background: "#F7EB67",
      top: 0,
      width: "100%",
      zIndex: 1000,
    },
    menuBarFixed: {
      position: "absolute",
    },
    menuBarIndex: {
      borderBottom: "1px solid #ccc",
      marginBottom: "10px",
      position: 'fixed',
    },
    accountButtonIcon: {
      fontSize: "1.5rem",
    },
    menuBarInner: {
      maxWidth: "92%",
      margin: "0 auto",
      height: "65px",
      paddingTop: "5px",
    },
    travelAgenbar: {
      background: "#9dd1c0",
      color: "black",
      padding: '5px',
      textAlign: 'center'
    }
  }));

  const classes = useStyles();

  return (
    <>
      {currentUser.role === "travel_agent" && (
        <div className={classes.travelAgenbar}>
          {I18n.t("travel_agent__navbar", {
            travel_agent_email: currentUser.email,
          })}{" "}
          |{" "}
          <a href="https://dashboard.hotelbreak.com">
            {I18n.t("travel_agent__navbar_link")}
          </a>
        </div>
      )}
      <div
        className={clsx(
          classes.menuBar,
          props.index && classes.menuBarIndex,
          props.landing && classes.menuBarFixed
        )}
      >

        <div className={classes.menuBarInner}>
          {props.pay ? (
            !props.paid ? (
              <div className={classes.logoBlock}>
                <img className={classes.logo} src={logo} data-original={logo} />
              </div>
            ) : (
              <a
                href={`/${currentLocale}${selectedArea && selectedArea != "false"
                    ? "/" + selectedArea.toLowerCase()
                    : ""
                  }`}
              >
                <div className={classes.logoBlock}>
                  <img className={classes.logo} src={logo} data-original={logo} />
                </div>
              </a>
            )
          ) : (
            <>
              <a
                href={`/${currentLocale}${selectedArea && selectedArea != "false"
                    ? "/" + selectedArea.toLowerCase()
                    : ""
                  }`}
              >
                <div className={classes.logoBlock}>
                  <img className={classes.logo} src={logo} data-original={logo} />
                </div>
              </a>

              {!props.landing && (
                <Button
                  className={classes.menuButton}
                  onClick={handleMenu}
                  disableRipple
                >
                  <MenuIcon />
                </Button>
              )}
              {currentUser.id ? (
                <>
                  <Button
                    className={classes.accountButton}
                    onClick={handleMenuUser}
                    disableRipple
                  >
                    {currentUser.image_url ? (
                      <img
                        className={classes.userImage}
                        src={currentUser.image_url}
                      />
                    ) : (
                      <i
                        className={clsx(
                          "fas fa-user-circle",
                          classes.accountButtonIcon
                        )}
                      ></i>
                    )}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={classes.signinButton}
                    onClick={handleUserRegisterOpen}
                    disableRipple
                  >
                    {I18n.t("sign_up")}
                  </Button>
                </>
              )}
              <LanguageSelector source={props.source} />

              <span
                className={classes.buttonTextLeft}
                onClick={props.landing ? props.handleClickOpen : handleClickOpen}
              >
                {selectedAreaName ? selectedAreaName : I18n.t("select_city")}
              </span>

              {/* <a href={`/${currentLocale}/${I18n.t("routes.about")}`}>
                <span className={classes.buttonText}>{I18n.t('about')}</span>
              </a> */}
              <a href={`/${currentLocale}/${I18n.t("routes.create_gift")}`}>
                <span className={classes.buttonText}>
                  {I18n.t("give_daycation")}
                </span>
              </a>
              {selectedAreaName == 'Mallorca' && currentLocale == 'es' ?
                <a href="#tally-open=w42YqY&tally-emoji-text=👋&tally-emoji-animation=wave" style={{ position: "relative", textDecoration: "underline", color: "black" }}>
                  <span className={classes.buttonText}>
                    <span className={classes.newButtonTextRecommend}>NEW</span>
                    Recomiéndame experiencias
                  </span>
                </a>
                : null}
              {/* <a href={`/${I18n.t("routes.blog")}`}>
                <span className={classes.buttonText}><div dangerouslySetInnerHTML={{ __html: I18n.t('blog_html') }} /></span>
              </a> */}
              {/* <a onClick={experienceClick} style={{cursor: 'pointer'}}>
                <span className={classes.buttonText}>{I18n.t('guides_experiences_title')}</span>
              </a> */}
              {currentUser.id && currentUser.wallet && (
                <a href={`/${currentLocale}/wallet`}>
                  <span className={classes.buttonText}>
                    Wallet: {to_eur(currentUser?.wallet?.balance)}€
                  </span>
                </a>
              )}
            </>
          )}

          <Drawer
            open={menu}
            onClose={menuClose}
            anchor={"right"}
            className={classes.paper}
            style={{ width: "390px !important" }}
          >
            {menuType === "principal" && (
              <DialogTitle id="alert-dialog-title">
                <img className={classes.logo} src={logo} data-original={logo} />
                <IconButton
                  className={classes.closeButton}
                  edge="end"
                  color="inherit"
                  onClick={menuClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
            )}
            {menuType === "user" && (
              <DialogTitle id="alert-dialog-title" onClick={handleIsMenu}>
                <IconButton edge="start" color="inherit" aria-label="close">
                  <ArrowBackIosIcon />
                </IconButton>
                {I18n.t("my_account")}
              </DialogTitle>
            )}
            {menuType === "area" && (
              <DialogTitle id="alert-dialog-title" onClick={handleIsMenu}>
                <IconButton edge="start" color="inherit" aria-label="close">
                  <ArrowBackIosIcon />
                </IconButton>
                {I18n.t("select_city")}
              </DialogTitle>
            )}
            {menuType === "principal" && (
              <DialogContent className={classes.mainNav}>
                <List component="nav" className={classes.fullList}>
                  {selectedAreaName == 'Mallorca' && currentLocale == 'es' ?
                    <a href="#tally-open=w42YqY&tally-emoji-text=👋&tally-emoji-animation=wave" style={{ top: "-10px", position: "relative", textDecoration: "underline", color: "black" }}>
                      <span className={classes.buttonTextRecommend}>
                        <span className={classes.newButtonTextRecommend}>NEW</span>
                        Recomiéndame experiencias
                      </span>
                    </a>
                    : null}
                  <ListItem
                    button
                    className={classes.areaSelector}
                    onClick={handleAreaNav}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={10}>
                        {props.areaName ? props.areaName : I18n.t("select_city")}
                      </Grid>

                      <Grid item xs={2}>
                        <ArrowForwardIosIcon />
                      </Grid>
                    </Grid>
                  </ListItem>

                  <br />

                  <AreaCategoryNav />
                </List>
              </DialogContent>
            )}
            {menuType === "user" && <UserNav />}
            {menuType === "area" && <AreaNav />}
            <DialogActions>
              {!currentUser.role && (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Button
                      className={clsx("btn btn-primary", classes.fullWith)}
                      onClick={handleUserLoginOpen}
                      variant="contained"
                    >
                      {I18n.t("begin_session")}
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      className={clsx("btn btn-primary", classes.fullWith)}
                      onClick={handleUserRegisterOpen}
                      variant="contained"
                    >
                      {I18n.t("sign_up")}
                    </Button>
                  </Grid>
                </Grid>
              )}

              {currentUser.role !== false && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Button
                      className={clsx("btn btn-primary", classes.fullWith)}
                      onClick={handleUserSignOut}
                      variant="contained"
                    >
                      {I18n.t("logout")}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </DialogActions>
          </Drawer>
        </div>
        <StyledDialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <SelectArea handleClose={handleClose}></SelectArea>
        </StyledDialog>
      </div>
    </>
  );
}

export default NavDesktop;
