export const defaultLang = "es";
export const supportedLangs = {
  es: "Español",
  en: "English",
  fr: "Français",
  pt: "Portugese",
  it: "Italiano",
};
export function determineUserLang(acceptedLangs) {
  const acceptedLangCodes = acceptedLangs.map(stripCountry);
  const supportedLangCodes = Object.keys(supportedLangs);
  const matchingLangCode = acceptedLangCodes.find(code =>
    supportedLangCodes.includes(code),
  );
  return matchingLangCode || defaultLang;
}
function stripCountry(lang) {
  return lang
    .trim()
    .replace("_", "-")
    .split("-")[0];
}