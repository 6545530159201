import React from "react";
import I18n from "../../i18n-js/index.js.erb";
import { useSelector } from "react-redux";
import TravelAgentUserForm from "./components/users/TravelAgentUserForm";
import { useStylesPayment } from "./styles/paymentOptionsStyles";

function TravelAgentUserStep(props) {
  const classes = useStylesPayment(props);
  const currentLocale = useSelector((state) => state.currentLocale);

  I18n.locale = currentLocale || props.locale;

  return (
    <React.Fragment>
      <br />
      <div className="col-md-7 checkout_column">
        <h4 className={classes.headings}>
          <span className="number_step">1</span>&nbsp;&nbsp;{" "}
          {I18n.t("checkout_first_step_travel_agent")}
        </h4>
        <TravelAgentUserForm {...props} />
      </div>
    </React.Fragment>
  );
}

export default TravelAgentUserStep;
