import React from "react";
import Slider from "react-slick";
import { useStylesSecondaryImagesSlider } from "../styles/useStylesSecondaryImagesSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowRight,
  faCircleArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import I18n from "../../../../i18n-js/index.js.erb";

function SecondaryImagesSlider(props) {
  const BASE_URL = props.baseUrl || '';
  const classes = useStylesSecondaryImagesSlider();
  const slideRef = React.useRef(null);

  var settings = {
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
  };

  return (
    <div className={clsx(props.phone ? classes.sliderMobile : classes.slider)}>
      <div style={{height: 0}}>
        <FontAwesomeIcon
          className={clsx(
            props.phone && classes.arrowLeftPhone,
            !props.phone && classes.arrowLeft
          )}
          onClick={() => slideRef?.current?.slickPrev()}
          icon={faCircleArrowLeft}
        />
        <FontAwesomeIcon
          className={clsx(
            props.phone && classes.arrowRightPhone,
            !props.phone && classes.arrowRight
          )}
          onClick={() => slideRef?.current?.slickNext()}
          icon={faCircleArrowRight}
        />
      </div>
      <Slider ref={slideRef} {...settings}>
        {props.secondaryImages.map((image) => {
          return (
            <div key={image.id} className={classes.experienceImageLarge}>
              <img
                key={image.id}
                className={clsx(props.phone ? classes.infoPhotoMobile : classes.infoPhoto)}
                src={`${BASE_URL}/${image.url}`}
                alt={props.title}
                title={props.title}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default SecondaryImagesSlider;
