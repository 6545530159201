import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import ProductSlide from "../components/slides/ProductSlide";
import ReviewSlide from "../reviews/ReviewSlide";
import {
  capitalizeFirstLetter,
  timizeDistance,
  findCapital,
} from "../../../helpers/base";
import { useSelector, useDispatch } from "react-redux";
import I18n from "../../../i18n-js/index.js.erb";
import LazyImage from "../../utilities/lazyload";
import Rectangle from "images/Rectangle.png";
import Emoji from "a11y-react-emoji";
import clsx from "clsx";
import { createMedia } from "@artsy/fresnel";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { changeHotelData } from "../../redux/actions/actions";
import ReviewBlock from "../reviews/ReviewBlock";
import Skeleton from "@material-ui/lab/Skeleton";
import NavMobile from "../nav/NavMobile";
import NavWrapper from "../nav/NavWrapper";
import HotelSlide from "./HotelSlide";
import { setDesktopUserLoginFormStatus } from "../../../components/redux/actions/actions";
import "react-image-lightbox/style.css";
import MetroStations from "../components/metros/metroStations";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImagesLightBox from "../components/lightbox/ImagesLightBox";
import SubscriptionForm from "../components/subscriptions/SubscriptionForm";
import {
  addCSRFToken,
  setCurrentUser,
  changeLocale,
  setAreaHomeProducts,
  changeSelectedArea,
  changeSelectedAreaName,
  setAreaHomeHotelCategories,
} from "../../redux/actions/actions";
import UserLoginWrap from "../components/modals/UserLogin/UserLoginWrap";
import slugify from "react-slugify";
import AnalyticsPoper from "../components/popups/AnalyticsPoper";

function Hotel(props) {
  const BASE_URL = props.baseUrl || '';

  const dispatch = useDispatch();
  dispatch(changeLocale(props.locale));
  dispatch(addCSRFToken(props.csrf_token));

  const hotel = props.hotel;
  const validation = props.validation;
  const currentLocale = useSelector((state) => state.currentLocale);
  const selectedAreaName = useSelector((state) => state.areaName);

  const selectedArea = capitalizeFirstLetter(
    useSelector((state) => state.area)
  );

  I18n.locale = currentLocale;

  const hotelUsedData = useSelector((state) => state.hotelUsedData);
  const currentUser = useSelector((state) => state.currentUser);

  const [openLightBox, setOpenLightBox] = useState(false);
  const [lightBoxIndex, setLightBoxIndex] = useState(0);
  const [images, setImages] = useState(false);
  const [openPhotos, setOpenPhotos] = useState(false);
  const [openPhotosBg, setOpenPhotosBg] = useState(false);
  const [openReviews, setOpenReviews] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [otherHotels, setOtherHotels] = useState();
  const [photos, setPhotos] = useState();
  const [scroll, setScroll] = useState("paper");
  const [map, setMap] = useState();
  const [metro, setMetro] = useState();
  const ref = useRef(0);
  const heightRef = useRef(0);
  const [ready, setReady] = useState(false);
  const [timeDistance, setTimeDistance] = useState();
  const [province, setProvince] = useState();
  const [city, setCity] = useState(false);
  const [viewCount, setViewCount] = useState(false);
  const csrfToken = useSelector((state) => state.csrfToken);
  const JWTToken = useSelector((state) => state.JWTToken);
  const [showMore, setShowMore] = useState(false);

  const toggleReadMore = () => {
    setShowMore(!showMore);
  };

  const charLimit = 200;
  const displayedText = showMore
    ? hotelUsedData.hotel?.summary
    : hotelUsedData.hotel?.summary.slice(0, charLimit);

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common["Authorization"] = `Bearer ${JWTToken}`;

  useEffect(() => {
    setReady(true);
  }, []);

  const hideAnalyticsPoper = () => {
    const timer = setTimeout(() => {
      setViewCount(0);
    }, 10000);
    return () => clearTimeout(timer);
  };

  const handleLightBoxOpen = (index) => {
    setOpenPhotos(false);
    setOpenPhotosBg(false);
    setOpenLightBox(true);
    setLightBoxIndex(index);
  };

  const handleChangeHotelData = (data) => {
    dispatch(changeHotelData(data));
  };

  const handlePhotosOpen = () => {
    setOpenPhotos(true);
  };

  const handlePhotosOpenBg = () => {
    setOpenPhotosBg(true);
  };

  const handlePhotosCloseBg = () => {
    setOpenPhotosBg(false);
  };

  const handlePhotosClose = () => {
    setOpenPhotos(false);
  };

  const handleReviewsOpen = () => {
    setOpenReviews(true);
  };

  const handleReviewsClose = () => {
    setOpenReviews(false);
  };

  const handleUserRegisterOpen = (source) => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: true,
        signin: false,
        register: false,
        selectRegister: true,
        selectSignin: false,
        source: source,
      })
    );
  };

  const handleFavoriteNoUser = (ev) => {
    ev.stopPropagation();
    handleUserRegisterOpen("favorite");
  };

  const handleFavoriteCreate = (ev) => {
    ev.stopPropagation();

    axios
      .get(`${BASE_URL}/api/main/favorites/create.json`, { params: { slug: hotel } })
      .then((response) => {
        setFavorite(true);
      });
  };

  const handleFavoriteDestroy = (ev) => {
    ev.stopPropagation();

    axios
      .get(`${BASE_URL}/api/main/favorites/destroy.json`, { params: { slug: hotel } })
      .then((response) => {
        setFavorite(false);
      });
  };

  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet,
      })
    );
    dispatch(changeLocale(props.current_user.user_locale));
  } else {
    dispatch(changeLocale(props.locale));
  }

  const setCookie = (area) => {
    axios
      .post(`${BASE_URL}/api/main/areas/cookie.json`, { area: slugify(area) })
      .then((response) => {
        if (response.data.status === true && area !== null) {
          dispatch(changeSelectedArea(slugify(area)));
        }
      });
  };

  useEffect(() => {
    if (props.area_cookie && selectedArea) {
      setCookie(selectedArea);
      var area_home_products_url = `/api/main/products?location=${selectedArea}&locale=${currentLocale}`;
    } else if (props.area_cookie && !selectedArea) {
      var area_home_products_url = `/api/main/products?location=${props.area_cookie}&locale=${currentLocale}`;
      setCookie(props.area_cookie);
    }
    axios.get(area_home_products_url).then((response) => {
      dispatch(
        setAreaHomeProducts(
          response.data.area_home_products
            .map((product_list) => {
              return {
                category: product_list.products,
                title: product_list.title,
                slug: product_list.slug,
                emoji: product_list.emoji,
                popularity: product_list.popularity,
                type: product_list.type,
              };
            })
            .sort((a, b) => {
              if (a.popularity > b.popularity) {
                return -1;
              } else if (
                a.popularity === "break_friday" ||
                a.popularity === "most_popular" ||
                a.popularity === "most_recent"
              ) {
                return -1;
              } else {
                return 1;
              }
            })
        )
      );
      dispatch(setAreaHomeHotelCategories(response.data.rooms_categories));
      dispatch(changeSelectedAreaName(response.data.province));
    });
  }, [selectedArea]);

  const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
    },
    mainPage: {
      background: "white",
      borderRadius: "10px 10px 0 0",
      position: "absolute",
      top: "35rem",
      width: "100%",
      zIndex: "10",
      marginBottom: "20rem",
    },
    mainPageMobile: {
      background: "white",
      borderRadius: "10px 10px 0 0",
      position: "relative",
      width: "100%",
      zIndex: "10",
    },
    mainImage: {
      width: "100%",
      height: "40rem",
      background: "whitesmoke",
      backgroundImage: `url(${photos && photos[0].block_photo})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    mainImageLarge: {
      width: "50%",
      marginLeft: "9.5%",
      height: "40rem",
      background: "whitesmoke",
      float: "left",
      position: "relative",
      backgroundImage: `url(${photos && photos[0].block_photo})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "10px 0 0 10px",
      cursor: "pointer",
    },
    secondaryImageLarge: {
      width: "30%",
      marginRight: "9.5%",
      height: "19.5rem",
      background: "whitesmoke",
      float: "right",
      backgroundImage: `url(${photos && photos[1].block_photo})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "0 10px 0 0",
      marginBottom: "1rem",
      cursor: "pointer",
    },
    tercaryImageLarge: {
      width: "30%",
      marginRight: "9.5%",
      height: "19.5rem",
      background: "whitesmoke",
      float: "right",
      backgroundImage: `url(${photos && photos[2].block_photo})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "0 0 10px 0",
      marginBottom: "1rem",
    },
    tercaryClickingZoneLarge: {
      width: "100%",
      height: "19.5rem",
      background: "transparent",
      float: "right",
      borderRadius: "0 0 10px 0",
      marginBottom: "1rem",
      cursor: "pointer",
      zIndex: 0,
    },
    anchorShow: {
      display: "block",
      marginTop: "2rem",
      textDecoration: "none",
      color: "inherit",
      outline: "0",
    },
    container: {
      margin: ".5rem .8rem",
    },
    secondaryTitle: {
      fontSize: "22px",
      fontWeight: "500 !important",
    },
    addressText: {
      fontSize: "16px",
      marginTop: "-1rem",
    },
    sameLineText: {
      display: "inline-block",
      marginRight: "1rem",
    },
    sameLineEmoji: {
      display: "inline-block",
      marginRight: "1rem",
      fontSize: "4rem",
    },
    headerSameLineEmoji: {
      display: "inline-block",
      marginRight: "1rem",
      fontSize: "2rem",
    },
    horizontalEelements: {
      display: "flex",
      justifyContent: "space-between",
    },
    paddingTop: {
      paddingTop: "2rem",
      cursor: "pointer",
    },
    positionMainButtons: {
      position: "absolute",
      top: "32rem",
      right: "3%",
    },
    positionMainButtonsLarge: {
      right: "1rem",
      float: "right",
      bottom: "-13rem",
      position: "relative",
      zIndex: 1,
    },
    buttonPhotos: {
      border: "2px solid white",
      borderRadius: "5px",
      color: `rgba(0, 0, 0, 0.9)`,
      height: "5rem",
      minWidth: "14rem",
      fontSize: "1.4rem",
      lineHeight: "2.2rem",
      fontWeight: "800",
      background: `rgba(255, 255, 255, 0.9)`,
    },
    buttonFavorites: {
      border: "2px solid white",
      borderRadius: "5px",
      color: `rgba(0, 0, 0, 0.9)`,
      height: "5rem",
      minWidth: "5.5rem",
      fontSize: "1.4rem",
      lineHeight: "2.2rem",
      fontWeight: "800",
      background: `rgba(255, 255, 255, 0.9)`,
    },

    filledFavButton: {
      color: "#e61942",
    },

    photoDialogBlock: {
      marginBottom: "2rem",
    },
    dialogTitle: {
      fontSize: "2rem",
      fontWeight: "300",
    },
    reviewBlock: {
      background: "#F4A583",
    },
    halfLargeContainer: {
      marginLeft: "10%",
      maxWidth: "50%",
      float: "initial",
    },
    largeContainer: {
      marginLeft: "9%",
      maxWidth: "82%",
      float: "initial",
      marginTop: "20px",
    },
    largeContainerSmallMargin: {
      marginLeft: "9%",
      maxWidth: "82%",
      float: "initial",
      marginTop: "-25px",
    },
    infoPhoto: {
      width: "100%",
    },
    infoPhotoLarge: {
      height: "150px",
      width: "100%",
      objectFit: "cover",
    },
    titleBlock: {
      marginTop: "20px",
    },
    infoBlock: {
      padding: "20px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      height: "fit-content",
      padding: "15px",
    },
    photoContent: {
      padding: "16px 20% 0",
    },
    stickyColumn: {
      borderRadius: "5px",
      position: "sticky",
      padding: "10px 20px",
      background: "white",
      border: "1px solid lightgrey",
      alignSelf: "flex-start",
      top: "40px",
      width: "100%",
    },
    firstSticky: {
      position: "absolute !important",
      left: "59%",
      zIndex: 10,
      height: `${ref.current.offsetHeight && ref.current.offsetHeight}px`,
    },
    allCol: {
      display: "flex",
    },
    leftCol: {
      flex: "1 1 0%",
      marginRight: "2%",
      marginLeft: "9%",
      maxWidth: "50%",
    },
    rightCol: {
      flexShrink: 0,
      width: "30.5%",
      marginRight: "9%",
      marginLeft: "2%",
      position: "relative",
    },
    greyBackground: {
      background: "#F4A583",
    },
    breadcrumb: {
      marginTop: "20px !important",
      marginBottom: "-30px !important",
      fontFamily: "Open Sans",
      display: "inline-block",
      fontSize: "10px",
      paddingInlineStart: "0 !important",
      marginBlockStart: "0 !important",
      paddingTop: "40px",
      marginBottom: "0",
    },
    breadcrumbUL: {
      paddingInlineStart: "0 !important",
      marginBlockStart: "0 !important",
    },
    breadcrumbList: {
      display: "inline-block",
      "& a, a:hover": {
        textDecoration: "underline dotted",
        color: "black !important",
      },
    },
    breadcrumbSeparator: {
      margin: "0 5px",
    },
  }));

  const classes = useStyles();

  const ReviewsDialog = withStyles({
    paper: {
      margin: "7rem 0 0",
      width: "100%",
    },
    paperScrollPaper: {
      maxHeight: "100%",
    },
  })(Dialog);

  const PhotosDialog = withStyles({
    paper: {
      width: "100%",
    },
    content: {
      padding: "16px 20%",
    },
    paperScrollPaper: {
      maxHeight: "100%",
    },
  })(Dialog);

  let lazyImgStyles = {
    height: "35vh",
    width: "100%",
    borderRadius: "10px",
    objectFit: "cover",
  };

  let lazyImgStylesLarge = {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
    objectFit: "cover",
  };

  let imageIndex = true;

  useEffect(() => {
    if (hotelUsedData.slug !== hotel) {
      axios
        .get(`${BASE_URL}/app/hotels/show.json`, {
          params: { slug: hotel, locale: currentLocale, validation: validation },
          "axios-retry": { retries: 2 },
        })
        .then((response) => {
          handleChangeHotelData(response.data);
          setPhotos(response.data.photos);
          setImages(
            response.data.photos.map((photo) => {
              return photo.block_photo;
            })
          );
          setMap(response.data.map);
          setMetro(response.data.metro);
          setTimeDistance(response.data.time_distance);
          setProvince(response.data.province.name);
          setCity(response.data.city.name);

          document.title =
            response.data.hotel.listing_name +
            " | " +
            response.data.province.name +
            " | hotelbreak";
        });
    }
    axios
      .get(`${BASE_URL}/app/hotels/index.json`, { params: { slug: hotel } })
      .then((response) => {
        setOtherHotels(response.data.all_rooms);
      });
  }, []);

  useEffect(() => {
    currentUser.id &&
      axios
        .get(`${BASE_URL}/api/main/favorites/show.json`, { params: { slug: hotel } })
        .then((response) => {
          setFavorite(response.data.status);
        });
  }, [currentUser]);

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  const mobileHotel = (cols) => {
    return (
      <div className={classes.root}>
        <div className={classes.mainImage}>
          <NavMobile areaName={selectedAreaName} />
          <div className={classes.positionMainButtons}>
            {!currentUser.id && (
              <button
                onClick={(ev) => handleFavoriteNoUser(ev)}
                className={clsx(classes.buttonFavorites, classes.sameLineText)}
              >
                <i className={clsx("far fa-heart")}></i>
              </button>
            )}

            {currentUser.id && !favorite && (
              <button
                onClick={(ev) => handleFavoriteCreate(ev)}
                className={clsx(classes.buttonFavorites, classes.sameLineText)}
              >
                <i className={clsx("far fa-heart")}></i>
              </button>
            )}

            {currentUser.id && favorite && (
              <button
                onClick={(ev) => handleFavoriteDestroy(ev)}
                className={clsx(classes.buttonFavorites, classes.sameLineText)}
              >
                <i
                  className={clsx("fas fa-heart", classes.filledFavButton)}
                ></i>
              </button>
            )}

            <button
              className={clsx(classes.buttonPhotos, classes.sameLineText)}
              onClick={handlePhotosOpen}
            >
              <i className="fas fa-images"></i>&nbsp;&nbsp;
              {I18n.t("view_more_photos")}
            </button>

            {openLightBox && hotelUsedData.hotel && images && (
              <ImagesLightBox
                images={images}
                photoIndex={lightBoxIndex}
              ></ImagesLightBox>
            )}

            <Dialog
              open={openPhotos}
              onClose={handlePhotosClose}
              className={classes.paper}
              scroll={scroll}
              fullScreen
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">
                <div className={classes.horizontalEelements}>
                  <p className={classes.dialogTitle}>{I18n.t("photos")}</p>
                  <div>
                    <ClearIcon
                      onClick={() => handlePhotosClose()}
                      style={{ fontSize: 30 }}
                    ></ClearIcon>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent dividers={scroll === "paper"}>
                {photos &&
                  photos.map((photo, index) => {
                    return (
                      <div
                        onClick={() => handleLightBoxOpen(index)}
                        className={classes.photoDialogBlock}
                      >
                        <LazyImage
                          src={`${photo.block_photo}`}
                          lqip={Rectangle}
                          alt={
                            hotelUsedData.hotel &&
                            hotelUsedData.hotel.listing_name
                          }
                          lazyImgStyles={lazyImgStyles}
                        >
                          {" "}
                        </LazyImage>
                      </div>
                    );
                  })}
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className={classes.mainPageMobile}>
          {hotelUsedData.hotel ? (
            <h3
              className="col-md-12"
              dangerouslySetInnerHTML={{
                __html: hotelUsedData.hotel && hotelUsedData.hotel.listing_name,
              }}
            ></h3>
          ) : (
            <Skeleton variant="text" />
          )}

          <h3 className="col-md-12">
            {" "}
            {hotelUsedData.product_categories ? (
              <p>{hotelUsedData.product_categories}</p>
            ) : (
              <Skeleton variant="text" />
            )}
          </h3>
          <br></br>
          {hotelUsedData.hotel ? (
            <div className="col-md-12">
              <div
                className="about_text"
                dangerouslySetInnerHTML={{ __html: displayedText }}
              ></div>
              {hotelUsedData.hotel?.summary.length > charLimit && (
                <p style={{ cursor: 'pointer' }} onClick={toggleReadMore}>
                  {showMore ? I18n.t("view_less") : I18n.t("view_more")}
                </p>
              )}
            </div>
          ) : (
            <Skeleton variant="text" />
          )}

          <div className={"col-md-12"}>
            {hotelUsedData.products.length !== 0 &&
              hotelUsedData.products.length !== false &&
              hotelUsedData.hotel &&
              (hotelUsedData.hotel.bookable || validation) && (
                <React.Fragment>
                  <br></br>
                  <h3>{I18n.t("all_experience")}</h3>
                  <br></br>

                  <ProductSlide
                    selector={"productSlide-mobile"}
                    listCols={cols}
                    modal={true}
                    baseUrl={props.baseUrl}
                  />
                </React.Fragment>
              )}
          </div>
          {hotelUsedData.hotel && !hotelUsedData.hotel.bookable && !validation &&(
            <SubscriptionForm baseUrl={props.baseUrl} hotel={hotel} full={true} bookable={false} />
          )}
          <br />

          {hotelUsedData.review_count > 0 && (
            <div className={classes.reviewBlock + " col-md-12"}>
              <div className={classes.horizontalEelements}>
                <div>
                  <h2
                    className={clsx(
                      classes.secondaryTitle,
                      classes.sameLineText
                    )}
                  >
                    <Emoji symbol="⭐" />{" "}
                    {`${hotelUsedData.average_review} / 5`}
                  </h2>
                  <p className={classes.sameLineText}>
                    {" "}
                    {I18n.t("review", { count: hotelUsedData.review_count })}
                  </p>
                </div>

                <div>
                  <p className={classes.paddingTop} onClick={handleReviewsOpen}>
                    {I18n.t("view_all")}
                  </p>
                  <ReviewsDialog
                    open={openReviews}
                    onClose={handleReviewsClose}
                    className={classes.paper}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                  >
                    <DialogTitle id="scroll-dialog-title">
                      <div className={classes.horizontalEelements}>
                        <p className={classes.dialogTitle}>
                          {I18n.t("hotel_reviews_title")}
                        </p>
                        <div>
                          <ClearIcon
                            onClick={() => handleReviewsClose()}
                            style={{ fontSize: 30 }}
                          ></ClearIcon>
                        </div>
                      </div>
                    </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                      {hotelUsedData.reviews &&
                        hotelUsedData.reviews.map((review) => {
                          return (
                            <div className={classes.photoDialogBlock}>
                              <ReviewBlock
                                description={review.description}
                                user_name={review.user_name}
                                note={review.note}
                                start_date_js_time={review.start_date_js_time}
                                review_js_time={review.review_js_time}
                                product_name={review.name}
                                truncate={false}
                              />
                            </div>
                          );
                        })}
                    </DialogContent>
                  </ReviewsDialog>
                </div>
              </div>
            </div>
          )}

          {hotelUsedData.review_count > 0 && (
            <>
              <ReviewSlide
                truncate={true}
                reviews={hotelUsedData.reviews}
                listCols={cols}
              />
              <br />
            </>
          )}

          <br />
          <div className={classes.container + " col-md-12"}>
            <h2 className={classes.secondaryTitle}>
              <Emoji symbol="📍" /> {I18n.t("how_to_access")}
            </h2>
            <p className={classes.infoText}>
              {hotelUsedData.hotel ? (
                <p>{hotelUsedData.hotel.listing_name}</p>
              ) : (
                <Skeleton variant="text" />
              )}
            </p>
            <p className={classes.infoText}>
              {hotelUsedData.hotel ? (
                <>
                  <p>{hotelUsedData.hotel.address}</p>
                  {metro && metro != "" && (
                    <>
                      <h6>
                        <Emoji symbol="🚇" /> {I18n.t("metro")}
                      </h6>
                      <MetroStations metro={metro} />
                      <br />
                    </>
                  )}

                  {(timeDistance && timeDistance !== 0 && hotelUsedData) ? (
                    <p>
                      {city != findCapital(selectedArea) && (
                        <Emoji symbol="🚙" />
                      )}{" "}
                      {timizeDistance(timeDistance, selectedArea, city)}
                    </p>
                  ) : null}

                  {hotelUsedData.hotel && hotelUsedData.hotel.is_parking && (
                    <>
                      <h6>
                        <Emoji symbol="🅿️" /> {I18n.t("parking")}{" "}
                      </h6>
                      <p>{I18n.t("free_parking")}</p>
                    </>
                  )}
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${hotelUsedData.hotel.latitude},${hotelUsedData.hotel.longitude}`}
                    target="_blank"
                  >
                    <img src={map} className={classes.infoPhoto} />
                  </a>
                </>
              ) : (
                <Skeleton variant="text" />
              )}
            </p>
          </div>
          <br />
          <div className={classes.container + " col-md-12"}>
            <h2 className={classes.secondaryTitle}>
              <Emoji symbol="🚨" /> {I18n.t("hotel_need_know_title")}
            </h2>
            {hotelUsedData.hotel ? (
              <p
                className={classes.infoText}
                dangerouslySetInnerHTML={{
                  __html: hotelUsedData.hotel.need_to_know,
                }}
              />
            ) : (
              <Skeleton variant="text" />
            )}
          </div>
          <br />
          <div className={classes.container + " col-md-12"}>
            <h2 className={classes.secondaryTitle}>
              <Emoji symbol="🤕" /> {I18n.t("hotel_cancelation_title")}
            </h2>
            {hotelUsedData.hotel ? (
              <p
                className={classes.infoText}
                dangerouslySetInnerHTML={{
                  __html: hotelUsedData.hotel.cancelation_policy,
                }}
              />
            ) : (
              <Skeleton variant="text" />
            )}
          </div>
          <br />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <MediaContextProvider>
        <Media at="sm">
          <UserLoginWrap baseUrl={props.baseUrl} fullscreen={true} />
          <div style={{ visibility: ready ? "visible" : "hidden" }}>
            <NavMobile
              products={props.products}
              source={"hotel"}
              areaName={selectedAreaName}
            />
            {viewCount != false && viewCount > 10 && (
              <AnalyticsPoper icon={"👓"} content={"hotel"} count={viewCount} />
            )}
            <div>{mobileHotel(1.3)}</div>
          </div>
        </Media>
        <Media at="md">
          <UserLoginWrap baseUrl={props.baseUrl} fullscreen={false} />
          <div style={{ visibility: ready ? "visible" : "hidden" }}>
            <NavMobile
              products={props.products}
              source={"hotel"}
              areaName={selectedAreaName}
            />
            {viewCount != false && viewCount > 10 && (
              <AnalyticsPoper icon={"👓"} content={"hotel"} count={viewCount} />
            )}
            <div>{mobileHotel(2.3)}</div>
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <UserLoginWrap baseUrl={props.baseUrl} fullscreen={false} />
          <div id="hero" style={{ visibility: ready ? "visible" : "hidden" }}>
            <NavWrapper
              products={props.products}
              source={"hotel"}
              brand={props.brand}
            />
            {viewCount != false && viewCount > 10 && (
              <AnalyticsPoper icon={"👓"} content={"hotel"} count={viewCount} />
            )}
            <div
              className="row"
              style={{ background: "#F7EB67", marginBottom: "0px" }}
            >
              {hotelUsedData && hotelUsedData.hotel && province ? (
                <div
                  className={clsx(
                    "row",
                    classes.breadcrumb,
                    classes.largeContainer,
                    "no-padding"
                  )}
                >
                  <ul
                    itemScope
                    itemType="https://schema.org/BreadcrumbList"
                    className={classes.breadcrumbUL}
                  >
                    <li
                      itemProp="itemListElement"
                      itemScope
                      itemType="https://schema.org/ListItem"
                      className={classes.breadcrumbList}
                    >
                      <a itemProp="item" href="/">
                        <span itemProp="name">hotelbreak.com</span>
                      </a>
                      <meta itemProp="position" content="1" />
                    </li>
                    <span className={classes.breadcrumbSeparator}>›</span>
                    <li
                      itemProp="itemListElement"
                      itemScope
                      itemType="https://schema.org/ListItem"
                      className={classes.breadcrumbList}
                    >
                      <a
                        itemScope
                        itemType="https://schema.org/WebPage"
                        itemProp="item"
                        itemID={`/${currentLocale}/${
                          selectedArea &&
                          selectedArea !== "undefined" &&
                          selectedArea.toLowerCase()
                        }`}
                        href={`/${currentLocale}/${
                          selectedArea && selectedArea.toLowerCase()
                        }`}
                      >
                        <span itemProp="name">{province}</span>
                      </a>
                      <meta itemProp="position" content="2" />
                    </li>
                    <span className={classes.breadcrumbSeparator}>›</span>
                    <li
                      itemProp="itemListElement"
                      itemScope
                      itemType="https://schema.org/ListItem"
                      className={classes.breadcrumbList}
                    >
                      <a
                        itemScope
                        itemType="https://schema.org/WebPage"
                        itemProp="item"
                        itemID={`/${currentLocale}/${
                          selectedArea &&
                          selectedArea !== "undefined" &&
                          selectedArea.toLowerCase()
                        }`}
                        href={`/${currentLocale}/${
                          selectedArea && selectedArea.toLowerCase()
                        }/hotels`}
                      >
                        <span itemProp="name">{I18n.t("hotels")}</span>
                      </a>
                      <meta itemProp="position" content="3" />
                    </li>
                    <span className={classes.breadcrumbSeparator}>›</span>
                    <li
                      itemProp="itemListElement"
                      itemScope
                      itemType="https://schema.org/ListItem"
                      className={classes.breadcrumbList}
                    >
                      <span itemProp="name">
                        {hotelUsedData.hotel.listing_name}
                      </span>
                      <meta itemProp="position" content="4" />
                    </li>
                  </ul>
                </div>
              ) : (
                <div
                  className={clsx(
                    "row",
                    classes.breadcrumb,
                    classes.largeContainer,
                    "no-padding"
                  )}
                >
                  <ul
                    itemScope
                    itemType="https://schema.org/BreadcrumbList"
                    className={classes.breadcrumbUL}
                  >
                    <li className={classes.breadcrumbList}>
                      <Skeleton variant="text" style={{ width: "40px" }} />
                    </li>
                    <span className={classes.breadcrumbSeparator}>›</span>
                    <li className={classes.breadcrumbList}>
                      <Skeleton variant="text" style={{ width: "40px" }} />
                    </li>
                    <span className={classes.breadcrumbSeparator}>›</span>
                    <li className={classes.breadcrumbList}>
                      <Skeleton variant="text" style={{ width: "40px" }} />
                    </li>
                    <span className={classes.breadcrumbSeparator}>›</span>
                    <li className={classes.breadcrumbList}>
                      <Skeleton variant="text" style={{ width: "40px" }} />
                    </li>
                  </ul>
                </div>
              )}
              <div className={classes.largeContainerSmallMargin}>
                <div
                  className="col-md-8 no-padding"
                  style={{ marginTop: "10px" }}
                >
                  {hotelUsedData.hotel ? (
                    <>
                      <h3
                        style={{ marginTop: "10px" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            hotelUsedData.hotel &&
                            hotelUsedData.hotel.listing_name,
                        }}
                      />
                      {hotelUsedData.product_categories && (
                        <p>{hotelUsedData.product_categories}</p>
                      )}
                    </>
                  ) : (
                    <>
                      <h3>
                        <Skeleton variant="text" />
                      </h3>
                      <Skeleton variant="text" />
                    </>
                  )}
                </div>
                <div className="col-md-4 text-right no-padding">
                  {hotelUsedData.review_count > 0 ? (
                    <>
                      <div>
                        <div>
                          <h2
                            className={clsx(
                              classes.secondaryTitle,
                              classes.sameLineText
                            )}
                          >
                            <Emoji symbol="⭐" />{" "}
                            {`${hotelUsedData.average_review} / 5`}
                          </h2>
                          <br />
                          <p className={classes.sameLineText}>
                            {" "}
                            {I18n.t("review", {
                              count: hotelUsedData.review_count,
                            })}
                          </p>
                        </div>
                        <br />
                      </div>
                    </>
                  ) : (
                    " "
                  )}
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                background: "linear-gradient(180deg, #F7EB67 35%, #FFF 35%)",
                paddinTop: "20px",
              }}
            >
              <div className="col-md-12" />
              <div className={classes.root}>
                <div
                  className={classes.mainImageLarge}
                  onClick={() => handlePhotosOpenBg()}
                />
                <div
                  className={classes.secondaryImageLarge}
                  onClick={() => handlePhotosOpenBg()}
                />
                <div className={classes.tercaryImageLarge}>
                  <div className={classes.positionMainButtonsLarge}>
                    {!currentUser.id && (
                      <button
                        onClick={(ev) => handleFavoriteNoUser(ev)}
                        className={clsx(
                          classes.buttonFavorites,
                          classes.sameLineText
                        )}
                      >
                        <i className={clsx("far fa-heart")}></i>
                      </button>
                    )}

                    {currentUser.id && !favorite && (
                      <button
                        onClick={(ev) => handleFavoriteCreate(ev)}
                        className={clsx(
                          classes.buttonFavorites,
                          classes.sameLineText
                        )}
                      >
                        <i className={clsx("far fa-heart")}></i>
                      </button>
                    )}

                    {currentUser.id && favorite && (
                      <button
                        onClick={(ev) => handleFavoriteDestroy(ev)}
                        className={clsx(
                          classes.buttonFavorites,
                          classes.sameLineText
                        )}
                      >
                        <i
                          className={clsx(
                            "fas fa-heart",
                            classes.filledFavButton
                          )}
                        ></i>
                      </button>
                    )}
                    <button
                      className={clsx(
                        classes.buttonPhotos,
                        classes.sameLineText
                      )}
                      onClick={() => handlePhotosOpenBg()}
                    >
                      <i className="fas fa-images"></i>&nbsp;&nbsp;
                      {I18n.t("view_more_photos")}
                    </button>
                    {openLightBox && hotelUsedData.hotel && images && (
                      <ImagesLightBox
                        images={images}
                        photoIndex={lightBoxIndex}
                      ></ImagesLightBox>
                    )}

                    <PhotosDialog
                      open={openPhotosBg}
                      onClose={() => handlePhotosCloseBg()}
                      className={classes.paper}
                      scroll={scroll}
                      fullScreen
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                    >
                      <DialogTitle id="scroll-dialog-title">
                        <div className={classes.horizontalEelements}>
                          <p className={classes.dialogTitle}>
                            {I18n.t("photos")}
                          </p>
                          <div>
                            <ClearIcon
                              onClick={() => handlePhotosCloseBg()}
                              style={{ fontSize: 30 }}
                            ></ClearIcon>
                          </div>
                        </div>
                      </DialogTitle>
                      <DialogContent
                        className={classes.photoContent}
                        dividers={scroll === "paper"}
                      >
                        {photos && (
                          <ImageList
                            sx={{ width: 500, height: 450 }}
                            variant="quilted"
                            cols={2}
                            gap={10}
                            rowHeight={"auto"}
                          >
                            {photos.map((photo, index) => {
                              return (
                                (imageIndex = index % 3 == 0 ? true : false),
                                (
                                  <ImageListItem
                                    key={index}
                                    cols={imageIndex ? 2 : 1}
                                    rows={1}
                                  >
                                    <div
                                      onClick={() => handleLightBoxOpen(index)}
                                    >
                                      <LazyImage
                                        src={`${photo.block_photo}?w=${
                                          200 * imageIndex ? 2 : 1
                                        }&fit=crop&auto=format`}
                                        srcSet={`${photo.block_photo}?w=${
                                          200 * imageIndex ? 2 : 1
                                        }&fit=crop&auto=format&dpr=2 2x`}
                                        lqip={Rectangle}
                                        alt={
                                          hotelUsedData.hotel &&
                                          hotelUsedData.hotel.listing_name
                                        }
                                        lazyImgStyles={lazyImgStylesLarge}
                                      >
                                        {" "}
                                      </LazyImage>
                                    </div>
                                  </ImageListItem>
                                )
                              );
                            })}
                          </ImageList>
                        )}
                      </DialogContent>
                    </PhotosDialog>
                  </div>
                  <div
                    className={classes.tercaryClickingZoneLarge}
                    onClick={() => handlePhotosOpenBg()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="inner" ref={ref}>
            <div className={classes.allCol}>
              <div className={classes.leftCol}>
                <div className="col-md-12">
                  {hotelUsedData.hotel ? (
                    <div
                      className="about_text"
                      dangerouslySetInnerHTML={{
                        __html: hotelUsedData.hotel.summary,
                      }}
                    ></div>
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </div>
              </div>
              <div className={classes.rightCol + " " + classes.firstSticky}>
                <div className={classes.stickyColumn}>
                  {hotelUsedData.hotel &&
                  hotelUsedData.hotel.disable_gift_voucher ? (
                    <p>
                      <Emoji symbol="❌" /> {I18n.t("not_gift_bookable")}
                    </p>
                  ) : (
                    <p>
                      <Emoji symbol="👛" /> {I18n.t("gives_wallet_credit")}
                    </p>
                  )}
                  {hotelUsedData.hotel && hotelUsedData.hotel.is_accessible && (
                    <p>
                      <Emoji symbol="♿" /> {I18n.t("accessible")}
                    </p>
                  )}
                  <hr />
                  <div ref={heightRef} className="col-md-7 no-padding">
                    <p className={classes.infoText}>
                      {hotelUsedData.hotel ? (
                        <strong>{hotelUsedData.hotel.listing_name}</strong>
                      ) : (
                        <Skeleton variant="text" />
                      )}
                    </p>
                    <p className={classes.infoText}>
                      {hotelUsedData.hotel ? (
                        <>
                          {hotelUsedData.hotel.address}
                          {metro && metro != "" && (
                            <>
                              <h6>
                                <Emoji symbol="🚇" /> {I18n.t("metro")}
                              </h6>
                              <MetroStations metro={metro} />
                            </>
                          )}

                          <br />
                          {(timeDistance &&
                            timeDistance !== 0 &&
                            hotelUsedData) ? (
                              <p>
                                {city != findCapital(selectedArea) && (
                                  <Emoji symbol="🚙" />
                                )}{" "}
                                {timizeDistance(
                                  timeDistance,
                                  selectedArea,
                                  city
                                )}
                              </p>
                            ): null}

                          {hotelUsedData.hotel &&
                            hotelUsedData.hotel.is_parking && (
                              <>
                                <h6>
                                  <Emoji symbol="🅿️" /> {I18n.t("parking")}{" "}
                                </h6>
                                <p>{I18n.t("free_parking")}</p>
                              </>
                            )}
                        </>
                      ) : (
                        <Skeleton variant="text" />
                      )}
                    </p>
                  </div>
                  <div className="col-md-5 no-padding">
                    {map ? (
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${hotelUsedData.hotel.latitude},${hotelUsedData.hotel.longitude}`}
                        target="_blank"
                      >
                        <img
                          src={`${map}`}
                          className={classes.infoPhotoLarge}
                        />
                      </a>
                    ) : (
                      <Skeleton variant="rect" width="100%" height={200} />
                    )}
                  </div>
                  <div style={{ clear: "both" }} />
                </div>
              </div>
            </div>
            <div className={classes.allCol}>
              <div className={classes.leftCol}>
                <br></br>
                <div className={"col-md-12 no-padding"}>
                  {hotelUsedData.products.length !== 0 &&
                    hotelUsedData.products.length !== false &&
                    hotelUsedData.hotel &&
                    (hotelUsedData.hotel.bookable || validation) && (
                      <React.Fragment>
                        <br></br>
                        <h3>{I18n.t("dashboard__experiencies")}</h3>
                        <br></br>

                        <ProductSlide
                          selector={"productSlide-mobile"}
                          listCols={2.3}
                          modal={true}
                          roomActive={hotelUsedData.hotel.bookable}
                          baseUrl={props.baseUrl}
                        />
                      </React.Fragment>
                    )}
                </div>
                {hotelUsedData.hotel && !hotelUsedData.hotel.bookable && !validation &&(
                  <SubscriptionForm
                    baseUrl={props.baseUrl}
                    hotel={hotel}
                    full={false}
                    bookable={false}
                  />
                )}
              </div>
              <div className={classes.rightCol} />
            </div>
            {hotelUsedData.review_count > 0 && (
              <>
                <div className={classes.allCol + " " + classes.greyBackground}>
                  <div className={classes.leftCol}>
                    <div
                      className={classes.reviewBlock + " col-md-12 no-padding"}
                    >
                      <div className={classes.horizontalEelements}>
                        <div>
                          <h2
                            className={clsx(
                              classes.secondaryTitle,
                              classes.sameLineText
                            )}
                          >
                            <Emoji symbol="⭐" />{" "}
                            {`${hotelUsedData.average_review} / 5`}
                          </h2>
                          <p className={classes.sameLineText}>
                            {" "}
                            {I18n.t("review", {
                              count: hotelUsedData.review_count,
                            })}
                          </p>
                        </div>

                        <div>
                          <p
                            className={classes.paddingTop}
                            onClick={handleReviewsOpen}
                          >
                            {I18n.t("see_all")}
                          </p>
                          <ReviewsDialog
                            open={openReviews}
                            onClose={handleReviewsClose}
                            className={classes.paper}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                          >
                            <DialogTitle id="scroll-dialog-title">
                              <div className={classes.horizontalEelements}>
                                <p className={classes.dialogTitle}>
                                  {I18n.t("hotel_reviews_title")}
                                </p>
                                <div>
                                  <ClearIcon
                                    onClick={() => handleReviewsClose()}
                                    style={{ fontSize: 30 }}
                                  ></ClearIcon>
                                </div>
                              </div>
                            </DialogTitle>
                            <DialogContent dividers={scroll === "paper"}>
                              {hotelUsedData.reviews &&
                                hotelUsedData.reviews.map((review) => {
                                  return (
                                    <div className={classes.photoDialogBlock}>
                                      <ReviewBlock
                                        description={review.description}
                                        user_name={review.user_name}
                                        note={review.note}
                                        start_date_js_time={
                                          review.start_date_js_time
                                        }
                                        review_js_time={review.review_js_time}
                                        product_name={review.name}
                                        truncate={false}
                                      />
                                    </div>
                                  );
                                })}
                            </DialogContent>
                          </ReviewsDialog>
                        </div>
                      </div>
                      <br />

                      <ReviewSlide
                        truncate={true}
                        reviews={hotelUsedData.reviews}
                        listCols={1}
                      />
                      <br />
                    </div>
                  </div>
                  <div className={classes.rightCol}></div>
                </div>
              </>
            )}

            <div className={classes.allCol}>
              <div className={classes.leftCol}>
                <div className="col-md-12">&nbsp;</div>
                <br />
                <div className={classes.infoBlock + " col-md-12"}>
                  <div className="col-md-12 no-padding">
                    <h2 className={classes.secondaryTitle}>
                      <Emoji symbol="🚨" /> {I18n.t("hotel_need_know_title")}
                    </h2>
                    {hotelUsedData.hotel ? (
                      <p
                        className={classes.infoText}
                        dangerouslySetInnerHTML={{
                          __html: hotelUsedData.hotel.need_to_know,
                        }}
                      />
                    ) : (
                      <Skeleton variant="text" />
                    )}
                    <h2 className={classes.secondaryTitle}>
                      <Emoji symbol="🤕" /> {I18n.t("hotel_cancelation_title")}
                    </h2>
                    {hotelUsedData.hotel ? (
                      <p
                        className={classes.infoText}
                        dangerouslySetInnerHTML={{
                          __html: hotelUsedData.hotel.cancelation_policy,
                        }}
                      />
                    ) : (
                      <Skeleton variant="text" />
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.rightCol} />
            </div>
          </div>
          {otherHotels && otherHotels.length > 0 ? (
            <div
              className={classes.largeContainer + " row col-md-12 no-padding"}
            >
              <h2>{I18n.t("might_interest_hotels")}</h2>
              <br />
              <HotelSlide
                hotels={otherHotels}
                favoriteids={favorite}
                current_locale={currentLocale}
                listCols={3.3}
              />
            </div>
          ) : (
            <br />
          )}
        </Media>
      </MediaContextProvider>
    </React.Fragment>
  );
}

export default Hotel;
