import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import {useCardElementStyle} from './styles/styles';
import clsx from 'clsx';
import I18n from '../../../i18n-js/index.js.erb'

function CardSection() {
  const classes = useCardElementStyle();

  return (
    <label style={{width: '100%'}}>
      {I18n.t('credit_card')}
      {/* <CardElement
        className={classes.cardBase}
      /> */}
      <CardNumberElement
        className={classes.cardBase}
      />
      <CardExpiryElement
        className={classes.cardBaseHalf}
      />
      <CardCvcElement
        className={classes.cardBaseHalf}
      />
    </label>
  );
};

export default CardSection;