import slugify from "react-slugify";
import I18n from "../i18n-js/index.js.erb";

export function mergeObjArrays(arrayObj1, arrayObj2) {
  var arrayObj3 = [];
  var loopIterations = arrayObj1.length;

  for (var i = 0; i < loopIterations; i++) {
    var obj1 = arrayObj1[i];

    var obj2 = arrayObj2[i];

    var obj3 = {};

    for (var attrname in obj1) {
      obj3[attrname] = obj1[attrname];
    }
    for (var attrname in obj2) {
      obj3[attrname] = obj2[attrname];
    }

    arrayObj3.push(obj3);
  }

  return arrayObj3;
}

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return string;
  }
}

export function titleCase(string) {
  if (string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  } else {
    return string;
  }
}

export function formatJsTime(js_time) {
  var currentDay = new Date(js_time);
  return currentDay.toISOString().substring(0, 10);
}

export function formatJSTimeLocale(js_time, locale) {
  var currentDay = new Date(js_time);

  const options = { day: "numeric", month: "long", year: "numeric" };

  return currentDay.toLocaleDateString(locale, options);
}

export function setNullToFuture(js_close_date) {
  if (js_close_date === null) {
    var now = new Date();
    return now.setDate(now.getDate() + 9000);
  } else {
    return js_close_date;
  }
}

export function numDaysBetween(d1, d2) {
  var diff = d1.getTime() - d2.getTime();
  return diff / (1000 * 60 * 60 * 24);
}

export function currencyToSymbol(currency) {
  switch (currency) {
    case "eur":
      return "€";
      break;
    case "usd":
      return "$";
      break;
    case "gbp":
      return "£";
      break;
    case "aed":
      return "AED";
      break;
    case "dop":
      return "RD$.";
      break;
    case "mxn":
      return "MXN$";
      break;
    default:
      return "€";
      break;
  }
}

export function parameterize(string) {
  return string.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "-");
}

export function parameterizeArray(array) {
  result = []
  array.forEach((e) => {result.push(parameterize(e))})
  return result.join();
}

export function to_eur(amount) {
  var result = parseFloat(amount) / 100;

  return roundTo(result, 2);
}

export function roundTo(num, precision) {
  const factor = Math.pow(10, precision)
  return Math.round(num * factor) / factor

}

export function roleUrlBuild(path, role) {
  if (role === "admin") {
    var url = path + "Admin";
  } else {
    var url = path + "Hotelier";
  }

  return url;
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

export function timizeDistance(time, area, city) {
  if (city != findCapital(area)) {
    if (time > 55) {
      var hours = Math.round(time / 60);
      var minutes =
        Math.ceil(((time / 60 - Math.round(time / 60)) * 60) / 5) * 5;
    } else {
      var minutes = Math.ceil(time / 5) * 5;
    }
    minutes = minutes < 10 ? "0" + minutes : minutes;
    if (hours != undefined && hours > 0) {
      return `· ${I18n.t("hour_time", {
        hours: hours,
        minutes: minutes,
        capital: findCapital(area),
      })}`;
    } else {
      return `· ${I18n.t("minutes_time", {
        minutes: minutes,
        capital: findCapital(area),
      })}`;
    }
  }
}

export function findCapital(area) {
  var test_area = slugify(area);
  switch (test_area) {
    case "mallorca":
      return "Palma";
    case "ile-de-majorque":
      return "Palma";
    case "majorca":
      return "Palma";
    case "gran-canaria":
      return "Las Palmas";
    case "tenerife":
      return "Santa Cruz";
    case "lanzarote":
      return "Arrecife";
    case "menorca":
      return "Mahon";
    case "castilla-la-mancha":
      return "Ciudad-Real";
    case "fuerteventura":
      return "Puerto del Rosario";
    case "bouches-du-rhone":
      return "Marseille";
    case "cote-d-azur":
      return "Nice";
    case "riviera-romagnola":
      return "Rimini";
    case "aix-marseille":
      return "Marseille";
    default:
      return area;
  }
}

var productCat_T = function (to_translate, locale) {
  const to_translate_en = {
    afternoon: "Afternoon",
    cabana: "Bali bed",
    dinner: "Dinners",
    lunch: "Lunches",
    massage: "Massages",
    outdoor: "Outdoor",
    breakfast: "Breakfast",
    night: "24h experience",
  };

  const to_translate_es = {
    afternoon: "Tarde",
    cabana: "Camas balinesas",
    dinner: "Cenas",
    lunch: "Almuerzos",
    massage: "Masajes",
    outdoor: "Aire libre",
    breakfast: "Desayunos",
    night: "24h experience",
  };

  const to_translate_fr = {
    afternoon: "Après-midi",
    cabana: "Lits balinais",
    dinner: "Dîners",
    lunch: "Déjeuners",
    massage: "Massages",
    outdoor: "Air libre",
    breakfast: "Petits-déjeuners",
    night: "24h experience",
  };

  const to_translate_it = {
    afternoon: "Pomeriggio",
    cabana: "Letto balinese",
    dinner: "Cena",
    lunch: "Pranzo",
    massage: "Massaggio",
    outdoor: "All'aperto",
    breakfast: "Prima colazione",
    night: "24h experience",
  };

  const to_translate_pt = {
    afternoon: "Tarde",
    lunch: "Almoço",
    breakfast: "Pequeno almoço",
    dinner: "Jantar",
    massage: "Massagem",
    cabana: "Cama balinesa",
    outdoor: "Ao ar livre",
    night: "24h experience",
  };

  switch (locale) {
    case "es":
      return to_translate_es[to_translate];
      break;
    case "en":
      return to_translate_en[to_translate];
      break;
    case "fr":
      return to_translate_fr[to_translate];
      break;
    case "pt":
      return to_translate_pt[to_translate];
      break;
    case "it":
      return to_translate_it[to_translate];
      break;
    default:
      return to_translate_es[to_translate];
  }
};

export function translate_products(product_array, locale) {
  var result = [];

  for (var i = 0; i < product_array.length; i++) {
    switch (product_array[i]) {
      case "Cabana":
        result.push(productCat_T("cabana", locale));
        break;
      case "Spa":
        result.push("Spa");
        break;
      case "Daypass":
        result.push("Daypass");
        break;
      case "Dinner":
        result.push(productCat_T("dinner", locale));
        break;
      case "Breakfast":
        result.push(productCat_T("breakfast", locale));
        break;
      case "Lunch":
        result.push(productCat_T("lunch", locale));
        break;
      case "Massage":
        result.push(productCat_T("massage", locale));
        break;
      case "Yoga":
        result.push("Yoga");
        break;
      case "Outdoor":
        result.push(productCat_T("outdoor", locale));
        break;
      case "Afternoon":
        result.push(productCat_T("afternoon", locale));
        break;
      case "Night":
        result.push(productCat_T("night", locale));
        break;
      case "Brunch":
        result.push("Brunch");
        break;
    }
  }

  return result.sort(function (a, b) {
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  });
}
