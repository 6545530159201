import React from "react";
import { useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import { makeStyles } from "@material-ui/core/styles";
import { capitalizeFirstLetter } from "../../../helpers/base";
import { createMedia } from "@artsy/fresnel";
import I18n from "../../../i18n-js/index.js.erb";
import slugify from "react-slugify";
import WidgetWalkthrough from "./WidgetWalkthrough";
import { useSelector, useDispatch } from "react-redux";
import WidgetUserLoginWrap from "./modals/WidgerUserLoginWrap";
import { setDesktopUserLoginFormStatus } from "../../redux/actions/actions";
import clsx from "clsx";
import { setCurrentUser } from "../../redux/actions/actions";
import WidgetFooter from "../WidgetFooter";

function WidgetVoucherFunnel(props) {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    slidingPane: {
      marginTop: "90px",
      paddingTop: "5px",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      backgroundColor: "white",
      width: "100%",
      height: "fit-content",
      position: "relative",
      boxShadow: "0 -2px 4px rgba(0,0,0,0.25)",
      zIndex: "1",
    },
    root: {
      backgroundImage: `url(${props.brand?.background})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      height: "40vh",
    },
    filtered: {
      filter: "blur(5px)",
    },
  }));

  const currentLocale = useSelector((state) => state.currentLocale);

  I18n.locale = currentLocale;

  const classes = useStyles();

  if (props?.current_user?.id) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
      })
    );
  }

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  useEffect(() => {
    document.title = `${I18n.t("gift_voucher")} | hotelbreak`;
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <MediaContextProvider>
        <Media at="sm">
          <HeaderMobile {...props} text={I18n.t("giftbanner__main")} />
          <div className={classes.slidingPane}>
            <WidgetWalkthrough {...props} />
          </div>
        </Media>
        <Media at="md">
          <HeaderMobile {...props} text={I18n.t("giftbanner__main")} />
          <div className={classes.slidingPane}>
            <WidgetWalkthrough {...props} />
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <Header {...props} text={I18n.t("giftbanner__main")} />
          <div className={classes.slidingPane}>
            <WidgetWalkthrough {...props} />
          </div>
        </Media>
      </MediaContextProvider>

      <WidgetUserLoginWrap />
      <WidgetFooter {...props} />
    </div>
  );
}

export default WidgetVoucherFunnel;
