import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { capitalizeFirstLetter } from "../../helpers/base.js";
import { setDesktopUserLoginFormStatus } from "../redux/actions/actions";
import clsx from "clsx";
import LanguageSelector from "../main/components/buttons/languageSelector";
import { Skeleton } from "@mui/material";
import axios from 'axios';
import logo from "images/logo3.png";
import I18n from "../../i18n-js/index.js.erb";
import tinycolor from "tinycolor2";


function WidgetNavMobile(props) {
  const dispatch = useDispatch();

  const selectedArea = capitalizeFirstLetter(
    useSelector((state) => state.area)
  );

  const desktopUserLoginFormStatus = useSelector(
    (state) => state.desktopUserLoginFormStatus
  );

  const currentLocale = useSelector((state) => state.currentLocale);

  const currentUser = useSelector((state) => state.currentUser);

  const [menu, setMenu] = useState(props.menu);
  const [menuType, setMenuType] = useState("principal");
  const [userNav, setUserNav] = useState(false);
  const [brand, setBrand] = useState(props.brand)

  const handleUserNav = () => {
    setMenuType("user");
  };

  const handleAreaNav = () => {
    setMenuType("area");
  };

  const handleIsMenu = () => {
    setMenuType("principal");
  };

  const handleMenu = () => {
    setMenu(true);
  };

  const handleMenuUser = () => {
    setMenu(true);
    setMenuType('user');
  };

  const menuClose = () => {
    setMenu(false);
    setMenuType('principal');
  };

  const handleUserLoginOpen = () => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: true,
        signin: false,
        register: false,
        selectRegister: false,
        selectSignin: true,
      })
    );
  };

  const handleUserRegisterOpen = () => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: true,
        signin: false,
        register: false,
        selectRegister: true,
        selectSignin: false,
      })
    );
  };

  const handleUserSignOut = () => {
    window.location.href = "/sign_out";
  };

  const useStyles = makeStyles((theme) => ({
    logo: {
      width: "120px",
      margin: '10px 0 0 20px'
    },
    emojis: {
      marginRight: "15px",
      fontSize: "15px",
    },
    fullList: {
      width: "auto",
    },
    paper: {
      overflow: "hidden",
      width: "390px !important",
    },
    mainNav: {
      padding: "8px 0 8px 24px !important",
    },
    menuBar: {
      height: "65px",
      background: brand?.main_color ? `${brand?.main_color}` : "white",
      top: 0,
      width: "100%"
    },
    menuBarFixed: {
      position: 'absolute',
    },
    menuBarIndex: {
      borderBottom: '1px solid #ccc',
      marginBottom: '10px'
    },
    accountButtonIcon: {
      fontSize: '1.5rem'
    },
    pL5: {
      paddingLeft: '5%'
    },
    hblogo: {
      padding: '10px 20px',
    },
    hbbanner: {
      textAlign: 'center',
      paddingTop: '15px'
    },
    secondaryColor: {
      background: brand?.secondary_color,
      color: tinycolor(brand?.secondary_color).isLight() ? 'black' : 'white',
    }
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={clsx(classes.menuBar, props.index && classes.menuBarIndex, props.landing && classes.menuBarFixed)}>
        {brand?.name === 'widget' ?
          <p className={classes.hbbanner}>Powered by <img src={logo} className={classes.hblogo} title={`hotelbreak logo`} alt={`hotelbreak logo`} width='120' /></p>
        :
          (<a href={`/`}>
            {
              brand?.logo && brand?.logo !== '' ?
                <img src={brand.logo} className={classes.pL5} title={`${brand?.name} logo`} alt={`${brand?.name} logo`} height='50' style={{marginTop: '5px'}} />
              :
                <Skeleton variant='text' className={classes.pL5} width='100px' />
            }
          </a>)
        }
      </div>
      {brand?.discount &&
        <div className={clsx(classes.secondaryColor, classes.pL5)}><p>{I18n.t("brand_special_discount", {brand: brand?.name.toUpperCase()})}</p></div>
      }
    </React.Fragment>
  );
}

export default WidgetNavMobile;