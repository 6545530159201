import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-dates/initialize";
import "stylesheets/_react_datepicker.scss";
import { SingleDatePicker } from "react-dates";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Moment from "moment";
import { to_eur, currencyToSymbol } from "../../../helpers/base";
import { makeStyles } from "@material-ui/core/styles";
import Emoji from "a11y-react-emoji";
import axios from "axios";
import HotelBreakLoader from "../components/loaders/hotelBreakLoader";
import {
  setAvailabilities,
  setSelectedTimeFrame,
} from "../../redux/actions/actions";
import I18n from "../../../i18n-js/index.js.erb";
import { wallet_amount } from "../../../helpers/wallet/wallet";
import { Box } from "@material-ui/core";
import TimeFrameSelector from "./components/TimeFrameSelector";
import { calculateRewardGain } from "../../../helpers/wallet/wallet";
import OddDialog from "../components/dialogs/OddPaxAlert";

function ProductBookingForm(props) {
  const dispatch = useDispatch();
  const BASE_URL = props.baseUrl || '';

  const currentAvailabilities = useSelector(
    (state) => state.currentAvailabilities
  );

  const area = props.area;
  const hotel = props.hotel;
  const product = props.product;
  const STRICT_MIN_AGE_KIDS_PRODUCTS = ["4776", "4779", "5061", "5060"];
  const allowGroupsMessage = Moment().month() < 4 || Moment().month() > 7;

  const csrfToken = useSelector((state) => state.csrfToken);
  const currentLocale = useSelector((state) => state.currentLocale);
  const selectedTimeFrame = useSelector((state) => state.selectedTimeFrame);

  I18n.locale = currentLocale;
  const [oddAccepted, setOddAccepted] = useState(false);
  const [oddDialogOpen, setOddDialogOpen] = useState(false);

  useEffect(() => {
    oddAccepted &&
      handleRedirectToReservationPage()
  }, [oddAccepted])

  const [selectedDate, setSelectedDate] = useState(
    currentAvailabilities.setSelectedDate &&
      currentAvailabilities.availabilities[
      currentAvailabilities.setSelectedDate
      ] !== undefined &&
      !currentAvailabilities.availabilities[
        currentAvailabilities.setSelectedDate
      ]?.blocked
      ? currentAvailabilities.setSelectedDate
      : Moment(currentAvailabilities.current_time).format("YYYY-MM-DD")
  );

  const defaultKids = () => {
    const faultyValues = [null, undefined, 0].includes(props.kids);
    const availableNumber =
      props.kids <=
      currentAvailabilities.availabilities[selectedDate].ticket_number_today;

    if (
      faultyValues ||
      !props.experience.attributes.productTickets.accept_kids ||
      !availableNumber
    ) {
      return 0;
    } else {
      return props.kids;
    }
  };

  const defaultAdults = () => {
    const faultyValues = [null, undefined, 0, 1].includes(props.adults);
    const availableNumber =
      props.adults <=
      currentAvailabilities.availabilities[selectedDate].ticket_number_today;

    if (faultyValues || !availableNumber) {
      return 1;
    } else {
      return props.adults;
    }
  };

  const [focusedOnce, setFocusedOnce] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const adults = defaultAdults();
  const kids = defaultKids();
  const [selectedPax, setSelectedPax] = useState(adults);
  const [selectedPaxKids, setSelectedPaxKids] = useState(kids);
  const [wantToSelectMorePax, setWantToSelectMorePax] = useState(false);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [finger, setFinger] = useState("👉");
  const [walletAmount, setWalletAmount] = useState();

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;

  const selectedPaxes = useSelector((state) => state.selectedPax);

  const calculateWalletAmount = () => {
    !loadingCalendar &&
      setWalletAmount(
        wallet_amount(
          currentAvailabilities.availabilities[selectedDate]?.price_today,
          selectedPax,
          currentAvailabilities.availabilities[selectedDate]?.price_kids_today,
          selectedPaxKids,
          props.experience.attributes.productTickets.max_guest
        )
      );
  };

  Moment.locale(currentLocale);

  const useStyles = makeStyles((theme) => ({
    primaryColor: {
      backgroundColor: "#F7EB67",
      color: "black !important",
    },
    paxSelector: {
      fontSize: "32px",
      fontWeight: "600 !important",
    },
    price: {
      fontSize: "30px",
      fontWeight: "800 !important",
    },
    totalText: {
      lineHeight: "43px",
    },
    fullBtn: {
      display: "block !important",
      width: "100%",
    },
    dividing: {
      margin: "10px -5px",
      background: "rgba(0,0,0,0.8",
    },
    giftButton: {
      display: "flex",
      alignItems: "center",
      flex: "2 1 auto",
      cursor: "pointer",
    },
    unstyledLink: {
      textDecoration: "none !important",
      color: "black",
      "&:hover": {
        textDecoration: "none !important",
      },
    },
  }));

  let discountPercent = {
    textDecoration: "none !important",
    fontWeight: 800,
    padding: 2,
    background: "#F7EB67",
    color: "black",
  };

  const classes = useStyles();

  const handleRedirect = (locale, id) => {
    const url = `/${locale}/${I18n.t("routes.create_gift")}?product_id=${id}`;
    window.open(url, "_self", "noopener,noreferrer");
  };

  const handleDatePickerFocus = () => {
    if (!focusedOnce) {
      setFocusedOnce(true);
    } else {
      setFocusedOnce(false);
    }

    setIsFocused(!isFocused);
  };

  const handleRemovePax = () => {
    handleSeeSchedules(false);

    if (selectedPax > 1) {
      setSelectedPax(selectedPax - 1);

      if (
        selectedPax + selectedPaxKids <=
        currentAvailabilities.availabilities[selectedDate].ticket_number_today *
        props.experience.attributes.productTickets.max_guest
      ) {
        setWantToSelectMorePax(false);
      }
    }
  };

  const handleAddPax = () => {
    handleSeeSchedules(false);

    if (
      selectedPax + selectedPaxKids <
      currentAvailabilities.availabilities[selectedDate].ticket_number_today *
      props.experience.attributes.productTickets.max_guest
    ) {
      setSelectedPax(selectedPax + 1);
    } else {
      setWantToSelectMorePax(true);
    }
  };

  const handleRemovePaxKids = () => {
    handleSeeSchedules(false);

    if (selectedPaxKids > 0) {
      setSelectedPaxKids(selectedPaxKids - 1);

      if (
        selectedPax + selectedPaxKids <=
        currentAvailabilities.availabilities[selectedDate].ticket_number_today *
        props.experience.attributes.productTickets.max_guest
      ) {
        setWantToSelectMorePax(false);
      }
    }
  };

  const handleAddPaxKids = () => {
    handleSeeSchedules(false);

    if (
      selectedPax + selectedPaxKids <
      currentAvailabilities.availabilities[selectedDate].ticket_number_today *
      props.experience.attributes.productTickets.max_guest
    ) {
      setSelectedPaxKids(selectedPaxKids + 1);
    } else {
      setWantToSelectMorePax(true);
    }
  };

  const handleIsBlockedDays = (momentDate) => {
    let isUnavailable =
      currentAvailabilities.availabilities[momentDate.format("YYYY-MM-DD")] &&
      currentAvailabilities.availabilities[momentDate.format("YYYY-MM-DD")]
        ?.blocked;

    let isBlockedByWeekDay =
      !currentAvailabilities.blocked_days[
      `${momentDate.locale("en").format("d")}`
      ][`${momentDate.locale("en").format("dddd")}`];

    let isPastDay =
      currentAvailabilities.first_date &&
      momentDate.format("YYYY-MM-DD") < currentAvailabilities.first_date;

    let isPastLastDate =
      momentDate.format("YYYY-MM-DD") > currentAvailabilities.last_date;
    if (isUnavailable || isBlockedByWeekDay || isPastDay || isPastLastDate) {
      return true;
    } else {
      return false;
    }
  };

  const discount = (pvp, base) => {
    return Math.round(((pvp - base) / base) * 100);
  };

  const handleHighlightedDays = (momentDate) => {
    let highlight =
      currentAvailabilities.availabilities[momentDate.format("YYYY-MM-DD")] &&
      discount(
        currentAvailabilities.availabilities[momentDate.format("YYYY-MM-DD")]
          .price_today,
        currentAvailabilities.availabilities[momentDate.format("YYYY-MM-DD")]
          ?.base_price
      ) < -5;

    return highlight;
  };

  const handleLowAvailabilityDays = (momentDate) => {
    let highlight =
      currentAvailabilities.availabilities[momentDate.format("YYYY-MM-DD")] &&
      currentAvailabilities.availabilities[momentDate.format("YYYY-MM-DD")].ticket_number_today <= 2 &&
      currentAvailabilities.base_ticket >= 4

    return highlight;
  }

  const reloadAvailabilities = (date) => {
    setLoadingCalendar(true);
    axios
      .get(
        `${BASE_URL}/api/main/product_booking_forms/show.json`,
        !props.modal
          ? {
            params: {
              locale: currentLocale,
              slug: product,
              area: area,
              hotel: hotel,
              date: date,
            },
          }
          : {
            params: {
              locale: currentLocale,
              slug: props.experience.attributes.slug,
              area: area,
              hotel: hotel,
              date: date,
            },
          }
      )
      .then((response) => {
        dispatch(setAvailabilities(response.data.currentAvailabilities));
        setSelectedDate({
          ...response.data.currentAvailabilities.current_time,
          setSelectedDate: false,
        });
        setLoadingCalendar(false);
      });
  };

  const toggleFinger = () => {
    finger === "👉" ? setFinger("👌") : setFinger("👉");
  };

  const handleSelectedDate = (date) => {
    dispatch(
      setSelectedTimeFrame({
        ...selectedTimeFrame,
        open: false,
      })
    );
    reloadAvailabilities(date);
    setLoadingCalendar(true);
    setSelectedPax(1);
    setSelectedPaxKids(0);
    setWantToSelectMorePax(false);
    calculateWalletAmount();
  };

  const handleSeeSchedules = (status) => {
    dispatch(
      setSelectedTimeFrame({
        ...selectedTimeFrame,
        open: status,
      })
    );
  };

  const handleRedirectToReservationPage = () => {
    if ((selectedPax + selectedPaxKids) % props.experience.attributes.productTickets.max_guest !== 0 && !oddAccepted) {
      setOddDialogOpen(true);
    } else {
      setLoadingCalendar(true);
      axios
        .post(`${BASE_URL}/api/main/reservations.json`, {
          start_date: selectedDate,
          hotel_slug: hotel,
          adults: selectedPax,
          kids: selectedPaxKids,
          product_slug: !props.modal ? product : props.experience.attributes.slug,
          locale: currentLocale,
          start_hour: selectedTimeFrame.start_hour,
          finish_hour: selectedTimeFrame.finish_hour,
        })
        .then((response) => {
          if (response.status == 200) {
            location.href =
              location.origin + response.data.redirect_to_reservation;
          } else {
            throw new Error("Error");
          }
        })
        .catch((error) => {
          location.href = location.origin + "/error";
        });
    }
  };

  useEffect(() => {
    calculateWalletAmount();
    selectedPaxes &&
      selectedPaxes["adults"] != false &&
      setSelectedPax(selectedPaxes["adults"]);
    selectedPaxes &&
      selectedPaxes["kids"] != false &&
      setSelectedPaxKids(selectedPaxes["kids"]);
  }, [loadingCalendar]);

  const highlightLegend = () => {
    return (
      <div>
        <p style={{ margin: "-10px 20px 10px" }}>
          <div
            style={{
              height: "10px",
              width: "10px",
              backgroundColor: "#ffe8bc",
              display: "inline-block",
            }}
          ></div>
          &nbsp;{I18n.t("low_price")}
        </p>
      </div>
    );
  };

  const ticketPrice = (pax, price) => {
    return parseFloat(
      to_eur(
        price *
        Math.ceil(
          pax /
          props.experience.attributes.productTickets.max_guest
        )
      ).toFixed(2)
    )
  }

  const totalPriceNow = () => {
    const availableTicketsToday = currentAvailabilities.availabilities[selectedDate];
    const uncompleteTicket = (selectedPax + selectedPaxKids) % props.experience.attributes.productTickets.max_guest !== 0;
    const priceKidsToday = currentAvailabilities.availabilities[selectedDate]?.price_kids_today
    const priceToday = currentAvailabilities.availabilities[selectedDate]?.price_today
    const totalPax = selectedPax + selectedPaxKids;


    if (availableTicketsToday && priceToday === priceKidsToday) {
      return ticketPrice(totalPax, priceToday)
    } else {
      return (availableTicketsToday &&
        uncompleteTicket)
        ? (ticketPrice(selectedPax + 1, priceToday) + ticketPrice(selectedPaxKids - 1, priceKidsToday)).toFixed(2)

        : (ticketPrice(selectedPax, priceToday)
          + ticketPrice(selectedPaxKids, priceKidsToday)).toFixed(2)
    }
  };

  return (
    <>
      <p>
        {I18n.t("select_date")}
        {props.experience.attributes.acceptsGift && (
          <span style={{ float: "right" }}>
            <a
              href={`https://www.hotelbreak.com/${currentLocale}/${I18n.t(
                "routes.create_gift"
              )}?product_id=${props.experience.id}`}
              target="_blank"
              onClick={() => {
                handleRedirect(currentLocale, props.experience.id);
              }}
              style={{ textDecoration: "underline dotted", color: "black" }}
            >
              <Emoji symbol="🎁" /> {I18n.t("gift_voucher_link_product")}
            </a>
          </span>
        )}
      </p>
      <SingleDatePicker
        date={selectedDate ? Moment(selectedDate) : null}
        onDateChange={(date) => handleSelectedDate(date.format("YYYY-MM-DD"))}
        id="dateSelector"
        focused={isFocused}
        onFocusChange={() => handleDatePickerFocus()}
        numberOfMonths={1}
        firstDayOfWeek={1}
        readOnly={true}
        renderCalendarInfo={() => highlightLegend()}
        isDayBlocked={(momentDate) => handleIsBlockedDays(momentDate)}
        isDayHighlighted={(momentDate) => handleHighlightedDays(momentDate)}
        displayFormat="LL"
        small
        withPortal={props.style === "inline" ? false : true}
        hideKeyboardShortcutsPanel
        keepOpenOnDateSelect={false}
        showDefaultInputIcon
      />
      <br />
      <br />
      {!loadingCalendar && handleLowAvailabilityDays(Moment(selectedDate)) && (
        <p
          style={{
            color: "black",
            background: "#ffce71",
            display: "inline-block",
            padding: "2px 10px",
            borderRadius: "10px",
          }}
        >
          {I18n.t("only_x_left", {
            count:
              currentAvailabilities.availabilities[selectedDate]
                .ticket_number_today,
          })}
        </p>
      )}

      {loadingCalendar && <HotelBreakLoader />}

      {!loadingCalendar && (
        <React.Fragment>
          <p>
            {I18n.t("adults")}
            {props.experience.attributes.productTickets.max_guest === 1 &&
              currentAvailabilities.availabilities[selectedDate] &&
              " (" +
              to_eur(
                currentAvailabilities.availabilities[selectedDate]
                  ?.price_today
              ).toFixed(2) +
              currencyToSymbol(props.experience.attributes.currency) +
              ")"}
            {discount(
              currentAvailabilities.availabilities[selectedDate]?.price_today,
              props.experience.attributes.basePvp
            ) < -5 && (
                <>
                  {" "}
                  <span style={{ textDecoration: "line-through" }}>
                    {to_eur(props.experience.attributes.basePvp)}
                    {currencyToSymbol(props.experience.attributes.currency)}
                  </span>{" "}
                  <span style={discountPercent}>
                    {discount(
                      currentAvailabilities.availabilities[selectedDate]
                        ?.price_today,
                      props.experience.attributes.basePvp
                    )}
                    %
                  </span>
                </>
              )}
          </p>
          <div className="row">
            <div className="col-xs-4 text-center">
              <Fab
                className={classes.primaryColor}
                aria-label="remove"
                onClick={handleRemovePax}
                disabled={selectedPax <= 1}
              >
                <RemoveIcon />
              </Fab>
            </div>
            <div className="col-xs-4 text-center">
              <p className={classes.paxSelector}>{selectedPax}</p>
            </div>
            <div className="col-xs-4 text-center">
              <Fab
                className={classes.primaryColor}
                aria-label="add"
                onClick={handleAddPax}
                disabled={wantToSelectMorePax}
              >
                <AddIcon />
              </Fab>
            </div>
          </div>

          {props.experience.attributes.productTickets.accept_kids &&
            currentAvailabilities.availabilities[selectedDate] &&
            currentAvailabilities.availabilities[selectedDate]
              ?.price_kids_today != 0 && (
              <React.Fragment>
                <p>
                  {I18n.t("kids")}{" "}
                  <small>
                    {I18n.t("from_age_to_age", {
                      from: props.experience.attributes.productTickets
                        .kids_min_age,
                      to: props.experience.attributes.productTickets
                        .kids_max_age,
                    })}
                  </small>{" "}
                  (
                  {currentAvailabilities.availabilities[selectedDate] &&
                    to_eur(
                      currentAvailabilities.availabilities[selectedDate]
                        ?.price_kids_today
                    ).toFixed(2)}
                  {currencyToSymbol(props.experience.attributes.currency)})
                  {discount(
                    currentAvailabilities.availabilities[selectedDate]
                      ?.price_kids_today,
                    props.experience.attributes.basePvpKids
                  ) < -5 && (
                      <>
                        {" "}
                        <span style={{ textDecoration: "line-through" }}>
                          {to_eur(props.experience.attributes.basePvpKids)}
                          {currencyToSymbol(props.experience.attributes.currency)}
                        </span>{" "}
                        <span style={discountPercent}>
                          {discount(
                            currentAvailabilities.availabilities[selectedDate]
                              ?.price_kids_today,
                            props.experience.attributes.basePvpKids
                          )}
                          %
                        </span>
                      </>
                    )}
                </p>
                <div className="row">
                  <div className="col-xs-4 text-center">
                    <Fab
                      className={classes.primaryColor}
                      aria-label="remove"
                      onClick={handleRemovePaxKids}
                      disabled={selectedPaxKids <= 0}
                    >
                      <RemoveIcon />
                    </Fab>
                  </div>
                  <div className="col-xs-4 text-center">
                    <p className={classes.paxSelector}>{selectedPaxKids}</p>
                  </div>
                  <div className="col-xs-4 text-center">
                    <Fab
                      className={classes.primaryColor}
                      aria-label="add"
                      onClick={handleAddPaxKids}
                      disabled={wantToSelectMorePax}
                    >
                      <AddIcon />
                    </Fab>
                  </div>
                </div>
                {props.experience.attributes.productTickets.kids_min_age > 0 &&
                  !STRICT_MIN_AGE_KIDS_PRODUCTS.includes(
                    props.experience.id
                  ) && (
                    <small>
                      {I18n.t("minor_under", {
                        count:
                          props.experience.attributes.productTickets
                            .kids_min_age,
                      })}
                    </small>
                  )}
              </React.Fragment>
            )}

          <div className="row" style={{ marginBottom: "0px", bottom: 0 }}>
            <div className="col-xs-12">
              <hr />
            </div>
            <div className="col-xs-6">
              <p className={classes.totalText}>Total</p>
            </div>
            <div className="col-xs-6 text-right">
              <p className={classes.price}>
                {totalPriceNow()}
                {currencyToSymbol(props.experience.attributes.currency)}
              </p>
            </div>

            <div className="col-xs-12">
              {wantToSelectMorePax && allowGroupsMessage && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: I18n.t("book_group_html", {
                      hotel: props.experience.attributes.hotel,
                      experience: props.experience.attributes.name,
                    }),
                  }}
                ></p>
              )}

              {selectedTimeFrame?.open &&
                props.experience.attributes.hasDuration && (
                  <TimeFrameSelector
                    kids={selectedPaxKids}
                    adults={selectedPax}
                    selectedDate={selectedDate}
                    hotelSlug={hotel}
                    productSlug={product}
                    massage={props.experience.attributes.category.includes("Massage")}
                    spa={props.experience.attributes.category.includes("Spa")}
                  />
                )}

              {(selectedTimeFrame?.open ||
                !props.experience.attributes.hasDuration) && (
                  <button
                    disabled={
                      !selectedTimeFrame.status &&
                      props.experience.attributes.hasDuration
                    }
                    className={"btn btn-primary " + classes.fullBtn}
                    onClick={() => handleRedirectToReservationPage()}
                  >
                    {I18n.t("book_now")}
                  </button>
                )}

              {!selectedTimeFrame?.open &&
                props.experience.attributes.hasDuration && (
                  <button
                    className={"btn btn-primary " + classes.fullBtn}
                    onClick={() => handleSeeSchedules(true)}
                  >
                    {I18n.t("product_booking__show_timeframes")}
                  </button>
                )}

              <br />
              {props.experience.attributes.acceptsGift ? (
                <p className="text-center">
                  <Emoji symbol="👛" /> {I18n.t("wallet_earn")}{" "}
                  {currentAvailabilities.availabilities[selectedDate] &&
                    calculateRewardGain(totalPriceNow(), area, props.experience.attributes.rewardPercent)}
                  €
                </p>
              ) : (
                <p>
                  <Emoji symbol="❌" /> {I18n.t("not_gift_bookable")}
                </p>
              )}
            </div>

            {props.experience.attributes.acceptsGift && (
              <>
                <div style={{ clear: "both" }} />
                <hr className={classes.dividing} />
                <a
                  className={classes.unstyledLink}
                  onClick={() => {
                    handleRedirect(currentLocale, props.experience.id);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flex: "2 1 auto",
                      alignItems: "center",
                      margin: "0 20px",
                      cursor: "pointer",
                    }}
                    onMouseEnter={toggleFinger}
                    onMouseLeave={toggleFinger}
                  >
                    <div style={{ flexGrow: 2 }}>
                      <h6
                        style={{
                          marginBottom: "0px",
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        <Emoji symbol="🎁" />{" "}
                        {I18n.t("gift_voucher_link_product")}
                      </h6>
                      <small
                        style={{
                          marginBottom: "0px",
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        {I18n.t("give_as_gift_explain")}
                      </small>
                    </div>
                    <div style={{ width: "29px" }}>
                      {<Emoji symbol={finger} />}
                    </div>
                  </Box>
                </a>
              </>
            )}
          </div>
        </React.Fragment>
      )}
      <OddDialog
        open={oddDialogOpen}
        maxGuest={props.experience.attributes.productTickets.max_guest}
        totalPax={selectedPax + selectedPaxKids}
        setOddDialogOpen={setOddDialogOpen}
        setOddAccepted={setOddAccepted}
      />
    </>
  );
}

export default React.memo(ProductBookingForm);
