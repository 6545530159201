import React from "react";

class FavoriteOn extends React.Component {
  render() {
    return (
      <div
        className="favoriteBtn__wrapper_favoriteOn"
        onClick={(ev) => this.props.handleChange(ev, "BtnOn")}
      >
        <i className="fas fa-heart"></i>
      </div>
    );
  }
}

export default FavoriteOn;
