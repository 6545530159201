import React from "react";
import I18n from '../../../i18n-js/index.js.erb';

function GiftBanner(props) {

		return( 
			
			<a className="giftbanner__anchor" href={`/${props.locale}/${I18n.t('routes.gift')}`} alt="gift-card">
				<div className="row" >
					<div className="col-md-12">
						<div className="gitfbanner no-padding-6-l col-md-12">
							<div className="giftbanner__text">
								<p className="giftbanner__text_main">{I18n.t('giftbanner__main')} </p>
								<p className="giftbanner__text_sub">{I18n.t('giftbanner__sub')}  😇</p>

								<p className="giftbanner__text_main2_desk"> {I18n.t('giftbanner__main2')} &gt; </p>
								<p className="giftbanner__text_main2_phone"> &gt; </p>
						</div>
					</div>
					</div>
			</div>
		</a>
		)
}


export default GiftBanner