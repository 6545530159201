import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Moment from "moment";
import { to_eur } from "../../helpers/base";
import { makeStyles } from "@material-ui/core/styles";
import Emoji from "a11y-react-emoji";
import axios from "axios";
import WidgetLoader from "./WidgetLoader";
import { setAvailabilities } from "../redux/actions/actions";
import I18n from "../../i18n-js/index.js.erb";
import { Box } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import tinycolor from "tinycolor2";

function WidgetProductBookingForm(props) {
  const dispatch = useDispatch();

  const currentAvailabilities = useSelector(
    (state) => state.currentAvailabilities
  );

  const hotelUsedData = useSelector((state) => state.hotelUsedData);

  const area = props.area;
  const hotel = props.hotel;
  const product = props.product;
  var showOnlyVouchers =
    props.experience.attributes.widgetVoucher &&
    props.experience.attributes.acceptsGift &&
    !hotelUsedData.hotel.direct_booking &&
    props.brand?.partner_type == "hotel";
  var showVoucherLinkSwitch =
    props.experience.attributes.widgetVoucher &&
    props.experience.attributes.acceptsGift &&
    hotelUsedData.hotel.direct_booking &&
    props.brand?.partner_type == "hotel";

  const allowGroupsMessage = Moment().month() < 4 || Moment().month() > 7;

  const csrfToken = useSelector((state) => state.csrfToken);
  const currentLocale = useSelector((state) => state.currentLocale);
  I18n.locale = currentLocale;

  const [selectedDate, setSelectedDate] = useState(
    currentAvailabilities.setSelectedDate &&
      currentAvailabilities.availabilities[
        currentAvailabilities.setSelectedDate
      ] !== undefined &&
      !currentAvailabilities.availabilities[
        currentAvailabilities.setSelectedDate
      ]?.blocked
      ? currentAvailabilities.setSelectedDate
      : Moment(currentAvailabilities.current_time).format("YYYY-MM-DD")
  );
  const [focusedOnce, setFocusedOnce] = useState(false);
  const [voucherView, setVoucherView] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const adults = [null, undefined, 0].includes(props.adults) ? 1 : props.adults;
  const kids = [null, undefined, 0].includes(props.kids) ? 0 : props.kids;
  const [selectedPax, setSelectedPax] = useState(adults);
  const [selectedPaxKids, setSelectedPaxKids] = useState(kids);
  const [wantToSelectMorePax, setWantToSelectMorePax] = useState(false);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [finger, setFinger] = useState("👉");

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;


  const selectedPaxes = useSelector((state) => state.selectedPax);

  Moment.locale(currentLocale);
  const useStyles = makeStyles((theme) => ({
    primaryColor: {
      backgroundColor: props.brand?.name !== 'widget' ? `${props.brand?.main_color} !important` : "#F7EB67",
      color: (props) => props.brand && tinycolor(props.brand?.main_color).isDark() ? '#FFF' : '#000 !important',
      "& *": {
        color: (props) => props.brand && tinycolor(props.brand?.main_color).isDark() ? '#FFF' : '#000 !important',
      },
      border: props.brand?.name !== 'widget' ?
                tinycolor(props.brand?.main_color).isLight() ?
                  "1px solid black"
                :
                  `1px solid ${props.brand?.main_color} !important`
              :
                "1px solid #F7EB67",
      borderRadius: '2px',
      fontWeight: 'bold',
      padding: '10px 25px 10px 25px',
      "&.Mui-disabled": {
        filter: "opacity(0.5)"
      },
      "&:hover": {
        backgroundColor: props.brand?.name !== 'widget' ? `${props.brand?.main_color} !important` : "#0098d7",
        border: props.brand?.name !== 'widget' ?
                tinycolor(props.brand?.main_color).isLight() ?
                  "1px solid black"
                :
                  `1px solid ${props.brand?.main_color} !important`
              :
                "1px solid #F7EB67",
      }
    },
    rounded: {
      borderRadius: '100px !important',
    },
    paxSelector: {
      fontSize: "32px",
      fontWeight: "600 !important",
    },
    price: {
      fontSize: "30px",
      fontWeight: "800 !important",
    },
    totalText: {
      lineHeight: "43px",
    },
    fullBtn: {
      display: "block !important",
      width: "100%",
    },
    dividing: {
      margin: "10px -5px",
      background: "rgba(0,0,0,0.8",
    },
    giftButton: {
      display: "flex",
      alignItems: "center",
      flex: "2 1 auto",
      cursor: "pointer",
    },
    unstyledLink: {
      textDecoration: "none !important",
      color: "black",
      "&:hover": {
        textDecoration: "none !important",
      },
    },
  }));

  const classes = useStyles();

  const handleRedirect = (locale, hotel, product) => {
    const url = `/${locale}/${I18n.t(
      "routes.create_voucher"
    )}/${hotel}/${product}?adults=${selectedPax}&kids=${selectedPaxKids}`;
    window.open(url, "_self", "noopener,noreferrer");
  };

  const handleDatePickerFocus = () => {
    if (!focusedOnce) {
      setFocusedOnce(true);
    } else {
      setFocusedOnce(false);
    }

    setIsFocused(!isFocused);
  };

  const handleRemovePax = () => {
    if (selectedPax > 1) {
      setSelectedPax(selectedPax - 1);

      if (
        selectedPax + selectedPaxKids <=
        currentAvailabilities.availabilities[selectedDate].ticket_number_today *
          props.experience.attributes.productTickets.max_guest
      ) {
        setWantToSelectMorePax(false);
      }
    }
  };

  const handleAddPax = () => {
    if (
      selectedPax + selectedPaxKids <
      currentAvailabilities.availabilities[selectedDate].ticket_number_today *
        props.experience.attributes.productTickets.max_guest
    ) {
      setSelectedPax(selectedPax + 1);
    } else {
      setWantToSelectMorePax(true);
    }
  };

  const handleRemovePaxKids = () => {
    if (selectedPaxKids > 0) {
      setSelectedPaxKids(selectedPaxKids - 1);

      if (
        selectedPax + selectedPaxKids <=
        currentAvailabilities.availabilities[selectedDate].ticket_number_today *
          props.experience.attributes.productTickets.max_guest
      ) {
        setWantToSelectMorePax(false);
      }
    }
  };

  const handleAddPaxKids = () => {
    if (
      selectedPax + selectedPaxKids <
      currentAvailabilities.availabilities[selectedDate].ticket_number_today *
        props.experience.attributes.productTickets.max_guest
    ) {
      setSelectedPaxKids(selectedPaxKids + 1);
    } else {
      setWantToSelectMorePax(true);
    }
  };

  const handleIsBlockedDays = (momentDate) => {
    let isUnavailable =
      currentAvailabilities.availabilities[momentDate.format("YYYY-MM-DD")] &&
      currentAvailabilities.availabilities[momentDate.format("YYYY-MM-DD")]
        ?.blocked;

    let isBlockedByWeekDay =
      !currentAvailabilities.blocked_days[
        `${momentDate.locale("en").format("d")}`
      ][`${momentDate.locale("en").format("dddd")}`];

    let isPastDay =
      currentAvailabilities.first_date &&
      momentDate.format("YYYY-MM-DD") < currentAvailabilities.first_date;

    let isPastLastDate =
      momentDate.format("YYYY-MM-DD") > currentAvailabilities.last_date;
    if (isUnavailable || isBlockedByWeekDay || isPastDay || isPastLastDate) {
      return true;
    } else {
      return false;
    }
  };

  const reloadAvailabilities = (date) => {
    setLoadingCalendar(true);
    axios
      .get(
        "/api/main/product_booking_forms/show.json",
        !props.modal
          ? {
              params: {
                locale: currentLocale,
                slug: product,
                area: area,
                hotel: hotel,
                date: date,
              },
            }
          : {
              params: {
                locale: currentLocale,
                slug: props.experience.attributes.slug,
                area: area,
                hotel: hotel,
                date: date,
              },
            }
      )
      .then((response) => {
        dispatch(setAvailabilities(response.data.currentAvailabilities));
        setSelectedDate({
          ...response.data.currentAvailabilities.current_time,
          setSelectedDate: false,
        });
        setLoadingCalendar(false);
      });
  };

  const toggleFinger = () => {
    finger === "👉" ? setFinger("👌") : setFinger("👉");
  };

  const handleSelectedDate = (date) => {
    reloadAvailabilities(date);
    setLoadingCalendar(true);
    setSelectedPax(1);
    setSelectedPaxKids(0);
    setWantToSelectMorePax(false);
  };

  const handleRedirectToReservationPage = () => {
    setLoadingCalendar(true);

    axios
      .post("/api/main/reservations.json", {
        start_date: selectedDate,
        hotel_slug: hotel,
        adults: selectedPax,
        kids: selectedPaxKids,
        product_slug: !props.modal ? product : props.experience.attributes.slug,
        locale: currentLocale,
      })
      .then((response) => {
        if (response.status == 200) {
          location.href =
            location.origin + response.data.redirect_to_reservation;
        } else {
          throw new Error("Error");
        }
      })
      .catch((error) => {
        location.href = location.origin + "/error";
      });
  };

  useEffect(() => {
    selectedPaxes &&
      selectedPaxes["adults"] != false &&
      setSelectedPax(selectedPaxes["adults"]);
    selectedPaxes &&
      selectedPaxes["kids"] != false &&
      setSelectedPaxKids(selectedPaxes["kids"]);
  }, [loadingCalendar]);

  return (
    <>
      <p>
        {!voucherView && !showOnlyVouchers && I18n.t("select_date")}
        {showVoucherLinkSwitch && (
          <span style={{ float: "right" }}>
            <a
              href={`/${currentLocale}/${I18n.t("routes.create_voucher")}/${
                props.experience.attributes.slug
              }`}
              target="_blank"
              onClick={() => {
                setVoucherView(!voucherView);
              }}
              style={{ textDecoration: "underline dotted", color: "black" }}
            >
              {voucherView ? <Emoji symbol="🎟️" /> : <Emoji symbol="🎁" />}{" "}
              {voucherView
                ? I18n.t("gift_voucher_redeem__step_title_2")
                : I18n.t("gift_voucher_link_product")}
            </a>
          </span>
        )}
      </p>
      {!voucherView && !showOnlyVouchers && (
        <SingleDatePicker
          date={selectedDate ? Moment(selectedDate) : null}
          onDateChange={(date) => handleSelectedDate(date.format("YYYY-MM-DD"))}
          id="dateSelector"
          focused={isFocused}
          onFocusChange={() => handleDatePickerFocus()}
          numberOfMonths={1}
          firstDayOfWeek={1}
          readOnly={true}
          isDayBlocked={(momentDate) => handleIsBlockedDays(momentDate)}
          displayFormat="LL"
          small
          withPortal={props.style === "inline" ? false : true}
          hideKeyboardShortcutsPanel
          keepOpenOnDateSelect={false}
          showDefaultInputIcon
        />
      )}
      <br />
      <br />

      {loadingCalendar && <WidgetLoader brand={props.brand} />}

      {!loadingCalendar && (
        <React.Fragment>
          <p>
            {I18n.t("adults")}
            {props.experience.attributes.productTickets.max_guest === 1 &&
              currentAvailabilities.availabilities[selectedDate] &&
              " (" +
                to_eur(
                  currentAvailabilities.availabilities[selectedDate]
                    ?.price_today
                ).toFixed(2) +
                "€)"}
          </p>
          <div className="row">
            <div className="col-xs-4 text-center">
              <Fab
                className={clsx(classes.primaryColor, classes.rounded)}
                aria-label="remove"
                onClick={handleRemovePax}
                disabled={selectedPax <= 1}
              >
                <RemoveIcon />
              </Fab>
            </div>
            <div className="col-xs-4 text-center">
              <p className={classes.paxSelector}>{selectedPax}</p>
            </div>
            <div className="col-xs-4 text-center">
              <Fab
                className={clsx(classes.primaryColor, classes.rounded)}
                aria-label="add"
                onClick={handleAddPax}
                disabled={wantToSelectMorePax}
              >
                <AddIcon />
              </Fab>
            </div>
          </div>

          {props.experience.attributes.productTickets.accept_kids &&
            currentAvailabilities.availabilities[selectedDate] &&
            currentAvailabilities.availabilities[selectedDate]
              ?.price_kids_today != 0 && (
              <React.Fragment>
                <p>
                  {I18n.t("kids")} <small>{I18n.t("from_age_to_age", {from: props.experience.attributes.productTickets.kids_min_age, to: props.experience.attributes.productTickets.kids_max_age})}</small> (
                  {currentAvailabilities.availabilities[selectedDate] &&
                    to_eur(
                      currentAvailabilities.availabilities[selectedDate]
                        ?.price_kids_today
                    ).toFixed(2)}
                  €)
                </p>
                <div className="row">
                  <div className="col-xs-4 text-center">
                    <Fab
                      className={clsx(classes.primaryColor, classes.rounded)}
                      aria-label="remove"
                      onClick={handleRemovePaxKids}
                      disabled={selectedPaxKids <= 0}
                    >
                      <RemoveIcon />
                    </Fab>
                  </div>
                  <div className="col-xs-4 text-center">
                    <p className={classes.paxSelector}>{selectedPaxKids}</p>
                  </div>
                  <div className="col-xs-4 text-center">
                    <Fab
                      className={clsx(classes.primaryColor, classes.rounded)}
                      aria-label="add"
                      onClick={handleAddPaxKids}
                      disabled={wantToSelectMorePax}
                    >
                      <AddIcon />
                    </Fab>
                  </div>
                </div>
                {props.experience.attributes.productTickets.kids_min_age > 0 &&
                  <small>{I18n.t("minor_under", {count: props.experience.attributes.productTickets.kids_min_age})}</small>
                }
              </React.Fragment>
            )}

          <div className="row" style={{ marginBottom: "0px", bottom: 0 }}>
            <div className="col-xs-12">
              <hr />
            </div>
            <div className="col-xs-6">
              <p className={classes.totalText}>Total</p>
            </div>
            <div className="col-xs-6 text-right">
              <p className={classes.price}>
                {currentAvailabilities.availabilities[selectedDate] &&
                selectedPax %
                  props.experience.attributes.productTickets.max_guest !==
                  0
                  ? parseFloat(
                      to_eur(
                        currentAvailabilities.availabilities[selectedDate]
                          ?.price_today *
                          Math.ceil(
                            (selectedPax + 1) /
                              props.experience.attributes.productTickets
                                .max_guest
                          )
                      ).toFixed(2)
                    ) +
                    parseFloat(
                      to_eur(
                        currentAvailabilities.availabilities[selectedDate]
                          ?.price_kids_today *
                          Math.ceil(
                            (selectedPaxKids - 1) /
                              props.experience.attributes.productTickets
                                .max_guest
                          )
                      ).toFixed(2)
                    )
                  : parseFloat(
                      to_eur(
                        currentAvailabilities.availabilities[selectedDate]
                          ?.price_today *
                          Math.ceil(
                            selectedPax /
                              props.experience.attributes.productTickets
                                .max_guest
                          )
                      ).toFixed(2)
                    ) +
                    parseFloat(
                      to_eur(
                        currentAvailabilities.availabilities[selectedDate]
                          ?.price_kids_today *
                          Math.ceil(
                            selectedPaxKids /
                              props.experience.attributes.productTickets
                                .max_guest
                          )
                      ).toFixed(2)
                    )}
                €
              </p>
            </div>

            <div className="col-xs-12">
              {wantToSelectMorePax && allowGroupsMessage && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: I18n.t("book_group_html", {
                      hotel: props.experience.attributes.hotel,
                      experience: props.experience.attributes.name,
                    }),
                  }}
                ></p>
              )}
              {!voucherView && !showOnlyVouchers && (
                <button
                  className={clsx("btn", classes.fullBtn, classes.primaryColor)}
                  onClick={() => handleRedirectToReservationPage()}
                >
                  {I18n.t("book_now")}
                </button>
              )}
              {(showOnlyVouchers || (voucherView && showVoucherLinkSwitch)) && (
                <button
                  className={clsx("btn", classes.fullBtn, classes.primaryColor)}
                  onClick={() => {
                    handleRedirect(
                      currentLocale,
                      props.hotel,
                      props.experience.attributes.slug
                    );
                  }}
                >
                  {I18n.t("give_daycations_btn")}
                </button>
              )}

              {!voucherView && !showOnlyVouchers && showVoucherLinkSwitch && (
                <>
                  <div style={{ clear: "both" }} />
                  <a
                    className={classes.unstyledLink}
                    onClick={() => {
                      setVoucherView(!voucherView);
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flex: "2 1 auto",
                        alignItems: "center",
                        margin: "0 20px",
                        cursor: "pointer",
                      }}
                      onMouseEnter={toggleFinger}
                      onMouseLeave={toggleFinger}
                    >
                      <div style={{ flexGrow: 2 }}>
                        <h6
                          style={{
                            marginBottom: "0px",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          <Emoji symbol="🎁" />{" "}
                          {I18n.t("gift_voucher_link_product")}
                        </h6>
                        <small
                          style={{
                            marginBottom: "0px",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          {I18n.t("give_as_gift_explain")}
                        </small>
                      </div>
                      <div style={{ width: "29px" }}>
                        {<Emoji symbol={finger} />}
                      </div>
                    </Box>
                  </a>
                </>
              )}
              <br />
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
}

export default React.memo(WidgetProductBookingForm);
