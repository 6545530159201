import React from "react";
import { useState, useEffect } from "react";
import I18n from "../../../../i18n-js/index.js.erb";
import Moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { DayPickerSingleDateController } from "react-dates";
import "react-dates/initialize";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

export default function SelectDate(props) {
  const dispatch = useDispatch();
  const [selectDate, setSelectDate] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [focusedOnce, setFocusedOnce] = useState(false);
  const today = Moment()

  const submitAndClose = () => {
    props.setExternalSubmit(true);
    setSelectDate(false);
  }
  const handleSelectedDate = (date) => {
    setIsFocused(!isFocused);
    props.setChosenDate(date);
  };
  const handleDatePickerFocus = () => {
    if (!focusedOnce) {
      setFocusedOnce(true);
    } else {
      setFocusedOnce(false);
    }

    setIsFocused(!isFocused);
  };
  const handleIsBlockedDays = (momentDate) => {
    const blockDayBeforeToday = Moment(momentDate).format("YYYY-MM-DD") < Moment().format("YYYY-MM-DD");
    const blockDayRange = momentDate > Moment(new Date()).add(90, "days");
    const blockCategory =
      momentDate.format("YYYY-MM-DD") === Moment().format("YYYY-MM-DD") &&
      props.todayNotAvailable;
    const blockTodayAfterTime =
      momentDate.format("YYYY-MM-DD") === Moment().format("YYYY-MM-DD") &&
      Moment().format("HH") >= 12;

    return blockDayBeforeToday || blockDayRange || blockCategory || blockTodayAfterTime;

  };

  const isTodayBlocked = (today = false, dayNum = false) => {
    const past_12 = today && Moment().format("HH") >= 12 && true
    const saturday = dayNum && (Moment().weekday() == 4 || Moment().weekday() == dayNum || Moment().weekday() == 6) && true // 5
    const sunday = dayNum && (Moment().weekday() == dayNum || Moment().weekday() == 5) && true // 6

    return past_12 || saturday || sunday
  }

  const isTodaySelected = (type, dayNum = false) => {
    const today = type == 'today' && props.chosenDate == Moment().format("YYYY-MM-DD")
    const tomorrow = type == 'tomorrow' && props.chosenDate == Moment().add(1, 'day').format("YYYY-MM-DD")
    const saturday = dayNum && Moment(props.chosenDate).format("YYYY-MM-DD") == Moment().day(dayNum).format("YYYY-MM-DD")
    const sunday = dayNum && Moment(props.chosenDate).format("YYYY-MM-DD") == Moment().day(dayNum).format("YYYY-MM-DD")

    return today || tomorrow || saturday || sunday
  }

  const getDateText = () => {
    if (props.chosenDate == Moment().format("YYYY-MM-DD")){
      return I18n.t('today')
    } else if (props.chosenDate == Moment().add(1, 'day').format("YYYY-MM-DD")) {
      return I18n.t('tomorrow')
    } else if (!(props.chosenDate == Moment().add(1, 'day').format("YYYY-MM-DD")) && (Moment(props.chosenDate).format("YYYY-MM-DD") == Moment().day(6).format("YYYY-MM-DD"))) {
      return I18n.t("this_saturday")
    } else if (!(props.chosenDate == Moment().add(1, 'day').format("YYYY-MM-DD")) && (Moment(props.chosenDate).format("YYYY-MM-DD") == Moment().day(7).format("YYYY-MM-DD"))) {
      return I18n.t("this_sunday")
    } else if (props.chosenDate == null) {
      return props.areaHeaderMobileMoving ? I18n.t('date_title') : I18n.t('select_date')
    } else {
      return Moment(props.chosenDate).format("DD/MM/YYYY")
    }
  }

  const useStyles = makeStyles((theme) => ({
    filterBarNoFix: {
      top: "10px !important",
      zIndex: "2",
      background: "white",
      display: 'flex',
      width: "100vw",
    },
    filterBar: {
      position: "fixed",
      top: 58,
      width: "100vw",
      zIndex: "11",
      background: "white",
      boxShadow: "0 4px 4px 1px rgba(0,0,0,0.1)",
      display: props.offset < 70 ? 'none' : 'flex',
      gap: 5,
      padding: "5px 15px"
    },
    filterBarDesktop: {
      position: 'fixed',
      top: 5,
      width: "20vw",
      margin: "0 35vw",
      zIndex: "1001",
      display: 'flex',
      gap: 5,
      padding: "5px 15px"
    },
    filterBarAreaHome: {
      position: 'inital',
      display: 'flex',
      gap: 5,
      padding: "0 5px",
    },
    filterBarAreaMobileMoving: {
      gap: "5px",
      top: "-45px",
      width: "fit-content",
      display: "flex",
      padding: "5px 15px",
      position: "relative",
      float: 'right'
    },
    filterBarInner: {
      padding: props.areaHeader ? "13px 10px" : props.areaHeaderMobileMoving ? "5px 10px" : "10px 10px",
      border: "1px solid darkgrey",
      borderRadius: props.desktop ? 20 : props.areaHeader ? 4 : 2,
      flex: "0 1 0",
      background: 'white',
      boxShadow: props.desktop ? "0 4px 4px 1px rgba(0, 0, 0, 0.1)" : "none",
      cursor: 'pointer'
    },
    daySelectors: {
      display: "flex",
      flexDirection: 'row',
      flexWrap: "wrap"
    },
    dayButtons: {
      margin: 5,
      padding: "5px 10px",
      border: "1px solid darkgrey",
      borderRadius: 2,
      flex: "1 1 33%",
      cursor: 'pointer',
      "&:hover": {
        boxShadow: "4px 4px 0px black"
      }
    },
    dayButtonsSelected: {
      margin: 5,
      padding: "5px 10px",
      border: "1px solid darkgrey",
      borderRadius: 2,
      flex: "1 1 33%",
      background: '#F7EB67',
      fontWeight: 600,
      cursor: 'pointer',
      "&:hover": {
        boxShadow: "4px 4px 0px black"
      }
    },
    dayButtonsDisabled: {
      margin: 5,
      padding: "5px 10px",
      border: "1px solid darkgrey",
      borderRadius: 2,
      flex: "1 1 33%",
      background: 'lightgrey',
      cursor: 'not-allowed',
    },
    applyButton: {
      textAlign: "center",
      background: "#F7EB67",
      padding: "5px 10px",
      textTransform: 'none',
      fontFamily: "TobiasTrial-SemiBold",
      borderRadius: "0",
      width: "100%",
      margin: "0 auto",
      fontSize: "16px",
      color: "black",
      fontWeight: 800,
      "&:hover": {
        background: '#F7EB67',
        boxShadow: "4px 4px 0px black"
      }
    },
    dialogTitle: {
      fontFamily: "TobiasTrial-Regular",
    },
    closeButton: {
      float: "right",
      marginTop: "-10px;",
    },
  }))

  const classes = useStyles();

  const selectDateClose = () => {
    setSelectDate(false);
  }

  const getFilterBarClass = () => {
    return props.desktop ?
             classes.filterBarDesktop
           :
             props.areaHeader ?
               classes.filterBarAreaHome
             : props.areaHeaderMobileMoving ?
                   classes.filterBarAreaMobileMoving
               : classes.filterBar
  }

  return (
    <React.Fragment>
      <div className={getFilterBarClass()}>
        <div className={classes.filterBarInner} style={{flexGrow: 1.4, textWrap: 'nowrap'}} onClick={() => setSelectDate(true)}>📆 {getDateText()}</div>
        {/* <div className={classes.filterBarInner} style={{flexGrow: 1}}>💁 Personas</div> */}
        <div className={classes.filterBarInner} style={{flexGrow: 0.2, textAlign: 'center'}} onClick={props.handleFiltersOpen}>{props.desktop ? I18n.t("all_filters") : "+"}</div>
      </div>

      {/* Date selection dialog */}
      <Dialog
        open={selectDate}
        onClose={selectDateClose}

      >
        <DialogTitle>
          <span className={classes.dialogTitle}>{I18n.t("select_date")}</span>
          <IconButton
            className={classes.closeButton}
            edge="end"
            color="inherit"
            onClick={selectDateClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.daySelectors}>
            {
              <Tooltip
                title={I18n.t("bookable_today_rules")}
                followCursor
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: '12px',
                    },
                  },
                }}
              >
                <div
                  className={isTodayBlocked(today) ? classes.dayButtonsDisabled : isTodaySelected('today') ? classes.dayButtonsSelected : classes.dayButtons}
                  onClick={() => isTodayBlocked(today) ? null : handleSelectedDate(Moment().format("YYYY-MM-DD"))}
                >
                  {I18n.t("today")}
                </div>
              </Tooltip>
            }
            <div
              className={isTodaySelected('tomorrow') ? classes.dayButtonsSelected :classes.dayButtons}
              onClick={() => handleSelectedDate(Moment().add(1, "days").format("YYYY-MM-DD"))}
            >
              {I18n.t("tomorrow")}
            </div>
            <div
              className={isTodayBlocked(false, 6) ? classes.dayButtonsDisabled : isTodaySelected(false, 6) ? classes.dayButtonsSelected : classes.dayButtons}
              onClick={() => isTodayBlocked(false, 6) ? null : handleSelectedDate(Moment().day(6).format("YYYY-MM-DD"))}
            >
              {I18n.t("this_saturday")}
            </div>
            <div
              className={isTodayBlocked(false, 0) ? classes.dayButtonsDisabled : isTodaySelected(false, 7) ? classes.dayButtonsSelected : classes.dayButtons}
              onClick={() => isTodayBlocked(false, 0) ? null : handleSelectedDate(Moment().day(7).format("YYYY-MM-DD"))}
            >
              {I18n.t("this_sunday")}
            </div>
          </div>
          <div style={{width: "100%", padding: props.desktop || props.areaHeader ? "0 1.9vw" : 'none'}}>
            <DayPickerSingleDateController
              date={props.chosenDate ? Moment(props.chosenDate) : null}
              onDateChange={(date) =>
                handleSelectedDate(date.format("YYYY-MM-DD"))
              }
              style={{margin: "0 auto"}}
              id="dateSelector"
              focused={isFocused}
              onFocusChange={() => handleDatePickerFocus()}
              isDayBlocked={(momentDate) => handleIsBlockedDays(momentDate)}
              numberOfMonths={1}
              firstDayOfWeek={1}
              hideKeyboardShortcutsPanel
              keepOpenOnDateSelect={false}
              noBorder
              daySize={35}
              horizontalMonthPadding={0}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.applyButton} onClick={() => submitAndClose()}>Aplicar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

