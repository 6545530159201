import I18n from "../../i18n-js/index.js.erb";
import React from 'react'
import creditCard from "images/credit_cards.svg";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import tinycolor from "tinycolor2";
import logoYellow from "images/logo_yellow.svg";

function WidgetFooter(props) {
  
  const useStyles = makeStyles((theme) => ({
    root: {
      background: props.brand && `${props.brand?.main_color} !important`,
      color: (props) => props.brand ? tinycolor(props.brand?.main_color).isDark() ? '#FFF !important' : '#000 !important' : '#FFF !important',
      "& *": {
        color: (props) => props.brand ? tinycolor(props.brand?.main_color).isDark() ? '#FFF !important' : '#000 !important' : '#FFF !important',
      }
    },
    headings: {
      fontFamily: props.brand ? props.brand?.font : "sans-serif",
    },
    support: {
      paddingTop: '80px',
    }
  }));

  const classes = useStyles(props);

  return (
    <React.Fragment>
      <footer id="footer" className={clsx("page-footer", classes.root)}>
        <hr />
        <div className="row no-gutters">
          <div className="col-md-6 col-md-offset-1 col-xs-12 text-left">
            <div className={clsx("footer-copyright", classes.root, classes.support)}>
              {props.brand?.partner_type === 'brand' && <span>{I18n.t("client_support")}: <a href="mailto:info@hotelbreak.com">info@hotelbreak.com</a></span>}
            </div>
          </div>
          <div className="col-md-4 text-right col-xs-12">
            <h5 className={clsx("text-uppercase", classes.headings)} >{I18n.t("secured_payment")}</h5>
            <img
              src={creditCard}
              data-original={creditCard}
              alt={I18n.t("secured_payment")}
              style={{width: "200px"}}
            />
          </div>
        </div>

        <div className="row no-gutters" style={{paddingTop: "20px"}}>
          <div className="col-md-6 col-md-offset-1 col-xs-12 text-left">
            <div className={clsx("footer-copyright", classes.root)}>
              {props.brand ?
                props.brand?.partner_type === 'brand' ?
                  <>
                    {(I18n.t('our_partner'))}: <a href='https://www.hotelbreak.com'>hotelbreak.com</a>
                  </> : (<p>Empowered by <a href='https://www.hotelbreak.com'>hotelbreak.com</a></p>)
                :
                  <img src={logoYellow} style={{width: "150px"}} />
              }
            </div>
          </div>
          <div className="col-md-4 col-xs-12 text-right">
            <a href="mailto:info@hotelbreak.com">{I18n.t("contact")}</a>&nbsp;&nbsp;&nbsp;&nbsp;
            <a href={`https://www.hotelbreak.com/${I18n.locale}/cookies`} >Cookies</a>&nbsp;&nbsp;&nbsp;&nbsp;
            <a href={`https://www.hotelbreak.com/${I18n.locale}/${I18n.t("routes.terms")}`} >{I18n.t("terms")}</a>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default WidgetFooter;