import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import I18n from "../../../../i18n-js/index.js.erb";
import MailCheck from "react-mailcheck";
import libphonenumbers from "libphonenumbers";
import React, { useState } from "react";
import { FormGroup, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { setCurrentUser } from "../../../redux/actions/actions";
import clsx from "clsx";
import { useStylesPayment } from "../../styles/paymentOptionsStyles";

const reservation_code = new URLSearchParams(window.location.search).get(
  "reservation_code"
);

function ReservationUpdateUser(props) {
  const BASE_URL = props.baseUrl || '';
  const dispatch = useDispatch();
  const classes = useStylesPayment(props);
  const currentLocale = useSelector((state) => state.currentLocale);
  const csrfToken = useSelector((state) => state.csrfToken);
  const currentUser = useSelector((state) => state.currentUser);

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;


  const [email, setEmail] = useState();

  const [phone, setPhone] = useState(currentUser.phoneNumber);

  const schema = yup.object({});
  const FORM_SOURCE = "Reservation update";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  let form_style = {
    width: "100%",
    borderRadius: "unset",
    height: "4rem",
  };

  let email_suggestion_link = {
    textDecoration: "underline",
    cursor: "pointer",
  };

  const [phoneError, setPhoneError] = useState(false);

  const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();

  const analyticsTrack = (eventType, inputHash) => {
    analytics.track(eventType, { inputHash, form: FORM_SOURCE });
  };

  const isValid = (value, country) => {
    const countryUp = country.toUpperCase();
    if (phoneUtil.parseAndKeepRawInput(value, countryUp)) {
      const number = phoneUtil.parseAndKeepRawInput(value, countryUp);
      if (!phoneUtil.isValidNumberForRegion(number, countryUp)) {
        setPhoneError(true);
        analyticsTrack("Filled reservation_phone", {
          input: value,
          country: country,
          validation: "passed",
        });
      } else {
        setPhoneError(false);
        analyticsTrack("Filled reservation_phone", {
          input: value,
          country: country,
          validation: "Not passed isValidNumberForRegion",
        });
      }
    } else {
      setPhoneError(false);
      analyticsTrack("Filled reservation_phone", {
        input: value,
        country: country,
        validation: "Not passed parseAndKeepRawInput",
      });
    }
  };

  I18n.locale = currentLocale;

  const onSubmit = (data) => {
    if (phoneError) {
      return false;
    }

    axios
      .put(`${BASE_URL}/api/main/funnel/users/${currentUser.id}`, {
        reservation_phone: phone,
        id_number: data.reservation_dni,
        reservation_code: reservation_code,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setCurrentUser(response.data.current_user.data.attributes));
          if (response.data.current_user.data.attributes.phoneNumber == null) {
            setPhoneError(true);
          }
        }
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-12 no-padding">
          <div className="col-md-6 no-padding-6-l">
            <Label for="reservation_name">
              <i className="fas fa-user"></i> {I18n.t("form_name")}
            </Label>

            <input
              type="text"
              name="reservation_name"
              className="form-control"
              placeholder={I18n.t("form_name")}
              autoFocus="true"
              required
              minLength="3"
              disabled={currentUser.fullname !== null}
              defaultValue={currentUser.fullname}
              onBlur={(ev) =>
                analyticsTrack("Filled reservation_name", {
                  input: ev.target.value,
                })
              }
              onFocus={(ev) =>
                analyticsTrack("Entered reservation_name", {
                  input: ev.target.value,
                })
              }
            />
          </div>
          <div className="col-md-6 no-padding-6-r">
            <Label for="reservation_lastname">
              {" "}
              {I18n.t("form_last_name")}
            </Label>
            <input
              type="text"
              name="reservation_lastname"
              className="form-control"
              placeholder={I18n.t("form_last_name_ex")}
              required
              minLength="3"
              disabled={currentUser.lastname !== null}
              defaultValue={currentUser.lastname}
              onBlur={(ev) =>
                analyticsTrack("Filled reservation_lastname", {
                  input: ev.target.value,
                })
              }
              onFocus={(ev) =>
                analyticsTrack("Entered reservation_lastname", {
                  input: ev.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-md-12 no-padding">
          <Label for="reservation_email">
            <i className="fas fa-envelope"></i> {I18n.t("form_email")}
          </Label>
          <MailCheck email={email}>
            {(suggestion) => (
              <div>
                <input
                  type="email"
                  value={currentUser.email}
                  name="reservation_email"
                  onChange={(ev) => setEmail(ev.target.value)}
                  className="form-control"
                  {...register("reservation_email")}
                  placeholder={I18n.t("form_email")}
                  disabled={true}
                  onBlur={(ev) =>
                    analyticsTrack("Filled reservation_email", {
                      input: ev.target.value,
                    })
                  }
                  onFocus={(ev) =>
                    analyticsTrack("Entered reservation_email", {
                      input: ev.target.value,
                    })
                  }
                />
                {suggestion && (
                  <div>
                    {I18n.t("do_you_mean")}{" "}
                    <span
                      style={email_suggestion_link}
                      onClick={(ev) => setEmail(suggestion.full)}
                    >
                      {suggestion.full}
                    </span>
                    ?
                  </div>
                )}
              </div>
            )}
          </MailCheck>
        </div>
        <div className="col-md-12 no-padding">
          <br />
          <p>
            <input
              type="checkbox"
              name="gdpr"
              onClick={(ev) =>
                analyticsTrack("Checked gdpr", {
                  checked: ev.target.checked,
                })
              }
            />
            {I18n.t("gdpr_sentence")}
          </p>
        </div>
        <div className="col-md-6 no-padding-6-l">
          <FormGroup>
            <Label
              for="reservation_phone"
              id="phoneLabel"
              className={phoneError ? "error" : ""}
            >
              <i className="fas fa-phone"></i> {I18n.t("form_phone")}
            </Label>
            <PhoneInput
              country={props.locale}
              value={phone}
              onChange={setPhone}
              localization={props.locale}
              enableSearch
              searchPlaceholder={I18n.t("search_button")}
              searchNotFound={I18n.t("not_found")}
              autocompleteSearch
              onBlur={(e, country) =>
                isValid(e.target.value, country.countryCode)
              }
              onFocus={(ev) =>
                analyticsTrack("Entered reservation_phone", {
                  input: ev.target.value,
                })
              }
              placeholder={I18n.t("form_phone")}
              countryCodeEditable={false}
              inputProps={{
                name: "reservation_phone",
                required: true,
              }}
              inputStyle={form_style}
            />

            {phoneError && <p className="error">{I18n.t("wrong_phone")}</p>}
          </FormGroup>
        </div>
        <div className="col-md-6 no-padding-6-r">
          {currentUser?.isInformationComplete?.country === "ESP" && (
            <>
              <Label for="reservation_dni">
                <i className="fas fa-id-card"></i> {I18n.t("identity_number")}
              </Label>
              <input
                type="text"
                name="reservation_dni"
                {...register("reservation_dni")}
                className="form-control"
                placeholder="ej: 13149093E"
                length="7"
                required
                defaultValue={currentUser.idNumber}
                onBlur={(ev) =>
                  analyticsTrack("Filled reservation_dni", {
                    input: ev.target.value,
                  })
                }
                onFocus={(ev) =>
                  analyticsTrack("Entered reservation_dni", {
                    input: ev.target.value,
                  })
                }
              />
              <br />
            </>
          )}
        </div>
        <div className="col-md-12 text-right no-padding">
          <button
            className={clsx("btn", classes.primaryColor)}
            type="submit"
            onClick={() => analyticsTrack("Continue to payment")}
          >
            {I18n.t("payment_form__continue_button")}{" "}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ReservationUpdateUser;
