import React from "react"
import {useState, useEffect} from 'react'
import PressBlock from './PressBlock'
import axios from 'axios'
import I18n from '../../../i18n-js/index.js.erb'
import NavDesktop from '../nav/NavDesktop'
import NavMobile from '../nav/NavMobile'
import { createMedia } from "@artsy/fresnel"
import { makeStyles } from '@material-ui/core/styles';
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  configAreaReducer,
  changeLocaleReducer,
  configCsrfTokenReducer,
  changeHotelDataReducer,
  currentUserReducer,
  currentAvailabilities,
  desktopUserLoginFormStatus,
  areaHomeProductsReducer,
  stepsVoucherCreationParams,
  clientSecret,
  areaHomeHotelCategoriesReducer,
  JWTTokenReducer,
} from "../../redux/reducers/reducers";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import Link from '@mui/material/Link';
import HotelBreakLoader from "../components/loaders/hotelBreakLoader"

const store = configureStore({
  reducer: {
    area: configAreaReducer,
    currentLocale: changeLocaleReducer,
    csrfToken: configCsrfTokenReducer,
    hotelUsedData: changeHotelDataReducer,
    currentUser: currentUserReducer,
    currentAvailabilities: currentAvailabilities,
    desktopUserLoginFormStatus: desktopUserLoginFormStatus,
    areaHomeProducts: areaHomeProductsReducer,
    stepsVoucherCreationParams: stepsVoucherCreationParams,
    clientSecret: clientSecret,
    areaHomeHotelCategories: areaHomeHotelCategoriesReducer,
    JWTToken: JWTTokenReducer,
  },
});


function PressList(props) {

  const [articles, setArticles] = useState();

  I18n.locale = props.current_locale

  useEffect(() => {
    axios
    .get("/api/v1/press/articles")
    .then((response) => {
      setArticles(response.data.data)
    });
    document.title = "hotelbreak | " + I18n.t('press')
  }, []);

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  })

  const useStyles = makeStyles((theme) => ({
    slidingPane: {
      marginTop: '190px',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
      backgroundColor: 'white',
      width: "100%",
      height: 'fit-content',
      position: 'relative',
      boxShadow: '0 -2px 4px rgba(0,0,0,0.25)'
    },
    backgroundImage: {
      top: 0,
      width: '100vw',
      position: 'fixed',
      zIndex: '-1'
    },
    nav: {
      position: 'absolute',
      top: 0
    }
  }));

  const classes = useStyles();

  return (
    <Provider store={store}>
    <div>
      {/* <div className={classes.nav}>
        <MediaContextProvider>
          <Media at="sm">
            <NavMobile />
          </Media>
          <Media at="md">
            <NavMobile />
          </Media>
          <Media greaterThanOrEqual="lg">
            <NavDesktop />
          </Media>
        </MediaContextProvider>
      </div> */}
      
      <div id="about_banner" className={classes.backgroundImage}></div>
      <div className={classes.slidingPane + " container"}>
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <h1>{I18n.t('press')}</h1>
            <br />
          </div>
          <div className="col-md-10 col-md-offset-1">
            <div className="about_text">{I18n.t('press_text_1')}</div>
            <div className="about_text">{I18n.t('press_text_2')}</div>
            <div className="about_text">{I18n.t('press_text_3')}</div>
            <div className="about_text">{I18n.t('press_text_4')}</div>
            <div className="about_text" dangerouslySetInnerHTML={{ __html: I18n.t('press_html')}}></div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <h2>{I18n.t('talk_about')}</h2>
            {articles ?
              articles.map(article => 
                <PressBlock 
                  key = {article.id}
                  title = {article.attributes.title}
                  link = {article.attributes.link}
                  publishDate = {article.attributes.publishDate} 
                  logo = {article.attributes.logo}
                /> )
              :
              <HotelBreakLoader />
            }
          </div>
        </div> 
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb" separator="›">
                <Link underline="hover" color="inherit" href="/">
                  hotelbreak.com
                </Link>
                <Typography color="text.primary">{I18n.t('press')}</Typography>
              </Breadcrumbs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Provider>
  )    
}

export default PressList;