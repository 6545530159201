import React from "react";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Emoji from "a11y-react-emoji";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import I18n from "../../../i18n-js/index.js.erb";
import slugify from "react-slugify";

function AreaCategoryNav(props) {
  const selectedArea = useSelector((state) => state.area);
  const areaHomeProducts = useSelector((state) => state.areaHomeProducts);
  const currentLocale = useSelector((state) => state.currentLocale);

  const handleCategoryChange = (category_slug) => {
    location.href =
      location.origin +
      `/${currentLocale}/${selectedArea.toLowerCase()}/${slug(category_slug)}`;
  };

  const useStyles = makeStyles((theme) => ({
    buttonText: {
      color: "black",
      fontSize: "16px",
    },
    emojis: {
      marginRight: "15px",
    },
  }));

  const classes = useStyles();

  const slug = (slug) => {
    switch (slug) {
    case "most-popular":
      return "experiences?orderby=mostPopular";
    case "most-recent":
      return "experiences?orderby=mostRecent";
    case "family-friendly":
      return "family-friendly";
    case "all-inclusive":
      return "all-inclusive";
    case "adults-only":
      return `adults-only`;
    case "recommended":
      return;
    case "recommended_south":
      return;
    case "recommended_north":
    default:
      return slugify(slug);
    }
  };

  return (
    <>
      {areaHomeProducts.status &&
        areaHomeProducts.payload.map(function (category) {
          return (
            <>
              {category.slug.includes("recommended") ? null : (
                <>
                  <div
                    onClick={() => handleCategoryChange(category.slug)}
                    title={category.title}
                  >
                    <ListItem button className={classes.buttonText}>
                      <Emoji
                        symbol={category.emoji}
                        className={classes.emojis}
                      />{" "}
                      {category.title}
                    </ListItem>
                  </div>
                  <Divider />
                </>
              )}
            </>
          );
        })}
    </>
  );
}

export default AreaCategoryNav;
