import React, { useState, useEffect } from "react";
import I18n from "../../../../i18n-js/index.js.erb";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  setDesktopUserLoginFormStatus,
  setCurrentUser,
} from "../../../redux/actions/actions";
import { Button, Row, Label, Input } from "reactstrap";
import * as yup from "yup";

export default function UserRegister() {
  const dispatch = useDispatch();

  const [resource, setResource] = useState(false);

  const desktopUserLoginFormStatus = useSelector(
    (state) => state.desktopUserLoginFormStatus
  );

  const schema = yup.object({
    fullname: yup.string().required(I18n.t("is_required")),
    lastname: yup.string().required(I18n.t("is_required")),
    email: yup
      .string()
      .email(I18n.t("reservation_email_format"))
      .required(I18n.t("is_required")),
  });

  const onSubmit = (data) => {
    axios
      .post("/widget/users", {
        ...resource,
        ...data,
        subscription: desktopUserLoginFormStatus.subscription,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setCurrentUser({
              ...response.data.current_user.data.attributes,
              id: response.data.current_user.data.id,
            })
          );
          dispatch(
            setDesktopUserLoginFormStatus({
              open: false,
              signin: false,
              register: false,
            })
          );
        } else {
          console.log("error", response.data.error);
        }
      })
      .catch(function (error) {
        [
          {
            type: "manual",
            name: "email",
            message: I18n.t("devise.failure.invalid"),
          },
        ].forEach(({ name, type, message }) =>
          setError(name, { type, message })
        );
      });
  };

  useEffect(() => {
    axios
      .get("/api/main/registrations/new")
      .then((response) => {
        if (response.data.status) {
          setResource(response.data.resource);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const body = (
    <>
      {/* <%= render 'shared/devisemes' %> */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <div className="col-md-12">
            <Label>
              <strong>{I18n.t("form_name")}</strong>
            </Label>

            <Controller
              name="fullname"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Input
                  type="text"
                  {...field}
                  innerRef={ref}
                  className={
                    errors.fullname ? "form-control error" : "form-control"
                  }
                  placeholder={I18n.t("form_last_name_ex")}
                />
              )}
            />

            <p className="error">{errors.fullname?.message}</p>
          </div>

          <div className="col-md-12">
            <Label>
              <strong>{I18n.t("form_last_name")}</strong>
            </Label>

            <Controller
              name="lastname"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Input
                  {...field}
                  type="text"
                  innerRef={ref}
                  className={
                    errors.fullname ? "form-control error" : "form-control"
                  }
                  placeholder={I18n.t("form_last_name_ex")}
                />
              )}
            />

            <p className="error">{errors.lastname?.message}</p>
          </div>

          <div className="col-md-12">
            <Label>
              <strong>{I18n.t("form_email")}</strong>
            </Label>

            <Controller
              name="email"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Input
                  {...field}
                  type="text"
                  innerRef={ref}
                  className={
                    errors.email ? "form-control error" : "form-control"
                  }
                  placeholder={I18n.t("form_email_ex")}
                />
              )}
            />
            <p className="error">{errors.email?.message}</p>
          </div>

          <input
            type="hidden"
            name="user_locale"
            {...register("user_locale")}
            value={I18n.locale}
          />

          <div className="col-md-12">
            <input
              className="form-check-input"
              type="checkbox"
              name="gdpr"
              {...register("gdpr")}
              id="gdpr"
              style={{ float: "right", marginTop: "20px" }}
            />
            <Label
              className="form-check-label"
              for="gdpr"
              style={{ maxWidth: "90%" }}
              check
            >
              {" "}
              {I18n.t("gdpr_sentence")}
            </Label>
          </div>

          <div className="col-md-12 text-right">
            <Button
              type="submit"
              id="registration_submit"
              className="btn btn-primary"
              style={{ width: "100%" }}
            >
              {I18n.t("sign_up")}
            </Button>
          </div>
        </Row>
      </form>
    </>
  );

  return <React.Fragment>{body}</React.Fragment>;
}
