import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  configAreaReducer,
  changeLocaleReducer,
  configCsrfTokenReducer,
  changeHotelDataReducer,
  currentUserReducer,
  currentAvailabilities,
  desktopUserLoginFormStatus,
  areaHomeProductsReducer,
  stepsVoucherCreationParams,
  clientSecret,
  configAreaNameReducer,
  areaHomeHotelCategoriesReducer,
  JWTTokenReducer,
} from "./redux/reducers/reducers";
import VoucherFunnelTemp from "./VoucherFunnelTemp";
import { CookiesProvider } from "react-cookie";

const store = configureStore({
  reducer: {
    area: configAreaReducer,
    areaName: configAreaNameReducer,
    currentLocale: changeLocaleReducer,
    csrfToken: configCsrfTokenReducer,
    hotelUsedData: changeHotelDataReducer,
    currentUser: currentUserReducer,
    currentAvailabilities: currentAvailabilities,
    desktopUserLoginFormStatus: desktopUserLoginFormStatus,
    areaHomeProducts: areaHomeProductsReducer,
    stepsVoucherCreationParams: stepsVoucherCreationParams,
    clientSecret: clientSecret,
    areaHomeHotelCategories: areaHomeHotelCategoriesReducer,
    JWTToken: JWTTokenReducer,
  },
});

function voucherFunnelApp(props) {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <VoucherFunnelTemp
          csrf_token={props.csrf_token}
          current_user={props.current_user}
          area_cookie={props.area_cookie}
          locale={props.locale}
          wallet={props.wallet}
          baseUrl={props.baseUrl}
        />
      </CookiesProvider>
    </Provider>
  );
}

export default voucherFunnelApp;
