import React from "react";
import WidgetNav from "../../../../javascript/components/widget/WidgetNav";
import { makeStyles } from "@material-ui/core/styles";
import I18n from "../../../i18n-js/index.js.erb";

function Header(props) {

  const useStyles = makeStyles((theme) => ({
    titleHeader: {
      margin: "70px 20px 50px",
      fontSize: "30px",
      fontWeight: "400 !important",
      textAlign: "center",
      color: "white",
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <WidgetNav {...props} />
      <h1 className={classes.titleHeader}>{I18n.t("give_daycations_btn")}</h1>
    </React.Fragment>
  );
}

export default Header;
