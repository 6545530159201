import React from "react";
import LazyImage from "../../utilities/lazyload";
import Rectangle from "images/Rectangle.png";
import { useState } from "react";
import {
  capitalizeFirstLetter,
  currencyToSymbol,
  timizeDistance,
} from "../../../helpers/base";
import I18n from "../../../i18n-js/index.js.erb";
import { useSelector } from "react-redux";
import Emoji from "a11y-react-emoji/lib/Emoji";

let noMarginTop = {
  marginTop: "0",
};

let prodNameStyles = {
  color: "grey",
  paddingTop: "15px",
};

let prodPriceStyles = {
  display: "block",
  margin: "0 10px 0 0",
  color: "rgb(45,45,45)",
};

let prodPriceDiscount = {
  textDecoration: "none !important",
  fontWeight: 800,
  padding: 2,
  background: "#F7EB67",
  color: "black",
};

let notAvailableStyles = {
  display: "block",
  margin: "0 10px 0 0",
  fontWeight: "800",
};

let listingNameStyles = {
  fontWeight: "600 !important",
  color: "rgb(45,45,45)",
  fontSize: "14px",
};

let cityAreaStyles = {
  fontSize: "14px",
};

let lazyImgStyles = {
  height: "20rem",
  width: "100%",
  borderRadius: "10px",
  objectFit: "cover",
};

function ProductBlock(props) {
  const [locale, setLocale] = useState(props.current_locale);
  const selectedArea = capitalizeFirstLetter(
    useSelector((state) => state.area)
  );

  const discount = Math.round(
    ((props.week_price - props.base_week_price) / props.base_week_price) * 100
  );

  return (
    <React.Fragment>
      <a
        href={`/${locale}/${props.area}/hotels/${props.hotelSlug}/${
          props.slug
        }?available_date=${
          props.available_date !== undefined && props.available_date !== null
            ? props.available_date
            : ""
        }&adults=${props.adults}&kids=${props.kids}`}
        className="toRoom marginbottom"
        alt={props.name}
        data-name={props.listing_name}
        data-price={props.week_price}
        data-position={props.index}
        target="_blank"
      >
        <div
          className="col-md-3 col-xs-6 hotel-card small-padding"
          id={props.id}
        >
          <div className="row result-hotel no-gutters" style={noMarginTop}>
            <div className="col-md-12">
              {props.block_photo && (
                <LazyImage
                  src={`${props.block_photo}`}
                  lqip={Rectangle}
                  alt={props.name}
                  lazyImgStyles={lazyImgStyles}
                />
              )}
              {props.is_new && <span className="category new">NEW</span>}
              {props.has_calendar && <span className="category direct_booking">🕟 &nbsp; {I18n.t("select_time")}</span>}
              {props.primaryCategoryId == 14 && <span className="category night">{I18n.t("night_included")}</span>}
            </div>

            <div className="col-md-12" style={prodNameStyles}>
              <div className="col-md-12 col-xs-12 no-padding">
                <span className="product_name">{props.name}</span>
              </div>

              <div className="col-md-12 col-xs-12 no-padding product_cat">
                <span style={listingNameStyles}>
                  {props.listing_name}&nbsp;{" "}
                  <small>
                    {props.star_rating && "*".repeat(props.star_rating)}
                  </small>
                </span>
                <br></br>
                {props.city && (
                  <span style={cityAreaStyles}>
                    {capitalizeFirstLetter(props.city)}{" "}
                    {props.timeDistance &&
                    props.timeDistance != 0 &&
                    selectedArea ? (
                      <span>
                        {timizeDistance(
                          props.timeDistance,
                          selectedArea,
                          props.city
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                )}
              </div>

              <div
                className="col-md-12 col-xs-12 no-padding"
                style={{ display: "flex" }}
              >
                {props.week_price && (
                  <p style={prodPriceStyles}>
                    {props.price_model === "net" &&
                      props.max_guest > 1 &&
                      I18n.t("price_from_per_x", {
                        price: props.week_price,
                        currency: props.currency,
                        pers: props.max_guest,
                      })}

                    {props.price_model === "net" &&
                      props.max_guest <= 1 &&
                      I18n.t("price_from_per_pers", {
                        price: props.week_price,
                        currency: props.currency,
                      })}

                    {props.price_model !== "net" &&
                      props.max_guest > 1 &&
                      I18n.t("price_from_per_x", {
                        price: props.week_price,
                        currency: props.currency,
                        pers: props.max_guest,
                      })}

                    {props.price_model !== "net" &&
                      props.max_guest <= 1 &&
                      I18n.t("price_from_per_pers", {
                        price: props.week_price,
                        currency: props.currency,
                      })}
                  </p>
                )}
                {discount < -5 && (
                  <div style={{ flex: 1 }}>
                    <span style={{ textDecoration: "line-through" }}>
                      {props.base_week_price}
                      {currencyToSymbol(props.currency)}
                    </span>{" "}
                    <span style={prodPriceDiscount}>{discount}%</span>
                  </div>
                )}

                {!props.week_price && (
                  <p style={notAvailableStyles}>{I18n.t("not_available")}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </a>
    </React.Fragment>
  );
}

export default ProductBlock;
