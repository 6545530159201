import { makeStyles } from "@material-ui/core/styles";
import tinycolor from "tinycolor2";

const flexAttributes = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "1em",
};

const paymentOptionflexAttributes = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "1em",
};

const useStylesPayment = makeStyles((theme) => ({
  radioStyle: {
    appearance: 'none',
    backgroundColor: '#fff',
    margin: 0,
    font: 'inherit',
    color: 'black',
    width: '1.15em',
    height: '1.15em',
    border: '0.15em solid black',
    borderRadius: '50%',
    transform: 'translateY(-0.075em)',
    display: 'grid',
    placeContent: 'center',
    '&::before': {
      content: "",
      width: '0.65em',
      height: '0.65em',
      borderRadius: '50%',
      transform: 'scale(0)',
      transition: '120ms transform ease-in-out',
      boxShadow: 'inset 1em 1em black',
    },
    '&:checked': {
      background: 'black'
    }
  },
  radioLabel: {
    margin: "0 5px",
    verticalAlign: 'middle'
  },
  partialOptionButton: {
    ...flexAttributes,
    width: "100%",
    boxShadow: "rgb(221 221 221) 0px 0px 0px 1px inset",
    borderRadius: "4px 4px 0 0",
    //height: "5em",
    cursor: "pointer",
  },
  fullOptionButton: {
    ...flexAttributes,
    width: "100%",
    boxShadow: "rgb(221 221 221) 0px 0px 0px 1px inset",
    borderRadius: "0 0 4px 4px",
    cursor: "pointer",
  },
  walletOptionButton: {
    ...flexAttributes,
    width: "100%",
    boxShadow: "rgb(221 221 221) 0px 0px 0px 1px inset",
    borderRadius: "4px",
    height: "4em",
    cursor: "pointer",
  },
  selectedOption: {
    boxShadow: "rgb(34 34 34) 0px 0px 0px 2px inset !important",
  },
  paymentOption: {
    ...paymentOptionflexAttributes,
    width: "100%",
    boxShadow: "rgb(221 221 221) 0px 0px 0px 1px inset",
    borderRadius: "4px",
    padding: "1em",
    cursor: "pointer",
    '& .StripeElement': {
      height: 'auto !important',
      width: 'auto !important',
      marginTop: '1em',
      boxShadow: 'none !important',
      border: 'none !important',
    },
  },
  paymentOptionFirst: {
    borderRadius: '4px 4px 0 0 !important'
  },
  paymentOptionOther: {
    borderRadius: '0 0 4px 4px !important'
  },
  paymentButtonTopWrap: {
    width: "100%",
    height: "auto",
  },
  standardWrap: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  bold: {
    fontWeight: "700",
  },
  subtitle: {
    marginTop: "-10px",
  },
  error: {
    color: "red",
  },
  separator: {
    height: "30px",
  },
  card_padding: {
    paddingTop: "4px",
  },
  disabledOpacity: {
    opacity: "20%",
  },
  explorerPaymentButton: {
    ...paymentOptionflexAttributes,
    width: "100%",
    boxShadow: "rgb(221 221 221) 0px 0px 0px 1px inset !important",
    borderRadius: "4px",
    padding: "1em",
    cursor: "pointer",
  },
  explorerWalletButtonWrapper: {
    margin: 0,
    padding: 0,
    '& .StripeElement': {
      width: '150px',
      border: 'none',
      float: 'right',
      padding: 0,
      marginTop: '20px'
    }
  },
  toolstip: {
    position: "relative",
    display: "inline-block",
    borderBottom: "1px dotted black",
    "&:hover": {
      "& $toolstiptext": {
        visibility: "visible",
      },
    },
  },
  toolstiptext: {
    visibility: "hidden",
    width: "10vw",
    backgroundColor: "black",
    color: "#fff",
    borderRadius: "6px",
    padding: "5px 10px",
    position: "absolute",
    zIndex: "1",
  },
  noFloat: {
    float: 'none !important'
  },
  paymentCheckBox: {
    flexShrink: 0,
  },
  labelPaymentCheckBox: {
    margin: '2px 10px !important'
  },
  checkedStep: {
    padding: '7px 9px !important',
    backgroundColor: '#6BD098 !important'
  },
  userBlock: {
    boxShadow: 'rgb(221 221 221) 0px 0px 0px 1px inset',
    borderRadius: '4px',
    padding: '1em'
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  userName: {
    fontWeight: 800,
    display: 'inline-block'
  },
  accountButton: {
    backgroundColor: "white",
    color: "black",
    textDecoration: "none"
  },
  userImage: {
    borderRadius: '100px',
    width: '20px',
    marginTop: '-1px !important',
    border: '1px solid rgba(0,0,0,0.8)'
  },
  accountButtonIcon: {
    fontSize: '1.5rem'
  },
  modifyUser: {
    float: 'right',
    cursor: 'pointer',
    textDecoration: 'underline dotted'
  },
  headings: {
    fontFamily: props => props.brand && props.brand?.data !== null && props.brand.data.attributes.font,
  },
  primaryColor: {
    backgroundColor: props => props.brand && props.brand?.data !== null && props.brand.data.attributes.name !== 'widget' ? `${props.brand.data.attributes.main_color} !important` : "#F7EB67",
    color: props => props.brand && props.brand?.data !== null && tinycolor(props.brand.data.attributes.main_color).isDark() ? '#FFF' : '#000 !important',
    "& *": {
      color: props => props.brand && props.brand?.data !== null && tinycolor(props.brand.data.attributes.main_color).isDark() ? '#FFF' : '#000 !important',
    },
    border: props => props.brand && props.brand?.data !== null && props.brand.data.attributes.name !== 'widget' ?
      tinycolor(props.brand.data.attributes.main_color).isLight() ?
        "1px solid black"
        :
        `1px solid ${props.brand.data.attributes.main_color} !important`
      :
      "1px solid #F7EB67",
    borderRadius: '2px',
    fontWeight: 'bold',
    padding: '10px 25px 10px 25px',
    "&.Mui-disabled": {
      filter: "opacity(0.5)"
    },
    "&:hover": {
      backgroundColor: props => props.brand && props.brand?.data !== null && props.brand.data.attributes.name !== 'widget' ? `${props.brand.data.attributes.main_color} !important` : "#0098d7",
      border: props => props.brand && props.brand?.data !== null && props.brand.data.attributes.name !== 'widget' ?
        tinycolor(props.brand.data.attributes.main_color).isLight() ?
          "1px solid black"
          :
          `1px solid ${props.brand.data.attributes.main_color} !important`
        :
        "1px solid #0098d7",
    }
  },
}));

export { useStylesPayment };
