import React from "react";
import { useState, useEffect } from "react";
import WidgetNav from "../../widget/WidgetNav";
import NavDesktop from "./NavDesktop";
import { useSelector, useDispatch } from "react-redux";

function NavWrapper(props) {
  const dispatch = useDispatch();

  if (props.brand) {
    return (
      <React.Fragment>
        <WidgetNav brand={props.brand} />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <NavDesktop products={props.products} source={props.source} />
      </React.Fragment>
    );
  }
}

export default NavWrapper;
