import { makeStyles } from "@material-ui/core/styles";

var funnelColor = "#337ab7";

const useStylesPayment = makeStyles((theme) => ({
	paymentButton: {
	  marginBottom: '50px',
	  float: 'right'
	},
	resumeTitle: {
		fontSize: '20px',
		paddingBottom: '40px',
		display: 'block'
	},
	toolstip: {
    position: "relative",
    display: "inline-block",
    borderBottom: "1px dotted black",
    "&:hover": {
      "& $toolstiptext": {
        visibility: "visible",
      },
    },
  },
  toolstiptext: {
    visibility: "hidden",
    width: "10vw",
    backgroundColor: "black",
    color: "#fff",
    borderRadius: "6px",
    padding: "5px 10px",
    position: "absolute",
    zIndex: "1",
  },
}));

const useCardElementStyle = makeStyles((theme) => ({
	cardBase: {
		height: '40px',
		padding: '10px 12px',
		boxSizing: "border-box",
		width: '100%',
		color: '#32325d',
		backgroundColor: 'white',
		borderRadius: '2px',
		boxShadow: '0 1px 3px 0 #e6ebf1',
		webkitTransition: 'box-shadow 150ms ease',
		transition: 'box-shadow 150ms ease',
	},
	cardBaseHalf: {
		height: '40px',
		padding: '10px 12px',
		width: '49%',
		color: '#32325d',
		backgroundColor: 'white',
		borderRadius: '2px',
		boxShadow: '0 1px 3px 0 #e6ebf1',
		webkitTransition: 'box-shadow 150ms ease',
		transition: 'box-shadow 150ms ease',
		marginTop: '10px',
		display: 'inline-block',
		"&:nth-child(2)": {
			marginRight: '2%',
		}
	},
  cardFocus: {
    boxShadow: '0 1px 3px 0 #cfd7df'
  },
  cardInvalid: {
    borderColor: '#fa755a'
  },
  cardWebKitAutofill: {
    backgroundColor: '#fefde5 !important'
  }
}));

export { useStylesPayment, useCardElementStyle };