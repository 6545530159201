import { makeStyles } from "@material-ui/core/styles";
var funnelColor = "#337ab7";

const useStylesHeader = makeStyles((theme) => ({
  header: {
    backgroundColor: (props) =>
      props.brand && props.brand !== null && props.brand.name !== "widget"
        ? `${props.brand.main_color} !important`
        : funnelColor,
    position: "fixed",
    top: 0,
    width: "100%",
    height: "100%",
  },
  logoBlock: {
    backdropFilter: "blur(3px)",
    color: "white",
    backgroundColor: "rgba(255,255,255,1)",
    padding: "10px 20px",
    borderRadius: "20px",
    display: "inline-block",
    margin: "10px 0 0 20px",
  },
  logo: {
    width: "120px",
  },
  titleHeader: {
    margin: "70px 20px 50px",
    fontSize: "30px",
    fontWeight: "400 !important",
    textAlign: "center",
    color: "white",
  },
  titleSubHeader: {
    marginTop: "-20px",
    textAlign: "right",
    maxWidth: "20vw",
    right: "160px",
    position: "absolute",
    color: "white",
  },
  filterButton: {
    position: "absolute",
    top: "190px",
    right: "20px",
    borderRadius: "20px",
    backgroundColor: "white",
    fontSize: "14px",
  },
  closeButton: {
    float: "right",
    marginTop: "-10px;",
  },
  buttonText: {
    color: "black",
    fontSize: "16px",
  },
}));

const useStylesHeaderMobile = makeStyles((theme) => ({
  header: {
    backgroundColor: (props) =>
      props.brand && props.brand !== null && props.brand.name !== "widget"
        ? `${props.brand.main_color} !important`
        : funnelColor,
    position: "fixed",
    top: 0,
    width: "100vw",
    paddingTop: "60px",
    paddingBottom: "40px",
  },
  titleHeader: {
    margin: "20px 20px 0",
    fontSize: "28px",
    fontWeight: "400 !important",
    textAlign: "left",
    color: "white",
  },
  titleSubHeader: {
    margin: "20px",
    color: "white",
  },
  filterButton: {
    position: "absolute",
    top: "125px",
    right: "20px",
    borderRadius: "20px",
    backgroundColor: "white",
    fontSize: "14px",
  },
  buttonText: {
    color: "black",
    fontSize: "16px",
  },
  emojis: {
    marginRight: "15px",
  },
}));

const useStylesRedeemVoucher = makeStyles((theme) => ({
  closeButton: {
    float: "right",
    marginTop: "-10px;",
  },
  root: {
    margin: "20px auto 0",
    width: "fit-content",
    maxWidth: "80%",
    textAlign: "center",
    height: "fit-content",
    paddingBottom: "50px",
  },
  inner: {
    maxWidth: "80vh",
  },
  fullWidth: {
    width: "100%",
  },
  success: {
    color: "#6BD098",
  },
}));

const useStylesPayment = makeStyles((theme) => ({
  paymentButton: {
    marginBottom: "50px",
    float: "right",
  },
}));

export {
  useStylesHeader,
  useStylesHeaderMobile,
  useStylesRedeemVoucher,
  useStylesPayment,
};
