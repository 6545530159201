import React from "react";
import { useSelector, useDispatch } from "react-redux";
import AreaHome from "./AreaHome";
import LandingPage from "./LandingPage";
import { changeSelectedArea } from "../redux/actions/actions";
import WidgetLandingPage from "../widget/WidgetLandingPage";

function AreaHomeWrap(props) {
  const dispatch = useDispatch();
  
  const selectedArea = useSelector((state) => state.area);

  if (
    props.area_cookie !== undefined &&
    props.area_cookie !== false &&
    props.area_cookie !== null &&
    selectedArea === false
  ) {
    dispatch(changeSelectedArea(props.area_cookie));
  }

  if (props.brand) {
    return (
      <WidgetLandingPage brand={props.brand} />
    )
  } else {
    if (selectedArea && props.hasProvince) {
      return (
        <React.Fragment>
          <AreaHome
            reloaded={props.reloaded}
            selectedDate={props.selectedDate}
            selectedPax={props.selectedPax}
            todayNotAvailable={props.todayNotAvailable}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <LandingPage />
        </React.Fragment>
      );
    }
  }
}

export default AreaHomeWrap;
