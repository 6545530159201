import {
  SELECT_HOTEL,
  CHANGE_LOCALE,
  CHANGE_CALENDAR_PRODUCT,
  SET_NOTIFICATIONS,
  SELECT_AREA,
  CHANGE_CALENDAR_DATE,
  SHOW_CALENDAR_MASSIVE_FORM,
  SHOW_CALENDAR_AVAILABILITY_FORM,
  ADD_CSRF_TOKEN,
  CHANGE_HOTEL_DATA,
  SET_AVAILABILITIES,
  SET_DESKTOP_USER_LOGIN_FORM_STATUS,
  SET_CURRENT_USER,
  SET_AREA_HOME_PRODUCTS,
  SET_STEPS_VOUCHER_CREATION_PARAMS,
  SET_CLIENT_SECRET,
  SET_TEAM_MEMBER_FORM,
  SELECT_AREA_NAME,
  SET_SCHEDULE_STATES,
  SET_SELECTED_DATE,
  SET_SELECTED_TIMEFRAME,
  SET_PAYMENT_DATA,
  SET_SELECTED_PAX,
  SET_FILTER_DATA,
  SET_AREA_HOME_HOTEL_CATEGORIES,
  SET_FILTERS_RESULTS,
  SET_JWT_TOKEN,
} from "./constants";

export const setJWTToken = (date) => {
  return {
    type: SET_JWT_TOKEN,
    payload: date,
  };
};

export const changeSelectedHotel = (id) => {
  return {
    type: SELECT_HOTEL,
    payload: id,
  };
};

export const changeSelectedArea = (id) => {
  return {
    type: SELECT_AREA,
    payload: id,
  };
};

export const changeSelectedAreaName = (id) => {
  return {
    type: SELECT_AREA_NAME,
    payload: id,
  };
};

export const changeLocale = (locale) => {
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};

export const changeCalendarProduct = (id) => {
  return {
    type: CHANGE_CALENDAR_PRODUCT,
    payload: id,
  };
};

export const changeCalendarDate = (date) => {
  return {
    type: CHANGE_CALENDAR_DATE,
    payload: date,
  };
};

export const showCalendarMassiveForm = (status) => {
  return {
    type: SHOW_CALENDAR_MASSIVE_FORM,
    payload: status,
  };
};

export const showCalendarAvailabilityForm = (availability_form_status) => {
  return {
    type: SHOW_CALENDAR_AVAILABILITY_FORM,
    payload: availability_form_status,
  };
};

export const addCSRFToken = (token) => {
  return {
    type: ADD_CSRF_TOKEN,
    payload: token,
  };
};

export const changeHotelData = (token) => {
  return {
    type: CHANGE_HOTEL_DATA,
    payload: token,
  };
};

export const setCurrentUser = (status) => {
  return {
    type: SET_CURRENT_USER,
    payload: status,
  };
};

export const setNotifications = (status) => {
  return {
    type: SET_NOTIFICATIONS,
    payload: status,
  };
};

export const setAvailabilities = (status) => {
  return {
    type: SET_AVAILABILITIES,
    payload: status,
  };
};

export const setDesktopUserLoginFormStatus = (status) => {
  return {
    type: SET_DESKTOP_USER_LOGIN_FORM_STATUS,
    payload: status,
  };
};

export const setAreaHomeProducts = (status) => {
  return {
    type: SET_AREA_HOME_PRODUCTS,
    payload: status,
  };
};

export const setAreaHomeHotelCategories = (status) => {
  return {
    type: SET_AREA_HOME_HOTEL_CATEGORIES,
    payload: status,
  };
};

export const setStepsVoucherCreationParams = (status) => {
  return {
    type: SET_STEPS_VOUCHER_CREATION_PARAMS,
    payload: status,
  };
};

export const setClientSecret = (status) => {
  return {
    type: SET_CLIENT_SECRET,
    payload: status,
  };
};

export const setTeamMemberForm = (status) => {
  return {
    type: SET_TEAM_MEMBER_FORM,
    payload: status,
  };
};

export const setScheduleStates = (status) => {
  return {
    type: SET_SCHEDULE_STATES,
    payload: status,
  };
};

export const setSelectedDate = (status) => {
  return {
    type: SET_SELECTED_DATE,
    payload: status,
  };
};

export const setSelectedTimeFrame = (status) => {
  return {
    type: SET_SELECTED_TIMEFRAME,
    payload: status,
  };
};

export const setPaymentData = (status) => {
  return {
    type: SET_PAYMENT_DATA,
    payload: status,
  };
};

export const setSelectedPax = (pax) => {
  return {
    type: SET_SELECTED_PAX,
    payload: pax,
  };
};

export const setFilterData = (status) => {
  return {
    type: SET_FILTER_DATA,
    payload: status,
  };
};

export const setFiltersResults = (status) => {
  return {
    type: SET_FILTERS_RESULTS,
    payload: status,
  };
};
