import { makeStyles } from "@material-ui/core/styles";

const useTimeFrameSelectorStyles = makeStyles((theme) => ({
  timeFrameRow: {
    width: "100%",
    height: "3em",
    cursor: "pointer",
    borderTop: "2px solid lightgray",
    color: "black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1em 8px 2px 2px",
  },
  radioButton: {
    cursor: "pointer",
    width: "1.5em",
    margin: "0 0 5px 0",
    border: "1px solid black",
    borderRadius: "1em",
  },
  timeText: {
    fontWeight: "bold",
    color: "black",
  },
  timeFramesWrap: {
    overflow: "scroll",
    height: "17em",
  },
  timeFramesTitle: {
    fontWeight: "bold",
    color: "grey",
  },
  selectedTime: {
    background: "black",
    border: "1px solid black",
    borderRadius: "1em",
  },
}));

export { useTimeFrameSelectorStyles };
