import React from "react";
import { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import NavDesktop from "./main/nav/NavDesktop";
import { configureStore } from "@reduxjs/toolkit";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import {
  configAreaReducer,
  configAreaNameReducer,
  changeLocaleReducer,
  configCsrfTokenReducer,
  changeHotelDataReducer,
  currentUserReducer,
  currentAvailabilities,
  desktopUserLoginFormStatus,
  areaHomeProductsReducer,
  stepsVoucherCreationParams,
  clientSecret,
  selectedDate,
  selectedPax,
  filterReducer,
  JWTTokenReducer,
} from "./redux/reducers/reducers";
import LandingMain from './landing/LandingMain'
import LandingMarketingMain from './landing/LandingMarketingMain'
import { PropaneSharp } from "@mui/icons-material";

const store = configureStore({
  reducer: {
    area: configAreaReducer,
    areaName: configAreaNameReducer,
    currentLocale: changeLocaleReducer,
    csrfToken: configCsrfTokenReducer,
    hotelUsedData: changeHotelDataReducer,
    currentUser: currentUserReducer,
    currentAvailabilities: currentAvailabilities,
    desktopUserLoginFormStatus: desktopUserLoginFormStatus,
    areaHomeProducts: areaHomeProductsReducer,
    stepsVoucherCreationParams: stepsVoucherCreationParams,
    clientSecret: clientSecret,
    selectedDate: selectedDate,
    selectedPax: selectedPax,
    filtersData: filterReducer,
    JWTToken: JWTTokenReducer,
  },
});


function LandingApp(props) {
  return (
    <>
      <Provider store={store}>
        {props.marketing ?
          <LandingMarketingMain {...props} />
        :
          <LandingMain {...props} />
        }
      </Provider>
    </>
  );
}

export default LandingApp;