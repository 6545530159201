import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { capitalizeFirstLetter } from "../../../../helpers/base";
import { useSelector, useDispatch } from "react-redux";
import LazyImage from "../../../utilities/lazyload";
import Rectangle from "images/Rectangle.png";
import { changeSelectedArea } from "../../../redux/actions/actions";
import axios from "axios";
import slugify from "react-slugify";


function AreaBlock(props) {
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    root: {},
    provinceBlockOuter: {
      boxShadow: "0px 2px 8px rgb(0 0 0 / 15%) !important",
      borderRadius: "10px",
      cursor: 'pointer',
      "&:hover": {
        boxShadow: "4px 4px 0px black",
      }
    },
    provinceImage: {
      width: "100%",
      height: "17vh",
      minHeight: "17rem",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      "&:hover": {
        boxShadow: "4px 4px 0px black",
      }
    },
    province: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingRight: "1rem",
      paddingLeft: "1rem",
      flex: "0 0 50%",
      maxWidth: "50%",
    },
    provinceBlock: {
      padding: "0 0",
    },
    provinceName: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      top: 0,
      height: "90%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#F7EB67",
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: "3rem",
    }
  }));

  let lazyImgStyles = {
    height: "20rem",
    width: "100%",
    borderRadius: "10px",
    objectFit: "cover",
  };

  const classes = useStyles();

  const onOldPageAreaSelection = () => {
    window.location.reload();
  };

  const onAreaSelect = (area) => {
    axios
      .post("/api/main/areas/cookie.json", { area: slugify(area) })
      .then((response) => {
        if (response.data.status === true && area !== null) {
          dispatch(changeSelectedArea(slugify(area)));
          onOldPageAreaSelection();
        }
      });
  };

  return (
    <React.Fragment>
        <figure className={classes.provinceBlockOuter}
          onClick={() => onAreaSelect(props.area.name)}
        >
          <LazyImage
            src={props.area.background}
            lqip={Rectangle}
            alt={props.name}
            lazyImgStyles={lazyImgStyles}
          />
          <div className={classes.provinceBlock + " area-block-text-box"}>
            <h4 className={classes.provinceName}>
              {capitalizeFirstLetter(props.area.name)}
            </h4>
          </div>
        </figure>
    </React.Fragment>
  );
}

export default AreaBlock;
