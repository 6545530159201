import React from "react";
import { useState } from "react";
import I18n from "../../../i18n-js/index.js.erb";

let noMarginTop = {
  marginTop: "0",
};

let giftName = {
  width: "100%",
  background: "#F4A583",
  color: "#7d2647",
  borderBottomRightRadius: "10px",
  borderBottomLeftRadius: "10px",
  height: "80px",
  padding: "24px 10px",
  fontFamily: "TobiasTRIAL-Regular",
  textAlign: "center",
  fontSize: "20px",
  position: "absolute",
  bottom: "-74px",
  right: 0
}

let giftNameMob = {
  width: "100%",
  background: "#F4A583",
  color: "#7d2647",
  borderBottomRightRadius: "10px",
  borderBottomLeftRadius: "10px",
  height: "80px",
  padding: "14px 10px",
  fontFamily: "TobiasTRIAL-Regular",
  textAlign: "center",
  fontSize: "20px",
  position: "absolute",
  bottom: "-74px",
  right: 0
}

export default function GiftBlock(props) {
  const [locale, setLocale] = useState(props.current_locale);

  return (
    <React.Fragment>
      <a
        href={`/${locale}/${I18n.t("routes.create_gift")}`}
        className="toRoom marginbottom"
        alt={I18n.t("gift_voucher")}
        target="_blank"
      >
        <div
          className="col-md-3 col-xs-6 hotel-card small-padding"
          id={props.id}
        >
          <div className="row result-hotel no-gutters" style={noMarginTop}>
            <div className="col-md-12" style={props.col === 2 ? {height: '200px'} : {}}>
              <img
                src="https://hotelbreak.s3.eu-west-3.amazonaws.com/gift_cards/giftcardlanding.png"
                alt={I18n.t("gift_voucher")}
                style={props.col === 2 ? {height: "100%", objectFit: 'cover', width: "100%", borderRadius: '5px'} : {width: "100%", borderRadius: '5px'}}
              />
              <span className='category new' style={{top: "10px",bottom: "unset",textTransform: "uppercase",fontWeight: "800"}}>{I18n.t("gift_voucher")}</span>
              <span style={props.col === 2 ? giftNameMob : giftName}>{I18n.t("gift_card_block")}</span>
            </div>
          </div>
        </div>
      </a>
    </React.Fragment>
  );
}
