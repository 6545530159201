import React, {useEffect, useState, useMemo, useRef} from 'react';
import {useDropzone} from 'react-dropzone';
import Emoji from "a11y-react-emoji";

const thumbCloser = {
  position: 'relative',
  top: '-13px',
  right: '-13px',
  float: 'right',
  cursor: 'pointer'
}

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function ImageDropZone(props) {
  const [files, setFiles] = useState(props.photos);
  const [thumbs, setThumbs] = useState([]);


  const renderThumbs = (files) => {
    
    return files.map(file => 
   
      {
        return(
          <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img
              src={URL.createObjectURL(file)}
              style={img}
            />
          </div>
          <div style={thumbCloser} onClick={removeFile(file)}><Emoji symbol="❌" /></div>
        </div>
        )
      }
  
    );
  }


  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      var filesAux = files
      setFiles(filesAux.concat(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))));
      props.handlePhotos(filesAux.concat(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))))

      setThumbs((filesAux.concat(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))))
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
  
  const removeFile = file => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(file), 1)
    setFiles(newFiles)
    setThumbs(newFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })))
    props.handlePhotos(newFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })))
  }

  const lastname = useRef(null)
  

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
    
  }, [files]);

  return (
    <div className="wrapper">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside style={thumbsContainer}>
        {renderThumbs(thumbs)}
      </aside>
    </div>
  );
}

export default ImageDropZone;