import React, { useState, useEffect } from "react";
import I18n from "../../i18n-js/index.js.erb";
import { currencyToSymbol, to_eur } from "../../helpers/base";
import { calculateRewardGain } from "../../helpers/wallet/wallet";
import LazyImage from "../utilities/lazyload";
import Rectangle from "images/Rectangle.png";
import { useSelector } from "react-redux";
import { useStylesPayment } from "./styles/paymentOptionsStyles";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";

let checkout = {
  lineHeight: "33px",
};

let productName = {
  fontFamily: "Open sans",
  margin: "0 !important",
  textSize: "2em",
};

let hrStyle = {
  margin: "10px 0 !important",
};

let infoStyle = {
  fontSize: "12px",
};

let greenText = {
  color: "green",
  textAlign: "center",
  display: "block",
};

let inlineBlock = {
  display: "inline-block",
};

let lazyImgStyles = {
  width: "100%",
  borderRadius: "5px",
  objectFit: "cover",
  height: "170px",
};

let lineThrough = {
  textDecoration: "line-through",
};

let overhead = {
  margin: " 10px 0 !important",
};

function PaidSummary(props) {
  const PRIME_FEE = 1599;
  const classes = useStylesPayment(props);
  const [summaryData, setSummaryData] = useState(false);
  const FunnelData = useSelector((state) => state.PaymentData);
  const reservation_code = new URLSearchParams(window.location.search).get(
    "reservation_code"
  );

  useEffect(() => {
    axios
      .get(`/api/main/funnel/paid_summaries/${reservation_code}`)
      .then((response) => {
        console.log(
          "show the paid summary",
          response.data.reservation.data.attributes
        );
        setSummaryData(response.data.reservation.data.attributes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const experiencePrice = () => {
    return (
      <React.Fragment>
        {FunnelData.old_PaymentData.total_margin &&
          FunnelData.old_PaymentData.total_margin >
            summaryData.initialPrice && (
            <span style={lineThrough}>
              {to_eur(FunnelData.old_PaymentData.total_margin)}
              {currencyToSymbol(summaryData.currency)}
            </span>
          )}
        &nbsp;
        {summaryData && (
          <span>
            {to_eur(summaryData.initialPrice) +
              currencyToSymbol(summaryData.currency)}{" "}
          </span>
        )}
      </React.Fragment>
    );
  };

  const toPayNow = () => {
    var toPayBefore =
      FunnelData.PaymentOption == "full_payment"
        ? FunnelData.old_PaymentData.total_margin +
          FunnelData.PaymentData.fee +
          summaryData?.membershipDiscount
        : FunnelData.old_PaymentData.pay_now +
          FunnelData.PaymentData.fee +
          summaryData?.membershipDiscount;

    if (summaryData?.isFirstMembershipBooking) {
      toPayBefore = toPayBefore + PRIME_FEE;
    }
    
    return (
      <React.Fragment>
        <div className="col-md-6 col-sm-6 col-xs-6 no-padding no-padding-6-l">
          <span>{I18n.t("paid_now")}</span>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6 text-right no-padding-6-r">
          {FunnelData.old_PaymentData.total_margin && (
            <span style={lineThrough}>
              {to_eur(toPayBefore)}
              {currencyToSymbol(FunnelData.ProductData.currency)}
            </span>
          )}
          &nbsp;
          {summaryData?.originalPaidByCard &&
            `${to_eur(summaryData.originalPaidByCard)}${currencyToSymbol(
              summaryData.currency
            )}`}{" "}
          {!summaryData && <Skeleton variant="text" />}
        </div>
      </React.Fragment>
    );
  };

  const overHeadFee = () => {
    if (
      summaryData?.fee &&
      summaryData.fee > 0 &&
      summaryData?.membershipDiscount > 0
    )
      return (
        <React.Fragment>
          <div className="col-md-12 col-sm-12 col-xs-12 no-padding">
            <hr style={overhead}></hr>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 no-padding-6-l">
            <span className={classes.toolstip}>
              {I18n.t("payments_summary__overhead")}{" "}
              <span className={classes.toolstiptext}>
                {I18n.t("payments_summary__overhead_desc")}
              </span>
            </span>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 text-right no-padding-6-r">
            <span>
              {to_eur(summaryData.fee)}
              {currencyToSymbol(summaryData.currency)}
            </span>
          </div>
        </React.Fragment>
      );
  };

  return (
    <React.Fragment>
      <div className="mobile_only">
        <br></br>
      </div>
      <h4 style={checkout} className={classes.headings}>
        {I18n.t("checkout_third_step")}
      </h4>
      <div className="summary">
        <div className="col-md-12 col-sm-12 no-padding">
          {FunnelData.ProductData && (
            <LazyImage
              src={`https://www.hotelbreak.com${FunnelData.ProductData.image}`}
              lqip={Rectangle}
              alt={FunnelData.ProductData.hotelName}
              lazyImgStyles={lazyImgStyles}
            >
              {" "}
            </LazyImage>
          )}
        </div>

        <div className="col-md-12 col-sm-12 no-padding">
          <h5 className={classes.headings}>
            {" "}
            {FunnelData.ProductData && FunnelData.ProductData.hotelName}{" "}
          </h5>
        </div>
        <div className="col-md-12 col-sm-12 no-padding" id="summary_text">
          <h5 style={productName}>
            <i className="fas fa-ticket-alt"></i>&nbsp;&nbsp;{" "}
            {FunnelData.ProductData && FunnelData.ProductData.name}
          </h5>{" "}
          <br></br>
          <i className="fas fa-calendar-day"></i>
          <span> {summaryData?.dateSummary && summaryData.dateSummary} </span>
          &nbsp;
          {summaryData?.scheduled?.status && (
            <React.Fragment>
              <i className="fas fa-clock"></i>
              <span> {summaryData.scheduled.message} </span>
            </React.Fragment>
          )}
          &nbsp;&nbsp;&nbsp;
          <br></br>
          <div className="col-md-6 col-sm-6 col-xs-6 no-padding-6-l">
            <i className="fas fa-user"></i>&nbsp;&nbsp;
            <span>{I18n.t("adults")}</span>&nbsp;&nbsp;&nbsp;
            <span>{summaryData?.adults && summaryData.adults}</span>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 no-padding-6-l">
            <i className="fas fa-child">&nbsp;&nbsp;</i>
            <span>{I18n.t("kids")}</span>&nbsp;&nbsp;&nbsp;
            <span>{summaryData?.kids && summaryData.kids}</span>
          </div>
          <br></br>
          <hr style={hrStyle}></hr>
        </div>

        <div className="col-md-9 col-sm-9 col-xs-9 no-padding-6-l">
          <span>{FunnelData.ProductData && FunnelData.ProductData.name}</span>
        </div>
        <div className="col-md-3 col-sm-3 col-xs-3 text-right no-padding-6-r">
          {experiencePrice()}
          {!summaryData && <Skeleton variant="text" />}
        </div>

        {overHeadFee()}

        <div className="col-md-12 col-sm-12 col-xs-12 no-padding">
          <hr style={hrStyle}></hr>
        </div>

        <div
          className="col-md-12 col-sm-12 col-xs-12 no-padding"
          id="separator"
        ></div>

        <div className="col-md-6 col-sm-6 col-xs-6 no-padding-6-l">
          <span>{I18n.t("payments_summary__overhead_total")}</span>
          <span style={infoStyle}>{" (" + I18n.t("tax_included") + ")"}</span>
        </div>

        <div className="col-md-6 col-sm-6 col-xs-6 text-right no-padding-6-r">
          {summaryData?.totalToPayUpfront &&
            `${to_eur(summaryData.totalToPayUpfront)}${currencyToSymbol(
              summaryData.currency
            )}`}
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12 no-padding">
          <hr style={hrStyle}></hr>
        </div>

        {toPayNow()}

        <div className="col-md-12 col-sm-12 col-xs-12 no-padding">
          <br></br>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12 no-padding">
          <br></br>
        </div>

        <div
          id="rest_to_pay_container"
          className="col-md-12 col-xs-12 text-center"
        >
          {summaryData && summaryData.leftToPay !== 0 && (
            <span
              id="rest_to_pay"
              data-rest-pay={`${summaryData.leftToPay}`}
              dangerouslySetInnerHTML={{
                __html: I18n.t("rest_to_pay_at_hotel_phrase_html", {
                  currency: currencyToSymbol(summaryData.currency),
                  amount: to_eur(summaryData.leftToPay),
                }),
              }}
            ></span>
          )}

          {!summaryData && <Skeleton variant="text" width="100%" />}
          <span></span>
        </div>

        <br></br>

        <div className="col-md-12 col-xs-12 text-center">
          {summaryData && (
            <small style={greenText}>
              <i className="fas fa-check"></i>
              {I18n.t("payment_form__policy")}
            </small>
          )}
        </div>
      </div>

      {!FunnelData.disable_gift_voucher &&
        (!props.brand || props.brand?.data == null) && (
          <div id="wallet_price" className="text-center col-md-12 col-xs-12">
            <p>
              {summaryData && "👛" + I18n.t("wallet_earn")}{" "}
              {summaryData && (
                <span id="wallet_earn_amount">
                  {to_eur(
                    calculateRewardGain(
                      summaryData.totalToPayUpfront,
                      summaryData.area,
                      summaryData.rewardPercent
                    )
                  )}
                </span>
              )}
              {!summaryData && <Skeleton variant="text" width="100%" />}
              {summaryData &&
                FunnelData.ProductData &&
                currencyToSymbol(summaryData.currency)}{" "}
              {summaryData && I18n.t("wallet_earn_end")}
            </p>
          </div>
        )}
    </React.Fragment>
  );
}

export default PaidSummary;
