import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { capitalizeFirstLetter } from "../../../../helpers/base";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

function AreaBlocklarge(props) {

  const useStyles = makeStyles((theme) => ({
    root: {},
    headings: {fontFamily: props.font ? props.font : 'sans-serif'},
    provinceLarge: {
      marginBottom: "10px",
      position: "relative",
      paddingRight: "2rem",
      paddingLeft: "2rem",
      paddingBottom: "4rem",
      flex: `0 0 33%`,
      maxWidth: "50%",
      cursor: "pointer",
    },
    province: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingRight: "2rem",
      paddingLeft: "2rem",
      paddingBottom: "4rem",
      flex: "0 0 50%",
      maxWidth: "100%",
      cursor: "pointer",
    },
    provinceBlockOuter: {
      borderRadius: "10px",
    },
    provinceImageLarge: {
      width: "100%",
      height: "250px",
      minHeight: "250px",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    provinceImage: {
      width: "100%",
      height: "150px",
      minHeight: "150px",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    provinceBlockLarge: {
      background: "white",
      borderRadius: "0 0 10px 10px",
    },
    areaText: {
      marginTop: '-10px'
    },
    logo: {
      width: '50%',
      marginLeft: '25%',
      marginTop: '-270px',
      borderRadius: '10px'
    },
    rightArrow: {
      float: 'right'
    }
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <li
        className={props.blocks > 1 ? classes.provinceLarge : classes.province}
        onClick={() => props.onAreaSelect(props.area)}
      >
        <figure className={classes.provinceBlockOuter}>
          <div
            className={props.blocks > 1 ? classes.provinceImageLarge : classes.provinceImage}
            style={{ backgroundImage: `url(${props.area.background})` }}
          />
          <img className={classes.logo} src={props.area.logo} />
          <div className={clsx(classes.provinceBlockLarge, classes.areaText)}>
            
            <h4 className={classes.headings}>
              {capitalizeFirstLetter(props.area.name)} <span className={classes.rightArrow}>➜</span>
            </h4>
          </div>
        </figure>
      </li>
    </React.Fragment>
  );
}

export default AreaBlocklarge;