import React from "react";
import { useState, useEffect, useRef } from "react";
import { useStylesChooseDesign } from "../../styles/stepStyles";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Fab from "@material-ui/core/Fab";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useSelector, useDispatch } from "react-redux";
import { setStepsVoucherCreationParams } from "../../../../redux/actions/actions";
import I18n from "../../../../../i18n-js/index.js.erb";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

function ChooseDesign(props) {
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const FILE_SIZE = 300000;

  let schema = yup.object().shape({
    photo: yup
      .mixed()
      .test(
        "fileSize",
        "Your file is too big",
        (value) => value && value.size < FILE_SIZE
      )
      .test(
        "fileType",
        "Wrong format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  });

  const dispatch = useDispatch();
  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const [hasLeftScroll, setHasLeftScroll] = useState(false);
  const [hasRightScroll, setHasRightScroll] = useState(true);

  const scrollRight = () => {
    $(`#gridListScroll`).animate(
      {
        scrollLeft: "+=400px",
      },
      "slow"
    );
    setHasLeftScroll(true);
    if (
      $(`#gridListScroll`).get(0).scrollWidth -
        $(`#gridListScroll`).scrollLeft() -
        $(`#gridListScroll`).width() -
        400 <=
      0
    ) {
      setHasRightScroll(false);
    }
  };

  const scrollLeft = () => {
    $(`#gridListScroll`).animate(
      {
        scrollLeft: "-=400px",
      },
      "slow"
    );
    setHasRightScroll(true);
    if ($(`#gridListScroll`).scrollLeft() - 400 <= 0) {
      setHasLeftScroll(false);
    }
  };

  const {
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleDesignPick = (design) => {
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step3: {
          status: true,
          design: design,
          custom: { status: false, image: false },
        },
      })
    );

    axios
      .post("/api/main/tracking/checkouts/completed.json", {
        params: { step: 3, step_name: "design", design: design },
      })
      .then((response) => {
        console.log(response.status);
      })
      .catch(function (error) {});

    setTimeout(() => {
      props.handleNext();
    }, 300);
  };

  const handleCustomDesignPick = (design) => {
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step3: {
          status: true,
          design: design.filename,
          custom_design_id: design.attachment_id,
          custom: { status: false, image: false },
        },
      })
    );

    axios
      .post("/api/main/tracking/checkouts/completed.json", {
        params: { step: 3, step_name: "design", design: design.filename },
      })
      .then((response) => {
        console.log(response.status);
      })
      .catch(function (error) {});

    setTimeout(() => {
      props.handleNext();
    }, 300);
  };

  useEffect(() => {
    if (!stepsVoucherCreationParams.step3.status) {
      axios
        .post("/api/main/tracking/checkouts/viewed.json", {
          params: { step: 3, step_name: "design" },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) {});
    }
  }, []);

  const classes = useStylesChooseDesign();

  const voucherDesigns = [
    "christmas",
    "christmas2",
    "cumple",
    "spa",
    "food",
    "valentine",
  ];

  const arrowButtons = () => {
    return (
      <React.Fragment>
        {hasLeftScroll && (
          <Fab
            color="white"
            aria-label="add"
            className={classes.buttonLeft}
            onClick={scrollLeft}
          >
            <ArrowLeftIcon />
          </Fab>
        )}
        {hasRightScroll && (
          <Fab
            color="white"
            aria-label="add"
            className={classes.buttonRight}
            onClick={scrollRight}
          >
            <ArrowRightIcon />
          </Fab>
        )}
      </React.Fragment>
    );
  };

  const showCustomImages = () => {
    return stepsVoucherCreationParams.images.images.map((design, index) => {
      if (design.attachment_id) {
        return (
          <ImageListItem
            onClick={() => handleCustomDesignPick(design)}
            cols={2.3}
            rows={1}
            key={design.attachment_id}
            classes={{
              root: classes.overRideImageList,
            }}
          >
            <img
              src={design.url}
              className={clsx(
                classes.cardDesign,
                stepsVoucherCreationParams.step3.custom_design_id ===
                  design.attachment_id && classes.selectedDesign
              )}
              alt={`design_${design.filename}`}
            />
            <p style={{ textAlign: "center" }}>{design.filename}</p>
          </ImageListItem>
        );
      }
    });
  };

  const showDefaultImages = () => {
    return voucherDesigns.map((design, index) => {
      {
        return (
          <ImageListItem
            onClick={() => handleDesignPick(design)}
            cols={2.3}
            rows={1}
            key={index}
            classes={{
              root: classes.overRideImageList,
            }}
          >
            <div
              id={`card_design_2`}
              className={
                classes.cardDesign +
                " " +
                clsx(
                  design,
                  stepsVoucherCreationParams.step3.design === design &&
                    classes.selectedDesign
                )
              }
              style={{ width: "300px !important" }}
            ></div>
            <p style={{ textAlign: "center" }}>
              {I18n.t(`desc_card_${design}`)}
            </p>
          </ImageListItem>
        );
      }
    });
  };

  return (
    <div>
      <div className={classes.gridWrapper}>
        {arrowButtons()}
        <ImageList
          className={classes.gridList}
          cols={2.3}
          rowHeight="auto"
          id={`gridListScroll`}
        >
          {stepsVoucherCreationParams.images.images.length > 0
            ? showCustomImages()
            : showDefaultImages()}
        </ImageList>
      </div>
    </div>
  );
}

export default ChooseDesign;
