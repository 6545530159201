import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  configAreaReducer,
  changeLocaleReducer,
  configCsrfTokenReducer,
  changeHotelDataReducer,
  currentUserReducer,
  currentAvailabilities,
  desktopUserLoginFormStatus,
  areaHomeProductsReducer,
  stepsVoucherCreationParams,
  clientSecret,
  configAreaNameReducer,
  areaHomeHotelCategoriesReducer,
  JWTTokenReducer
} from "./redux/reducers/reducers";
import WidgetVoucherFunnelTemp from "./widget/widgetVoucherFunnel/WidgetVoucherFunnelTemp";
 

const store = configureStore({
  reducer: {
    area: configAreaReducer,
    areaName: configAreaNameReducer,
    currentLocale: changeLocaleReducer,
    csrfToken: configCsrfTokenReducer,
    hotelUsedData: changeHotelDataReducer,
    currentUser: currentUserReducer,
    currentAvailabilities: currentAvailabilities,
    desktopUserLoginFormStatus: desktopUserLoginFormStatus,
    areaHomeProducts: areaHomeProductsReducer,
    stepsVoucherCreationParams: stepsVoucherCreationParams,
    clientSecret: clientSecret,
    areaHomeHotelCategories: areaHomeHotelCategoriesReducer,
    JWTToken: JWTTokenReducer,
  },
});

function WidgetVoucherFunnelApp(props) {
  return (
    <Provider store={store}>
      <WidgetVoucherFunnelTemp {...props} />
    </Provider>
  );
}

export default WidgetVoucherFunnelApp;
