import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonAreablock(props) {
  const dispatch = useDispatch();
  const csrfToken = useSelector((state) => state.csrfToken);

  const useStyles = makeStyles((theme) => ({
    root: {},
    province: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingRight: ".5rem",
      paddingLeft: ".5rem",
      flex: "0 0 50%",
      maxWidth: "50%",
    },
    provinceBlockOuter: {
      boxShadow: "0px 2px 8px rgb(0 0 0 / 15%) !important",
      borderRadius: "10px",
    },
    provinceImage: {
      width: "100%",
      height: "17vh",
      minHeight: "17rem",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    provinceBlock: {
      padding: "0 20px 10px",
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <li className={classes.province}>
        <figure className={classes.provinceBlockOuter}>
          <Skeleton variant="rect" className={classes.provinceImage} />
          <div className={classes.provinceBlock + " area-block-text-box"}>
            <div className="area-block-subtitle">
              <Skeleton variant="text" />
            </div>
            <h4>
              <Skeleton variant="text" />{" "}
            </h4>
          </div>
        </figure>
      </li>
    </React.Fragment>
  );
}

export default SkeletonAreablock;
