import React, { useEffect } from "react";
import I18n from "../../../../i18n-js/index.js.erb";
import { to_eur, currencyToSymbol } from "../../../../helpers/base";
import { useStylesPayment } from "../../styles/paymentOptionsStyles";
import { useSelector, useDispatch } from "react-redux";
import { setPaymentData } from "../../../redux/actions/actions";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Emoji from "a11y-react-emoji";
import parsePhoneNumber from "libphonenumber-js/max";
import { setCurrentUser } from "../../../redux/actions/actions";

function PaymentOptions(props) {
  const classes = useStylesPayment(props);
  const dispatch = useDispatch();
  const FunnelData = useSelector((state) => state.PaymentData);
  const currentUser = useSelector((state) => state.currentUser);
  const currentLocale = useSelector((state) => state.currentLocale);
  const phoneNumber = parsePhoneNumber(
    `+${currentUser.phoneNumber}`,
    currentLocale.toUpperCase()
  );

  const FORM_SOURCE = "Payment Options";

  const analyticsTrack = (eventType, inputHash) => {
    analytics.track(eventType, { inputHash, form: FORM_SOURCE });
  };

  const payNowAmount =
    FunnelData.PaymentData.pay_now + FunnelData.PaymentData.fee;
  const PaymentDataLoaded = FunnelData.PaymentData.pay_now;

  const modifyUser = () => {
    analyticsTrack("Press modifyUser", {});

    dispatch(
      setCurrentUser({
        ...currentUser,
        isInformationComplete: {
          ...currentUser.isInformationComplete,
          status: false,
        },
        loggedIn: true,
        clientUserDataUp: false,
      })
    );
  };

  const handlePartialPayment = () => {
    if (!FunnelData.is_hotelbreak_direct) {
      analyticsTrack("Press partial_payment", {});

      dispatch(
        setPaymentData({
          ...FunnelData,
          PaymentOption: "partial_payment",
        })
      );
    }
  };

  const handleFullPayment = () => {
    analyticsTrack("Press full_payment", {});

    dispatch(
      setPaymentData({
        ...FunnelData,
        PaymentOption: "full_payment",
      })
    );
  };

  const handleWalletPayment = () => {
    analyticsTrack("Press wallet_payment", {});
    analyticsTrack("Press full_payment", {});

    FunnelData.PaymentOption == 'wallet_payment' ?
      (dispatch(
        setPaymentData({
          ...FunnelData,
          PaymentOption: "full_payment",
        })
      ))
    :
      (dispatch(
        setPaymentData({
          ...FunnelData,
          PaymentOption: "wallet_payment",
        })
      ))
  };

  const handleHBDiscount = () => {
    analyticsTrack("Press hb_discount", {});

    dispatch(
      setPaymentData({
        ...FunnelData,
        PaymentOption: "hb_discount",
      })
    );
  };

  const walletPaymentButton = () => {
    return (
      <React.Fragment>
        <div className={classes.walletWrap}>
          <p>
            <strong>{I18n.t("payments_funnel__wallet_title")}</strong>{" "}
            &nbsp;&nbsp;
            <small>{I18n.t("payments_funnel__wallet_subtitle")}</small>
          </p>
          <div
            id="wallet_option"
            onClick={() => handleWalletPayment()}
            className={clsx(
              classes.walletOptionButton,
              FunnelData.PaymentOption === "wallet_payment" &&
                classes.selectedOption,
              classes.fontFamily
            )}
          >
            <div>
              <span>
                <p className={classes.bold}>
                  👛{" "}
                  {FunnelData.wallet.balance &&
                    I18n.t("payments_funnel__wallet_balance", {
                      balance: to_eur(FunnelData.wallet.balance),
                    })}
                </p>
              </span>
            </div>
            <div>
              <input
                type="radio"
                className={classes.radioStyle}
                id="wallet_payment_radio"
                name="wallet_payment"
                checked={FunnelData.PaymentOption === "wallet_payment"}
              ></input>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const hbDiscountButton = () => {
    return (
      <React.Fragment>
        <div className={classes.walletWrap}>
          <div
            id="hb_discount"
            onClick={() => handleHBDiscount()}
            className={clsx(
              classes.walletOptionButton,
              FunnelData.PaymentOption === "hb_discount" &&
                classes.selectedOption,
              classes.fontFamily
            )}
          >
            <div>
              <span>
                <p className={classes.bold}>
                  {I18n.t("payments_funnel__hbdiscount_title")}
                </p>
              </span>
            </div>
            <div>
              <input
                type="radio"
                className={classes.radioStyle}
                id="hb_discount_radio"
                name="hb_discount"
                checked={FunnelData.PaymentOption === "hb_discount"}
              ></input>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const modifyUserBlock = () => {
    var reservationUser = currentUser.client_user
      ? currentUser.client_user
      : currentUser;

    return (
      <React.Fragment>
        <div className={classes.userBlock}>
          <div className={classes.accountButton}>
            {reservationUser.image ? (
              <img className={classes.userImage} src={reservationUser.image} />
            ) : (
              <span className={clsx(classes.accountButtonIcon)}>
                <Emoji symbol="👤" />
              </span>
            )}{" "}
            <p className={classes.userName}>
              {reservationUser.fullname} {reservationUser.lastname}
            </p>
            <span className={classes.modifyUser} onClick={() => modifyUser()}>
              {I18n.t("modify")}
            </span>
          </div>
          <div className={classes.userInfo}>
            <div>
              <Emoji symbol="📧" /> {reservationUser.email}
            </div>
            <div>
              <Emoji symbol="📞" />{" "}
              {phoneNumber && phoneNumber.formatNational()}
            </div>
            {reservationUser?.isInformationComplete?.country === "ESP" && (
              <div>
                <Emoji symbol="🆔" /> {reservationUser.idNumber}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (
      FunnelData.is_hotelbreak_direct ||
      currentUser.role === "travel_agent"
    ) {
      dispatch(
        setPaymentData({
          ...FunnelData,
          PaymentOption: "full_payment",
        })
      );
    }
  }, [FunnelData.is_hotelbreak_direct]);

  return (
    <React.Fragment>
      <div>
        <h4 className={classes.headings}>
          <span className={clsx("number_step", classes.checkedStep)}>
            <FontAwesomeIcon icon={faCheck} />
          </span>
          &nbsp;&nbsp;
          {I18n.t("my_info")}
        </h4>
        {modifyUserBlock()}
        <br />
        <h4 className={classes.headings}>
          <span className="number_step">{FunnelData.user_billing_info ? "3" : "2"}</span>&nbsp;&nbsp;
          {I18n.t("checkout_second_step")}
        </h4>

        {currentUser.role !== "travel_agent" && (
          <React.Fragment>
            <div
              id="standard_payment"
              onClick={() => handlePartialPayment()}
              className={clsx(
                classes.partialOptionButton,
                FunnelData.PaymentOption === "partial_payment" &&
                  classes.selectedOption,
                classes.fontFamily,
                FunnelData.is_hotelbreak_direct && classes.disabledOpacity
              )}
            >
              {
                <React.Fragment>
                  <div style={{ flex: 1 }}>
                    <p className={classes.bold}>
                      {I18n.t("payments_funnel__partial_title")}
                    </p>
                    <p className={classes.subtitle}>
                      {PaymentDataLoaded &&
                        I18n.t("payments_funnel__partial_calculations", {
                          pay_now: to_eur(payNowAmount),
                          rest_to_pay: to_eur(
                            FunnelData.PaymentData.rest_to_pay
                          ),
                        })}{" "}
                      {!PaymentDataLoaded && (
                        <Skeleton variant="text" width="100%" />
                      )}{" "}
                    </p>
                  </div>
                  <div>
                    <p className={classes.bold}>
                      {PaymentDataLoaded &&
                        `${to_eur(
                          FunnelData.PaymentData.pay_now +
                            FunnelData.PaymentData.fee
                        )}${currencyToSymbol(FunnelData.ProductData.currency)}`}
                      {!PaymentDataLoaded && <Skeleton variant="text" />}

                      <label className={classes.radioLabel}>
                        <input
                          type="radio"
                          className={classes.radioStyle}
                          id="partial_payment_radio"
                          name="option_button"
                          checked={
                            FunnelData.PaymentOption === "partial_payment" &&
                            !FunnelData.is_hotelbreak_direct
                          }
                        ></input>
                      </label>
                    </p>
                  </div>
                </React.Fragment>
              }
            </div>
          </React.Fragment>
        )}

        {(!FunnelData.disable_gift_voucher ||
          FunnelData.is_hotelbreak_direct ||
          currentUser.role === "travel_agent") && (
          <React.Fragment>
            <div
              id="full_payment"
              onClick={() => handleFullPayment()}
              className={clsx(
                classes.fullOptionButton,
                (FunnelData.PaymentOption === "full_payment" ||
                  FunnelData.PaymentOption === "wallet_payment" ||
                  FunnelData.is_hotelbreak_direct ||
                  currentUser.role === "travel_agent") &&
                  classes.selectedOption,
                classes.fontFamily
              )}
            >
              <div style={{ flex: 1 }}>
                <p className={classes.bold}>
                  {" "}
                  {I18n.t("payments_funnel__full_title")}
                </p>
                <p className={classes.subtitle}>
                  {I18n.t("payments_funnel__full_subtitle")}
                </p>
              </div>
              <div>
                <p className={classes.bold}>
                  {PaymentDataLoaded &&
                    `${to_eur(
                      FunnelData.is_hotelbreak_direct &&
                        FunnelData.PaymentOption !== "wallet_payment"
                        ? FunnelData.PaymentData.total_margin +
                            FunnelData.PaymentData.fee
                        : FunnelData.PaymentOption === "wallet_payment"
                        ? FunnelData.PaymentData.pay_now +
                            FunnelData.PaymentData.rest_to_pay +
                            FunnelData.PaymentData.fee -
                            FunnelData.wallet.balance >
                          0
                          ? FunnelData.PaymentData.pay_now +
                            FunnelData.PaymentData.rest_to_pay +
                            FunnelData.PaymentData.fee -
                            FunnelData.wallet.balance
                          : 0
                        : FunnelData.PaymentData.pay_now +
                          FunnelData.PaymentData.rest_to_pay +
                          FunnelData.PaymentData.fee
                    )}${currencyToSymbol(FunnelData.ProductData.currency)}`}
                  {!PaymentDataLoaded && <Skeleton variant="text" />}

                  <label className={classes.radioLabel}>
                    <input
                      type="radio"
                      className={classes.radioStyle}
                      id="full_payment_radio"
                      name="option_button"
                      checked={
                        FunnelData.PaymentOption === "full_payment" ||
                        FunnelData.PaymentOption === "wallet_payment" ||
                        FunnelData.is_hotelbreak_direct ||
                        currentUser.role === "travel_agent"
                      }
                    />
                  </label>
                </p>
              </div>
            </div>
          </React.Fragment>
        )}
        <div className={classes.separator}>&nbsp;</div>

        {FunnelData.wallet.balance > 0 &&
          !FunnelData.disable_gift_voucher === true &&
          (!props.brand || props.brand?.data === null) &&
          currentUser.role !== "travel_agent" &&
          walletPaymentButton()}

        {currentUser.role === "admin" &&
          !FunnelData.disable_gift_voucher &&
          (!props.brand || props.brand?.data === null) &&
          hbDiscountButton()}
      </div>

    </React.Fragment>
  );
}

export default PaymentOptions;
