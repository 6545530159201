import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MetroLineLogo from "./metroLineLogo";

function MetroStations(props) {
  const useStyles = makeStyles((theme) => ({
    metroName: {
      marginLeft: '4px',
      paddingTop: '1px'
    }
  }))

  const classes = useStyles();

  return (
    <React.Fragment>
      {props.metro ?
        props.metro.map((stop) => {
          return (
            <>
              <p>
                {stop.metro_lines.map((line) => {
                  return (
                    <>
                      <MetroLineLogo color={line.color} shape={line.shape} message={line.name} />
                    </>
                  )
                })}
                <span className={classes.metroName}>{stop.name}</span>
              </p>
            </>
          )
        })
        :
        <p>nok</p>
      }
    </React.Fragment>
  );
}

export default MetroStations;
