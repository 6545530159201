import React from "react";
import loadingLogo from "images/logo_reduced.png";

function HotelBreakLoader(props) {
  return (
    <React.Fragment>
      <div className="loading__container">
        <img
          className="loading__hotelbreak"
          alt={"Loading logo"}
          src={loadingLogo}
          data-original={loadingLogo}
          style={props.innerStyle}
        />
      </div>
    </React.Fragment>
  );
}

export default HotelBreakLoader;
