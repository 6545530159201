import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Emoji from "a11y-react-emoji";
import logo from "images/logo3.png";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
// import AreaCategoryNav from "../nav/AreaCategoryNav";
import NavDesktop from "../nav/NavDesktop";
import { useStylesHeader } from "./styles/styles.js";
import RedeemVoucher from "./dialogs/RedeemVoucherForm";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import I18n from "../../../i18n-js/index.js.erb";
import clsx from "clsx";

function Header(props) {
  const classes = useStylesHeader();

  const emojis = ["🎁", "🎉", "🎈", "💝"]

  const randomEmoji = emojis[Math.floor(Math.random()*emojis.length)];

  return (
    <div className={classes.header}>
      <NavDesktop products={props.products} />

      <h1 className={classes.titleHeader}><Emoji symbol={randomEmoji} /> {props.text}</h1>

      {!props.redeem &&
        <React.Fragment>
          <p className={classes.titleSubHeader}>{I18n.t('gift_voucher_already')}</p>
          <NavLink
            to={`/${I18n.locale}/${I18n.t('routes.redeem_voucher')}`}
            className="nav-link"
          >
            <Button
              className={clsx("btn btn-secondary", classes.filterButton)}
            >
              {I18n.t('redeem_button')}
            </Button>
          </NavLink>
        </React.Fragment>
      }
    </div>
  );
}

export default Header;
