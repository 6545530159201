import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import { makeStyles } from "@material-ui/core/styles";
import { capitalizeFirstLetter } from "../../../helpers/base";
import { createMedia } from "@artsy/fresnel";
import I18n from "../../../i18n-js/index.js.erb";
import slugify from "react-slugify";
import loadingLogo from "images/logo_reduced.png";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
  useLocation,
} from "react-router-dom";

function Checkout(props) {
  const [paymentLoading, setPaymentLoading] = useState(false);

  const payment_intent = useLocation().search.split("&")[0].split("=")[1];

  const reservation_dni = useLocation().search.split("&")[1].split("=")[1];

  const useStyles = makeStyles((theme) => ({
    slidingPane: {
      marginTop: "170px",
      paddingTop: "5px",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      backgroundColor: "white",
      width: "100%",
      height: "fit-content",
      position: "relative",
      boxShadow: "0 -2px 4px rgba(0,0,0,0.25)",
      zIndex: "1",
    },
    root: {
      backgroundColor: "#F5F5F5",
    },
  }));

  const classes = useStyles();

  const loadingHotelbreak = () => {
    return (
      paymentLoading && (
        <img
          className="loading__hotelbreak"
          alt={"Loading logo"}
          src={loadingLogo}
          data-original={loadingLogo}
        />
      )
    );
  };

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  const handleTransactionComplete = () => {
    axios
      .get(
        `/api/main/gift_steps/transaction_state_get.json?payment_intent=${payment_intent}`
      )
      .then((response) => {
        if (response.data.status) {
          window.location.replace(`${response.data.url}`);
        } else {
          setTimeout(function () {
            handleTransactionComplete();
          }, 800);
        }
      });
  };

  useEffect(() => {
    axios
      .get(
        `/api/main/gift_steps/confirm_payment_funnel.json?payment_intent=${payment_intent}&reservation_dni=${reservation_dni}`
      )
      .then((response) => {
        if (response.status) {
          handleTransactionComplete();
        }
      });
  }, []);

  return (
    <div className={classes.root}>
      <MediaContextProvider>
        <Media at="sm">
          <HeaderMobile text={I18n.t('giftbanner__main')}/>
          {!paymentLoading && (
            <div className={classes.slidingPane}>
            <div className="container">
              <div className="row transaction_loading__content">
            
                <div className="loading__container">
                  <img className="loading__hotelbreakLogo" src={loadingLogo} />
                </div>
              
                <div className="col-md-8 col-md-offset-2 text-center">
                  <h3 className="margin-top-60">
                    {I18n.t('charge_loading_title')}
                  </h3>
                </div>
            
              </div>
            </div>
          </div>
          )}
          {paymentLoading && (
            <div className={classes.slidingPane}>{loadingHotelbreak()}</div>
          )}
        </Media>
        <Media at="md">
          <HeaderMobile text={I18n.t('giftbanner__main')} />
          {!paymentLoading && (
            <div className={classes.slidingPane}>
            <div className="container">
              <div className="row transaction_loading__content">
            
                <div className="loading__container">
                  <img className="loading__hotelbreakLogo" src={loadingLogo} />
                </div>
              
                <div className="col-md-8 col-md-offset-2 text-center">
                  <h3 className="margin-top-60">
                    {I18n.t('charge_loading_title')}
                  </h3>
                </div>
            
              </div>
            </div>
          </div>
          )}
          {paymentLoading && (
            <div className={classes.slidingPane}>{loadingHotelbreak()}</div>
          )}{" "}
        </Media>
        <Media greaterThanOrEqual="lg">
          <Header text={I18n.t('giftbanner__main')} />
          {!paymentLoading && (
            <div className={classes.slidingPane}>
            <div className="container">
              <div className="row transaction_loading__content">
            
                <div className="loading__container">
                  <img className="loading__hotelbreakLogo" src={loadingLogo} />
                </div>
              
                <div className="col-md-8 col-md-offset-2 text-center">
                  <h3 className="margin-top-60">
                    {I18n.t('charge_loading_title')}
                  </h3>
                </div>
            
              </div>
            </div>
          </div>
          )}
          {paymentLoading && (
            <div className={classes.slidingPane}>{loadingHotelbreak()}</div>
          )}{" "}
        </Media>
      </MediaContextProvider>
    </div>
  );
}

export default Checkout;
