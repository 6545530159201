import React from "react";
import { useState, useEffect } from "react";
import ProductBlock from "../ProductBlock";
import InfiniteScroll from "react-infinite-scroll-component";
import loadingLogo from "images/logo_hotelbreak_mini.png";
import FilterBar from "../../filters/FilterBar";
import { currencyToSymbol, to_eur } from "../../../../helpers/base";
import I18n from "../../../../../javascript/i18n-js/index.js.erb";
import GuideBanner from "../../promos/GuideBanner";
import axios from "axios";

let noMarginTop = {
  marginTop: "0",
};

let infiniteStyle = {
  overflow: "visible",
};

let resultTitleStyles = {
  fontSize: "20px",
  padding: "0 0 0 15px",
};

export default function ProductList(props) {
  const [locale, setLocale] = useState(props.current_locale);
  const [all_products, setAllProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loadFilter, setLoadFilter] = useState(false);
  const [pageNumber, setPageNumber] = useState(2);
  const [orderBy, setOrderBy] = useState("all");

  axios.defaults.headers.common["X-CSRF-Token"] = props.csrf_token;
  axios.defaults.withCredentials = true;

  I18n.locale = locale;

  let guideBlock = false;

  useEffect(() => {
    setAllProducts(props.all_products);

    if (props.all_products.length <= 7) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, []);

  var fetchFilteredData = (orderby) => {
    setOrderBy(orderby);
    setHasMore(true);

    axios
      .get("/api/v1/productlist/productblocks.json", {
        params: {
          page_number: 1,
          filter: orderby,
          filterrific: props.filterrific_params,
          location: props.location,
          product_category: props.product_category,
        },
      })

      .then((response) => {
        setPageNumber(2);
        setAllProducts(response.data);
        setLoadFilter(false);
      });
  };

  const allProducts = () => {
    var content = [];

    var already_rendered_keys = [];
    var actual_index = -1;

    all_products.forEach((product, index) => {
      const productblock = (
        <ProductBlock
          key={product.id}
          id={product.id}
          name={product.name}
          block_photo={product.block_photo}
          week_price={to_eur(product.pvp)}
          listing_name={product.listing_name}
          is_direct_booking={product.direct_booking}
          star_rating={product.star_rating}
          city={product.city}
          area={product.area}
          max_guest={product.max_guest}
          price_model={product.price_model}
          currency={currencyToSymbol(product.currency)}
          index={actual_index + 1}
          slug={product.slug}
          hotelSlug={product.room_slug}
          available_date={props.available_date}
          current_locale={locale}
          has_calendar={product.has_calendar}
        ></ProductBlock>
      );

      if (!already_rendered_keys.includes(product.id)) {
        actual_index = actual_index + 1;

        if ((actual_index + 1) % 4 == 0) {
          content.push(
            <div className="row" key={product.id}>
              {productblock}
            </div>
          );

          if (actual_index === all_products.length) {
            if (
              (props.filterrific_params &&
                props.filterrific_params.area === "mallorca") ||
              (props.location && props.location === "mallorca")
            ) {
              if (props.filterrific_params) {
                var area_name = props.filterrific_params.area;
              } else {
                var area_name = props.location;
              }

              content.push(
                <React.Fragment>
                  <GuideBanner
                    locale={props.locale}
                    guides={props.guides}
                    area={area_name}
                  />
                </React.Fragment>
              );
              guideBlock = true;
            }
          }
        } else {
          content.push(productblock);
        }
      }

      already_rendered_keys.push(product.id);
    });

    return content;
  };

  const getOrder = (orderBy) => {
    setAllProducts([]);
    fetchFilteredData(orderBy);
  };

  const filterRooms = (orderBy) => {
    setLoadFilter(true);
    setAllProducts([]);
    getOrder(orderBy);
  };

  const fetchMoreData = () => {
    setTimeout(function () {
      axios
        .get("/api/v1/productlist/productblocks.json", {
          params: {
            page_number: pageNumber,
            filter: orderBy,
            location: props.location,
            product_category: props.product_category,
            wallet_friendly: props.wallet_friendly          },
        })

        .then((response) => {
          setPageNumber(pageNumber + 1);
          setAllProducts(all_products.concat(response.data));

          if (response.data.length === 0) {
            setHasMore(false);
          }
        });
    }, 500);
  };

  return (
    <React.Fragment>
      <FilterBar
        filterRooms={filterRooms}
        text_list={props.text_list}
        current_locale={locale}
        hotel_search__filter={props.hotel_search__filter}
        count_filters={props.count_filters}
        initial_filter={props.initial_filter}
      />

      <div className="container">
        <h1
          className="result_title nodisplay_above768"
          style={resultTitleStyles}
          dangerouslySetInnerHTML={{ __html: props.text_list }}
        ></h1>

        <div className="row" style={noMarginTop}>
          <div className="col-md-12"></div>
        </div>

        <div className="panel-body">
          <InfiniteScroll
            dataLength={all_products.length}
            next={fetchMoreData}
            hasMore={hasMore}
            style={infiniteStyle}
            loader={
              <div className="loading__container">
                <img
                  className="loading__hotelbreak"
                  alt={"Loading logo"}
                  src={loadingLogo}
                  data-original={loadingLogo}
                />
              </div>
            }
            endMessage={
              props.area === "mallorca" && (
                <React.Fragment>
                  <div className="col-md-12"></div>
                  <GuideBanner
                    locale={locale}
                    guides={props.guides}
                    area={props.area}
                  />
                </React.Fragment>
              )
            }
          >
            {allProducts()}
          </InfiniteScroll>
        </div>
      </div>
    </React.Fragment>
  );
}
