import React, { useState } from "react";
import I18n from "../../../../i18n-js/index.js.erb";
import { useSelector } from "react-redux";
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import clsx from "clsx";

function PasswordRecovery(props) {
  const currentLocale = useSelector((state) => state.currentLocale);
  const FunnelData = useSelector((state) => state.PaymentData);
  const [error, setError] = useState({ status: false, message: "" });

  const schema = yup.object({
    email_recovery: yup
      .string()
      .email(I18n.t("reservation_email_format"))
      .required(I18n.t("is_required")),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  I18n.locale = currentLocale;

  const onSubmit = (data) => {
    setError({ ...error, status: false });
    axios
      .post("/api/main/passwords", { email: data["email_recovery"] })
      .then((response) => {
        props.handleClose();
      })
      .catch(function (error) {
        setError({ status: true, message: error.response.data.error_message });
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={FunnelData.passwordRecovery.open}
        toggle={props.handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle toggle={props.handleClose}>
            {I18n.t("forgot_your_password")}
            <IconButton edge="end" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <input
              defaultValue={FunnelData.used_email ? FunnelData.used_email : ""}
              type="text"
              {...register("email_recovery")}
              name="email_recovery"
              className={clsx(
                (errors.email_recovery?.message || error.status) &&
                  "form-control error",
                !errors.email_recovery?.message &&
                  !error.status &&
                  "form-control"
              )}
              placeholder={I18n.t("form_email_ex")}
            />
            <p className="error">{errors.email_recovery?.message}</p>
            {error.status && <p className="error">{error.message}</p>}
          </DialogContent>
          <DialogActions>
            <button className={clsx("btn", "btn-primary")} type="submit">{I18n.t("pax__modal_validate")}</button>
          </DialogActions>
        </form>
      </Dialog>
      <DevTool control={control} />
    </React.Fragment>
  );
}

export default PasswordRecovery;
