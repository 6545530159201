import React from "react"

function PressBlock(props) {

  var date = new Date(props.publishDate).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' })

  return (
      <div>
        <div className="col-md-2 text-center">
            <img src={props.logo} className="press__logo" />
        </div>
        <div className="col-md-10 logo_press">
          <a href={props.link} rel="no-follow" alt={props.title}>
            {props.title}
          </a>
          <br/>
          <p>{date}</p>
        </div>
        <hr className="col-md-10 col-md-offset-1"></hr>
        <div className="col-md-12"></div>
      </div>
  );
}

export default PressBlock