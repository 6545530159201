import React from "react";
import { useStylesPriceSelection } from "../../styles/stepStyles";
import clsx from "clsx";
import { Input } from "reactstrap";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { setStepsVoucherCreationParams } from "../../../../redux/actions/actions";
import I18n from '../../../../../i18n-js/index.js.erb'
import axios from "axios";

function ChooseFormat(props) {
  const dispatch = useDispatch();
  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );
  const classes = useStylesPriceSelection();
  const [selectedEVoucher, setSelectedEVoucher] = useState(false);
  const [selectedPrintVoucher, setSelectedPrintVoucher] = useState(false);

  const handleSelectEVoucher = () => {
    setSelectedPrintVoucher(false);
    setSelectedEVoucher(true);
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step2: {status: true, print: false, evoucher: true},
      })
    );

    axios
      .post("/api/main/tracking/checkouts/completed.json", {params: {step: 2, step_name: 'format', format: 'evoucher', version: cookies["variant"]}})
      .then((response) => {console.log(response.status)})
      .catch(function (error) {});

    props.handleNext();
  };

  useEffect(() => {
    axios
      .post("/api/main/tracking/checkouts/viewed.json", {params: {step: 2, step_name: 'format', version: cookies["variant"]}})
      .then((response) => {console.log(response.status)})
      .catch(function (error) {});
  }, [])

  const handleSelectPrintVoucher = () => {
    setSelectedEVoucher(false);
    setSelectedPrintVoucher(true);
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step2: {status: true, print: true, evoucher: false},
        step3: {status: true, custom: {status: false}, physical: true}
      })
    );
    axios
      .post("/api/main/tracking/checkouts/completed.json", {params: {step: 2, step_name: 'format', format: 'physical', version: cookies["variant"]}})
      .then((response) => {console.log(response.status)})
      .catch(function (error) {});
    props.jumpOver();
  };

  return (
    <div className={classes.priceBoxWrap}>
      <div
        onClick={handleSelectEVoucher}
        className={clsx(
          props.mobile ? classes.formatBoxMobile : classes.formatBox,
          (selectedEVoucher || stepsVoucherCreationParams.step2.evoucher) && classes.priceBoxSelected
        )}
      >
        <img className={props.mobile ? classes.formatBoxImageMobile : classes.formatBoxImage} src="https://testmaxime.s3.us-east-2.amazonaws.com/photos/images/default/gift/evoucher.png" />
        <div className={props.mobile ? classes.formatBoxTextMobile : classes.formatBoxText}>
          <p className={classes.formatBoxTitle}>{I18n.t('gift_voucher_format_print')}</p>
          <p>{I18n.t('gift_voucher_format_print_tooltip')}</p>
        </div>
      </div>

      <div
        // onClick={handleSelectPrintVoucher}
        className={clsx(classes.filtered,
          props.mobile ? classes.formatBoxMobile : classes.formatBox,
          (selectedPrintVoucher || stepsVoucherCreationParams.step2.print) && classes.priceBoxSelected && classes.greyed
        )}
      >
        <img className={props.mobile ? classes.formatBoxImageMobile : classes.formatBoxImage} src="https://testmaxime.s3.us-east-2.amazonaws.com/photos/images/default/gift/physical_card.png" />
        <div className={props.mobile ? classes.formatBoxTextMobile : classes.formatBoxText}>
          <p className={classes.formatBoxTitle}>{I18n.t('gift_voucher_format_send')}</p>
          <p>{I18n.t('gift_voucher_format_send_tooltip')}</p>
          <small className={classes.portCostTooltip}>+4€ {I18n.t('gift_voucher_delivery_fee_text')}</small>
        </div>
      </div>
    </div>
  );
}

export default ChooseFormat;
