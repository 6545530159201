import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createMedia } from "@artsy/fresnel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import SelectArea from "./components/dialogs/SelectArea";
import { makeStyles } from "@material-ui/core/styles";
import NavMobile from "../main/nav/NavMobile";
import NavDesktop from "./nav/NavDesktop";
import { capitalizeFirstLetter } from "../../helpers/base.js";
import { changeSelectedArea } from "../redux/actions/actions";
import slugify from "react-slugify";
import Skeleton from "@material-ui/lab/Skeleton";
import AreaBlock from "./components/areas/AreaBlock";
import ProductSlide from "./components/slides/ProductSlide";
import SkeletonAreablock from "./components/areas/SkeletonAreablock";
import SkeletonAreablocklarge from "./components/areas/SkeletonAreablocklarge";
import gift_card_image from "../../../assets/images/gift_card_home.png"
import I18n from "../../i18n-js/index.js.erb";
import clsx from "clsx";
import AreaSlide from "./components/slides/AreaSlide";
import CategoryBlock from "./components/CategoryBlock.js";

function LandingPage(props) {
  const dispatch = useDispatch();
  const csrfToken = useSelector((state) => state.csrfToken);
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;

  const currentLocale = useSelector((state) => state.currentLocale);

  const [open, setOpen] = useState(false);
  const [areasArray, setAreasArray] = useState(false);
  const [ready, setReady] = useState(false);
  const [categoryArray, setCategoryArray] = useState([]);

  useEffect(() => {
    setReady(true);
  }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAreaSelect = (area) => {
    axios
      .post("/api/main/areas/cookie.json", { area: slugify(area) })
      .then((response) => {
        if (response.data.status === true && area !== null) {
          location.href = location.origin + "/" + currentLocale + "/" + slugify(area) 
          dispatch(changeSelectedArea(slugify(area)));
        }
      });
  };

  const StyledDialog = withStyles({
    paper: {
      width: "120rem",
      height: "50rem",
    },
  })(Dialog);

  useEffect(() => {
    axios.get(`/api/main/landing_page/area_count.json?lang=${currentLocale}`)
    .then((response) => {
      setAreasArray(response.data.areas);
      setCategoryArray(response.data.categories.data);
    });
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {},
    hero: {
      position: "relative",
      width: "100%",
      padding: "0 0 2rem",
      backgroundColor: "#F7EB67",
    },
    heroLarge: {
      position: "relative",
      width: "100%",
      padding: "0 0 2rem",
      backgroundColor: "#F7EB67",
      marginBottom: '150px'
    },
    heroBlock: {
      padding: "0 25px 25px",
      textAlign: "left",
      fontFamily: 'TobiasTRIAL-Regular',
      background: '#F7EB67'
    },
    heroText: {
      marginTop: "0",
      fontSize: "24px",
      fontFamily: 'TobiasTRIAL-Regular',
      fontWeight: 'initial',
    },
    heroTextLarge: {
      marginTop: "0",
      fontSize: "4em",
      fontFamily: 'TobiasTRIAL-Regular',
      fontWeight: 'initial',
    },
    pTop200: {
      paddingTop: '140px',
    },
    heroTextBold: {
      fontFamily: 'TobiasTRIAL-Heavy'
    },
    linkText: {
      fontWeight: "800 !important",
      fontSize: "14px",
      marginBottom: "20px",
      borderRadius: 0,
      textTransform: 'none !important',
    },
    secondaryHeroText: {
      fontFamily: "TobiasTRIAL-Regular",
      fontSize: "18px",
      maxWidth: '90%',
    },
    verticalDivider: {
      display: "block",
      height: "2vh",
      width: "0",
      margin: "1rem auto 0",
      border: "1px solid black",
    },
    provinceBlock: {
      padding: "0 20px 10px",
    },
    provinceList: {
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      margin: "3rem -.5rem 0",
      padding: "0",
      justifyContent: "space-between"
    },
    province: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingRight: ".5rem",
      paddingLeft: ".5rem",
      flex: "0 0 50%",
      maxWidth: "25%",
    },
    category: {
      marginBottom: "50px",
      position: "relative",
      width: "100%",
      flex: "0 0 100%",
      paddingBottom: "4rem",
      maxWidth: "100%",
    },
    categoryLarge: {
      marginBottom: "10px",
      position: "relative",
      width: "100%",
      paddingBottom: "4rem",
      flex: "0 0 50%",
      maxWidth: "23.5%",
      cursor: "pointer",
    },
    categoryLargeCaption: {
      position: "absolute",
      bottom: "-30px",
      width: "100%",
      background: "#F4A583",
      color: "#7d2647",
      borderBottomRightRadius: "10px",
      borderBottomLeftRadius: "10px",
      height: "80px",
      padding: "20px 20px",
      fontFamily: "TobiasTRIAL-Regular",
      textAlign: "center",
      fontSize: "26px",
      cursor: 'pointer'
    },
    provinceName: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      top: 0,
      height: "100%",
      width: "80% !important",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: "2rem",
    },
    provinceNameLarge: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      top: 0,
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#fff",
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: "3rem",
      paddingBlockEnd: "4rem",
      paddingRight: "4rem",
    },
    provinceImage: {
      width: "100%",
      height: "17vh",
      minHeight: "17rem",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    provinceImageLarge: {
      width: "100%",
      height: "250px",
      minHeight: "250px",
      borderRadius: "10px",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    experienceBlock: {
      padding: "0 20px",
      position: "relative",
      marginTop: "20px",
    },
    sectionTitle: {
      paddingLeft: '1.5rem',
    },
    sectionSubTitle: {
      paddingLeft: '1.5rem',
    },
    giftCardBlock: {
      padding: '0 4rem'
    },
    container: {
      width: '90%',
      margin: '0 auto',
      clear: "both",
    },
    yellowBlockLarge: {
      background: "#F7EB67",
      borderRadius: 20,
      margin: '50px auto 80px',
      padding: '25px 25px 70px 25px',
      position: 'relative',
      top: '50px',
      maxWidth: '85%'
    },
    yellowBlock: {
      background: "#F7EB67",
      borderRadius: 20,
      margin: '50px auto',
      padding: '25px',
      position: 'relative',
      maxWidth: '100%'
    },
    giftCardImage: {
      width: '100%'
    },
    blockRow: {
      paddingTop: "100px",
      paddingBottom: "100px",
      marginBottom: '-10px'
    },
    giftRowLarge: {
      background: "#F7EB67",
      backgroundImage: 'url(https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/hotelbreak-gift-cards.jpg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50vw 50%',
    },
    giftRow: {
      background: "#F7EB67",
    },
    socialRow: {
      background: "#F4A583",
      color: '#7d2647'
    },
    hoteliersRowLarge: {
      backgroundImage: 'url(https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/hotelbreak-for-hoteliers.jpeg) !important',
    },
    logoBrandLarge: {
      maxWidth: "100px",
      marginTop: "-20px",
      display: 'inline-block',
      marginLeft: "50px",
      "&:nth-of-type(1), &:nth-of-type(4)": {
        marginLeft: '0 !important'
      }
    },
    logoBrand: {
      maxWidth: "80%",
      justifySelf: 'center',
    },
    noPadding: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      marginLeft: '0 !important',
      marginRight: '0 !important',
    },
    hoteliersLogo: {
      display: 'grid',
      gridTemplateColumns: "1fr 1fr",
    }
  }));

  const classes = useStyles();

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  const landing = () => {
    return (
      <React.Fragment>
        <div>
          <div className="col-md-4 no-padding">
            <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/hotelbreak-day-pass-en-hoteles.png" style={{width: '100%'}} />
          </div>
          <div className="col-md-8 no-padding">
            <div className={classes.heroBlock}>
              <h1 className={clsx(classes.heroText)} style={{marginBottom: '30px', paddingTop: '50px'}}>
                {" "}
                Your time, <span className={classes.heroTextBold}>enjoy what matters</span>
              </h1>
              <p className={classes.secondaryHeroText} style={{marginBottom: '30px'}}>
                {I18n.t("landing.your_time_text")}
              </p>
              <Button
                onClick={handleClickOpen}
                className={classes.linkText + " btn btn-secondary"}
              >
                {" "}
                {I18n.t("landing.view_experience_in_my_city")}
              </Button>
            </div>
          </div>
        </div>
        <div className={clsx("row", classes.noPadding)}>
          <div className={classes.container}>
            <div>
              <div className="col-md-4">
                <br /><br />
              </div>
              <div className="col-md-8 no-padding">
                <h2 className={classes.heroText} style={{paddingTop: '0 !important', paddingBottom: '30px'}}>
                  <span className={classes.heroTextBold}>Enjoy.</span> Today.
                </h2>
                <p className={classes.secondaryHeroText}>{I18n.t("landing.enjoy_today_text")}</p>
                <ul className={classes.provinceList}>
                  {areasArray ?
                    <AreaSlide
                      areas={areasArray}
                      selector="areas"
                      listCols={1.3}
                    />

                  : [...Array(6)].map((e, i) => <SkeletonAreablocklarge />)
                }
                </ul>
                <div style={{marginTop: "30px"}}>
                  <Button
                    onClick={handleClickOpen}
                    className={classes.linkText + " btn btn-primary"}
                  >
                    {" "}
                    {I18n.t("landing.hotelbreak_universe")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className={clsx(classes.container, classes.yellowBlock)}>
            <div className={classes.experienceBlock}>
              <h2 className={clsx(classes.heroTextBold, classes.heroText)}>
                365 days. 365 ways.
              </h2>
              <ul className={classes.provinceList}>
                {categoryArray && categoryArray.map((category) =>
                  <CategoryBlock image={`${category.attributes.webp}`} text={category.attributes.category_block} large={false} function={handleClickOpen} />
                )}
              </ul>
            </div>
          </div>
          <StyledDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <SelectArea handleClose={handleClose}></SelectArea>
          </StyledDialog>
        </div>
        <div className={clsx("row", classes.noPadding, classes.blockRow, classes.giftRow)}>
          <div className={clsx("col-md-6 col-md-offset-1 col-xs-12", classes.giftCardBlock)}>
            <div style={{width: '100%', marginBottom: '30px'}} >
              <h2 className={clsx(classes.heroTextBold, classes.heroText)}>Give time. Give memories.</h2>
            </div>
            <div style={{width: '80%', marginBottom: '30px'}} >
              <div className={classes.secondaryHeroText} dangerouslySetInnerHTML={{__html: I18n.t("landing.gift_text_html")}}/>

            </div>
            
            <a
              href={`/${I18n.locale}/${I18n.t('routes.create_gift')}`}
              className={clsx(classes.linkText, 'btn btn-secondary')}
              alt={I18n.t("give_daycations_btn")}
              title={I18n.t("give_daycations_btn")}
            >
              {I18n.t("give_daycations_btn")}
            </a>
          </div>
          <div className="col-md-5">
            <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/hotelbreak-gift-cards.webp" style={{position: 'relative', width: '100%', marginBottom: '-160px', marginTop: '20px'}} />
          </div>
        </div>
        <div className={clsx("row", classes.noPadding, classes.blockRow, classes.socialRow)}>
          <div className={classes.container}>
            <div className={"col-md-7 col-md-offset-5"}>
              <h2 className={clsx(classes.heroTextBold, classes.heroText)} style={{marginTop: '40px'}}>Stop waiting for friday</h2>
            </div>
            <div className="col-md-7">
              <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/ig-feed.webp" alt="hotelbreak instagram feed" style={{maxWidth: '100%'}} title="hotelbreak instagram" />
            </div>
            <div className={clsx("col-md-5")} style={{marginTop: '50px'}}>
              <p className={clsx(classes.secondaryHeroText)} style={{fontSize: '26px'}}><strong>3100+</strong> {I18n.t("landing.num_experience_text")}</p>
              <p className={clsx(classes.secondaryHeroText)} style={{fontSize: '26px'}}><strong>100,000+</strong> {I18n.t("landing.num_hotelbreakers_text")}</p>
            </div>
          </div>
        </div>
        <div className={clsx("row", classes.noPadding, classes.blockRow, classes.giftRow, classes.hoteliersRow)}>
          <div className={clsx("col-md-6 col-md-offset-1 col-xs-12", classes.giftCardBlock)}>
            <div style={{width: '100%', marginBottom: '30px'}} >
              <h2 className={clsx(classes.heroTextBold, classes.heroText)}>Hoteliers-first community</h2>
              <div className={classes.hoteliersLogo}>
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/ihg-logo.png" className={classes.logoBrand} />
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/hilton-logo.png" className={classes.logoBrand} />
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/jumeirah-logo.png" className={classes.logoBrand} />
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/accor-logo.png" className={classes.logoBrand} />
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/melia-logo.png" className={classes.logoBrand} />
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/marriott-logo.png" className={classes.logoBrand} />
              </div>
            </div>
            <div style={{width: '80%', marginBottom: '30px'}} >
              <p className={classes.secondaryHeroText}>
                {I18n.t("landing.hoteliers_community")}
              </p>
            </div>
            <div className="col-md-12 no-padding">
              <a
                href={`/${I18n.locale}/${I18n.t('routes.partners')}`}
                className={clsx(classes.linkText, 'btn btn-secondary')}
                alt={I18n.t("give_daycations_btn")}
                title={I18n.t("give_daycations_btn")}
              >
                {I18n.t("landing.hoteliers_button")}
              </a>
              
            </div>
          </div>
          <div className="col-md-5">
          </div>
        </div>
        <StyledDialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <SelectArea handleClose={handleClose}></SelectArea>
        </StyledDialog>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <MediaContextProvider>
        <Media at="sm">
          <div className={clsx("row", classes.noPadding)} style={{ visibility: ready ? 'visible' : 'hidden' }}>
            <NavMobile />
            {landing()}
          </div>
        </Media>
        <Media at="md">
          <div className={clsx("row", classes.noPadding)} style={{ visibility: ready ? 'visible' : 'hidden' }}>
            <NavMobile />
            {landing()}
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <div className={clsx("row", classes.noPadding)} style={{ visibility: ready ? 'visible' : 'hidden' }}>
            <div className={clsx(classes.heroLarge, "col-md-6")}>
              <NavDesktop landing={true}  source={"landing_page"} handleClickOpen={handleClickOpen} />
              <div className={classes.container}>
                <div className="col-md-8">
                  <div className={classes.heroBlock}>
                    <h1 className={clsx(classes.heroTextLarge, classes.pTop200)} style={{marginBottom: '30px', width: '130%', position: 'relative', zIndex: 1}}>
                      {" "}
                      Your time, <span className={classes.heroTextBold}>enjoy what matters</span>
                    </h1>
                    <p className={classes.secondaryHeroText} style={{marginBottom: '30px', position: 'relative', zIndex: 1, maxWidth: '60%'}}>
                      {I18n.t("landing.your_time_text")}
                    </p>
                    <Button
                      onClick={handleClickOpen}
                      className={classes.linkText + " btn btn-secondary"}
                    >
                      {" "}
                      {I18n.t("landing.view_experience_in_my_city")}
                    </Button>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/hotelbreak-day-pass-en-hoteles.webp" style={{width: '50vw', position: 'absolute', top: '180px', right: 0, zIndex: 0, maxHeight: "370px", objectFit: 'cover'}} />
                </div>
              </div>
            </div>
          </div>
          <div className={clsx("row", classes.noPadding)}>
            <div className={classes.container}>
              <div className={classes.provinceBlock}>
                <div className="col-md-4">
                  <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/hotelbreak-cocktails.jpg" style={{width: '90%', marginTop: '-60px'}} />
                </div>
                <div className="col-md-8">
                  <h2 className={classes.heroTextLarge} style={{paddingTop: '5% !important', paddingBottom: '30px'}}>
                    <span className={classes.heroTextBold}>Enjoy.</span> Today.
                  </h2>
                  <p className={classes.secondaryHeroText}>{I18n.t("landing.enjoy_today_text")}</p>
                  <ul className={classes.provinceList}>
                    {areasArray ?
                      <AreaSlide
                        areas={areasArray}
                        selector="areas"
                        listCols={4.3}
                      />

                    : [...Array(6)].map((e, i) => <SkeletonAreablocklarge />)
                  }
                  </ul>
                  <div style={{marginTop: "30px"}}>
                    <Button
                      onClick={handleClickOpen}
                      className={classes.linkText + " btn btn-primary"}
                    >
                      {" "}
                      {I18n.t("landing.hotelbreak_universe")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className={clsx(classes.container, classes.yellowBlockLarge)}>
              <div className={classes.experienceBlock}>
                <h2 className={clsx(classes.heroTextBold, classes.heroTextLarge)}>
                  {/* I18n.t("take_a_daycation") */}
                  365 days. 365 ways.
                </h2>
                <ul className={classes.provinceList}>
                  {categoryArray && categoryArray.map((category) =>
                    <CategoryBlock image={category.attributes.webp} text={category.attributes.category_block} large={true} function={handleClickOpen} />
                  )}
                </ul>
              </div>
            </div>
            <StyledDialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <SelectArea handleClose={handleClose}></SelectArea>
            </StyledDialog>
          </div>
          <div className={clsx("row",classes.noPadding, classes.blockRow, classes.giftRowLarge)}>
            <div className={clsx("col-md-6 col-md-offset-1 col-xs-12", classes.giftCardBlock)}>
              <div style={{width: '130%', marginBottom: '30px'}} >
                <h2 className={clsx(classes.heroTextBold, classes.heroTextLarge)}>Give time. Give memories.</h2>
              </div>
              <div style={{width: '80%', marginBottom: '30px'}} >
                <div className={classes.secondaryHeroText} dangerouslySetInnerHTML={{__html: I18n.t("landing.gift_text_html")}}/>
              </div>

              <a
                href={`/${I18n.locale}/${I18n.t('routes.create_gift')}`}
                className={clsx(classes.linkText, 'btn btn-secondary')}
                alt={I18n.t("give_daycations_btn")}
                title={I18n.t("give_daycations_btn")}
              >
                {I18n.t("give_daycations_btn")}
              </a>
            </div>
            <div className="col-md-5">
            </div>
          </div>
          <div className={clsx("row", classes.noPadding, classes.blockRow, classes.socialRow)}>
            <div className={classes.container} style={{maxWidth: '85%'}}>
              <div className={"col-md-7 col-md-offset-5"}>
                <h2 className={clsx(classes.heroTextBold, classes.heroTextLarge)}>Stop waiting for friday</h2>
              </div>
              <div className="col-md-7">
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/ig-feed.webp" alt="hotelbreak instagram feed" style={{maxWidth: '100%'}} title="hotelbreak instagram" />
              </div>
              <div className={clsx("col-md-5")} style={{marginTop: '50px'}}>
                <p className={clsx(classes.secondaryHeroText)} style={{fontSize: '26px'}}><strong>3100+</strong> {I18n.t("landing.num_experience_text")}</p>
                <p className={clsx(classes.secondaryHeroText)} style={{fontSize: '26px'}}><strong>100,000+</strong> {I18n.t("landing.num_hotelbreakers_text")}</p>
              </div>
            </div>
          </div>
          <div className={clsx("row", classes.noPadding, classes.blockRow, classes.giftRowLarge, classes.hoteliersRowLarge)}>
            <div className={clsx("col-md-6 col-md-offset-1 col-xs-12", classes.giftCardBlock)}>
              <div style={{width: '130%', marginBottom: '30px'}} >
                <h2 className={clsx(classes.heroTextBold, classes.heroTextLarge)}>Hoteliers-first community</h2>
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/ihg-logo.png" className={classes.logoBrandLarge} />
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/hilton-logo.png" className={classes.logoBrandLarge} />
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/jumeirah-logo.png" className={classes.logoBrandLarge} />
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/accor-logo.png" className={classes.logoBrandLarge} />
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/melia-logo.png" className={classes.logoBrandLarge} />
                <img src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/marriott-logo.png" className={classes.logoBrandLarge} />
              </div>
              <div style={{width: '80%', marginBottom: '30px'}} >
                <p className={classes.secondaryHeroText}>
                  {I18n.t("landing.hoteliers_community")}
                </p>
              </div>
              <div className="col-md-12 no-padding">
                <a
                  href={`/${I18n.locale}/${I18n.t('routes.partners')}`}
                  className={clsx(classes.linkText, 'btn btn-secondary')}
                  alt={I18n.t("give_daycations_btn")}
                  title={I18n.t("give_daycations_btn")}
                >
                  {I18n.t("landing.hoteliers_button")}
                </a>
                
              </div>
            </div>
            <div className="col-md-5">
            </div>
          </div>
        </Media>
      </MediaContextProvider>
    </React.Fragment>
  );
}

export default LandingPage;
