import React from "react";
import { useState, useEffect } from "react";
import ProductBlock from "./main/products/ProductBlock";
import InfiniteScroll from "react-infinite-scroll-component";
import UserLoginWrap from "./main/components/modals/UserLogin/UserLoginWrap";
import loadingLogo from "images/logo_reduced.png";
import FilterBar from "./main/filters/FilterBar";
import { currencyToSymbol, capitalizeFirstLetter } from "../helpers/base";
import I18n from "../i18n-js/index.js.erb";
import { to_eur } from "../helpers/base";
import GuideBanner from "./main/promos/GuideBanner";
import axios from "axios";
import { createMedia } from "@artsy/fresnel";
import slugify from "react-slugify";
import NavMobile from "./main/nav/NavMobile";
import NavDesktop from "./main/nav/NavDesktop";
import { useSelector, useDispatch } from "react-redux";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import moment from "moment";
import {
  addCSRFToken,
  setCurrentUser,
  changeLocale,
  changeSelectedArea,
  setAreaHomeProducts,
  changeSelectedAreaName,
  setAreaHomeHotelCategories,
} from "./redux/actions/actions";
import Maps from "./main/components/maps/maps";
import GiftBlock from "./main/products/GiftBlock";

let noMarginTop = {
  marginTop: "0",
};

let infiniteStyle = {
  overflow: "visible",
};

let resultTitleStyles = {
  fontSize: "20px",
  padding: "0 0 0 15px",
};

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    lg: 1024,
  },
});

export default function ProductList(props) {
  const [locale, setLocale] = useState(props.current_locale);
  const [AllProducts, setAllProducts] = useState([]);
  const [AllCategories, setAllCategories] = useState([]);
  const [AllTranslatedCategories, setAllTranslatedCategories] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(2);
  const [orderBy, setOrderBy] = useState(props.order_by);
  axios.defaults.headers.common["X-CSRF-Token"] = props.csrf_token;
  axios.defaults.withCredentials = true;

  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const [mapVisible, setMapVisble] = useState(false);
  const adults = [null, undefined, 0].includes(props.adults) ? 1 : props.adults;
  const kids = [null, undefined, 0].includes(props.kids) ? 0 : props.kids;
  dispatch(addCSRFToken(props.csrf_token));
  dispatch(changeLocale(props.current_locale));
  I18n.locale = locale;

  var allProductsList = [];
  // Builder config
  builder.init("51c4483a55434a2c87bd705c818c4de4");
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: window.location.pathname
        })
        .promise();

      setContent(content);
      setNotFound(!content);

      // if the page title is found,
      // set the document title
      if (content?.data.title) {
       document.title = content.data.title
      }
    }
    fetchContent();
  }, [window.location.pathname]);
  // Builder config

  var multipleCategories =
    props.product_category === null ||
    ["black_friday", "black-friday", "Break Friday"].includes(props.product_category) ||
    props.product_category?.split(",").length > 1;

  const isMapShown = (e) => {
    setMapVisble(e);
  };

  useEffect(() => {
    setAllProducts(props.all_products.data);

    if (props.all_products.length <= 7) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, []);

  useEffect(() => {
    setReady(true);
  }, []);

  const selectedArea = useSelector((state) => state.area);
  const currentLocale = useSelector((state) => state.currentLocale);
  const selectedAreaName = useSelector((state) => state.areaName);

  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet,
      }),
      changeLocale(props.locale)
    );
  } else {
    changeLocale(props.locale);
  }

  useEffect(() => {
    if ((props.area || props.province) && selectedArea) {
      dispatch(changeSelectedArea(selectedArea));
      var location_params = selectedArea;
    } else if ((props.area || props.province) && !selectedArea) {
      dispatch(changeSelectedArea(props.area));
      var location_params = props.area;
    }
    axios
      .get("/api/main/products", {
        params: { location: location_params, locale: currentLocale },
      })
      .then((response) => {
        dispatch(
          setAreaHomeProducts(
            response.data.area_home_products
              .map((product_list) => {
                return {
                  category: product_list.products.map(
                    (productSerialization) => productSerialization.attributes
                  ),
                  title: product_list.title,
                  slug: product_list.slug,
                  emoji: product_list.emoji,
                  popularity: product_list.popularity,
                  type: product_list.type,
                };
              })
              .sort((a, b) => {
                if (a.popularity > b.popularity) {
                  return -1;
                } else if (
                  a.popularity === "break_friday" ||
                  a.popularity === "most_popular" ||
                  a.popularity === "most_recent"
                ) {
                  return -1;
                } else {
                  return 1;
                }
              })
          )
        );
        dispatch(setAreaHomeHotelCategories(response.data.rooms_categories));
        dispatch(changeSelectedAreaName(response.data.province));
      });
  }, [selectedArea]);

  var fetchFilteredData = (orderby) => {
    setOrderBy(orderby);
    setHasMore(true);
    axios
      .get("/api/v1/productlist/productblocks.json", {
        params: {
          page_number: 1,
          filter: orderby,
          filterrific: props.filterrific_params,
          location: props.location,
          product_category: props.product_category,
          all_inclusive: props.all_inclusive,
          family_friendly: props.family_friendly,
          adults_only: props.adults_only,
          available_date: props.available_date,
          wallet_friendly: props.wallet_friendly,
          price_low: props.price_low,
          price_high: props.price_high,
          category: props.categories,
          product: props.initial_product_param
        },
      })

      .then((response) => {
        setPageNumber(2);
        setAllProducts(response.data.products.data);
      });
  };

  const allProducts = (blockNumber, currentCategory, index) => {
    var content = [];
    var already_rendered_keys = [];
    var actual_index = -1;

    if (currentCategory && !multipleCategories) {
      allProductsList = AllProducts.filter((product) => {
        const categoryName = product.attributes?.category_primary_name?.toLowerCase();
        return categoryName === currentCategory.toLowerCase();
      });
    } else {
      allProductsList = AllProducts;
    }

    if (
      allProductsList.length > 0 &&
      currentCategory && !hasMore &&
      currentCategory.toLowerCase() !==
        props.primary_category_name.toLowerCase()
    ) {
      content.push(
        <React.Fragment>
          <div className="row" key={`${currentCategory}-break`}></div>
          <h1>
            {I18n.t("product_list__others_sentence", {
              nextCategory: capitalizeFirstLetter(props.product_category),
              currentCategory: AllTranslatedCategories[index],
            })}
          </h1>
        </React.Fragment>
      );
    }
    allProductsList.splice(3, 0, <GiftBlock current_locale={locale} col={blockNumber} />);
    allProductsList.map((product, index) => {
      const productblock = (product.type === GiftBlock && index === 3) ? (
                <GiftBlock current_locale={locale} col={blockNumber} />
      ) : product.attributes ? (
        <ProductBlock
          key={product.attributes.id}
          id={product.attributes.id}
          name={product.attributes.name}
          block_photo={product.attributes.block_photo}
          week_price={to_eur(product.attributes.pvp)}
          base_week_price={to_eur(product.attributes.base_pvp)}
          listing_name={product.attributes.listing_name}
          is_direct_booking={product.attributes.direct_booking}
          star_rating={product.attributes.star_rating}
          city={product.attributes.room_city && product.attributes.room_city}
          area={slugify(selectedArea)}
          province={slugify(selectedArea)}
          max_guest={product.attributes.max_guest}
          price_model={product.attributes.price_model}
          currency={currencyToSymbol(product.attributes.currency)}
          index={actual_index + 1}
          slug={product.attributes.slug}
          hotelSlug={product.attributes.room_slug}
          timeDistance={product.attributes.time_distance}
          available_date={props.available_date}
          current_locale={locale}
          adults={adults}
          kids={kids}
          primary_category={product.attributes.category_primary_name}
          primaryCategoryId={product.attributes.primary_category_id}
          is_new={
            moment(
              product.attributes.bookable_at || product.attributes.created_at
            ) > moment().subtract(2, "months")
          }
          has_calendar={product.attributes.has_calendar}
        />
      ) : null;

      if (!already_rendered_keys.includes(product.id)) {
        actual_index = actual_index + 1;

        if ((actual_index + 1) % blockNumber == 0) {
          if (blockNumber > 2) {
            content.push(
              <div className="row" key={product.id}>
                {productblock}
              </div>
            );
          } else {
            content.push(productblock);
            content.push(<div style={{ clear: "both" }} />);
          }

          /** if (actual_index === AllProducts.length) {
            if (
              (props.filterrific_params &&
                props.filterrific_params.area === "mallorca") ||
              (props.location && props.location === "mallorca")
            ) {
              if (props.filterrific_params) {
                var area_name = props.filterrific_params.area;
              } else {
                var area_name = props.location;
              }

              content.push(
                <React.Fragment>
                  <GuideBanner
                    locale={props.locale}
                    guides={props.guides}
                    area={area_name}
                  />
                </React.Fragment>
              );
            }
          } **/
        } else {
          content.push(productblock);
        }
      }

      already_rendered_keys.push(product.id);
    });

    return content;
  };

  const getOrder = (orderBy) => {
    setAllProducts([]);
    fetchFilteredData(orderBy);
  };

  const filterRooms = (orderBy) => {
    setAllProducts([]);
    getOrder(orderBy);
  };

  let smallerColumn = {
    maxWidth: mapVisible ? "61%" : "inherit",
    margin: mapVisible && "0 0 0 1%",
  };

  const fetchMoreData = () => {
    setTimeout(function () {
      axios
        .get("/api/v1/productlist/productblocks.json", {
          params: {
            page_number: pageNumber,
            filter: orderBy,
            filterrific: props.filterrific_params,
            location: props.location,
            product_category: props.product_category,
            all_inclusive: props.all_inclusive,
            family_friendly: props.family_friendly,
            adults_only: props.adults_only,
            available_date: props.available_date,
            wallet_friendly: props.wallet_friendly,
            price_low: props.price_low,
            price_high: props.price_high,
            adults: adults,
            kids: kids,
            brand: props.brand,
            category: props.categories,
            product: props.initial_product_param
          },
        })

        .then((response) => {
          setPageNumber(pageNumber + 1);
          setAllProducts(AllProducts.concat(response.data.products.data));

          if (AllCategories.length === 0) {
            setAllCategories(response.data.all_categories);
            setAllTranslatedCategories(response.data.all_categories_translated);
          }

          if (response.data.all_prods.length === 0) {
            setHasMore(false);
          }
        });
    }, 500);
  };

  return (
    <div style={{ visibility: ready ? "visible" : "hidden" }}>
      <MediaContextProvider>
        <Media at="sm">
          <UserLoginWrap baseUrl={props.baseUrl} fullscreen={true} />
        </Media>

        <Media at="md">
          <UserLoginWrap baseUrl={props.baseUrl} fullscreen={false} />
        </Media>

        <Media greaterThanOrEqual="lg">
          <UserLoginWrap baseUrl={props.baseUrl} fullscreen={false} />
        </Media>
      </MediaContextProvider>
      <MediaContextProvider>
        <Media at="sm">
          <NavMobile index={true} areaName={selectedAreaName} />
          {
            //<BuilderComponent model="page" content={content} />
          }
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <FilterBar
              filterRooms={filterRooms}
              text_list={props.text_list}
              current_locale={locale}
              hotel_search__filter={props.hotel_search__filter}
              count_filters={props.count_filters}
              initial_filter={props.order_by}
              filterrific_params={props.filterrific_params}
              exp_var={props.exp_var}
              source={"experiences"}
              product_category={props.product_category}
              adults={adults}
              kids={kids}
              wallet_friendly={props.wallet_friendly}
              price_high={props.price_high}
              price_low={props.price_low}
              available_date={props.available_date}
              categories={props.categories}
              initial_product_param={props.initial_product_param}
            />

            <Maps
              geo={props.geo_rooms}
              long={props.long}
              lat={props.lat}
              isMapShown={isMapShown}
              hoveredHotel={false}
              device={"mobile"}
            />

            <div className="container">
              <h1
                className="result_title nodisplay_above768"
                style={resultTitleStyles}
                dangerouslySetInnerHTML={{ __html: props.text_list }}
              ></h1>

              <div className="row" style={noMarginTop}>
                <div className="col-md-12"></div>
              </div>

              <div className="panel-body">
                <InfiniteScroll
                  dataLength={AllProducts.length}
                  scrollThreshold={0.6}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  style={infiniteStyle}
                  loader={
                    <div className="loading__container">
                      <img
                        className="loading__hotelbreak"
                        alt={"Loading logo"}
                        src={loadingLogo}
                        data-original={loadingLogo}
                      />
                    </div>
                  }
                  // endMessage={
                  //   props.area === "mallorca" && (
                  //     <React.Fragment>
                  //       <div className="col-md-12"></div>
                  //       <GuideBanner
                  //         locale={locale}
                  //         guides={props.guides}
                  //         area={props.area}
                  //       />
                  //     </React.Fragment>
                  //   )
                  // }
                >
                  {AllCategories.length > 0 && !multipleCategories
                    ? AllCategories.map((category, index) => {
                        return allProducts(2, category, index);
                      })
                    : allProducts(2, false, false)}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <NavDesktop index={true} areaName={selectedAreaName} />
          {
            //<BuilderComponent model="page" content={content} />
          }
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <FilterBar
              filterRooms={filterRooms}
              text_list={props.text_list}
              current_locale={locale}
              hotel_search__filter={props.hotel_search__filter}
              count_filters={props.count_filters}
              initial_filter={props.order_by}
              exp_var={props.exp_var}
              area={props.area}
              filterrific_params={props.filterrific_params}
              source={"experiences"}
              product_category={props.product_category}
              adults={adults}
              kids={kids}
              wallet_friendly={props.wallet_friendly}
              price_high={props.price_high}
              price_low={props.price_low}
              available_date={props.available_date}
              categories={props.categories}
              initial_product_param={props.initial_product_param}
              desktop
            />

            <Maps
              geo={props.geo_rooms}
              long={props.long}
              lat={props.lat}
              isMapShown={isMapShown}
              hoveredHotel={false}
              device={"desktop"}
            />

            <div className="container" style={smallerColumn}>
              <h1
                className="result_title nodisplay_above768"
                style={resultTitleStyles}
                dangerouslySetInnerHTML={{ __html: props.text_list }}
              ></h1>

              <div className="row" style={noMarginTop}>
                <div className="col-md-12"></div>
              </div>

              <div className="panel-body">
                <InfiniteScroll
                  dataLength={AllProducts.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  style={infiniteStyle}
                  loader={
                    <div className="loading__container">
                      <img
                        className="loading__hotelbreak"
                        alt={"Loading logo"}
                        src={loadingLogo}
                        data-original={loadingLogo}
                      />
                    </div>
                  }
                  // endMessage={
                  //   props.area === "mallorca" && (
                  //     <React.Fragment>
                  //       <div className="col-md-12"></div>
                  //       <GuideBanner
                  //         locale={locale}
                  //         guides={props.guides}
                  //         area={props.area}
                  //       />
                  //     </React.Fragment>
                  //   )
                  // }
                >
                  {AllCategories.length > 0 && !multipleCategories
                    ? AllCategories.map((category, index) => {
                        return allProducts(4, category, index);
                      })
                    : allProducts(4, false, false)}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </Media>
      </MediaContextProvider>
    </div>
  );
}
