import { makeStyles } from "@material-ui/core/styles";

const useStylesProductForm = makeStyles((theme) => ({
  experienceImage: {
    maxWidth: "100vw",
    width: "100vw",
    minHeight: "290px",
  },
  experienceImageLarge: {
    width: "calc(100% - 20%)",
    zIndex: 7,
    objectFit: "cover",
    position: "static",
    maxWidth: "100%",
    height: "470px",
    marginLeft: "calc(10% + 20px)",
    marginTop: "-40px",
    borderRadius: '10px'
  },
  backgroundImage: {
    height: '600px',
    backgroundColor: '#F7EB67',
    position: "absolute",
    width: "100%",
    top: "-20px",
    zIndex: '-1'
  },
  slidingPane: {
    paddingTop: "5px",
    backgroundColor: "white",
    width: "100%",
    height: "fit-content",
    position: "relative",
  },
  slidingPaneLarge: {
    width: "100%",
    height: "fit-content",
    marginTop: "180px",
    paddingTop: "5px",
    backgroundColor: "white",
  },
  title: {
    fontSize: "18px",
    fontWeight: "500 !important",
  },
  titleLarge: {
    fontSize: "20px",
    fontWeight: "500 !important",
  },
  divider: {
    height: "6px",
    width: "100%",
    backgroundColor: "#ECECEC",
    marginBottom: "10px",
  },
  hotelName: {
    paddingTop: "10px",
    marginBottom: "-15px",
  },
  hotelNameLarge: {
    fontSize: "20px",
    paddingTop: "80px",
    marginbottom: "-15px",
  },
  largeContainer: {
    marginLeft: "10%",
    maxWidth: "80%",
  },
  container: {
    margin: "20px 30px",
  },
  hotelSlider: {
    margin: "10px 30px",
  },
  hotelLink: {
    fontSize: "18px",
    fontWeight: "800",
  },
  secondaryTitle: {
    fontSize: "18px",
    fontWeight: "500 !important",
  },
  leftColumn: {
    width: '65%',
    marginTop: '-140px',
    display: 'inline-block',
  },
  formBookingLarge: {
    width: "100%",
    minWidth: "41.5rem",
    minHeight: "49rem",
    position: "sticky",
    zIndex: "10",
    background: "white",
    top: "56px",
    padding: "20px 20px 10px",
    boxShadow: "rgb(0 0 0 / 25%) 0px 2px 4px",
    marginBottom: "20px",
    borderRadius: '10px'
  },
  rightColumn: {
    float: "right",
    paddingRight: "90px",
    maxWidth: "35%",
    position: "sticky",
    marginTop: "-180px"
  },
  formBookingLargeAcceptKids: {
    width: "100%",
    minHeight: "61rem",
    position: "sticky",
    zIndex: "10",
    background: "white",
    top: "56px",
    padding: "20px 20px 10px",
    boxShadow: "rgb(0 0 0 / 25%) 0px 2px 4px",
    borderRadius: '10px'
  },
  infoBlock: {
    padding: "20px",
    border: "1px solid lightgrey",
    borderRadius: "5px",
    height: "fit-content",
    padding: "15px",
  },
  horizontalEelements: {
    display: "flex",
    justifyContent: "space-between",
  },
  infoPhoto: {
    width: "100%",
    borderRadius: "5px",
  },
  sameLineEmoji: {
    display: "inline-block",
    marginRight: "1rem",
    fontSize: "2rem",
  },
  photoDialogBlock: {
    marginBottom: "2rem",
  },
  dialogTitle: {
    fontSize: "2rem",
    fontWeight: "300",
  },
  secondaryTitle: {
    fontSize: "22px",
    fontWeight: "500 !important",
  },
  sameLineText: {
    display: "inline-block",
    marginRight: "1rem",
  },
  paddingTop: {
    paddingTop: "2rem",
    color: '#7d2647',
    cursor: 'pointer'
  },
  reviewBlock: {
    background: "#F4A583",
    clear: "both",
    width: '100vw',
    "& $horizontalEelements, $largeContainer": {
      maxWidth: "50%",
      marginLeft: "8%",
    },
    "& $sameLineText, $paddingTop": {
      color: '#7d2647'
    },
    "& $horizontalEelementsMobile": {
      maxWidth: "85%",
      marginLeft: "8%",
    }
  },
  largeContainerBottom: {
    maxWidth: '73%',
    marginLeft: '14%'
  },
  fullBtn: {
    display: "block !important",
    width: "100%",
  },
  stickyBtn: {
    padding: "10px",
    background: "white",
    borderRadius: "4px 0 0 4px",
    position: "fixed",
    top: "67vh",
    width: "100%",
  },
  breadcrumb: {
    marginTop: "20px !important",
    marginBottom: "10px !important",
    fontFamily: "Open Sans",
    display: "inline-block",
    fontSize: "10px",
    paddingInlineStart: "0 !important",
    marginBlockStart: "0 !important",
    marginBottom: "0",
    "&.accept_kids": {
      paddingTop: "0 !important",
    },
  },
  breadcrumbUL: {
    paddingInlineStart: "0 !important",
    marginBlockStart: "0 !important",
  },
  breadcrumbList: {
    display: "inline-block",
  },
  breadcrumbSeparator: {
    margin: "0 5px",
  },
  hotelBlock: {
    backgroundColor: '#9dd1c0',
    border: 'none'
  },
  cancelBlock: {
    backgroundColor: '#F7EB67',
    border: 'none'
  }
}));

export { useStylesProductForm };
