import React from "react";
import { useState, useEffect } from "react";
import I18n from "../../../i18n-js/index.js.erb";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faArrowDownShortWide,
} from "@fortawesome/free-solid-svg-icons";
import FiltersModal from "./FiltersModal";
import { useSelector, useDispatch } from "react-redux";
import { setFilterData } from "../../redux/actions/actions";
import { makeStyles } from "@material-ui/core/styles";
import SelectDate from "../components/dialogs/SelectDate";

function FilterBar(props) {
  const dispatch = useDispatch();
  const filtersData = useSelector((state) => state.filtersData);

  const currentLocale = useSelector((state) => state.currentLocale);
  I18n.locale = currentLocale;

  const [offset, setOffset] = useState(0);
  const [externalSubmit, setExternalSubmit] = useState(false);
  const [chosenDate, setChosenDate] = useState(
    useSelector((state) => state.selectedDate)
      ? useSelector((state) => state.selectedDate)
      : props.available_date
      ? props.available_date
      : null
  );

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  const [orderBy, setOrderBy] = useState(props.initial_filter);
  const [orderBy_selected, setOrderBySelected] = useState(props.initial_filter);

  const handleFiltersOpen = () => {
    dispatch(setFilterData({ ...filtersData, open: true }));
  };

  const handleFiltersClose = () => {
    dispatch(setFilterData({ ...filtersData, open: false }));
  };

  let modalStyles = {
    float: "right",
    cursor: "pointer",
  };

  let orderFilterStyles = {
    padding: "1rem 0 0 2rem",
  };

  let textStyles = {
    fontSize: "20px",
    margin: "0.9rem 0 0 0",
    fontFamily: props.font && props.font
  };

  let btnWithMarginStyles = {
    float: "right",
    margin: "0 0 0 20px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };

  let btnFilterWrapStyles = {
    display: "flex",
    flexDirection: "row-reverse",
  };

  let stickyStyles = {
    position: "sticky",
  };

  let newStyle = {
    padding: "10px 0",
  };

  let btnStyles = {
    float: "right",
  };

  let filterStyles = {
    margin: "0 0 0 5px",
  };

  let headings = {
    fontFamily: props.font && props.font
  }

  const useStyles = makeStyles((theme) => ({
    root: {},
    headings: {
      fontFamily: props.font && `${props.font} !important`,
    },
  }))

  const classes = useStyles();

  var changeOrderBy = (event) => {
    setOrderBy(event.target.value);
  };

  var sendOrderBy = () => {
    setOrderBySelected(orderBy);
    props.filterRooms(orderBy);
  };

  var usedFilter = () => {
    switch (orderBy_selected) {
      case "mostPopular":
        return "filter__mostPopular";
        break;
      case "mostRecent":
        return "filter__mostRecent";
        break;
      case "lowestUserRating":
        return "filter__lowestRating";
        break;
      case "userRating":
        return "filter__highestRating";
        break;
      case "lowPrice":
        return "filter__lowestPrice";
        break;
      case "highPrice":
        return "filter__highestPrice";
        break;
      default:
        return "filter__mostPopular";
    }
  };

  useEffect(() => {
    console.log('CHOSEN DATE', chosenDate)
  }, [chosenDate])

  return (
    <React.Fragment>
      <SelectDate
        desktop={props.desktop}
        available_date={props.available_date}
        todayNotAvailable={props.todayNotAvailable}
        setExternalSubmit={setExternalSubmit}
        handleFiltersOpen={handleFiltersOpen}
        chosenDate={chosenDate}
        setChosenDate={setChosenDate}
      />
      {/* Date selection dialog end */}
      <div style={{height: 140, width: "100%"}}></div>

    {/* <div style={{ flex: "none", width: "100%" }}>
      <div
        className="modal fade"
        id="order_filters_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="order_filters_modal"
        aria-hidden=""
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={orderFilterStyles}>
            <div className="modal-header">
              <h5
                className="modal-title text-center"
                id="exampleModalLongTitle"
              >
                {"Ordenar"}
              </h5>
              <div
                type="button"
                className="text-right"
                style={modalStyles}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i className="fa fa-times "></i>
                </span>
              </div>
            </div>

            <form onChange={(ev) => changeOrderBy(ev)}>
              <br></br>
              <input
                type="radio"
                value="lowPrice"
                id="lowPrice"
                name="order"
              />{" "}
              <label htmlFor="lowPrice">
                &nbsp; 💶 {I18n.t("filter__lowestPrice")}
              </label>
              <br></br>
              <input
                type="radio"
                value="highPrice"
                id="highPrice"
                name="order"
              />{" "}
              <label htmlFor="highPrice">
                &nbsp; 💶 {I18n.t("filter__highestPrice")}
              </label>
              <br></br>
              <input
                type="radio"
                value="lowestUserRating"
                id="lowestUserRating"
                name="order"
              />{" "}
              <label htmlFor="lowestUserRating">
                &nbsp; ✍️ {I18n.t("filter__lowestRating")}
              </label>
              <br></br>
              <input
                type="radio"
                value="userRating"
                id="userRating"
                name="order"
              />{" "}
              <label htmlFor="userRating">
                &nbsp; ✍️ {I18n.t("filter__highestRating")}
              </label>
              <br></br>
              <input
                type="radio"
                value="mostPopular"
                id="mostPopular"
                name="order"
              />{" "}
              <label htmlFor="mostPopular">
                &nbsp; 😍 {I18n.t("filter__mostPopular")}
              </label>
              <br></br>
              <input
                type="radio"
                value="mostRecent"
                id="mostRecent"
                name="order"
              />{" "}
              <label htmlFor="mostRecent">
                &nbsp; 🆕 {I18n.t("filter__mostRecent")}
              </label>
              <br />
              <br />
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={sendOrderBy}
                >
                  {I18n.t("filterBtn__inbtn")}{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}


      {props.brand ? null : <FiltersModal
        open={filtersData.open}
        alreadyFiltersResults={false}
        hb={props.hb}
        area={props.area}
        city={props.city}
        products={props.products}
        available_date={chosenDate}
        price_low={props.price_low}
        price_high={props.price_high}
        wallet_friendly={props.wallet_friendly}
        accepts_kids={props.accepts_kids}
        adults_only={props.adults_only}
        all_inclusive={props.all_inclusive}
        is_water_park={props.is_water_park}
        is_spa={props.is_spa}
        categories={props.categories}
        fullscreen={false}
        filterrific_params={props.filterrific_params}
        location={props.location}
        onClose={handleFiltersClose}
        source={props.source}
        product_category={props.product_category}
        adults={props.adults}
        kids={props.kids}
        locale={props.current_locale}
        initial_product_param={props.initial_product_param}
        externalSubmit={externalSubmit}
      />}

    <div id="roomlist__filterBar" style={{width: "100vw"}} className="panel panel-default">
        <div
          className={"no-padding"}
          style={stickyStyles}
        >
          <br />
          <div
            className={clsx(
              "container-fluid",
              "no-padding"
            )}
          >
            <div className="container">
              <div className="col-md-8">
                <h1
                  className={clsx("result_title__roomlist nodisplay_under768", classes.headings)}
                  style={textStyles}
                  dangerouslySetInnerHTML={{ __html: props.text_list }}
                ></h1>
              </div>

              {/* <div style={btnFilterWrapStyles}>
                <div
                  className="btn btn-nude"
                  style={btnWithMarginStyles}
                  data-toggle="modal"
                  data-target="#order_filters_modal"
                >
                  <FontAwesomeIcon icon={faArrowDownShortWide} />{" "}
                  {I18n.t(usedFilter())}{" "}
                </div>
                {props.brand ? null : <div
                  className="btn btn-nude"
                  style={btnStyles}
                  onClick={handleFiltersOpen}
                >
                  <FontAwesomeIcon icon={faFilter} />{" "}
                  {props.hotel_search__filter}{" "}
                  <span
                    style={filterStyles}
                    className="badge badge-pill badge-danger"
                  >
                    {props.count_filters}
                  </span>
                </div>
                }
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FilterBar;
