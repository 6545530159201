import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import I18n from "../../../../i18n-js/index.js.erb";


function AnalyticsPoper(props) {

  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'fixed',
      left: '20px',
      bottom: '20px',
      borderRadius: '5px',
      boxShadow: '0 0 4px rgba(0,0,0,0.5)',
      display: 'flex',
      width: '250px',
      height: 'auto',
      zIndex: '1000',
      background: "#F7EB67",
      padding: '15px 15px 15px 10px',
    },
    icon: {
      maxWidth: '25%',
      margin: 'auto',
      textAlign: 'center',
      fontSize: '20px',
    },
    content: {
      width: '75%',
      fontWeight: 800,
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        {props.icon}
      </div>
      <div className={classes.content}>
        {I18n.t(`${props.content}ViewCount`, {count: props.count})}
      </div>
    </div>
  )
}

export default AnalyticsPoper;