import React from "react";
import I18n from "../../../../i18n-js/index.js.erb";

export default function CategoryTag(props) {

  const allowedCategories = ['Night', 'Spa', 'Breakfast', 'Brunch', 'Lunch', 'Dinner', 'Massage', 'Yoga', 'Daypass']


  const getTranslation = (name) => {
    return I18n.t(`category_tag.${name.toLowerCase()}`)
  }

  const style = {
    position: "relative",
    display: 'inline-block',
    top: "-15px",
    left: '0',
    marginBottom: '6px'
  }

  return (
    allowedCategories.includes(props.name) &&
      <span className="category night" style={style}>{props.icon} {getTranslation(props.name)}</span>
  )

}