import React, {useEffect, useState} from "react";
import NavDesktop from "../main/nav/NavDesktop";
import NavMobile from "../main/nav/NavMobile";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import I18n from "../../i18n-js/index.js.erb";
import {
  addCSRFToken,
  setCurrentUser,
  changeLocale,
  changeSelectedArea,
  setAreaHomeProducts,
  changeSelectedAreaName,
  setAreaHomeHotelCategories,
} from "../redux/actions/actions";
import { createMedia } from "@artsy/fresnel";
import UserLoginWrap from "../main/components/modals/UserLogin/UserLoginWrap";
import clsx from "clsx";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { capitalizeFirstLetter, to_eur } from "../../helpers/base";
const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    lg: 1024,
  },
});


export default function Reviews(props) {
  const dispatch = useDispatch();
  axios.defaults.headers.common["X-CSRF-Token"] = props.csrf_token;
  axios.defaults.withCredentials = true;

  dispatch(addCSRFToken(props.csrf_token));
  dispatch(changeLocale(props.locale));

  moment.locale(props.locale);


  const selectedAreaName = useSelector((state) => state.areaName);
  const currentLocale = useSelector((state) => state.currentLocale);
  const selectedArea = useSelector((state) => state.area);
  const [currentPage, setCurrentPage] = useState(1);
  const [allReviews, setAllReviews] = useState(props.reviews.data);
  const [totalReviews, setTotalReviews] = useState(props.totalReviews);
  const [loader, setLoader] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    document.title = I18n.t("reviews_title");
    setReady(true)
  }, [])

  I18n.locale = currentLocale;

  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet,
      }),
      changeLocale(props.locale)
    );
  } else {
    changeLocale(props.locale);
  }

  const handlePageChange = (ev) => {
    setLoader(true);
    var pageNumber = ev.target.textContent;
    axios
      .get(`/reviews`, {
        params: {page_number: pageNumber}
      })
      .catch((error) => {
        console.log("error", error)
      })
      .then((response) => {
        console.log("response", response)
        setAllReviews(response.data.reviews.data)
        setLoader(false);
      })
  }

  useEffect(() => {
    if ((props.area || props.province) && selectedArea) {
      dispatch(changeSelectedArea(selectedArea));
      var location_params = selectedArea;
    } else if ((props.area || props.province) && !selectedArea) {
      dispatch(changeSelectedArea(props.area));
      var location_params = props.area;
    }
    axios
      .get("/api/main/products", {
        params: { location: location_params, locale: currentLocale },
      })
      .then((response) => {
        dispatch(
          setAreaHomeProducts(
            response.data.area_home_products
              .map((product_list) => {
                return {
                  category: product_list.products.map(
                    (productSerialization) => productSerialization.attributes
                  ),
                  title: product_list.title,
                  slug: product_list.slug,
                  emoji: product_list.emoji,
                  popularity: product_list.popularity,
                  type: product_list.type,
                };
              })
              .sort((a, b) => {
                if (a.popularity > b.popularity) {
                  return -1;
                } else if (
                  a.popularity === "break_friday" ||
                  a.popularity === "most_popular" ||
                  a.popularity === "most_recent"
                ) {
                  return -1;
                } else {
                  return 1;
                }
              })
          )
        );
        dispatch(setAreaHomeHotelCategories(response.data.rooms_categories));
        dispatch(changeSelectedAreaName(response.data.province));
      });
  }, [selectedArea]);

  const reviewsList = () => {
    return(
      <div className="container">
        <div className="row" style={{marginBottom: "20px"}}>
          <h1 style={{marginLeft: "15px", marginRight: "15px"}}>{I18n.t("reviews_title")} <small>{I18n.t("review", {count: props.totalReviews})}</small></h1>
          <div className="col-md-12 col-xs-12">
            {allReviews && allReviews.length > 1 && !loader &&
              allReviews.map(review =>
                <React.Fragment>
                  <a
                    href={`https://www.hotelbreak.com/${currentLocale}/${review.attributes.hotelArea}/hotels/${review.attributes.hotelSlug}`}
                    target="_blank"
                    style={{color: 'black'}}
                    title={review.attributes.hotelName}
                  >
                    <h2 style={{fontSize: '20px'}}>
                      {review.attributes.hotelName}
                      <span style={{float: 'right'}}>{to_eur(review.attributes.note)}/5</span>
                    </h2>
                    </a>
                  <strong style={{fontSize: '18px'}}>{moment(review.attributes.createdAt).format('LL')} - {capitalizeFirstLetter(review.attributes.userFirstname)}</strong>
                  <p style={{fontSize: '16px'}}>{review.attributes.description}</p>
                  <br />
                  <a
                    href={`https://www.hotelbreak.com/${currentLocale}/${review.attributes.hotelArea}/hotels/${review.attributes.hotelSlug}`}
                    target="_blank"
                  >
                    {I18n.t("view_all_reviews", {count: review.attributes.hotelReviewCount})}
                  </a>
                  <hr />
                </React.Fragment>
              )
            }
          </div>
          <div className="col-md-12 col-xs-12">
            <div
              className="text-center"
              style={{
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={Math.ceil(totalReviews / 40)}
                color="primary"
                onChange={(ev) => handlePageChange(ev)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <MediaContextProvider>
      <UserLoginWrap baseUrl={props.baseUrl} />
      <Media at="sm">
        <NavMobile areaName={selectedAreaName} />
        {reviewsList()}
      </Media>
      <Media greaterThanOrEqual="lg">
        <NavDesktop areaName={selectedAreaName} />
        {reviewsList()}
      </Media>
    </MediaContextProvider>
  )
}
