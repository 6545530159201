import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'

function SkeletonSlideProductBlock (props) {

  const useStyles = makeStyles((theme) => ({
    root: {
      overflow: 'hidden',
      margin: '20px 0 0',
      padding: '0 10px'
    },
    gridList: {
      flexWrap: 'nowrap'
    },
    title: {
      paddingLeft: '10px',
      marginLeft: props.device == 'desktop' ? '5%' : ""
    },
    link: {
      fontWeight: '800',
      color: 'black',
      textDecoration: 'none',
      padding: '0 10px',
      marginTop: '-15px',
      fontFamily: 'Futura, Didact Gothic, sans-serif'
    },
    gridWrapper: {
      width: '100%',
      height: '350px',
      overflow: 'hidden',
      position: 'relative',
      marginRight: '0px'
    },
    firstListElem: {
      marginLeft: '5% !important'
    }
  }));

  const classes = useStyles();

  const gridListTile = [];

  let i = 0;

  gridListTile.push(
    <ImageListItem key={0} className={props.device == 'desktop' && classes.firstListElem}>
      <Skeleton variant="rect" width="100%" height={200} />
      <Skeleton variant="text" />
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="text" width="40%" />
      <Skeleton variant="text" width="60%" />
    </ImageListItem>
  )

  for (i=1; i<6; i++) {
    gridListTile.push(
      <ImageListItem key={i}>
        <Skeleton variant="rect" width="100%" height={200} />
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="60%" />
      </ImageListItem>
    )
  }

  return (
    <div>
      <div className={classes.root}>
        <h5 className={classes.title}><Skeleton variant="text" width="30%"/></h5>
        <div className={classes.gridWrapper} >
          <ImageList className={classes.gridList} cols={props.listCols} rowHeight='auto'>      
            {gridListTile}
          </ImageList>
        </div>
      </div>
    </div>
  )

}

export default SkeletonSlideProductBlock;