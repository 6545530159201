import React, { useEffect, useState } from "react";
import Summary from "./Summary";
import PaidSummary from "./PaidSummary";
import UserStep from "./UserStep";
import { Row } from "reactstrap";
import I18n from "../../i18n-js/index.js.erb";
import HotelBreakLoader from "../main/components/loaders/hotelBreakLoader";
import {
  setPaymentData,
  changeLocale,
  addCSRFToken,
  setCurrentUser,
} from "../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import clsx from "clsx";
import PaymentWrap from "./PaymentWrap";
import WidgetNav from "../widget/WidgetNav";
import WidgetNavMobile from "../widget/WidgetNavMobile";
import WidgetFooter from "../widget/WidgetFooter";
import Confirmation from "./Confirmation";
import { useMediaQuery } from "../hooks/useMediaQuery";
import NavMobile from "../main/nav/NavMobile";
import NavDesktop from "../main/nav/NavDesktop";
import TravelAgentUserStep from "./TravelAgentUserStep";
import AnalyticsPoper from "./../main/components/popups/AnalyticsPoper";
import UserBillingStep from "./UserBillingStep";
import { useCookies } from "react-cookie";

let wrapStyle = {
  margin: "40px 0 0",
};

const reservation_code = new URLSearchParams(window.location.search).get(
  "reservation_code"
);

function Main(props) {
  const BASE_URL = props.baseUrl || '';
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const [cookies, setCookie] = useCookies(['jwt']);
  const [viewCount, setViewCount] = useState(false)
  dispatch(addCSRFToken(props.csrf_token));
  const isMobile = useMediaQuery("(max-width: 764px)");
  const FunnelData = useSelector((state) => state.PaymentData);
  const currentLocale = useSelector((state) => state.currentLocale);
  const currentUser = useSelector((state) => state.currentUser);
  const FORM_SOURCE = "Reservation start payment funnel";
  axios.defaults.headers.common["X-CSRF-Token"] = props.csrf_token;
  axios.defaults.withCredentials = true;

  const brand = props.brand;

  I18n.locale = currentLocale || props.locale;

  useEffect(() => {
    const WebFont = require("webfontloader");
    brand?.data?.attributes?.font &&
      WebFont.load({
        google: {
          families: [brand?.data?.attributes?.font, "sans-serif"],
        },
      });
  }, [brand?.data?.attributes?.font]);

  useEffect(() => {
    !viewCount &&
      axios
        .get(`${BASE_URL}/api/main/tracking/analytics`, { params: { event_type: "Funnel view" } })
        .then((response) => {
          setViewCount(response.data.count)
          hideAnalyticsPoper();
        });
  }, [ready])

  const hideAnalyticsPoper = () => {
    const timer = setTimeout(() => {
      setViewCount(0);
    }, 10000);
    return () => clearTimeout(timer);
  }

  var showPayment =
    currentUser?.loggedIn &&
    currentUser?.isInformationComplete.status &&
    !currentUser?.isGuest &&
    !props.paid;

  const analyticsTrack = (eventType, inputHash) => {
    analytics.track(eventType, { inputHash, form: FORM_SOURCE });
  };

  const setFunnelData = () => {
    axios
      .get(`${BASE_URL}/api/main/funnel/summaries/${reservation_code}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setPaymentData({
              ...FunnelData,
              ProductData: response.data.product.data.attributes,
              ReservationData: response.data.reservation.data.attributes,
              PaymentData: response.data.payment_data,
              old_PaymentData: response.data.original_payment_data,
              wallet: response.data.wallet,
              is_referral_active: response.data.is_referral_active,
              intent: props.intent,
              clientSecret: response.data.client_secret,
              PaymentOption: response.data.payment_option,
              is_hotelbreak_direct: response.data.is_hotelbreak_direct,
              disable_gift_voucher:
                brand?.data !== null
                  ? true
                  : response.data.disable_gift_voucher,
              scheduled: response.data.scheduled,
              firstLoad: true,
              dispatch_id: "main60",
              area: response.data.area,
              user_billing_info: response.data.user_billing_info,
              billable: response.data.billable,
              club: response.data.club,
              remaining_discount: response.data.remaining_discount
            })
          );
          setReady(true);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    analyticsTrack("Enter reservation payment funnel");
    dispatch(changeLocale(props.locale));
    dispatch(addCSRFToken(props.csrf_token));

    if (currentUser.loggedIn === false) {
      dispatch(setCurrentUser(props.current_user.data.attributes));
    }

    setFunnelData();
  }, [currentUser]);

  useEffect(() => {
    if (cookies["jwt"] && !currentUser?.id) {
      axios
        .get(
          `${BASE_URL}/api/v2/registrations/new`, {
          headers: {
            Authorization: `Bearer ${cookies['jwt']}`
          }
        }
        )
        .then((response) => {
          dispatch(setCurrentUser({
            ...response.data.current_user.data.attributes,
            wallet: response.data.wallet
          }));
        }).catch(function (error) {
          console.log("error 326", error)
        });
    }
  }, []);

  return (
    <React.Fragment>
      {brand && brand.data != null ? (
        isMobile ? (
          <WidgetNavMobile brand={brand?.data?.attributes} location="funnel" />
        ) : (
          <WidgetNav brand={brand?.data?.attributes} location="funnel" />
        )
      ) : (isMobile ? (
        <NavMobile pay="true" paid={props.paid} />
      ) : (
        <NavDesktop pay="true" paid={props.paid} />
      )) && viewCount != false &&
      viewCount > 10 && !props.paid &&
      <AnalyticsPoper icon={"🎟️"} content={"funnel"} count={viewCount} />
      }
      <div
        id="pay__transaction_container"
        className={clsx(
          "pay__transaction_container",
          !FunnelData.loading && "displaynone"
        )}
      >
        <div className="row transaction_loading__content">
          <HotelBreakLoader />

          <div className="col-md-8 col-md-offset-2 text-center">
            <h3 className="margin-top-60">{I18n.t("charge_loading_title")}</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <Row style={wrapStyle}>
          {currentUser.role !== "travel_agent" &&
            !showPayment &&
            !props.paid && <UserStep {...props} />}

          {currentUser.role !== "travel_agent" &&
            showPayment &&
            FunnelData.billable == false &&
            FunnelData.user_billing_info == true &&
            <UserBillingStep {...props} />
          }

          {(currentUser.clientUserDataUp ||
            (currentUser.role !== "travel_agent" &&
              showPayment &&
              (FunnelData.user_billing_info == false || FunnelData.billable == true))) && (
              <PaymentWrap {...props} />
            )}

          {currentUser.role === "travel_agent" &&
            !currentUser.clientUserDataUp && (
              <TravelAgentUserStep {...props} />
            )}

          {props.paid && <Confirmation {...props} />}

          {!props.paid && (<div className="col-md-5">
            <Summary {...props} />
          </div>)}

          {props.paid && (<div className="col-md-5">
            <PaidSummary {...props} />
          </div>)}


        </Row>
      </div>
      <WidgetFooter
        brand={brand && brand?.data !== null && brand?.data?.attributes}
      />
    </React.Fragment>
  );
}

export default Main;
