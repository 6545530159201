import React from "react";
import { useEffect } from "react";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import { makeStyles } from "@material-ui/core/styles";
import { createMedia } from "@artsy/fresnel";
import I18n from "../../../i18n-js/index.js.erb";
import Walktrough from "./Walkthrough";
import WalkthroughReorder from "./WalkthroughReorder";
import { useSelector, useDispatch } from "react-redux";
import UserLoginWrap from "../components/modals/UserLogin/UserLoginWrap";
import clsx from "clsx";
import { Cookies, useCookies } from "react-cookie";

function GiftVoucherFunnel(props) {
  const useStyles = makeStyles((theme) => ({
    slidingPane: {
      marginTop: "250px",
      paddingTop: "5px",
      backgroundColor: "white",
      width: "100%",
      height: "fit-content",
      position: "relative",
      zIndex: "1",
    },
    root: {
      backgroundColor: "#F5F5F5",
    },
    filtered: {
      filter: 'blur(5px)',
    }
  }));

  const currentLocale = useSelector(
    (state) => state.currentLocale
  );

  const [cookies, setCookie] = useCookies(['variant'])

  I18n.locale = currentLocale

  const currentUser = useSelector(
    (state) => state.currentUser
  );

  const classes = useStyles();

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  useEffect(() => {
    document.title = `${I18n.t('gift_voucher')} | hotelbreak`
  }, [])

  const correctWalkthrough = () => {
    return cookies["variant"] == 1 ?
      <WalkthroughReorder {...props} />
    :
      <Walktrough {...props} />
  }

  return (
    <div className={clsx(classes.root)}>
      <MediaContextProvider>
        <Media at="sm">
          <HeaderMobile text={I18n.t('giftbanner__main')} />
          <div className={classes.slidingPane}>
            {correctWalkthrough()}
          </div>
        </Media>
        <Media at="md">
          <HeaderMobile text={I18n.t('giftbanner__main')} />
          <div className={classes.slidingPane}>
            {correctWalkthrough()}
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <Header text={I18n.t('giftbanner__main')} />
          <div className={classes.slidingPane}>
            {correctWalkthrough()}
          </div>
        </Media>
      </MediaContextProvider>

      <UserLoginWrap {...props} />
    </div>
  );
}

export default GiftVoucherFunnel;
