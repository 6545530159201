import React from "react";
import AddFavorite from "./addfavorite";
import { add_margin } from "../helpers/app/prices";
import LazyImage from "./utilities/lazyload";
import Rectangle from "images/Rectangle.png";
import { useState } from "react";
import clsx from "clsx";
import I18n from "../i18n-js/index.js.erb";
import { useSelector } from "react-redux";
import {capitalizeFirstLetter, timizeDistance} from "../helpers/base"
import slugify from "react-slugify";

let rowStyles = {
  margin: "0 0 0 0",
};

let colStyles = {
  color: "grey",
  padding: "15px 0 0 0",
};

let productCat = {
  fontWeight: "1000 !important",
  color: "rgb(45,45,45)",
  fontSize: "18px",
};

let priceStyle = {
  display: "block",
  margin: "0 10px 0 0",
  fontWeight: "800",
  color: "rgb(45,45,45)",
};

let locationStyle = {
  fontSize: "14px",
};

let lazyImgStyles = {
  width: "100%",
  borderRadius: "10px",
  display: "inline",
  backgroundImage:
    "url(&quot;//s3-us-east-2.amazonaws.com/testmaxime/photos/images/000/000/630/croppable/65FF652D-BAD2-41EE-91C1-071785310675.jpeg?1563835390&quot;)",
};

let notAvailableStyles = {
  display: "block",
  margin: "0 10px 0 0",
  fontWeight: "800",
};

let blockWrap = {
  marginBottom: "20px",
};


function RoomBlock(props) {
  const [locale, setLocale] = useState(props.current_locale);
  const selectedArea = capitalizeFirstLetter(
    useSelector((state) => state.area)
  );

  const setNewInterfaceURL = (area, locale, slug) => {
    let url = `/${locale}/${slugify(area.toLowerCase())}/hotels/${slug}`

    return url
  };

  const newButtonStyles = props.brand ? {
    backgroundColor: props.brand.main_color,
    color: props.brand.secondary_color,
    fontFamily: props.brand.font,
    bottom: '10px',
    top: 'unset',
    borderRadius: '20px',
    fontSize: '10px',
    lineHeight: '20px',
    fontWeight: 1000
  } : {};

  return (
    <div>
      <div
        className={
          props.source === "hotelSlide"
            ? clsx(
                "col-md-12",
                "no-padding-6-l",
                "col-xs-12",
                "hotel-card",
                props.is_closed_today && "greyed"
              )
            : clsx(
                "col-md-6",
                "col-xs-12",
                "hotel-card",
                props.is_closed_today && "greyed"
              )
        }
        style={blockWrap}
        id={`${props.roomid}`}
        onMouseEnter={() => props.hoveredHotelFunc(props.roomid)}
        onMouseLeave={() => props.hoveredHotelFunc(undefined)}
      >
        <a
          href={setNewInterfaceURL(props.area, locale, props.slug)}
          className="toRoom"
          alt={props.listing_name}
          title={props.listing_name}
          data-id={props.slug}
          data-price={props.price}
          data-name={props.listing_name}
          data-position={props.index}
        >
          <div className="row result-hotel no-gutters" style={rowStyles}>
            <div className="col-md-12">
              {props.category && (
                <span className={`category ${props.category}`}>
                  {typeof props.category === "string" && I18n.t(props.category)}
                </span>
              )}
              {props.is_new && <span className={"category new"} style={newButtonStyles} >NEW</span>}
              {props.direct_booking && !props.is_closed_today && (
                <span className="category direct_booking">
                  <i className="fa fa-bolt"></i> {I18n.t("direct_booking")}{" "}
                </span>
              )}
              {props.block_photo && (
                <LazyImage
                  src={`${props.block_photo}`}
                  lqip={Rectangle}
                  alt={props.listing_name}
                  lazyImgStyles={lazyImgStyles}
                />
              )}
            </div>

            <div className="col-md-12" style={colStyles}>
              <div className="col-md-8 col-xs-8 no-padding">
                {props.city && props.location && (
                  <span style={locationStyle}>
                    {props.city.toUpperCase()} {(props.timeDistance && props.timeDistance != 0 && props.location) ?
                                                        <span>{timizeDistance(props.timeDistance, props.location, props.city)}</span>
                                                        :
                                                        ""
                                                      }
                  </span>
                )}
              </div>

              {props.review_note > 0 && props.review_note > 300 && (
                <div className="col-md-4 col-xs-4 no-padding">
                  <span className="note">
                    {(props.review_note / 100).toFixed(2)}
                    <small>&nbsp;({props.reviews_count})</small>
                  </span>
                </div>
              )}

              <div className="col-md-12 col-xs-12 no-padding product_cat">
                <span style={productCat}>
                  {props.listing_name}

                  <small>
                    &nbsp;
                    {props.star_rating && "*".repeat(props.star_rating)}
                  </small>
                </span>
                <br></br>

                {props.product_categories.join(
                  " • "
                )}
              </div>

              <div className="col-md-12 col-xs-12 no-padding">
                <p
                  className={clsx(props.is_closed_today && "displaynone")}
                  style={priceStyle}
                >
                  {props.price_model === "net" &&
                    I18n.t("price_from", {
                      currency: props.currency,
                      price: add_margin(props.price, props.margin),
                    })}
                  {props.price_model === "pvp" &&
                    I18n.t("price_from", {
                      currency: props.currency,
                      price: props.price,
                    })}
                  &nbsp;
                  {props.min_price_max_guest == 1 && I18n.t("per_person")}
                  {props.min_price_max_guest > 1 &&
                    I18n.t("for_various_number", {
                      number: props.min_price_max_guest,
                    })}
                </p>

                <p
                  className={clsx(!props.is_closed_today && "displaynone")}
                  style={notAvailableStyles}
                >
                  {I18n.t("not_available")}
                </p>
              </div>
            </div>
          </div>
          {props.favoriteids && !props.widget && (
            <AddFavorite
              slug={props.slug}
              favoriteids={props.favoriteids}
              roomid={props.roomid}
              current_user={props.current_user}
              csrf_token={props.csrf_token}
            />
          )}
        </a>
      </div>
    </div>
  );
}

export default RoomBlock;
