import React from "react";
import { useState, useEffect } from "react";
import ProductBlockFull from "../main/products/ProductBlockFull";
import { currencyToSymbol, to_eur } from "../../helpers/base.js";
import I18n from "../../i18n-js/index.js.erb";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Emoji from "a11y-react-emoji";
import slugify from "react-slugify";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Fab from "@material-ui/core/Fab";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import clsx from "clsx";
import moment from "moment";

function WidgetSlideProductBlock(props) {
  const [hasLeftScroll, setHasLeftScroll] = useState(false);
  const [hasRightScroll, setHasRightScroll] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      overflow: "hidden",
      margin: "20px 0 0",
      padding: "0 10px",
    },
    headings: {
      fontFamily:  props.brand?.font ? props.brand?.font : 'inherit',
    },
    gridList: {
      flexWrap: "nowrap",
      bottom: "-29px",
      right: "-20px",
      position: "static",
      overflowY: "auto",
      minHeight: '380px'
    },
    title: {
      paddingLeft: "10px",
      width: "100%",
      marginLeft: props.device == "desktop" ? "5% !important" : "",
    },
    link: {
      fontWeight: "800",
      color: "black",
      textDecoration: "none",
      padding: "0 10px",
      marginTop: "10px",
      fontFamily: "Futura, Didact Gothic, sans-serif",
    },
    gridWrapper: {
      width: "100%",
      height: "420px",
      overflow: "hidden",
      position: "relative",
      marginRight: "0px",
    },
    buttonLeft: {
      position: "absolute",
      marginTop: "9rem",
      marginLeft: "20px",
      zIndex: "10",
      background: "white",
    },
    buttonRight: {
      position: "absolute",
      zIndex: "10",
      right: "10px",
      background: "white",
      marginTop: "9rem",
      marginRight: "10px",
    },
    viewAll: {
      height: "20rem",
      width: "100%",
      borderRadius: "10px",
      objectFit: "cover",
      border: "1px solid darkgrey",
      background: "white",
    },
    viewAllText: {
      bottom: "-16rem",
      left: "20px",
      position: "relative",
      "&:hover": {
        textDecoration: "none !important",
      },
    },
    link: {
      color: "black",
      "&:hover": {
        textDecoration: "none !important",
      },
    },
    over: {
      zIndex: 11,
    },
    categoryViewAll: {
      float: "right",
      display: "inline-block",
      fontSize: "1.4rem",
      marginTop: "5px",
    },
    firstListElem: {
      marginLeft: "5% !important",
    }
  }));

  const classes = useStyles();

  const scrollRight = () => {
    $(`#gridListScroll-${props.selector}`).animate(
      {
        scrollLeft: "+=400px",
      },
      "slow"
    );
    setHasLeftScroll(true);
    if (
      $(`#gridListScroll-${props.selector}`).get(0).scrollWidth -
        $(`#gridListScroll-${props.selector}`).scrollLeft() -
        $(`#gridListScroll-${props.selector}`).width() -
        400 <=
      0
    ) {
      setHasRightScroll(false);
    }
  };

  const scrollLeft = () => {
    $(`#gridListScroll-${props.selector}`).animate(
      {
        scrollLeft: "-=400px",
      },
      "slow"
    );
    setHasRightScroll(true);
    if ($(`#gridListScroll-${props.selector}`).scrollLeft() - 400 <= 0) {
      setHasLeftScroll(false);
    }
  };

  const selectAdults = (adults) => {
    if (adults && adults !== false && adults !== 0) {
      return "&adults=" + adults;
    } else {
      return "";
    }
  };

  const selectKids = (kids) => {
    if (kids && kids !== false && kids !== 0) {
      return "&kids=" + kids;
    } else {
      return "";
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <h3 className={clsx(classes.title, classes.headings)}>
          {props.title}
        </h3>
        <div className={classes.gridWrapper}>
          {hasLeftScroll && (
            <Fab
              color="white"
              aria-label="add"
              className={classes.buttonLeft}
              onClick={scrollLeft}
            >
              <ArrowLeftIcon />
            </Fab>
          )}
          {hasRightScroll &&
            props.products !== undefined &&
            props.products.length > props.listCols && (
              <Fab
                color="white"
                aria-label="add"
                className={classes.buttonRight}
                onClick={scrollRight}
              >
                <ArrowRightIcon />
              </Fab>
            )}
          <ImageList
            className={classes.gridList}
            cols={props.listCols}
            rowHeight="auto"
            id={`gridListScroll-${props.selector}`}
          >
            {props.products && props.products !== undefined &&
              props.products.map(function (product, index) {
                console.log('prod', product)
                return (
                  <ImageListItem
                    key={product.id}
                    className={clsx(
                      index == 0 &&
                        props.device == "desktop" &&
                        classes.firstListElem
                    )}
                  >
                    <ProductBlockFull
                      key={product.id}
                      id={product.id}
                      name={""}
                      block_photo={product.block_photo}
                      week_price={to_eur(product.lowest_price)}
                      listing_name={product.listing_name}
                      city={product.area.toUpperCase()}
                      area={product.area}
                      max_guest={product.min_price_max_guest}
                      price_model={product.price_model}
                      currency={currencyToSymbol(product.currency)}
                      roomSlug={product.roomSlug ? product.roomSlug : product.slug}
                      star_rating={product.star_rating}
                      categories={product.product_categories}
                      widget={true}
                      brand={props.brand}
                      is_new={moment(product.bookable_at || product.created_at) >
                              moment().subtract(2, "months")}
                      widgetFeatured={props.widgetFeatured}
                      has_calendar={product.has_calendar}
                    />
                  </ImageListItem>
                );
              })}
            {props.products !== undefined &&
              props.products.length > props.listCols &&
              props.show_all_link && (
                <ImageListItem
                  key={props.products.length + 1}
                  className={classes.over}
                >
                  <a
                    href={`/${props.locale}/${props.area}/${slug(
                      props.selector,
                      props.selectedDate,
                      selectAdults(props.selectedPax.adults),
                      selectKids(props.selectedPax.kids)
                    )}`}
                    target="_blank"
                    className={classes.link}
                  >
                    <div className={classes.viewAll}>
                      <p className={classes.viewAllText}>
                        {I18n.t("view_all")} <Emoji symbol="→" />
                      </p>
                    </div>
                  </a>
                </ImageListItem>
              )}
          </ImageList>
        </div>
        {props.link_text !== undefined && (
          <a
            href={props.link_destination}
            className="toRoom marginbottom"
            alt={props.link_text}
          >
            <p className={classes.link}>
              {props.link_text} <ArrowRightAltIcon />
            </p>
          </a>
        )}
      </div>
    </div>
  );
}

export default WidgetSlideProductBlock;
