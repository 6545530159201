import React from 'react';
import I18n from '../../../i18n-js/index.js.erb';

let guideBlockStyle = {
  margin: '0 0 20px'
};

let guideTitleStyle = {
  float: 'right !imporant',
  margin: '10px 0'
};

let lazyImgStyles = {
  'width': '100%',
  'borderRadius': '10px',
  'display': 'inline', 
  'backgroundImage': 'url(&quot;//s3-us-east-2.amazonaws.com/testmaxime/photos/images/000/000/630/croppable/65FF652D-BAD2-41EE-91C1-071785310675.jpeg?1563835390&quot;)',
}

const titleize = require('titleize');

function GuideBanner(props) {

		return( 
			<div>
        <div className="col-md-8 col-xs-12">
          <h4>{I18n.t('discover_guides_title', { area: titleize(props.area) })}</h4>
        </div>
        <div className="col-md-4 text-right desktop_only" >
          <a href={`/${props.locale}/mallorca/guias`} >
            <p className="guide-sidebar__title" style={guideTitleStyle}>
              {I18n.t('guides_show__sidebar_allguides_in', { area: titleize(props.area) })}
              {" >"} 
            </p>
          </a>
        </div>
        <div className="row">
          <div className="col-md-12">
          {props.guides.slice(0,3).map(function(guide) {
            return (
              <a href={`/${props.locale}/${props.area}/guias/${guide.slug}`}>
                <div className="col-md-4 col-xs-12" style={guideBlockStyle}>
                  <div className="guide-other__guide-img">
                    <span className="guide-other__guide-img-mark">{I18n.t('guidelist_guide')}</span>
                    <h4 className="guide-other__guide-img-title">{I18n.t('guide_show__title_in_short_html', { number: guide.selections.length, title: guide.title, area: titleize(props.area) })} </h4>
                    <img src={guide.block_photo} className='img-responsive-full gradient-effect' style={lazyImgStyles} />
                  </div>
                </div>
              </a>  
            )})
          }
          </div>
        </div>
        <a href="" className="mobile_only">
          <p className="guide-sidebar__title text-center">
            {I18n.t('guides_show__sidebar_allguides_in', { area: titleize(props.area) })}
          </p>
        </a>
      </div>
		)
}


export default GuideBanner;