import React from "react";
import I18n from "../../i18n-js/index.js.erb";
import { useSelector } from "react-redux";
import ReservationUserFormFunnel from "./components/users/ReservationUserFormFunnel";
import ReservationUpdateUser from "./components/users/ReservationUpdateUser";
import ReservationUserLog from "./components/users/ReservationUserLog";
import { useStylesPayment } from "./styles/paymentOptionsStyles";


function UserStep(props) {
  const classes = useStylesPayment(props);
  const FunnelData = useSelector((state) => state.PaymentData);
  const currentLocale = useSelector((state) => state.currentLocale);
  const currentUser = useSelector((state) => state.currentUser);

  I18n.locale = currentLocale || props.locale;

  return (
    <React.Fragment>
      <br />
      <div className="col-md-7 checkout_column">
        <h4 className={classes.headings}>
          <span className="number_step">1</span>&nbsp;&nbsp;{" "}
          {I18n.t("checkout_first_step")}
        </h4>
        {!currentUser.loggedIn && !FunnelData.log_in_form && (
          <ReservationUserFormFunnel {...props} />
        )}
        {!currentUser.loggedIn && FunnelData.log_in_form && (
          <ReservationUserLog {...props} />
        )}
        {currentUser.loggedIn && <ReservationUpdateUser {...props} />}
      </div>
    </React.Fragment>
  );
}

export default UserStep;
