import React from "react";
import { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHotel } from "@fortawesome/free-solid-svg-icons";
import Emoji from "a11y-react-emoji";
import I18n from "../../../../i18n-js/index.js.erb";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

function Maps(props) {
  const mapContainer = useRef();
  const map = useRef(null);
  const lng = parseFloat(props.long);
  const lat = parseFloat(props.lat);
  const zoom = 8;
  const [mapVisible, setMapVisble] = useState(false);
  const [mapInitiated, setMapInitiated] = useState(false);
  const [lastHoveredHotel, setLastHoveredhotel] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(
    typeof window !== "undefined" ? window.scrollY : 0
  );

  const setApiKey = () => {
    if (process.env.RAILS_ENV !== "production") {
      return "pk.eyJ1IjoibXJlbmF1ZGluIiwiYSI6ImNsMzMyMXdyczAzYnoza3A2MTNzb2ZiM2gifQ.JhkX3MfZjq34-s2e86FSWw";
    } else {
      return process.env.MAPBOX_KEY;
    }
  };

  let mapStyle = {
    display: mapVisible ? "block" : "none",
    height:
      props.device == "desktop" ? "calc(100vh - 40px)" : "calc(100vh - 60px)",
    width: props.device == "desktop" ? "35%" : "100%",
    zIndex: props.device == "mobile" && 100,
    top:
      props.device == "mobile"
        ? "50px"
        : scrollPosition > 100
        ? "20px"
        : "150px",
    position: props.device == "desktop" ? "sticky" : "fixed",
    right: 0,
    bottom: "20px",
    borderRadius: "0",
    marginTop: "15px",
    right: props.device == "desktop" ? "2.5%" : 0,
    order: 2,
  };

  const useStyles = makeStyles({
    viewMapBtn: {
      position: "fixed",
      bottom: "5vh",
      textAlign: "center",
      background: "#F7EB67",
      padding: "10px 20px",
      borderRadius: "0",
      left: 0,
      right: 0,
      width: "max-content",
      margin: "0 auto",
      zIndex: 101,
      fontSize: "16px",
      color: "black",
      fontWeight: 800,
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      "&:hover": {
        boxShadow: "4px 4px 0px black"
      }
    },
    popUp: {
      padding: "0px !important",
    },
  });

  useEffect(() => {
    const setScollPositionCallback = () => setScrollPosition(window.scrollY);

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", setScollPositionCallback);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", setScollPositionCallback);
      }
    };
  }, []);

  useEffect(() => {
    scrollPosition > 100 &&
      mapContainer &&
      mapContainer != undefined &&
      mapContainer.current.style.visibility === true &&
      map.current.resize();
  }, [scrollPosition]);

  const classes = useStyles(props);

  const showMap = () => {
    if (!mapInitiated) {
      initMap(setApiKey());
    }
    setMapVisble(!mapVisible);
    props.isMapShown(!mapVisible);
    map.current.resize();
  };

  const initMap = (token) => {
    if (map.current) {
      map.current.resize();
      return;
    }
    mapboxgl.accessToken = token;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
      trackResize: true,
      maxBounds: [
        [lng - 1, lat - 1],
        [lng + 1, lat + 1],
      ],
    });
    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();
    map.current &&
      props.geo &&
      map.current.on("load", () => {
        map.current.resize();
        map.current.addSource("hotels", {
          type: "geojson",
          data: props.geo,
          cluster: true,
          clusterMaxZoom: 14,
        });
        map.current.addLayer({
          id: "clusters",
          type: "circle",
          source: "hotels",
          filter: ["has", "point_count"],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              "#ffb400",
              "#337ab7",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              10,
              30,
              30,
              40,
            ],
          },
        });
        map.current.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "hotels",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
          paint: {
            "text-color": "#ffffff",
          },
        });
        map.current.addLayer({
          id: "hotels",
          type: "circle",
          source: "hotels",
          filter: ["!", ["has", "point_count"]],
          "text-field": ["get", "title"],
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 1.25],
          "text-anchor": "top",
          paint: {
            "circle-color": [
              "match",
              ["get", "category"],
              "Resort",
              "#337ab7",
              "City",
              "#ffb400",
              "Luxury",
              "#373852",
              "Rural",
              "#2cb866",
              "Boutique",
              "#eb6b2a",
              /* other */ "black",
            ],
            "circle-radius": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              15,
              10,
            ],
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
          },
        });
        map.current.on("click", "clusters", (e) => {
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });
          const clusterId = features[0].properties.cluster_id;
          map.current
            .getSource("hotels")
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              map.current.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              });
            });
        });
        let clusterId = null;
        map.current.on("mouseenter", "clusters", (e) => {
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });

          map.current.getCanvas().style.cursor = "pointer";

          if (clusterId) {
            map.current.removeFeatureState({
              source: "hotels",
              id: clusterId,
            });
          }

          clusterId = features[0].properties.cluster_id;

          map.current.setFeatureState(
            {
              source: "hotels",
              id: clusterId,
            },
            {
              hover: true,
            }
          );
        });
        map.current.on("mouseleave", "clusters", () => {
          map.current.getCanvas().style.cursor = "";
          if (clusterId) {
            map.current.removeFeatureState({
              source: "hotels",
              id: clusterId,
            });
          }
        });
        map.current.on("click", "hotels", (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const brand = e.features[0].properties.brand;
          const title = e.features[0].properties.title;
          const img = e.features[0].properties.photo;
          const products = e.features[0].properties.product_categories;
          const lowest_price = e.features[0].properties.lowest_price;
          const average_review = e.features[0].properties.average_review;
          const url = e.features[0].properties.url;
          const point = e.point;

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new mapboxgl.Popup({ className: classes.popUp })
            .setLngLat(coordinates)
            .setHTML(
              `
            <a target="_blank" href="${url}" alt="${title}" title="${title}" style="text-decoration: none; color: black;">
              <img src="${img}" style="width: 100%; height: 100px; padding: -15px; object-fit: cover;">
              <div style="padding: 10px 15px 0">
                🏨 <strong>${title}</strong><br>
                <i>${products}</i><br>
                ${I18n.t("from_price") + " " + to_eur(lowest_price)}€
              </div>
            </a>
            <br>
            <a target="_blank" href="${url}" alt="${title}" title="${title}" style="text-decoration: none; color: black;">
              <div class="btn btn-primary" style="width: 100%'>Ver hotel</div>
            </a>
            `
            )
            .addTo(map.current);
        });
        let hotelId = null;
        map.current.on("mouseenter", "hotels", (e) => {
          map.current.getCanvas().style.cursor = "pointer";

          if (hotelId) {
            map.current.removeFeatureState({
              source: "hotels",
              id: hotelId,
            });
          }

          hotelId = e.features[0].id;

          map.current.setFeatureState(
            {
              source: "hotels",
              id: hotelId,
            },
            {
              hover: true,
            }
          );
        });

        // Change it back to a pointer when it leaves.
        map.current.on("mouseleave", "hotels", () => {
          map.current.getCanvas().style.cursor = "";
          if (hotelId) {
            map.current.removeFeatureState({
              source: "hotels",
              id: hotelId,
            });
          }
        });
      });

      setMapInitiated(true)
  };

  useEffect(() => {
    if (map.current && map.current.loaded()) {
      if (props.hoveredHotel != undefined) {
        if (lastHoveredHotel && map.current) {
          map.current.removeFeatureState({
            source: "hotels",
            id: lastHoveredHotel,
          });
          setLastHoveredhotel(false);
        }

        setLastHoveredhotel(props.hoveredHotel);

        map.current.setFeatureState(
          {
            source: "hotels",
            id: props.hoveredHotel,
          },
          {
            hover: true,
          }
        );
      }
    }
  }, [props.hoveredHotel]);

  return (
    <React.Fragment>
      <div className={classes.viewMapBtn} onClick={() => showMap()}>
        {mapVisible ? (
          I18n.t("hide_map")
        ) : (
          <span>
            {I18n.t("view_map")} <Emoji symbol="🗺" />
          </span>
        )}
      </div>

      <div ref={mapContainer} style={mapStyle} />
    </React.Fragment>
  );
}

export default Maps;
