import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { capitalizeFirstLetter } from "../../../helpers/base";
import { useSelector, useDispatch } from "react-redux";
import I18n from "../../../i18n-js/index.js";
import Moment from "moment";
import { setFilterData } from "../../redux/actions/actions";
import FiltersModal from "../filters/FiltersModal";
import clsx from "clsx";
import axios from "axios";
import Search from "../filters/Search";
import { Skeleton } from "@mui/material";
import SelectDate from "../components/dialogs/SelectDate.js";

function AreaHeader(props) {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.selectedDate);
  const currentLocale = useSelector((state) => state.currentLocale);
  const filtersData = useSelector((state) => state.filtersData);
  const filtersResults = useSelector((state) => state.filtersResults);
  const [externalSubmit, setExternalSubmit] = useState(false);
  const [chosenDate, setChosenDate] = useState(null);

  I18n.locale = currentLocale;

  Moment.locale(currentLocale);

  const handleClickOpen = () => {
    dispatch(setFilterData({ ...filtersData, open: true }));
  };

  const handleClose = () => {
    dispatch(setFilterData({ ...filtersData, open: false }));
  };

  const useStyles = makeStyles((theme) => ({
    header: {
      backgroundColor: "#f7eb67",
      position: "relative",
      width: "100%",
      height: "350px",
      padding: "20px 6vw",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50vw 50%",
      backgroundSize: "cover",
    },
    logoBlock: {
      backdropFilter: "blur(3px)",
      color: "white",
      backgroundColor: "rgba(255,255,255,1)",
      padding: "10px 20px",
      borderRadius: "20px",
      display: "inline-block",
      margin: "10px 0 0 20px",
    },
    logo: {
      width: "120px",
    },
    titleHeader: {
      margin: "60px 0 30px",
      fontSize: "30px",
      paddingBottom: "10px",
      fontWeight: "400 !important",
      display: "block",
      borderRadius: "40px",
      width: "fit-content",
      color: "black",
    },
    dateButton: {
      position: "absolute",
      top: "190px",
      left: "5%",
      marginLeft: "10px",
      width: () => (selectedDate ? "190px" : "135px"),
      borderRadius: "20px",
      fontSize: "14px",
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 500,
      height: "36.5px",
    },
    paxButton: {
      position: "absolute",
      top: "190px",
      left: () =>
        selectedDate ? "calc(190px + 6vw + 10px)" : "calc(135px + 6vw + 10px)",
      borderRadius: "20px",
      fontSize: "14px",
      fontFamily: "Open Sans",
    },
    closeButton: {
      float: "right",
      marginTop: "-10px;",
    },
    buttonText: {
      color: "black",
      fontSize: "16px",
    },
    secondaryHeroText: {
      fontFamily: "TobiasTRIAL-Regular",
      fontSize: "18px",
      maxWidth: "90%",
      marginBottom: "30px",
    },
    linkText: {
      fontWeight: "800 !important",
      fontSize: "14px",
      marginBottom: "20px",
      borderRadius: 0,
      textTransform: "none !important",
    },
  }));

  const classes = useStyles();

  return (
    <div
      className={classes.header}
      style={{ backgroundImage: `url(${props.image})` }}
    >
      {props.areaName ? (
        <>
          <h1 className={classes.titleHeader}>
            Enjoy {capitalizeFirstLetter(props.areaName)}
          </h1>
          <p className={classes.secondaryHeroText} style={{ maxWidth: "40%" }}>
            {I18n.t("area_home.header_text", {
              city: capitalizeFirstLetter(props.areaName),
            })}
          </p>
        </>
      ) : (
        <>
          <h1 className={classes.titleHeader}>
            <Skeleton variant="text" width={300} />
          </h1>
          <Skeleton variant="text" width={300} />
          <Skeleton variant="text" width={250} />
          <Skeleton variant="text" width={280} />
        </>
      )}

      <div className="col-md-6 no-padding">
      {filtersResults && filtersResults != undefined ? (
        <React.Fragment>
          <div className="col-md-7 no-padding">
              <Search results={filtersResults} mobile_header={false} />
          </div>
          <div className="col-md-5 no-padding-6-l">
            <SelectDate
              todayNotAvailable={props.todayNotAvailable}
              setExternalSubmit={setExternalSubmit}
              handleFiltersOpen={handleClickOpen}
              chosenDate={chosenDate}
              setChosenDate={setChosenDate}
              areaHeader
            />
          </div>
        </React.Fragment>
      ) : (
        <Skeleton variant="rect" width={600} height={40} />
      )}
      </div>
      <div className="col-md-6" />

      <FiltersModal
        open={open}
        fullscreen={false}
        alreadyFiltersResults={true}
        onClose={handleClose}
        source={"experiences"}
        resultsFilters={filtersResults}
        available_date={chosenDate}
        todayNotAvailable={props.todayNotAvailable}
        externalSubmit={externalSubmit}
        areaHome
      />
    </div>
  );
}

export default AreaHeader;
