import React, { useEffect } from "react";
import CustomizeEvoucher from "./CustomizeEvoucher";
import CustomizeVoucherPhysical from "./CustomizeVoucherPhysical";
import { useSelector } from "react-redux";
import axios from "axios";
import { Cookies, useCookies } from "react-cookie";

function CustomizeVoucher(props) {
  const BASE_URL = props.baseUrl || '';

  const [cookies, setCookie] = useCookies(['variant'])

  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  useEffect(() => {
    if (!stepsVoucherCreationParams.step4.status) {
    axios
      .post(`${BASE_URL}/api/main/tracking/checkouts/viewed.json`, {params: {step: 4, step_name: 'customize', version: cookies["variant"]}})
      .then((response) => {console.log(response.status)})
        .catch(function (error) {}); }
  }, [])

  return (
    stepsVoucherCreationParams.step2.evoucher ? (
      <CustomizeEvoucher handleNext={props.handleNext} handleBack={props.handleBack} />
    ) :
      stepsVoucherCreationParams.step2.print && (
        <CustomizeVoucherPhysical handleNext={props.handleNext} handleBack={props.handleBack} />
      )
  )
}

export default CustomizeVoucher
