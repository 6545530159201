import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import Skeleton from "@material-ui/lab/Skeleton";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Fab from '@material-ui/core/Fab';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AreaBlock from "../areas/AreaBlock";


function AreaSlide(props) {

  const [hasLeftScroll, setHasLeftScroll] = useState(false);
  const [hasRightScroll, setHasRightScroll] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      overflow: "hidden",
      margin: "20px 0 0",
      padding: "0 10px",
    },
    gridList: {
      flexWrap: "nowrap",
      width: 'initial',
    },
    title: {
      paddingLeft: "10px",
    },
    buttonLeft: {
      position: 'absolute',
      marginTop: '70px',
      marginLeft: '-30px',
      zIndex: '10',
      background: "#F7EB67 !important",
      "&:hover": {
        background: "#F7EB67 !important",
        boxShadow: "4px 4px 0px black",
      }
    },
    buttonRight: {
      position: "absolute",
      zIndex: "10",
      right: "10px",
      background: "#F7EB67",
      marginTop: "70px",
      marginRight: "-30px",
      "&:hover": {
        background: "#F7EB67 !important",
        boxShadow: "4px 4px 0px black",
      }
    },
    item: {
      padding: '0 5px !important',
      "&:first-child": {
        paddingLeft: '-5px !important'
      }
    }
  }));

  const classes = useStyles();

  const scrollRight = () => {
    $(`#gridListScroll-${props.selector}`).animate({
      scrollLeft: "+=400px"
    }, "slow");
    setHasLeftScroll(true)
    if ($(`#gridListScroll-${props.selector}`).get(0).scrollWidth-$(`#gridListScroll-${props.selector}`).scrollLeft()-$(`#gridListScroll-${props.selector}`).width()-400 <= 0) {
      setHasRightScroll(false)
    }
  }

  const scrollLeft = () => {
    $(`#gridListScroll-${props.selector}`).animate({
      scrollLeft: "-=400px"
    }, "slow");
    setHasRightScroll(true)
    if ($(`#gridListScroll-${props.selector}`).scrollLeft()-400 <= 0) {
      setHasLeftScroll(false)
    }
  }

  return (
    <React.Fragment>
      {props.areas ? (
        <>
        {hasLeftScroll &&
          <Fab color="white" aria-label="add" className={classes.buttonLeft} onClick={scrollLeft}>
            <ArrowLeftIcon />
          </Fab>
        }
        {hasRightScroll && props.areas.length > props.listCols &&
          <Fab color="white" aria-label="add" className={classes.buttonRight} onClick={scrollRight}>
            <ArrowRightIcon />
          </Fab>
        }

        <ImageList
          className={classes.gridList}
          cols={props.listCols}
          rowHeight="auto"
          id={`gridListScroll-${props.selector}`}
        >
          {props.areas.map((area, index) => {
            return (
              <ImageListItem className={classes.item} key={index}>
                <AreaBlock
                  key={index}
                  area={area}
                />
              </ImageListItem>
            );
          })}
        </ImageList>
        </>
      ) :  <Skeleton variant="rect" width="100%" height="20rem" /> }
    </React.Fragment>
  );
}

export default AreaSlide;
