import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import {
  changeLocaleReducer,
  configCsrfTokenReducer,
  currentUserReducer,
  JWTTokenReducer,
} from "./redux/reducers/reducers";
import WidgetVoucherCheckoutPage from "./widget/WidgetVoucherCheckoutPage.js";

const store = configureStore({
  reducer: {
    currentLocale: changeLocaleReducer,
    csrfToken: configCsrfTokenReducer,
    currentUser: currentUserReducer,
    JWTToken: JWTTokenReducer,
  },
});

function CheckoutApp(props) {
  return (
    <Provider store={store}>
      <WidgetVoucherCheckoutPage {...props} />
    </Provider>
  );
}

export default CheckoutApp;
