import React from 'react'
import { useStylesPriceSelection } from "../../styles/stepStyles";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { setStepsVoucherCreationParams } from "../../../../redux/actions/actions";
import Emoji from "a11y-react-emoji";
import I18n from 'i18n-js';


function PriceBox(props) {
	const classes = useStylesPriceSelection();

	const addExtraPrice = (pricebox) => {
    if (props.campaign.discount > 0) {
      const discountFloat = props.campaign.discount / 100;

      return pricebox * discountFloat + pricebox;
    }
  };

	return (
		<div
			onClick={props.setVoucherPrice(props.priceBox)}
			className={clsx(
				props.mobile ? classes.priceBoxMobile : classes.priceBox,
				parseInt(props.voucherPrice) === props.priceBox && classes.selected
			)}
		>
		<div className={classes.priceBoxColumns}>
			<div className={classes.priceBoxColumn}>
				{props.campaign.status && <small className={classes.overText}>{I18n.t('new_gift__campaign_pay')}</small>}
					<span className={classes.price} >{props.priceBox}€</span>
			</div>
			{props.campaign.status && 
				<div className={classes.priceBoxColumn}>
					<small className={classes.overText}><Emoji symbol="🎉" /> {I18n.t('new_gift__campaign_get')}</small>
					<span className={classes.price + " " + classes.campaignPrice} >{addExtraPrice(props.priceBox)}€</span>
				</div>
			}
		</div>
		<small className={classes.subText}>{props.text}</small>
	</div>
	)
}

export default PriceBox;