import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import NavMobile from "../nav/NavMobile";
import NavDesktop from "../nav/NavDesktop";
import Emoji from "a11y-react-emoji";
import { createMedia } from "@artsy/fresnel";
import {
  capitalizeFirstLetter,
  timizeDistance,
  findCapital,
} from "../../../helpers/base.js";
import SlideProductBlock from "../areaHome/SlideProductBlock";
import { useSelector, useDispatch } from "react-redux";
import ProductBookingForm from "./ProductBookingForm";
import ProductOnDemandForm from "./ProductOnDemandForm";
import Skeleton from "@material-ui/lab/Skeleton";
import SkeletonSlideProductBlock from "../skeletons/SkeletonsSlideProductBlock";
import clsx from "clsx";
import ReviewBlock from "../reviews/ReviewBlock";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClearIcon from "@material-ui/icons/Clear";
import ReviewSlide from "../reviews/ReviewSlide";
import { setAvailabilities } from "../../redux/actions/actions";
import MetroStations from "../components/metros/metroStations";
import I18n from "../../../i18n-js/index.js.erb";
import SubscriptionForm from "../components/subscriptions/SubscriptionForm";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import HotelBreakLoader from "../components/loaders/hotelBreakLoader";
import {
  addCSRFToken,
  setCurrentUser,
  changeLocale,
  changeSelectedArea,
  setAreaHomeProducts,
  changeSelectedAreaName,
  setAreaHomeHotelCategories,
} from "../../redux/actions/actions";
import UserLoginWrap from "../components/modals/UserLogin/UserLoginWrap";
import slugify from "react-slugify";
import { useStylesProductForm } from "./styles/productFormStyles";
import SecondaryImagesSlider from "./components/SecondaryImagesSlider";
import AnalyticsPoper from "../components/popups/AnalyticsPoper";
import CategoryTag from "./components/CategoryTag.js";

function Product(props) {
  const BASE_URL = props.baseUrl || '';
  const dispatch = useDispatch();
  dispatch(changeLocale(props.locale));
  dispatch(addCSRFToken(props.csrf_token));

  const hotel = props.hotel;
  const area = props.area;
  const product = props.slug;
  const locale = props.locale;
  var adults = [null, undefined, 0].includes(props.selectedAdults)
    ? 1
    : parseInt(props.selectedAdults);
  var kids = [null, undefined, 0].includes(props.selectedKids)
    ? 0
    : parseInt(props.selectedKids);
  I18n.locale = props.locale;

  const selectedArea = capitalizeFirstLetter(props.area);
  const selectedAreaName = useSelector((state) => state.areaName);
  const currentLocale = useSelector((state) => state.currentLocale);
  const currentUser = useSelector((state) => state.currentUser);
  const csrfToken = useSelector((state) => state.csrfToken);
  const currentAvailabilities = useSelector(
    (state) => state.currentAvailabilities
  );
  const [experience, setExperience] = useState();
  const [otherProducts, setOtherProducts] = useState();
  const [otherProductsHotel, setOtherProductsHotel] = useState();
  const [reviews, setReviews] = useState();
  const [reviewCount, setReviewCount] = useState();
  const [averageReview, setAverageReview] = useState();
  const [openReviews, setOpenReviews] = useState(false);
  const [directBooking, setDirectBooking] = useState(undefined);
  const [bookable, setBookable] = useState(undefined);
  const [onDemand, setOnDemand] = useState(undefined);
  const [scroll, setScroll] = useState("paper");
  const [acceptKids, setAcceptKids] = useState();
  const [metro, setMetro] = useState();
  const [hotelStatus, setHotelStatus] = useState();
  const [timeDistance, setTimeDistance] = useState();
  const [province, setProvince] = useState();
  const [secondaryImages, setSecondaryImages] = useState(false);
  const [ready, setReady] = useState(false);
  const [height, setHeight] = useState(0);
  const [viewCount, setViewCount] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  const hideAnalyticsPoper = () => {
    const timer = setTimeout(() => {
      setViewCount(0);
    }, 10000);
    return () => clearTimeout(timer);
  };

  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet,
      })
    );
    dispatch(changeLocale(props.current_user.user_locale));
  } else {
    dispatch(changeLocale(props.locale));
  }

  const handleReviewsOpen = () => {
    setOpenReviews(true);
  };

  const handleReviewsClose = () => {
    setOpenReviews(false);
  };

  const StyledDialog = withStyles({
    paper: {
      margin: "7rem 0 0 0",
      borderRadius: "1.8rem",
    },
    paperScrollPaper: {
      maxHeight: "80vh",
    },
  })(Dialog);

  const setCookie = (area) => {
    axios
      .post(`${BASE_URL}/api/main/areas/cookie.json`, { area: slugify(area) })
      .then((response) => {
        if (response.data.status === true && area !== null) {
          dispatch(changeSelectedArea(slugify(area)));
        }
      });
  };

  useEffect(() => {
    let mounted = true;

    if (props.area_cookie && selectedArea) {
      setCookie(selectedArea);
      var location_param = selectedArea;
    } else if (props.area_cookie && !selectedArea) {
      setCookie(props.area_cookie);
      var location_param = props.area_cookie;
    }
    axios
      .get(`${BASE_URL}/api/main/products`, {
        params: { location: location_param, locale: currentLocale },
      })
      .then((response) => {
        if (mounted) {
          dispatch(
            setAreaHomeProducts(
              response.data.area_home_products
                .map((product_list) => {
                  return {
                    category: product_list.products.map(
                      (productSerialization) => productSerialization.attributes
                    ),
                    title: product_list.title,
                    slug: product_list.slug,
                    emoji: product_list.emoji,
                    popularity: product_list.popularity,
                    type: product_list.type,
                  };
                })
                .sort((a, b) => {
                  if (a.popularity > b.popularity) {
                    return -1;
                  } else if (
                    a.popularity === "break_friday" ||
                    a.popularity === "most_popular" ||
                    a.popularity === "most_recent"
                  ) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
            )
          );
        }
        dispatch(setAreaHomeHotelCategories(response.data.rooms_categories));
        dispatch(changeSelectedAreaName(response.data.province));
      });

    return () => {
      mounted = false;
    };
  }, [selectedArea]);

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;

  useEffect(() => {
    let mounted = true;
    axios
      .get(`${BASE_URL}/api/main/products/show.json`, {
        params: {
          slug: props.slug,
          area: props.area,
          hotel: props.hotel,
          locale: props.locale,
        },
      })
      .then((response) => {
        if (mounted) {
          dispatch(
            setAvailabilities({
              ...response.data.currentAvailabilities,
              setSelectedDate: props.selectedDate,
            })
          );
          setExperience(response.data.experience.data);
          setHotelStatus(response.data.experience.data.attributes.hotelStatus);
          setOtherProducts(response.data.others_area);
          setOtherProductsHotel(response.data.others_hotel);
          setDirectBooking(response.data.direct_booking);
          setBookable(response.data.bookable);
          setOnDemand(response.data.experience.data.attributes.onDemand);
          setReviews(response.data.reviews);
          setReviewCount(response.data.review_count);
          setAverageReview(response.data.average_review);
          setSecondaryImages(response.data.secondary_images);
          setAcceptKids(
            response.data.experience.data.attributes.productTickets.accept_kids
          );
          setMetro(response.data.experience.data.attributes.metro);
          setTimeDistance(
            response.data.experience.data.attributes.timeDistance
          );
          setProvince(response.data.experience.data.attributes.province);

          document.title =
            response.data.experience.data.attributes.name +
            " | " +
            response.data.experience.data.attributes.hotel +
            " | " +
            selectedArea +
            " | hotelbreak";
        }
      });

    return () => {
      mounted = false;
    };
  }, [props.slug]);

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  const classes = useStylesProductForm();

  const ProductMobile = (cols) => {
    return (
      <>
        <NavMobile source={"product"} areaName={selectedAreaName} />
        <div ref={ref}>
          {experience != undefined ? (
            secondaryImages.length > 1 ? (
              <SecondaryImagesSlider
                baseUrl={props.baseUrl}
                slidesToShow={1}
                phone={true}
                secondaryImages={secondaryImages}
                title={experience.attributes.hotel}
              />
            ) : (
              <img
                className={classes.experienceImage}
                id="image"
                src={experience.attributes.photo}
                alt={
                  experience != undefined &&
                  `${experience.attributes.hotel} | ${experience.attributes.name}`
                }
                title={
                  experience != undefined &&
                  `${experience.attributes.hotel} | ${experience.attributes.name}`
                }
              />
            )
          ) : (
            <Skeleton variant="rect" className={classes.experienceImage} />
          )}

          <div className={classes.slidingPane}>
            <div className={classes.container}>
              {experience != undefined ? (
                <p className={classes.hotelName}>
                  {experience.attributes.hotel}&nbsp;{experience.attributes.hotelStarRating && "*".repeat(experience.attributes.hotelStarRating)}
                </p>
              ) : (
                <Skeleton variant="text" />
              )}
              {experience != undefined ? (
                <h1 className={classes.title}>{experience.attributes.name}</h1>
              ) : (
                <Skeleton variant="text" />
              )}
              <br />
              {experience != undefined ? (
                <div>
                  {experience?.attributes.category.map(x =>
                    <CategoryTag name={x} />
                  )}
                </div>
              ) :
                <Skeleton variant="text"  />
              }
              {experience != undefined ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: experience.attributes.description,
                  }}
                ></div>
              ) : (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </>
              )}
              <br />
              <br />
            </div>
            <div className={classes.divider} />
            <div className={classes.container}>
              {onDemand !== undefined &&
              directBooking !== undefined &&
              hotelStatus &&
              Object.keys(currentAvailabilities.availabilities).length > 0 &&
              bookable ? (
                directBooking && !onDemand ? (
                  <ProductBookingForm
                    baseUrl={props.baseUrl}
                    experience={experience}
                    hotel={hotel}
                    product={product}
                    area={area}
                    adults={adults}
                    kids={kids}
                  />
                ) : (
                  <ProductOnDemandForm
                    experience={experience}
                    hotel={hotel}
                    product={product}
                    area={area}
                  />
                )
              ) : bookable !== undefined && !bookable ? (
                <SubscriptionForm
                  baseUrl={props.baseUrl}
                  full={false}
                  hotel={hotel}
                  product={product}
                  area={area}
                  avails={
                    Object.keys(currentAvailabilities.availabilities).length
                  }
                  bookable={false}
                />
              ) : (
                <HotelBreakLoader />
              )}

              {experience === undefined &&
                hotelStatus === undefined &&
                directBooking === undefined &&
                onDemand === undefined &&
                Object.keys(currentAvailabilities.availabilities) ===
                  undefined && <Skeleton variant="rect" height="200" />}
            </div>

            {reviewCount > 0 && (
              <div className={classes.reviewBlock}>
                <div
                  className={
                    classes.horizontalEelements + " " + classes.container
                  }
                >
                  <div>
                    <h2
                      className={clsx(
                        classes.secondaryTitle,
                        classes.sameLineText
                      )}
                    >
                      {`${averageReview} / 5`}
                    </h2>
                    <p className={classes.sameLineText}>
                      {" "}
                      {I18n.t("review", { count: reviewCount })}
                    </p>
                  </div>

                  <div>
                    <p
                      className={classes.paddingTop}
                      onClick={handleReviewsOpen}
                    >
                      {I18n.t("see_all")}
                    </p>
                    <StyledDialog
                      open={openReviews}
                      onClose={handleReviewsClose}
                      className={classes.paper}
                      scroll={scroll}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                    >
                      <DialogTitle id="scroll-dialog-title">
                        <div className={classes.horizontalEelements}>
                          <p className={classes.dialogTitle}>
                            {I18n.t("hotel_reviews_title")}
                          </p>
                          <div>
                            <ClearIcon
                              onClick={() => handleReviewsClose()}
                              style={{ fontSize: 30 }}
                            ></ClearIcon>
                          </div>
                        </div>
                      </DialogTitle>
                      <DialogContent dividers={scroll === "paper"}>
                        {reviews &&
                          experience &&
                          reviews.map((review) => {
                            return (
                              <div className={classes.photoDialogBlock}>
                                <ReviewBlock
                                  description={review.description}
                                  user_name={review.user_name}
                                  note={review.note}
                                  start_date_js_time={review.start_date_js_time}
                                  review_js_time={review.review_js_time}
                                  product_name={review.name}
                                  truncate={false}
                                />
                              </div>
                            );
                          })}
                      </DialogContent>
                    </StyledDialog>
                  </div>
                </div>
                <div className={classes.container}>
                  <ReviewSlide
                    reviews={reviews.slice(0, 5)}
                    truncate={true}
                    listCols={2.3}
                  />
                </div>
                <br />
                <br />
              </div>
            )}

            <div className={classes.container}>
              <h2 className={classes.secondaryTitle}>
                {I18n.t("how_to_access")}
              </h2>
              {experience != undefined ? (
                <>
                  <img
                    src={`https://www.hotelbreak.com${experience.attributes.hotelBlockPhoto}`}
                    className={classes.infoPhoto}
                    alt={
                      experience != undefined &&
                      `${experience.attributes.hotel}`
                    }
                    title={
                      experience != undefined &&
                      `${experience.attributes.hotel}`
                    }
                  />
                  <h5>{experience.attributes.hotel}</h5>
                  <p>{experience.attributes.address}</p>
                  {metro && metro != "" && (
                    <>
                      <h6>
                        <Emoji symbol="🚇" /> {I18n.t("metro")}
                      </h6>
                      <MetroStations metro={metro} />
                    </>
                  )}
                  {timeDistance && timeDistance != 0 && experience && (
                    <p>
                      {experience.attributes.city !=
                        findCapital(selectedArea) && <Emoji symbol="🚙" />}{" "}
                      {timizeDistance(
                        timeDistance,
                        selectedArea,
                        experience.attributes.city
                      )}
                    </p>
                  )}
                  <a
                    href={`/${props.locale}/${props.area}/hotels/${hotel}`}
                    className="toRoom marginbottom"
                    alt={I18n.t("view_hotel")}
                  >
                    <p className={classes.link}>
                      {I18n.t("view_hotel")} <ArrowRightAltIcon />
                    </p>
                  </a>
                </>
              ) : (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </>
              )}
            </div>
            <br />
            <div className={classes.container}>
              <h2 className={classes.secondaryTitle}>
                {I18n.t("hotel_need_know_title")}
              </h2>
              {experience != undefined ? (
                <>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: experience.attributes.needToKnow,
                    }}
                  />
                  {experience.attributes.roomDisabledGiftCard && (
                    <p>{I18n.t("not_gift_bookable")}</p>
                  )}
                </>
              ) : (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </>
              )}
            </div>
            <br />
            <div className={classes.container}>
              <h2 className={classes.secondaryTitle}>
                {I18n.t("hotel_cancelation_title")}
              </h2>
              {experience != undefined ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: experience.attributes.cancelationPolicy,
                  }}
                />
              ) : (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </>
              )}
            </div>
            <br />

            {otherProductsHotel &&
              experience != undefined &&
              otherProductsHotel.data?.length !== 0 &&
              selectedAreaName !== false && (
                <SlideProductBlock
                  selector={"otherProductsHotel-mobile"}
                  products={otherProductsHotel}
                  title={I18n.t("more_in_this_hotel")}
                  emoji={"✨"}
                  listCols={cols}
                  area={props.area}
                  hotel={props.hotel}
                  product_slug={props.slug}
                  link_text={I18n.t("view_hotel")}
                  link_destination={`/${props.locale}/${props.area}/hotels/${props.hotel}`}
                  show_all_link={false}
                  areaName={selectedAreaName}
                  baseUrl={props.baseUrl}
                />
              )}

            {otherProductsHotel &&
              experience === undefined &&
              otherProductsHotel.data?.length !== 0 && (
                <SkeletonSlideProductBlock listCols={cols} />
              )}

            {experience !== undefined && selectedAreaName !== false ? (
              <SlideProductBlock
                selector={"otherProducts-mobile"}
                products={otherProducts}
                title={I18n.t("more_in_area", { area: selectedArea })}
                emoji={"✨"}
                listCols={cols}
                area={props.area}
                hotel={props.hotel}
                product_slug={props.slug}
                show_all_link={false}
                areaName={selectedAreaName}
                baseUrl={props.baseUrl}
              />
            ) : (
              <>
                <SkeletonSlideProductBlock listCols={1.3} />
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={classes.nav}
        style={{ visibility: ready ? "visible" : "hidden" }}
      >
        {viewCount != false && viewCount > 10 && (
          <AnalyticsPoper icon={"👓"} content={"product"} count={viewCount} />
        )}
        <MediaContextProvider>
          <Media at="sm">
            {ProductMobile(1.3)}
            <UserLoginWrap baseUrl={props.baseUrl} fullscreen={true} />
          </Media>
          <Media at="md">
            {ProductMobile(2.3)}
            <UserLoginWrap baseUrl={props.baseUrl} fullscreen={false} />
          </Media>
          <Media greaterThanOrEqual="lg">
            {<NavDesktop source={"product"} areaName={selectedAreaName} />}
            <div>
              <br />
              <br />
              {experience != undefined && (
                <div className={classes.backgroundImage} />
              )}
              <div className={classes.slidingPaneLarge}>
                <div className={classes.leftColumn} ref={ref}>
                  {experience != undefined ? (
                    <>
                      {experience != undefined && secondaryImages.length > 1 ? (
                        <SecondaryImagesSlider
                          baseUrl={props.baseUrl}
                          slidesToShow={1}
                          phone={false}
                          secondaryImages={secondaryImages}
                          title={experience.attributes.hotel}
                        />
                      ) : (
                        <img
                          className={classes.experienceImageLarge}
                          id="image"
                          src={experience.attributes.photo}
                          alt={
                            experience != undefined &&
                            `${experience.attributes.hotel} | ${experience.attributes.name}`
                          }
                          title={
                            experience != undefined &&
                            `${experience.attributes.hotel} | ${experience.attributes.name}`
                          }
                        />
                      )}
                    </>
                  ) : (
                    <Skeleton
                      variant="rect"
                      className={classes.experienceImageLarge}
                    />
                  )}
                  <div className={classes.container}>
                    {experience && experience.attributes && province ? (
                      <div
                        className={clsx(
                          "row",
                          classes.breadcrumb,
                          classes.largeContainer,
                          "no-padding",
                          acceptKids != undefined &&
                            acceptKids &&
                            experience.attributes.pvpKids != 0 &&
                            "accept_kids"
                        )}
                      >
                        <ul
                          itemScope
                          itemType="https://schema.org/BreadcrumbList"
                          className={classes.breadcrumbUL}
                        >
                          <li
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                            className={classes.breadcrumbList}
                          >
                            <a itemProp="item" href="/">
                              <span itemProp="name">hotelbreak.com</span>
                            </a>
                            <meta itemProp="position" content="1" />
                          </li>
                          <span className={classes.breadcrumbSeparator}>›</span>
                          <li
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                            className={classes.breadcrumbList}
                          >
                            <a
                              itemScope
                              itemType="https://schema.org/WebPage"
                              itemProp="item"
                              itemID={`/${currentLocale}/${selectedArea.toLowerCase()}`}
                              href={`/${currentLocale}/${selectedArea.toLowerCase()}`}
                            >
                              <span itemProp="name">{province}</span>
                            </a>
                            <meta itemProp="position" content="2" />
                          </li>
                          <span className={classes.breadcrumbSeparator}>›</span>
                          <li
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                            className={classes.breadcrumbList}
                          >
                            <a
                              itemScope
                              itemType="https://schema.org/WebPage"
                              itemProp="item"
                              itemID={`/${currentLocale}/${selectedArea.toLowerCase()}`}
                              href={`/${currentLocale}/${selectedArea.toLowerCase()}/hotels`}
                            >
                              <span itemProp="name">{I18n.t("hotels")}</span>
                            </a>
                            <meta itemProp="position" content="3" />
                          </li>
                          <span className={classes.breadcrumbSeparator}>›</span>
                          <li
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                            className={classes.breadcrumbList}
                          >
                            <a
                              itemScope
                              itemType="https://schema.org/WebPage"
                              itemProp="item"
                              itemID={`/${currentLocale}/${selectedArea.toLowerCase()}`}
                              href={`/${currentLocale}/${selectedArea.toLowerCase()}/hotels/${hotel}`}
                            >
                              <span itemProp="name">
                                {experience &&
                                  experience.attributes &&
                                  experience.attributes.hotel}
                              </span>
                            </a>
                            <meta itemProp="position" content="4" />
                          </li>
                          <span className={classes.breadcrumbSeparator}>›</span>
                          <li
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                            className={classes.breadcrumbList}
                          >
                            <span itemProp="name">
                              {experience &&
                                experience.attributes &&
                                experience.attributes.name}
                            </span>
                            <meta itemProp="position" content="5" />
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div
                        className={clsx(
                          "row",
                          classes.breadcrumb,
                          classes.largeContainer,
                          "no-padding"
                        )}
                      >
                        <ul
                          itemScope
                          itemType="https://schema.org/BreadcrumbList"
                          className={classes.breadcrumbUL}
                        >
                          <li className={classes.breadcrumbList}>
                            <Skeleton
                              variant="text"
                              style={{ width: "40px" }}
                            />
                          </li>
                          <span className={classes.breadcrumbSeparator}>›</span>
                          <li className={classes.breadcrumbList}>
                            <Skeleton
                              variant="text"
                              style={{ width: "40px" }}
                            />
                          </li>
                          <span className={classes.breadcrumbSeparator}>›</span>
                          <li className={classes.breadcrumbList}>
                            <Skeleton
                              variant="text"
                              style={{ width: "40px" }}
                            />
                          </li>
                          <span className={classes.breadcrumbSeparator}>›</span>
                          <li className={classes.breadcrumbList}>
                            <Skeleton
                              variant="text"
                              style={{ width: "40px" }}
                            />
                          </li>
                          <span className={classes.breadcrumbSeparator}>›</span>
                          <li className={classes.breadcrumbList}>
                            <Skeleton
                              variant="text"
                              style={{ width: "40px" }}
                            />
                          </li>
                        </ul>
                      </div>
                    )}
                    {experience != undefined ? (
                      <h1
                        className={
                          classes.titleLarge + " " + classes.largeContainer
                        }
                        style={{ marginTop: "0px" }}
                      >
                        {experience.attributes.name}
                      </h1>
                    ) : (
                      <Skeleton
                        variant="text"
                        className={
                          classes.titleLarge + " " + classes.largeContainer
                        }
                      />
                    )}
                    {experience != undefined ? (
                      <p className={classes.largeContainer}>
                        {experience.attributes.hotel}&nbsp;{experience.attributes.hotelStarRating && "*".repeat(experience.attributes.hotelStarRating)}
                      </p>
                    ) : (
                      <Skeleton
                        variant="text"
                        className={classes.largeContainer}
                      />
                    )}
                    <br />
                    {experience != undefined ? (
                      <div className={classes.largeContainer}>
                        {experience?.attributes.category.map(x =>
                          <CategoryTag name={x} />
                        )}
                      </div>
                    ) :
                      <Skeleton variant="text" className={classes.largeContainer} />
                    }
                    <br />
                    {experience != undefined ? (
                      <div
                        className={classes.largeContainer}
                        dangerouslySetInnerHTML={{
                          __html: experience.attributes.description,
                        }}
                      ></div>
                    ) : (
                      <>
                        <Skeleton
                          variant="text"
                          className={classes.largeContainer}
                        />
                        <Skeleton
                          variant="text"
                          className={classes.largeContainer}
                        />
                        <Skeleton
                          variant="text"
                          className={classes.largeContainer}
                        />
                      </>
                    )}
                    <br />
                    <br />
                  </div>

                  {reviewCount > 0 && (
                    <div className={classes.reviewBlock}>
                      <div
                        className={
                          classes.horizontalEelements +
                          " " +
                          classes.largeContainer
                        }
                      >
                        <div>
                          <h2
                            className={clsx(
                              classes.secondaryTitle,
                              classes.sameLineText
                            )}
                          >
                            {`${averageReview} / 5`}
                          </h2>
                          <p className={classes.sameLineText}>
                            {" "}
                            {I18n.t("review", { count: reviewCount })}
                          </p>
                        </div>

                        <div>
                          <p
                            className={classes.paddingTop}
                            onClick={handleReviewsOpen}
                          >
                            {I18n.t("see_all")}
                          </p>
                          <StyledDialog
                            open={openReviews}
                            onClose={handleReviewsClose}
                            className={classes.paper}
                            scroll={scroll}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                          >
                            <DialogTitle id="scroll-dialog-title">
                              <div className={classes.horizontalEelements}>
                                <p className={classes.dialogTitle}>
                                  {I18n.t("hotel_reviews_title")}
                                </p>
                                <div>
                                  <ClearIcon
                                    onClick={() => handleReviewsClose()}
                                    style={{ fontSize: 30 }}
                                  ></ClearIcon>
                                </div>
                              </div>
                            </DialogTitle>
                            <DialogContent dividers={scroll === "paper"}>
                              {reviews &&
                                experience &&
                                reviews.map((review) => {
                                  return (
                                    <div className={classes.photoDialogBlock}>
                                      <ReviewBlock
                                        description={review.description}
                                        user_name={review.user_name}
                                        note={review.note}
                                        start_date_js_time={
                                          review.start_date_js_time
                                        }
                                        review_js_time={review.review_js_time}
                                        product_name={review.name}
                                        truncate={false}
                                      />
                                    </div>
                                  );
                                })}
                            </DialogContent>
                          </StyledDialog>
                        </div>
                      </div>
                      <div className={classes.largeContainer}>
                        <ReviewSlide
                          reviews={reviews.slice(0, 5)}
                          truncate={true}
                          listCols={2.3}
                        />
                      </div>
                      <br />
                      <br />
                    </div>
                  )}

                  <div className={classes.container}></div>
                  <div className={classes.largeContainerBottom}>
                    <div
                      className={clsx(
                        classes.infoBlock,
                        classes.hotelBlock,
                        "row"
                      )}
                    >
                      {experience != undefined ? (
                        <>
                          <h3>En el hotel {experience.attributes.hotel}</h3>
                          <p>{experience.attributes.address}</p>
                          <div className="col-md-4 no-padding-6-l">
                            <img
                              src={`https://www.hotelbreak.com${experience.attributes.hotelBlockPhoto}`}
                              className={classes.infoPhoto}
                              alt={
                                experience != undefined &&
                                `${experience.attributes.hotel}`
                              }
                              title={
                                experience != undefined &&
                                `${experience.attributes.hotel}`
                              }
                            />
                          </div>
                          <div className="col-md-4 no-padding-6-l">
                            <img
                              src={`https://www.hotelbreak.com${experience.attributes.blockPhoto}`}
                              className={classes.infoPhoto}
                              alt={
                                experience != undefined &&
                                `${experience.attributes.hotel}`
                              }
                              title={
                                experience != undefined &&
                                `${experience.attributes.hotel}`
                              }
                            />
                          </div>
                          <div className="col-md-4 no-padding-6-l">
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${experience.attributes.hotelLatitude},${experience.attributes.hotelLongitude}`}
                              target="_blank"
                            >
                              <img
                                src={experience.attributes.hotelMap}
                                className={classes.infoPhoto}
                              />
                            </a>
                          </div>
                          <div className="col-md-12 no-padding-6-l">
                            <br />
                            <a
                              href={`/${props.locale}/${props.area}/hotels/${hotel}`}
                              className="toRoom marginbottom"
                              alt={I18n.t("view_hotel")}
                              style={{ float: "right" }}
                            >
                              <p className={"btn btn-primary"}>
                                {I18n.t("view_hotel")} <ArrowRightAltIcon />
                              </p>
                            </a>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className={classes.largeContainerBottom}>
                    <div
                      className={clsx(
                        classes.infoBlock,
                        classes.cancelBlock,
                        "row"
                      )}
                    >
                      <div className="col-md-6 no-padding-6-l">
                        {experience != undefined ? (
                          <>
                            <h2 className={classes.secondaryTitle}>
                              {I18n.t("hotel_need_know_title")}
                            </h2>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: experience.attributes.needToKnow,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                          </>
                        )}
                        {experience != undefined &&
                          experience.attributes.roomDisabledGiftCard && (
                            <p>
                              <Emoji symbol="❌" />{" "}
                              {I18n.t("not_gift_bookable")}
                            </p>
                          )}
                      </div>
                      <div className="col-md-6 no-padding-6-r">
                        {experience != undefined ? (
                          <>
                            <h2 className={classes.secondaryTitle}>
                              {I18n.t("hotel_cancelation_title")}
                            </h2>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: experience.attributes.cancelationPolicy,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className={classes.largeContainer}>
                    {otherProductsHotel &&
                      experience != undefined &&
                      otherProductsHotel.data?.length !== 0 &&
                      selectedAreaName !== false && (
                        <SlideProductBlock
                          selector={"otherProductsHotel-desktop"}
                          products={otherProductsHotel}
                          title={I18n.t("more_in_this_hotel")}
                          emoji={"✨"}
                          listCols={2.3}
                          area={props.area}
                          hotel={props.hotel}
                          product_slug={props.slug}
                          link_text={I18n.t("view_hotel")}
                          link_destination={`/${props.locale}/${props.area}/hotels/${props.hotel}`}
                          areaName={selectedAreaName}
                          baseUrl={props.baseUrl}
                        />
                      )}

                    {otherProductsHotel &&
                      experience === undefined &&
                      otherProductsHotel.data?.length !== 0 && (
                        <SkeletonSlideProductBlock listCols={1.7} />
                      )}
                  </div>
                  <div className={classes.largeContainer}>
                    {experience != undefined && selectedAreaName !== false ? (
                      <SlideProductBlock
                        selector={"otherProducts-desktop"}
                        products={otherProducts}
                        title={I18n.t("more_in_area", { area: selectedArea })}
                        emoji={"✨"}
                        listCols={2.3}
                        area={props.area}
                        hotel={props.hotel}
                        product_slug={props.slug}
                        areaName={selectedAreaName}
                        baseUrl={props.baseUrl}
                      />
                    ) : (
                      <>
                        <SkeletonSlideProductBlock listCols={1.7} />
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={classes.rightColumn}
                  style={{ minHeight: `${height}px` }}
                >
                  <div
                    className={
                      acceptKids != undefined &&
                      acceptKids &&
                      experience.attributes.pvpKids != 0
                        ? classes.formBookingLargeAcceptKids
                        : classes.formBookingLarge
                    }
                  >
                    {onDemand !== undefined &&
                    directBooking !== undefined &&
                    hotelStatus &&
                    Object.keys(currentAvailabilities.availabilities).length >
                      0 &&
                    bookable ? (
                      directBooking && !onDemand ? (
                        <ProductBookingForm
                          baseUrl={props.baseUrl}
                          experience={experience}
                          hotel={hotel}
                          product={product}
                          area={area}
                          adults={adults}
                          kids={kids}
                        />
                      ) : (
                        <ProductOnDemandForm
                          experience={experience}
                          hotel={hotel}
                          product={product}
                          area={area}
                        />
                      )
                    ) : bookable !== undefined && !bookable ? (
                      <SubscriptionForm
                        baseUrl={props.baseUrl}
                        full={false}
                        hotel={hotel}
                        product={product}
                        area={area}
                        avails={
                          Object.keys(currentAvailabilities.availabilities)
                            .length
                        }
                        bookable={false}
                      />
                    ) : (
                      <HotelBreakLoader />
                    )}

                    {experience === undefined &&
                      hotelStatus === undefined &&
                      directBooking === undefined &&
                      onDemand === undefined &&
                      Object.keys(currentAvailabilities.availabilities) ===
                        undefined && <Skeleton variant="rect" height="200" />}
                  </div>
                </div>
              </div>
            </div>
            <UserLoginWrap baseUrl={props.baseUrl} fullscreen={false} />
          </Media>
        </MediaContextProvider>
      </div>
    </>
  );
}

export default React.memo(Product);
