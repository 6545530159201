import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  addCSRFToken,
  setCurrentUser,
  changeLocale,
} from "./redux/actions/actions";
import I18n from "../i18n-js/index.js";
import GiftVoucherFunnel from "./main/vouchers/GiftVoucherFunnel";
import RedeemVoucher from "./main/vouchers/RedeemVoucher";
import Checkout from "./main/vouchers/Checkout";
import { createMedia } from "@artsy/fresnel";
import { useSelector, useDispatch } from "react-redux";
import { Cookies, useCookies } from "react-cookie";

function VoucherFunnelTemp(props) {
  const dispatch = useDispatch();

  const csrfToken = useSelector((state) => state.csrfToken);
  const JWTToken = useSelector((state) => state.JWTToken);

  const [ready, setReady] = useState(false);

  I18n.locale = props.locale;

  const [cookies, setCookie] = useCookies(['variant'])

  dispatch(addCSRFToken(props.csrf_token));

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common["Authorization"] = `Bearer ${JWTToken}`;

  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet
      }),
      changeLocale(props.locale)
    );
  } else {
    dispatch(changeLocale(props.locale))
  }

  useEffect(() => {
    setReady(true);
    !cookies["variant"] && setCookie('variant', Math.round(Math.random()), { path: '/' })
  }, []);

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path={`/:locale/${I18n.t("routes.create_gift")}`}>
            <GiftVoucherFunnel {...props} />
          </Route>
          <Route path={`/:locale/${I18n.t("routes.redeem_voucher")}`}>
            <RedeemVoucher {...props} />
          </Route>

          <Route path={"/:locale/checkout"}>
            <Checkout />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default VoucherFunnelTemp;

// <MediaContextProvider>
// <Media at="sm">
//   <UserLoginWrap fullscreen={true} />
// </Media>

// <Media at="md">
// <UserLoginWrap fullscreen={false} />
// </Media>

// <Media greaterThanOrEqual="lg">
// <UserLoginWrap fullscreen={false} />
// </Media>
// </MediaContextProvider>

// <Router>
// <ScrollToTop>
//   <Switch>
//     <Route exact path={"/"}>
//       <AreaHomeWrap area_cookie={props.area_cookie} />
//     </Route>

//     <Route exact path={"/:locale"}>
//       <AreaHomeWrap area_cookie={props.area_cookie} />
//     </Route>

//     <Route path={"/:locale/tarjeta-regalo"}>
//       <GiftVoucherFunnel />
//     </Route>

//     <Route path={"/:locale/redeem-voucher"}>
//       <RedeemVoucher />
//     </Route>

//     <Route path={"/:locale/checkout"}>
//       <Checkout />
//     </Route>
//   </Switch>
// </ScrollToTop>
// </Router>
