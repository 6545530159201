import { makeStyles } from "@material-ui/core/styles";

const filterModalStyles = makeStyles((theme) => ({
  closeButton: {
    float: "right",
    marginTop: "-10px;",
  },
  buttonText: {
    paddingTop: "0 !important",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  viewAllBtn: {
    marginBottom: "20px",
    display: "block",
    fontSize: "12px",
  },
  paxInnerSelect: {
    display: "flex",
    justifyContent: "space-between",
  },
  paxSelector: {
    width: "100px",
  },
  paxBtn: {
    border: "1px solid black",
    padding: "2px",
    borderRadius: "10px",
  },
  grow4: {
    flexFlow: 4,
  },
  blocked: {
    cursor: "not-allowed",
  },
  pointer: {
    cursor: "pointer",
  },
  drawer: {
    zIndex: "1299 !important",
  },
  fullWidth: {
    width: "100%",
  },
  sectionTitle: {
    fontWeight: "700",
    marginBottom: "10px",
  },
  overFlowOverride: {
    overflowY: 'visible !important'
  }
}));

export { filterModalStyles };
