import React, { useEffect, useRef } from "react";
import I18n from "../../i18n-js/index.js.erb";
import {
  setPaymentData,
  changeLocale,
  setCurrentUser,
  addCSRFToken,
  setClientSecret,
} from "../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";
import { currencyToSymbol, to_eur } from "../../helpers/base";
import okGif from "images/okok.gif";
import Reward from "react-rewards";
import moment from "moment";
import { useStylesPayment } from "./styles/paymentOptionsStyles";

import { RWebShare } from "react-web-share";
import clsx from "clsx";
import { faTemperatureEmpty } from "@fortawesome/free-solid-svg-icons";

function Confirmation(props) {
  const rewardRef = useRef();
  const currentUser = useSelector((state) => state.currentUser);
  const currentLocale = useSelector((state) => state.currentLocale);
  const classes = useStylesPayment(props);
  const copyLink = `https://www.hotelbreak.com/${currentLocale}/invitations?ref=${currentUser.referralCode}&utm_source=confirmation_share&utm_medium=referral`;

  moment.locale(currentLocale);

  const bookingDate = moment(
    props.reservation.start_date,
    "YYYY-MM-DD HH:mm:ss"
  ).format("dddd");

  const FunnelData = useSelector((state) => state.PaymentData);

  const callReward = () => {
    setTimeout(() => {
      rewardRef.current.rewardMe();
    }, 500);
  };

  useEffect(() => {
    callReward();
  }, []);

  const isBrand = () => {
    return props.brand?.data !== null;
  };

  return (
    <React.Fragment>
      <br />
      <br />
      <div className="col-md-6 margin-top-30">
        <div style={{ textAlign: "center" }}>
          <h3 className={clsx("margin-top-30", isBrand() && classes.headings)}>
            <img
              class="img-circle"
              style={{ width: "50px" }}
              src={okGif}
              data-original={okGif}
            />
            {isBrand()
              ? I18n.t("widget_booking_confirm")
              : I18n.t("booking_confirm")}
            <Reward ref={rewardRef} type="confetti" />
          </h3>
          <h4 className={isBrand() && classes.headings}>
            {I18n.t("your_booking_code", {
              code: props.reservation.reservation_code,
            })}
          </h4>
        </div>
        <br />
        <h4 className={isBrand() && classes.headings}>
          {I18n.t("booking_thanks", { name: currentUser.fullname })}
        </h4>
        <h5 className={isBrand() && classes.headings}>
          {I18n.t("hotel_need_know_title")}
        </h5>
        {FunnelData.reservationData?.isCalendarExperience && (
          <p>
            {I18n.t("confirmation__call_spa", {
              experience: props.product.name,
              number: props.formatted_phone,
              email: props.room.booking_contact,
            })}
          </p>
        )}
        <p dangerouslySetInnerHTML={{ __html: props.room.need_to_know }} />
        <hr />
        <h5 className={isBrand() && classes.headings}>
          {I18n.t("at_checkin")}
        </h5>
        <p>
          {I18n.t("at_checkin_text", {
            price: to_eur(props.rest_to_pay),
            currency: currencyToSymbol(props.room.currency),
          })}
        </p>
        {!isBrand() && (
          <React.Fragment>
            <hr />
            <h5>{I18n.t("booking_share")}</h5>
            <p>{I18n.t("booking_share_tips")}</p>
            <RWebShare
              data={{
                text: I18n.t("confirmation_share", {
                  date: bookingDate,
                  category: props.product.category[0],
                  hotel: props.room.listing_name,
                }),
                url: copyLink,
                title: `${props.room.listing_name}`,
              }}
              sites={["facebook", "twitter", "whatsapp", "mail", "copy"]}
              onClick={() => console.log("shared successfully!")}
            >
              <button className={"btn btn-primary"}>Compartir 🔗</button>
            </RWebShare>
          </React.Fragment>
        )}
      </div>
      <div className="col-md-1 margin-top-30" />
    </React.Fragment>
  );
}

export default Confirmation;
