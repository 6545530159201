import React from "react";
import Emoji from "a11y-react-emoji";
import Button from "@material-ui/core/Button";
import NavMobile from "../nav/NavMobile";
import { useStylesHeaderMobile } from "./styles/styles.js";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import I18n from "../../../i18n-js/index.js.erb";
import clsx from "clsx";

function HeaderMobile(props) {
  const classes = useStylesHeaderMobile();

  const emojis = ["🎁", "🎉", "🎈", "💝"]

  const randomEmoji = emojis[Math.floor(Math.random()*emojis.length)];

  return (
    <>
      <NavMobile style={{zIndex: 2}} />
      <div className={classes.header}>
        <h1 className={classes.titleHeader}><Emoji symbol={randomEmoji} /> {props.text}</h1>

        {!props.redeem &&
          <React.Fragment>
            <p className={classes.titleSubHeader}>{I18n.t('gift_voucher_already')}</p>
            <NavLink
              to={`/${I18n.locale}/${I18n.t('routes.redeem_voucher')}`}
              className="nav-link"
            >
              <Button className={clsx("btn btn-secondary", classes.filterButton)} variant="contained">
                {I18n.t('redeem_button')}
              </Button>
            </NavLink>
          </React.Fragment>
        }
      </div>
    </>
  );
}

export default HeaderMobile;
