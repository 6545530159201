import I18n from '../../../../i18n-js/index.js.erb'
import React, {useState, useEffect} from "react";
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import UserCreation from './UserCreation'
import UpdateRegistration from './UpdateRegistration'

function NewsletterWrapper(props) {

  const [gdpr, setGdpr] = useState(props.gdpr_status)
  const [locale, setLocale] = useState(props.current_locale);
  const [currentUser, setCurrentUser] = useState(props.current_user)
  const [userUpdated, setUserUpdated] = useState(false)
  const [visible, setVisible] = useState(true);

  function handleUpdate() {
    setUserUpdated(true);
    setTimeout(() => {
      setVisible(false);
    }, 4000)
  }

  const isMobile = useMediaQuery('(max-width: 764px)');

  const styles = {
    h1_styles: isMobile => ({
      fontSize: isMobile ? '25px' : '40px',
    fontWeight: '800',
    fontFamily: 'Futura'
    }),
    updatedText: {
      fontSize: '25px'},
    email_suggestion_link: {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    newsletterbanner: {
      background: 'whitesmoke',
      padding: '40px',
      maxWidth: '97%',
      margin: '0 15px 40px',
      borderRadius: '10px'
    },
    checkBoxHide: {
      display: 'none'
    }
  };

  if (userUpdated) {
    if (visible) {
      return (
        <div>
          <div className="col-md-12" id="updatedBanner" style={styles.newsletterbanner}>
            <div className="col-md-12 no-padding">
              <div className="col-md-12 no-padding">
                <p style={styles.updatedText} className="text-center">{I18n.t('register_banner__success')}</p>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  } else {
    if (currentUser && !gdpr) {
      return <UpdateRegistration 
                authenticity_token={props.authenticity_token}
                current_user={props.current_user}
                current_user_id={props.current_user_id} 
                userUpdated={userUpdated}
                handleUpdate={handleUpdate}
                isMobile={isMobile}
                styles={styles}
              />
    } else if (!currentUser && !gdpr) {
      return <UserCreation 
                authenticity_token={props.authenticity_token}
                isMobile={isMobile}
                styles={styles}
                userUpdated={userUpdated}
                handleUpdate={handleUpdate}
              />
    } else {
      return null
    }
  }
}

export default NewsletterWrapper