import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  addCSRFToken,
  setCurrentUser,
  changeLocale,
} from "../../redux/actions/actions";
import I18n from "../../../i18n-js/index.js";
import WidgetVoucherFunnel from "./WidgetVoucherFunnel";
import WidgetCheckout from "./WidgetCheckout";
import { useSelector, useDispatch } from "react-redux";

function WidgetVoucherFunnelTemp(props) {
  const dispatch = useDispatch();
  const csrfToken = useSelector((state) => state.csrfToken);
  const [ready, setReady] = useState(false);

  I18n.locale = props.locale;

  dispatch(addCSRFToken(props.csrf_token));

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;

  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet,
      }),
      changeLocale(props.locale)
    );
  } else {
    dispatch(changeLocale(props.locale));
  }

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path={`/:locale/${I18n.t("routes.create_voucher")}`}>
            <WidgetVoucherFunnel {...props} />
          </Route>

          <Route path={"/:locale/checkout"}>
            <WidgetCheckout {...props} />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default WidgetVoucherFunnelTemp;
