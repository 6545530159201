import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import WidgetFooter from "../../WidgetFooter";
import I18n from "../../../../i18n-js/index.js.erb";
import axios from "axios";
import { changeHotelData, changeLocale, addCSRFToken, setCurrentUser } from "../../../redux/actions/actions";
import WidgetNav from "../../WidgetNav";
import { to_eur, currencyToSymbol } from "../../../../helpers/base";
import moment from "moment";
import ProductBlockFull from "../../../main/products/ProductBlockFull";
import { makeStyles } from "@material-ui/core/styles";
import { createMedia } from "@artsy/fresnel";
import clsx from "clsx";


function Index(props) {
	const hotelUsedData = useSelector((state) => state.hotelUsedData);

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 680,
      lg: 980,
    },
  });

	const dispatch = useDispatch();

  dispatch(changeLocale(props.locale));
  dispatch(addCSRFToken(props.csrf_token));
  const csrfToken = useSelector((state) => state.csrfToken);

  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet,
      })
    );
    dispatch(changeLocale(props.current_user.user_locale));
  } else {
    dispatch(changeLocale(props.locale));
  }


	useEffect(() => {
    const WebFont = require("webfontloader");
    serialized_brand?.font &&
      WebFont.load({
        google: {
          families: [serialized_brand?.font, "sans-serif"],
        },
      });
  }, [serialized_brand?.font]);

	const hotel = props.hotel;
  const area = props.area;
  const product = props.slug;

	const [map, setMap] = useState();
  const [metro, setMetro] = useState();
	const [photos, setPhotos] = useState();
  const [images, setImages] = useState(false);
  const [timeDistance, setTimeDistance] = useState();
	const [province, setProvince] = useState();
  const [city, setCity] = useState(false);
  const [brand, setBrand] = useState(false);
	const currentLocale = useSelector((state) => state.currentLocale);
	const [otherHotels, setOtherHotels] = useState();
	const serialized_brand = props.serialized_brand.data.attributes;

	const handleChangeHotelData = (data) => {
    dispatch(changeHotelData(data));
  };

	const useStyles = makeStyles((theme) => ({
    headings: {
			color: 'black',
			marginBottom: '30px',
      fontFamily:
        brand && brand?.font ? brand?.font : "inherit",
    },
    postFeed: {
      display: 'grid',
      justifyContent: 'space-between',
      gridGap: '30px',
    },
    siteMain: {
      padding: '4vw 0',
    },
    siteHead: {
      paddingTop: '20px',
      paddingBottom: '20px',
      color: '#fff',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    siteBanner: {
      maxWidth: '80%',
      margin: '0 auto',
      padding: '10vw 0',
      textAlign: 'center',
    },
    siteBannerTitle: {
      margin: '0',
      padding: '0',
      color: '#fff',
      fontSize: '4rem',
      lineHeight: '1.3em',
    }
  }));

  const classes = useStyles();

	useEffect(() => {
    if (true) {
      axios
        .get("/app/hotels/show.json", {
          params: { slug: hotel, locale: currentLocale, brand: props.brand },
          "axios-retry": { retries: 2 },
        })
        .then((response) => {
          handleChangeHotelData(response.data);
          setPhotos(response.data.photos);
          setImages(
            response.data.photos.map((photo) => {
              return photo.block_photo;
            })
          );
          setMap(response.data.map);
          setMetro(response.data.metro);
          setTimeDistance(response.data.time_distance);
          setProvince(response.data.province.name);
          setCity(response.data.city.name);
          setBrand(response.data.brand.data.attributes);

          document.title =
            response.data.hotel.listing_name +
            " | " +
            `${response.data.brand.data.attributes.name}`;
        });
    }
		axios
			.get("/app/hotels/index.json", {
				params: { slug: hotel, brand: props.brand },
			})
			.then((response) => {
				setOtherHotels(response.data.all_rooms);
			});
	}, []);

  const page = (col) => {
    return (<>
			<div className="viewport">
				<div className="viewport-top">
					{/* The main header section on top of the screen */}
					<WidgetNav brand={brand} location={"hotel"} />
					<header
						className={classes.siteHead}
						style={{
								...(photos && {
										backgroundImage: `url(${photos[0].block_photo})`,
								}),
						}}
					>
						<div className="container">
							<div className={classes.siteBanner}>
								<h1 className={clsx(classes.siteBannerTitle, classes.headings)}>
									{hotelUsedData?.hotel?.listing_name}
								</h1>
							</div>
						</div>
					</header>

					<main className={classes.siteMain}>
						{/* All the main content gets inserted here, index.js, post.js */}
						<div className="container">
							<h2 className={classes.headings}>{I18n.t("all_experience")}</h2>
							<section className={classes.postFeed} style={{gridTemplateColumns: col}}>
								{hotelUsedData?.products && hotelUsedData?.products.map((product) => (
									// The tag below includes the markup for each post - components/common/PostCard.js
									<ProductBlockFull
                      key={product.id}
                      id={product.id}
                      name={product.attributes.name}
                      block_photo={product.attributes.block_photo}
                      week_price={to_eur(product.attributes.pvp)}
                      listing_name={hotelUsedData.hotel.listing_name}
                      is_direct_booking={product.attributes.direct_booking}
                      star_rating={product.attributes.star_rating}
                      city={product.attributes.room_city}
                      area={props.area}
                      max_guest={product.attributes.max_guest}
                      price_model={product.attributes.price_model}
                      currency={currencyToSymbol(product.attributes.currency)}
                      slug={product.attributes.slug}
                      roomSlug={hotelUsedData.hotel.slug}
                      hotel={hotelUsedData.hotel.listing_name}
                      product_slug={props.product_slug}
                      disabledGiftVoucher={product.attributes.room_disable_gift_voucher}
                      is_new={
                        moment(product.created_at) >
                        moment().subtract(3, "month")
                      }
                      timeDistance={product.attributes.time_distance}
                      selectedDate={props.selectedDate}
                      selectedPax={props.selectedPax}
											modal={true}
											brand={brand}
                      has_calendar={product.attributes.has_calendar}
                    />
								))}
							</section>
						</div>
					</main>
				</div>
			</div>

			<WidgetFooter brand={brand} />
		</>)
  }


	return (
		<MediaContextProvider>
      <Media at="sm">
        {page('1fr')}
      </Media>
      <Media at="md">
         {page('1fr 1fr')}
      </Media>
      <Media at="lg">
        {page('1fr 1fr 1fr')}
      </Media>
    </MediaContextProvider>
	)
};


export default Index;
