import React from "react";
import { useStylesPriceSelection } from "../../styles/stepStyles";
import clsx from "clsx";
import { Input, Label } from "reactstrap";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@mui/material/Button";
import { setStepsVoucherCreationParams } from "../../../../redux/actions/actions";
import axios from "axios";
import { convertDate } from "../../../../../helpers/base";
import Emoji from "a11y-react-emoji";
import PriceBox from './PriceBox'
import I18n from '../../../../../i18n-js/index.js.erb'
import { Cookies, useCookies } from "react-cookie";

function PriceSelection(props) {
  const BASE_URL = props.baseUrl || '';

  const [cookies, setCookie] = useCookies(['variant'])

  const schema = yup
    .object({
      voucherPrice: yup.number().max(1000).required(I18n.t('is_required')).positive(I18n.t('validation_positive_number')),
    })
    .required();
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const voucherPrice = watch("voucherPrice");
  const classes = useStylesPriceSelection();
  const [campaign, setCampaign] = useState({ status: false, discount: 0 });
  const [error, setError] = useState(false);

  const stepsVoucherCreationParams = useSelector(
    (state) => state.stepsVoucherCreationParams
  );

  const onSubmit = (data) => {
    dispatch(
      setStepsVoucherCreationParams({
        ...stepsVoucherCreationParams,
        step1: { status: true, price: data["voucherPrice"] },
        step2: { status: true, print: false, evoucher: true }
      })
    );

    axios
      .post(`${BASE_URL}/api/main/tracking/checkouts/started.json`, {params: {step: 1, step_name: 'price_selection', version: cookies["variant"]}})
      .then((response) => {console.log(response.status)})
      .catch(function (error) {});

    axios
      .post(`${BASE_URL}/api/main/tracking/checkouts/completed.json`, { params: { step: 1, step_name: 'price_selection' } })
      .then((response) => { console.log(response.status) })
      .catch(function (error) { });

    props.handleNext();
  };

  const setVoucherPrice = (price) => () => {
    setValue("voucherPrice", price);
  };

  useEffect(() => {
    if (!stepsVoucherCreationParams.step1.status) {
      axios.get(`${BASE_URL}/api/main/campaigns/funnel_step1.json`).then((response) => {
        setCampaign({
          status: true,
          discount: response.data.data.attributes.discount,
          end_date: response.data.data.attributes.end_date,
        });
      })
        .catch(function (error) {
          setError(error.response.data.error_message)
        });

    axios
      .post(`${BASE_URL}/api/main/tracking/checkouts/viewed.json`, {params: {step: 1, step_name: 'price_selection', version: cookies["variant"]}})
      .then((response) => {console.log(response.status)})
      .catch(function (error) {});


      setValue("voucherPrice", stepsVoucherCreationParams.step1.price);
    }
  }, []);

  const priceBox1 = 50;
  const priceBox2 = 100;
  const priceBox3 = 150;
  const priceBox4 = 200;

  const addExtraPrice = (pricebox) => {
    if (campaign.discount > 0) {
      const discountFloat = campaign.discount / 100;

      return pricebox * discountFloat + pricebox;
    }
  };

  return (
    <div>
      {campaign.status &&
        <div className={classes.discountBox}>
          <Emoji symbol="🎉" className={classes.discountBoxEmoji} />
          <div className={classes.discountBoxElemTitle}>
            <span className={classes.discountBoxTitle}>
              {I18n.t('new_gift__campaign_main', { percent: campaign.discount })}
            </span>
            <small>{I18n.t('new_gift__campaign_subtitle', { date: convertDate(campaign.end_date) })}</small>
          </div>
        </div>
      }

      <section className={classes.priceBoxWrap}>
        <PriceBox
          setVoucherPrice={setVoucherPrice}
          priceBox={priceBox1}
          voucherPrice={voucherPrice}
          campaign={campaign}
          text={I18n.t('new_gift__50_explain')}
          mobile={props.mobile}
        />

        <PriceBox
          setVoucherPrice={setVoucherPrice}
          priceBox={priceBox2}
          voucherPrice={voucherPrice}
          campaign={campaign}
          text={I18n.t('new_gift__100_explain')}
          mobile={props.mobile}
        />

        <PriceBox
          setVoucherPrice={setVoucherPrice}
          priceBox={priceBox3}
          voucherPrice={voucherPrice}
          campaign={campaign}
          text={I18n.t('new_gift__150_explain')}
          mobile={props.mobile}
        />

        <PriceBox
          setVoucherPrice={setVoucherPrice}
          priceBox={priceBox4}
          voucherPrice={voucherPrice}
          campaign={campaign}
          text={I18n.t('new_gift__200_explain')}
          mobile={props.mobile}
        />
        <br />

        {[50, 100, 150, 200].includes(voucherPrice) &&
          <React.Fragment>
            <p style={{ marginTop: "20px" }}>{I18n.t(`new_gift__${voucherPrice}_subtext`)}</p>
          </React.Fragment>
        }

      </section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label className={classes.priceFieldLabel} >{I18n.t('new_gift__other_amount')} </Label>
        <Controller
          name="voucherPrice"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, onChange, ...field } }) => (
            <Input
              {...field}
              onChange={onChange}
              type="number"
              max="1000"
              className={clsx(
                props.mobile ? classes.priceFieldMobile : classes.priceField,
                voucherPrice !== 0 && ` ${classes.selected}`,
                errors.voucherPrice && 'error'
              )
              }
              innerRef={ref}
              step={0.01}
            />
          )}
        />

        <p className="error">{errors.voucherPrice?.message}</p>

        <Button className={clsx("btn btn-primary", props.mobile && classes.fullWidth)} style={{marginTop: '20px'}} type="submit">
          {cookies["variant"] == 0 ? I18n.t('new_gift__amount_button') : I18n.t("new_gift__custom_button")}
        </Button>
      </form>
    </div>
  );
}

export default PriceSelection;
